import axios from "axios";
import { request, client_id, client_secret } from "./base";

const scope = "streaming  user-read-email user-read-private user-modify-playback-state user-read-playback-state user-library-read user-library-modify playlist-read-private"

const redirect_uri = process.env.VUE_APP_AUTHORIZE_URL

export function getAuthorizeURL(state, code_challenge){
    return`https://accounts.spotify.com/en/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`
}
export function requestAuthToken(data) {
    const headers = {'Content-Type': 'application/x-www-form-urlencoded','Authorization': btoa(`${client_id}:${client_secret}`)};
    const params = new URLSearchParams();
    params.append('grant_type', data.grant_type);
    params.append('client_id', client_id);

    if(data.grant_type=='authorization_code'){
        let authorize_code = data.code
        if(!authorize_code){
            authorize_code = localStorage.getItem('authorize_code')
        }
        params.append('code', authorize_code);
        params.append('redirect_uri', redirect_uri);
        params.append('code_verifier', localStorage.getItem('code_verifier'));
    }else{
        let refresh_token = data.refresh_token
        if(!refresh_token){
            refresh_token = localStorage.getItem('spotify_refresh_token')
        }
        params.append('refresh_token', refresh_token);
    }
    return axios.post("https://accounts.spotify.com/api/token", params);
}
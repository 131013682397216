<template>
    <div>
        <div v-for="(thread,i) in message_threads" :key="i" class="flex-horizontal-centered message-item" :class="thread.messagethread==$route.params.id?'active':''" @click="showThreadMessages(thread)">
            <div class="message-member-thumb large">
                <div class="single" v-if="thread.members.upclubusers_count===1">
                    <img :src="getImage(thread.members.upclubusers[0].thumbnail_picture)" alt="">
                </div>
                <div class="double" v-if="thread.members.upclubusers_count===2">
                    <img :src="getImage(thread.members.upclubusers[0].thumbnail_picture)" alt="">
                    <img :src="getImage(thread.members.upclubusers[1].thumbnail_picture)" alt="">
                </div>
                <div class="triple" v-if="thread.members.upclubusers_count===3">
                    <img :src="getImage(thread.members.upclubusers[0].thumbnail_picture)" alt="">
                    <img :src="getImage(thread.members.upclubusers[1].thumbnail_picture)" alt="">
                    <img :src="getImage(thread.members.upclubusers[2].thumbnail_picture)" alt="">
                </div>
                <div class="more" v-if="thread.members.upclubusers_count>3">
                    <img :src="getImage(thread.members.upclubusers[0].thumbnail_picture)" alt="">
                    <img :src="getImage(thread.members.upclubusers[1].thumbnail_picture)" alt="">
                    <img :src="getImage(thread.members.upclubusers[2].thumbnail_picture)" alt="">
                    <img :src="getImage(thread.members.upclubusers[3].thumbnail_picture)" alt="" v-if="thread.members.upclubusers_count>4">
                    <Badge :count="thread.members.upclubusers_count-3" overflow-count="999" v-else class="message-member-badge"></Badge>
                </div>
            </div>
            <div class="message-content">
                <div>
                    <p class="message-members" v-if="thread.messagethreadname">{{ thread.messagethreadname}}</p>
                    <p class="message-members" v-else>{{ getMemberNames(thread.members.upclubusers) | truncate(70, '...')}}</p>
                    <p class="item-message" v-if="thread.latest_message.text">
                        <span v-if="thread.members.upclubusers_count>1">
                            <span v-if="thread.latest_message.upclubuser===getUserDetails.upclubuser">you: </span>
                            <span v-else>{{thread.latest_message.username}}: </span>
                            {{ thread.latest_message.text| truncate(70, '...')}}
                        </span>
                        <span v-else>
                            <span v-if="thread.latest_message.upclubuser===getUserDetails.upclubuser">you: </span>
                            {{ thread.latest_message.text| truncate(70, '...')}}
                        </span>
                    </p>
                    <p class="item-message" v-else-if="!thread.latest_message.text&&thread.messagethread!=='new'">
                         <span v-if="thread.members.upclubusers_count>1">
                            <span v-if="thread.latest_message.upclubuser===getUserDetails.upclubuser">you sent a song</span>
                            <span v-else>{{thread.latest_message.username}} sent a song</span>
                        </span>
                        <span v-else>
                            <span v-if="thread.latest_message.upclubuser===getUserDetails.upclubuser">you sent a song</span>
                            <span v-else>sent a song</span>
                        </span>
                    </p>
                </div>
            </div>
            <div class="message-age">
                <Badge color="red" v-if="!thread.already_read" class="thread-badge" />
                <span>{{thread.notification_age}}</span>
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="getMessageThreads"></infinite-loading>
        <div class="no-data" v-if="showNoData">No messages found</div>
    </div>
</template>

<script>
    import {getMessageThreads, getMessageThreadInfo, searchMessageThreads} from '@/services/upclub/messages'
    import {mapGetters} from "vuex";

    export default {
        props:['search_query'],
        data () {
            return {
                infiniteId:0,
                showNoData:false,
                next_request_uri:null,
                message_threads:[]
            }
        },
        computed: {
            ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
        },
        methods: {
            getMemberNames(members){
                 let names = members.map(member=>{return member.username })
                 return names.join(', ')
            },
            getMessageThreads($state){
                if(this.search_query){
                    this.getSearchMessageThreads($state)
                }else{
                    this.getAllMessageThreads($state)
                }
            },
            showThreadMessages(thread){
                thread.already_read=true
                this.$router.push({name:'message_box',params:{id:thread.messagethread}}).catch(err => {})
            },
            getAllMessageThreads($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                getMessageThreads(queryParams).then(res=>{
                    this.next_request_uri = res.data.next
                    let thread_ids = this.message_threads.map(thread=>{return thread.messagethread})
                    res.data.results.map((thread,index)=>{
                        if(thread_ids.indexOf(thread.messagethread)===-1){
                            this.message_threads.push(thread)
                            if((index+1)===res.data.results.length){
                                if(this.next_request_uri){
                                    $state.loaded();
                                }else{
                                    if(this.message_threads.length===0){
                                        this.showNoData=true
                                    }
                                    $state.complete();
                                }
                            }
                        }
                    })
                    
                })
            },
            getSearchMessageThreads($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                searchMessageThreads(this.search_query,queryParams).then(res=>{
                    this.next_request_uri = res.data.next
                    this.message_threads = this.message_threads.concat(res.data.results)
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        if(this.message_threads.length===0){
                            this.showNoData=true
                        }
                        $state.complete();
                    }
                })
            },
            insertNewThread(new_thread){
                this.message_threads.unshift(new_thread)
            },
            async insertUpdatedThread(new_thread,type){
                let thread_ids = this.message_threads.map(thread=>{return thread.messagethread})
                getMessageThreadInfo(new_thread).then(res=>{
                    let thread_details=res.data
                    let duplicate_index = thread_ids.indexOf(new_thread)
                    if(type==='new_message'){
                        let new_thread_index = thread_ids.indexOf('new')
                        if(duplicate_index>-1){
                            this.message_threads.splice(duplicate_index, 1);
                        }
                        if(new_thread_index>-1){
                            this.message_threads.splice(new_thread_index, 1);
                        }
                        this.message_threads.unshift(thread_details)
                    }
                    if(type==='update_thread'){
                        if(duplicate_index>-1){
                            this.message_threads.splice(duplicate_index, 1, thread_details);
                        }
                    }
                })
            },
             async removeThread(thread_id){
                let thread_ids = this.message_threads.map(thread=>{return thread.messagethread})
                let thread_index = thread_ids.indexOf(thread_id)
                if(thread_index>-1){
                    this.message_threads.splice(thread_index, 1);
                }
            },
            infiniteReload(){
                this.showNoData=false
                this.next_request_uri=null
                this.message_threads=[]
                this.infiniteId+=1
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>

<template>
    <div class="search-item-container">
        <div v-for="(item,i) in trackListing" :key="i" class="search-item" :class="itemClass? itemClass:''">
            <div class="search-item-image"  @click="openTrackModal(item)">
                <img :src="item.album.images[1]['url']" alt="">
            </div>
            <div class="search-item-details"  @click="openTrackModal(item)">
                <div>
                    <h4 class="track-name">{{item.name}}</h4>
                    <p class="track-artist">{{getArtistNames(item.artists)}}</p>
                </div>
            </div>
            <div class="search-item-play flex-vertical-centered">
                <img v-if="!context.paused&&playing_id==item.id" class="trigger-icon"  @click="pause();"   src="../../assets/images/icons/pause.svg" alt="">
                <img v-else @click="playTrack(item)" class="trigger-icon" src="../../assets/images/icons/play.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import { getTrack } from '@/services/spotify/tracks'
    import { play, pause } from "@/services/spotify/player";
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: "SearchTracks",
        props: ["trackList", 'itemClass'],
        data () {
            return {
                playing_id:null,
            }
        },
        components:{ },
        computed: {
            ...mapGetters({
                device_id: "player/getDeviceID",
                playback: "player/getPlayback",
                context: "player/getPlaybackContext",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost"
            }),
           trackListing: function () {
                return this.trackList
            },
        },
        methods: {
            ...mapActions({
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
            }),
            getArtistNames(artists){
                let names = artists.map(artist=>{return artist.name })
                return names.join('  -  ')
            },
            openTrackModal(track){
                this.pause()
                this.$emit('openTrackModal',track)
            },
            playTrack(song){
                getTrack(song.id).then(track=>{
                    let data ={
                        offset: {
                            position: 0
                        },
                        uris: [track.data.uri],
                        position_ms: 0
                    }
                    play(data).then(res=>{
                        this.playing_id=song.id
                        // this.setCurrentPlayingPost({index:this.currentPlayingPost.index, post:null})
                        // localStorage.setItem('current_track_page', this.$route.name)
                        // localStorage.setItem('current_track_list', JSON.stringify([track.data]))
                    })
                })
            },
            pause() {
                if(!this.context.paused&&!this.playback.item.post_details){
                    pause();
                }
            },
        },
        mounted() {
            this.playing_id=null
        }
    }
</script>

<style scoped></style>

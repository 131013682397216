<template>
    <!-- <div class="welcome-wrapper">
        <img class="logo" src="../../assets/images/upclub_logo_large.svg">
        <div>
            <h2>A new way to share and discover music.</h2>
            <Row class="action" :gutter="40">
                <i-col :xs="12">
                    <Button class="btn black-btn" long size="large" @click="$router.push({name:'login'})">Login</Button>
                </i-col>
                <i-col :xs="12">
                    <Button class="btn black-btn" long size="large" @click="$router.push({name:'signup'})">Sign up</Button>
                </i-col>
            </Row>
        </div>
    </div> -->
    <div class="welcome-wrapper">
        <div class="welcome-header">
            <img class="logo" src="../../assets/images/upclub_logo.svg">
        </div>
        <div class="welcome-body">
            <Row  class="welcome-row" :gutter="40" >
                <i-col :xs="12">
                    <div class="app-slogan">
                        <h1>An app designed to share music with friends. </h1><br>
                        <h2>The destination to discover, listen and share. </h2>
                    </div><br><br>
                    <Row class="action" :gutter="10" style="width: 320px;">
                        <i-col :xs="12">
                            <Button class="btn black-btn" long size="large" @click="$router.push({name:'login'})">Login</Button>
                        </i-col>
                        <i-col :xs="12">
                            <Button class="btn black-btn" long size="large" @click="$router.push({name:'signup'})">Sign up</Button>
                        </i-col>
                    </Row>
                </i-col>
                <i-col :xs="12">
                    <Carousel class="onboarding-slides"  v-model="active_slide" autoplay loop autoplay-speed="6000">
                        <CarouselItem class="onboarding-slide slide-1">
                            <!-- image set to background -->
                        </CarouselItem>
                        <CarouselItem class="onboarding-slide slide-2">
                            <div>
                                <img src="../../assets/images/onboarding/discover.png" alt="">
                                <div class="slide-caption">
                                    <h2>Discover</h2>
                                    <p>A live music feed curated by real people.</p>
                                </div>  
                            </div>
                        </CarouselItem>
                        <CarouselItem class="onboarding-slide slide-3">
                            <div>
                                <img src="../../assets/images/onboarding/share.png" alt="">
                                <div class="slide-caption">
                                    <h2>Share</h2>
                                    <p>Post music directly from Spotify and your personal playlists.</p>
                                </div>  
                            </div>
                        </CarouselItem>
                        <CarouselItem class="onboarding-slide slide-4">
                            <div>
                                <img src="../../assets/images/onboarding/connect.png" alt="">
                                <div class="slide-caption">
                                    <h2>Connect</h2>
                                    <p>Send and discuss songs privately with other users and groups.</p>
                                </div>  
                            </div>
                        </CarouselItem>
                        <CarouselItem class="onboarding-slide slide-5">
                            <div>
                                <img src="../../assets/images/onboarding/curate.png" alt="">
                                <div class="slide-caption">
                                    <h2>Curate</h2>
                                    <p>Follow users to curate your feed to your specific tastes.</p>
                                </div>    
                            </div>
                        </CarouselItem>
                    </Carousel>
                </i-col>
            </Row>
        </div>
    </div> 
</template>
<script>
    export default {
        data () {
            return {
                active_slide: 0
            }
        }
    }
</script>
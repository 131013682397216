<template>
    <div class="post-message-box" :class="`type-${posttrack.post_type}`" v-if="posttrack">
        <div class="white-box">
            <div class="track-message flex-horizontal-centered" v-if="posttrack.post_type!==1">
                <img :src="getImage(posttrack.thumbnail_picture)"  @click="goToProfile(posttrack.username)" class="user-thumb"/>
                <div>
                    <div>
                        <a @click="goToProfile(track.username)" class="user-link">{{posttrack.username}}</a>
                        <span class="track-message-age">{{posttrack.post_age}}</span>
                        <div class="track-message-votes">
                            <span>{{posttrack.formatted_likes}}</span>
                            <img src="../../assets/images/icons/up-vote.svg" alt="" >
                        </div>
                    </div>
                    <p class="description" v-if="posttrack.comment">
                        <span v-for="(comment,i) in generateMentionsText(posttrack.comment,posttrack.mentions)" :key="i" 
                        @click="comment.username?goToProfile(comment.username):''" :class="comment.username?'highlight-mention':''">{{comment.username?`@${comment.username}`:comment.text}}</span>
                    </p>
                </div>
            </div>
            <div class="flex-horizontal-centered">
                <div class="message-track-image">
                    <img :src="posttrack.track_details.album.images[1]['url']" alt="">
                    <div class="message-track-play">
                        <div>
                            <img v-if="!context.paused&&playback.item&&playback.item.id === posttrack.track_details.id&&currentPlayingPost.post.song_post===posttrack.song_post" class="trigger-icon"  @click="pauseTrack()" src="../../assets/images/icons/pause.svg" alt="">
                            <img v-else @click="playTrack(false, posttrack.song_post)" class="trigger-icon" src="../../assets/images/icons/play.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="track-details">
                    <p class="track-name" @click="playTrack(true, posttrack.song_post)">{{posttrack.track_details.name | truncate(60, '...')}}</p>
                    <p class="track-singer">{{getArtistNames(posttrack.track_details.artists) | truncate(80, '...')}}</p>
                </div>
            </div>
            <!-- <div class="track-item-vote" :class="track.liked?'active':''" @click="track.liked?UnlikeSongPost():LikeSongPost()">
                <img src="../../assets/images/icons/up-vote.svg" alt="" >
            </div> -->
        </div>
        <!-- <div class="track-message-actions">
            <more-actions :hide_delete="true" :track="track"></more-actions>
            <img src="../../assets/images/icons/share.svg" alt="" class="trigger-icon" @click="openShare()">
            <img src="../../assets/images/icons/comment.svg" alt="" class="trigger-icon" @click="setPlayerSong">                                
        </div> -->
        <share-post-message ref="sharePostToMessage"></share-post-message>  
    </div>
                            
</template>

<script>
    import { getTrack } from '@/services/spotify/tracks'
    import { play, pause, addToQueue, transferUsersPlayback } from "@/services/spotify/player";
    import { likeSongPost,unlikeSongPost } from "@/services/upclub/post"
    import { EventBus } from '@/eventBus';
    import { mapGetters, mapActions } from "vuex";
    import SharePostMessage from "@/components/messages/SharePostMessage"
    import MoreActions from '@/components/track/MoreActions'

    export default {
        props:['message', 'request_page'],
        data () {
            return {
               track:null,
               trackIndex:0,
            }
        },
        computed: {
            ...mapGetters({
                device_id: "player/getDeviceID",
                playback: "player/getPlayback",
                context: "player/getPlaybackContext",
                thread_tracks: "upclubMessages/getThreadTracks",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
            }),
            messageDetails(){
                return this.message
            },
            posttrack(){
                return this.track
            }
        },
        components:{SharePostMessage,MoreActions},
        methods: {
            ...mapActions({
                setCurrentPostList: "upclubPlayer/setCurrentPostList",
                setCurrentPostPage: "upclubPlayer/setCurrentPostPage",
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
                prependCurrentPostList: "upclubPlayer/prependCurrentPostList",
                setThreadTrackList: "upclubMessages/setThreadTrackList"
            }),
            async getPostTrack(){
                this.track=null
                this.trackIndex=0
                getTrack(this.message.message.song).then(async track=>{
                    let posttrack = this.message.message
                    posttrack.track_details = track.data
                    posttrack.order=this.message.id
                    this.track = posttrack
                    let tracks_list = Object.assign([],this.thread_tracks)
                    // if(tracks_list.length&&this.playback&&!this.context.paused){
                    //     addToQueue(track.data.uri)
                    // }
                    this.trackIndex = tracks_list.length
                    await tracks_list.push({request_page:this.request_page.page, message_id:this.message.id, post:this.message.message.song_post, track:posttrack})
                    this.setThreadTrackList(tracks_list)
                    if(this.request_page.last_song===this.message.id){
                        let page_songs = tracks_list.reduce((posts,message)=>{
                            if(message.request_page===this.request_page.page){
                                posts.push(message.track)
                            }
                            return posts
                        },[])
                        this.prependCurrentPostList(page_songs)
                    }
                }).catch(songerr=>{
                    this.track = null
                })
            },
            getArtistNames(artists){
                let names = artists.map(artist=>{return artist.name })
                return names.join('  -  ')
            },
            LikeSongPost(){
                let data = {song_post:this.track.song_post}
                likeSongPost(data).then(res=>{
                    this.track.formatted_likes=res.data.song_post_formatted_likes
                    this.track.liked=true                })
            },
            UnlikeSongPost(){
                let data = {song_post:this.track.song_post}
                unlikeSongPost(data).then(res=>{
                    this.track.formatted_likes=res.data.song_post_formatted_likes
                    this.track.liked=false
                })
            },
            trackUris() {
                let sorted_thread_tracks = this.thread_tracks.sort(function(a, b) { 
                    return - ( a.message_id - b.message_id );
                });
                let sorted_tracks = sorted_thread_tracks.map(track=>{return track.track})
                return sorted_tracks ? sorted_tracks.map((el) => el.uri) : [];
            },
            async playTrack(openMainPlayer, song_post){
                let sorted_thread_tracks = this.thread_tracks.sort(function(a, b) { 
                    return - ( b.message_id - a.message_id );
                });
                let sorted_tracks = sorted_thread_tracks.map(track=>{return track.track})
                let sorted_posts = sorted_thread_tracks.map(track=>{return track.post})
                let postIndex = sorted_posts.indexOf(song_post)

                let data ={
                    offset: {
                        position: 0
                    },
                    uris:[sorted_tracks[postIndex].track_details.uri] ,
                    position_ms: 0
                }
                if(this.playback && this.playback.device.id!==this.device_id){
                    transferUsersPlayback([this.device_id], true)
                }else{
                    play(data).then(res=>{
                        this.setCurrentPlayingPost({index:postIndex, post:sorted_tracks[postIndex]})
                        this.setCurrentPostPage({name:this.$route.name, params:this.$route.params})
                        this.setCurrentPostList({status:'open',list:sorted_tracks})
                        if(openMainPlayer&&this.track.post_type!==1){
                            EventBus.$emit('OPEN_PLAYER', 'play', this.track);
                        }
                    })
                }
            },
            pauseTrack(){
                pause()
            },
            openShare(){
                this.$refs.sharePostToMessage.openModal(this.track.song_post)
            }
        },
        mounted() {
            this.getPostTrack()
        }
    }
</script>

<style scoped>

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueCroppie from 'vue-croppie'
import InfiniteLoading from 'vue-infinite-loading';
import VueCryptojs from 'vue-cryptojs'
import locale from 'view-design/dist/locale/en-US';
import firebaseMessaging from './firebase'
import {clickOutside} from './directives';
// import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

require('./assets/js/spotify-player.js');

import 'croppie/croppie.css'

import 'view-design/dist/styles/iview.css'

import "./assets/css/styles.css"
import "./assets/css/responsive.css"

Vue.config.productionTip = false
Vue.use(ViewUI, { locale });
Vue.use(VueAxios, axios)
Vue.use(VueMoment, {moment,})
Vue.use(VueCroppie)
Vue.use(VueCryptojs)
Vue.use(InfiniteLoading, {
  slots:{
    noMore: '',
    noResults: '',
  }
});
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
      return text.substring(0, length) + suffix;
  } else {
      return text;
  }
});
// Vue.use(PerfectScrollbar)
Vue.directive('click-outside', clickOutside)

Vue.prototype.$messaging = firebaseMessaging
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export function hasPermission(route){
    if(route){
        const user_type = localStorage.getItem('user_type')
        const permission = route.meta.permission ? route.meta.permission : ''

        if(permission==='hasMetricAccess'){
            const USER_METRICS_ACCESS = 0x2;
            if((user_type & USER_METRICS_ACCESS) == USER_METRICS_ACCESS){
                return true
            }else{
                return false
            }
        }else if(permission==='hasBroadcastAccess'){
            const USER_BROADCAST_ACCESS = 0x4;
            if((user_type & USER_BROADCAST_ACCESS) == USER_BROADCAST_ACCESS){
                return true
            }else{
                return false
            }
        }else{
            return true
        }

    }else{
        return false
    }
}
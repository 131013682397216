import Vue from 'vue'
import Vuex from 'vuex'
import spotifyAuth from './modules/spotify/auth'
import player from './modules/spotify/player'
import upclubAuth from './modules/upclub/auth'
import upclubPlayer from './modules/upclub/player'
import upclubMessages from './modules/upclub/messages'
import upclubPushNotif from './modules/upclub/pushnotif'
import spotifyInterceptors from '../services/spotify/interceptors'
import upclubInterceptors from '../services/upclub/interceptors'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    spotifyAuth,
    player,
    upclubAuth,
    upclubPlayer,
    upclubMessages,
    upclubPushNotif
  },
  actions: {
    init({ dispatch }) {
      dispatch("player/init", null, { root: true });
    },
    resetAll({commit}){
      commit("spotifyAuth/reset")
      commit("player/reset")
      commit("upclubAuth/reset")
    },
    resetUpClub({commit}){
      commit("upclubAuth/reset")
    }
  },
  plugins: [spotifyInterceptors, upclubInterceptors],
})

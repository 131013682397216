<template>
    <Modal v-model="searchModal" width="624" @on-visible-change="visibleChange($event)" :title="title" footer-hide :transfer="true" :lock-scroll="false" class-name="post-song white-modal">
        <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <div class="modal-body">
            <div class="search-with-btn">
                <div class="search-spotify">
                    <div class="search-field flex-horizontal-centered">
                        <div class="search-btn">
                            <img src="../../assets/images/icons/search.svg" alt="">
                        </div>
                        <i-input type="text" class="search-input" clearable v-model="search_query" placeholder="Search Spotify" @on-change="searchChanged($event)"></i-input>   
                    </div>
                </div>
                <Button class="round-trigger filter" @click="showSearchTracFilters()" :disabled="!search_query.length">
                    <img src="../../assets/images/icons/filter.svg" alt="">
                </Button>
            </div>
            <div class="track-search-filters" v-if="showTrackFilters">
                <RadioGroup v-model="filter" type="button" class="track-filter-options" @on-change="searchChanged()">
                    <Radio label="all"></Radio>
                    <Radio label="track"></Radio>
                    <Radio label="artist"></Radio>
                    <Radio label="album"></Radio>
                    <Radio label="playlist"></Radio>
                </RadioGroup>
            </div>
            <search-all-track ref="searchAllTrack" v-show="filter=='all'&&search_query.length" :filter="filter" class="modal-search-results" @changeFilter="changeFilter" @goToPlaylist="goToPlaylist" @openTrackModal="openTrackModal"></search-all-track>
            <search-filter-track ref="searchFilterTrack" v-show="filter!='all'&&search_query.length" :filter="filter" class="modal-search-results" @goToPlaylist="goToPlaylist" @openTrackModal="openTrackModal"></search-filter-track>
            <playlist v-show="!search_query.length" ref="playlist" class="modal-search-results" @goToPlaylist="goToPlaylist"></playlist>           
        </div>
    </Modal>
</template>

<script>
    import { play, pause, transferUsersPlayback } from "@/services/spotify/player";
    import { mapGetters, mapActions } from "vuex";
    import Playlist from "./Playlist"
    import SearchAllTrack from "./SearchAllTrack"
    import SearchFilterTrack from "./SearchFilterTrack"

    export default {
        props: ['save_as'],
        data () {
            return {
                loading:false,
                title:'',
                showTrackFilters: false,
                searchModal:false,
                search_query:'',
                idletimer:null,
                filter:null,
            }
        },
        components: {Playlist,SearchAllTrack,SearchFilterTrack},
        computed: {
            ...mapGetters({
                player: "player/getPlayer",
                playback: "player/getPlayback",
                device_id: "player/getDeviceID",
                context: "player/getPlaybackContext",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost"
            }),
        },
        methods: {
            ...mapActions({
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
            }),
            visibleChange(val){
                if(!val){
                    this.showTrackFilters= false
                    this.searchModal=false
                    this.search_query=''
                    this.idletimer=null
                    this.filter=null
                    if(localStorage.hasOwnProperty('prev_playback')){
                        let prev_playback = JSON.parse(localStorage.getItem('prev_playback'))
                        let prev_context = JSON.parse(localStorage.getItem('prev_context'))
                        let prev_post  = JSON.parse(localStorage.getItem('prev_post'))
                        if(prev_playback.item&&prev_playback.item.uri!==this.playback.item.uri){
                            this.player.pause()
                            this.setCurrentPlayingPost(prev_post)
                            if(this.playback && this.playback.device.id!==this.device_id){
                                transferUsersPlayback([this.device_id], true)
                            }
                            let data ={
                                offset: {
                                    position: 0
                                },
                                uris:[prev_playback.item.uri] ,
                                position_ms: prev_context.position
                            }

                            play(data).then(res=>{
                                if(prev_context.paused){
                                    this.player.pause()
                                }
                                localStorage.removeItem('prev_playback')
                            })
                        }
                    }else{
                        this.player.pause()
                    }
                }
                
            },
            pause() {
                if(!this.context.paused&&!this.playback.item.post_details){
                    pause();
                }
            },
            changeFilter(val){
                this.showTrackFilters=true
                this.filter=val
                this.searchChanged()
            },
            openModal(){
                this.searchModal=true
                this.$refs.playlist.reloadInfinite()
                let current_playing_post = localStorage.getItem('current_playing_post')
                if(this.currentPlayingPost.post){
                    localStorage.setItem('prev_playback', JSON.stringify(this.playback))
                    localStorage.setItem('prev_context', JSON.stringify(this.context))
                    localStorage.setItem('prev_post', current_playing_post)
                }
                if(this.save_as==='message'){
                    this.title = 'Send a song'
                }else if(this.save_as==='message_to_all'){
                    this.title = 'Send a song to all'
                }else{
                    this.title = 'Post a song'
                }
            },
            goToPlaylist(source, details){
                this.$emit('openPostPlaylistModal',source, details)
            },
            openTrackModal(track){
                this.$emit('openTrackModal',track)
            },
            searchChanged(){
                if(this.search_query.length){
                    if(!this.filter){
                        this.filter='all'
                    }
                    if(this.filter==='all'){
                        this.$refs.searchAllTrack.searchChanged(this.search_query, this.filter)
                    }else{
                        this.$refs.searchFilterTrack.searchChanged(this.search_query, this.filter)
                    }
                }else{
                    this.filter=null
                    this.showTrackFilters=false
                }
            },
            showSearchTracFilters(){
                this.showTrackFilters=!this.showTrackFilters
                if(this.showTrackFilters){
                    if(!this.filter){
                        this.filter='all'
                    }else{
                        if(!this.search_query.length){
                            this.filter=null
                        }
                    }
                }else{
                    if(!this.search_query.length){
                        this.filter=null
                    }
                }
            }
      
        },
        mounted() {
        },
        destroy(){
        }
    }
</script>

<style scoped>
 .round-trigger{
    box-shadow: none;
 }
</style>

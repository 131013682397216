
<template>
    <div class="followedclubs-content">
        <div v-if="!showNoData">
            <div class="display-grid clubs-grid-list">
                <club-card v-for="(club,i) in clubs" :key="i" :club="club" @manageCurators="manageCurators" @inviteCurators="inviteCurators"></club-card>
                 <!-- to fill empty space (max 4 colums) -->
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="getClubs"></infinite-loading> 
    </div>
</template>

<script>
    import { getFollowedClubs } from '@/services/upclub/clubs'
    import ClubCard from '@/components/clubs/ClubCard'
    import {mapGetters} from 'vuex'
    export default {
        props:['searchstring', 'category'],
        data () {
            return {
                dataloading:false,
                next_request_uri:null,
                clubs_count:null,
                clubs:[],
                infiniteId:0,
            }
        },
        components: {ClubCard},
        computed: {
            ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
            showNoData(){
                return !this.dataloading&&this.clubs_count===0
            },
            searchIsActive(){
                return this.searchstring&&this.searchstring.length>0?true:false
            }
        },
        methods: {
            getResults(){
                this.dataloading=true
                this.$emit('setLoadingStatus',true)
                this.next_request_uri=null
                this.clubs=[]
                this.infiniteId+=1
            },
            resetData(){
                this.next_request_uri=null
                this.clubs=[]
                this.$emit('resetFilters')
            },
            reloadData(){
                this.resetData()
                this.infiniteId+=1
            },
            async getClubs($state){
                let queryParams = {upclubuser:this.getUserDetails.upclubuser}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                if(this.searchstring&&this.searchstring.length){
                    queryParams['searchstring'] = this.searchstring
                }
                if(this.category){
                    queryParams['category'] = this.category
                }
                getFollowedClubs(queryParams).then(res=>{
                    this.clubs_count=res.data.count
                    this.next_request_uri = res.data.next
                    this.clubs=this.clubs.concat(res.data.results)
                    this.dataloading=false
                    this.$emit('setLoadingStatus',false)
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            manageCurators(club){
                this.$emit('manageCurators',club)
            },
            inviteCurators(club){
                this.$emit('inviteCurators',club)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>



<template>
    <div  class="search-track-container">
        <track-list ref="postPlaylist" v-show="filter==='track'" :trackList="trackList" :itemClass="'white-box'" @openTrackModal="openTrackModal"></track-list>
        <source-list ref="postSourceList" v-show="filter!=='track'" :source="filter" :sourceList="sourceList" :itemClass="'white-box'" @goToPlaylist="goToPlaylist"></source-list>
        <infinite-loading @infinite="getSearchData" :identifier="infiniteId"></infinite-loading>              
    </div>
</template>

<script>
    const shortNum = require('number-shortener')

    import {getMyTracks} from '@/services/spotify/playlist'
    import {search} from '@/services/spotify/search'
    import TrackList from '@/components/trackSearchList/Tracks'
    import SourceList from '@/components/trackSearchList/TrackSource'
    export default {
        data () {
            return {
                infiniteId:0,
                idletimer:null,
                searchVal:"",
                filter:null,
                offset:0,
                trackList:[],
                sourceList:[]
            }
        },
        components: {TrackList,SourceList},
        methods: {
            goToPlaylist(source,details){
                this.$emit('goToPlaylist',source,details)
            },
            openTrackModal(track){
                this.$emit('openTrackModal',track)
            },
            searchChanged(query,filter){
                this.searchVal=query
                this.filter=filter
                clearTimeout(this.idletimer);
                if(filter!==this.filter){
                    this.idletimer =  setTimeout(async()=>{ 
                        this.trackList=[]
                        this.sourceList=[]
                        this.offset=0
                        this.infiniteId += 1
                    }, 600);
                }else{
                    this.trackList=[]
                    this.sourceList=[]
                    this.offset=0
                    this.infiniteId += 1
                }
            },
            getSearchData($state){
                if(this.searchVal===""){
                    this.getMyTracks($state)
                }else{
                    if(this.filter==="artist"){
                        this.getArtistResults($state)
                    }else if(this.filter==="album"){
                        this.getAlbumResults($state)
                    }else if(this.filter==="playlist"){
                        this.getPlaylistResults($state)
                    }else{
                        this.getTrackResults($state)
                    }
                }
            },
            getMyTracks($state){
                getMyTracks(this.offset,10).then(res=>{
                    if(res.data.items.length>0){
                        this.offset+=10
                        let tracks_arr = res.data.items.map(track=>{return track.track})
                        this.trackList = this.trackList.concat(tracks_arr)
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            getTrackResults($state){
                search(this.searchVal,this.filter,this.offset,10).then(res=>{
                    if(res.data.tracks.items.length>0){
                        this.offset+=10
                        this.trackList = this.trackList.concat(res.data.tracks.items)
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            getArtistResults($state){
                search(this.searchVal,this.filter,this.offset,10).then(res=>{
                    if(res.data.artists.items.length>0){
                        let artists = res.data.artists.items.map((artist,index)=>{
                            let artist_data = {
                                id:artist.id,
                                image:null,
                                name:artist.name,
                                info:`${shortNum(artist.followers.total)} ${artist.followers.total>1?'followers':'follower'}`,
                                artist: artist
                            }
                            if(artist.images.length){
                                artist_data.image=artist.images[0]['url']
                            }
                            return artist_data
                        })
                        this.sourceList = this.sourceList.concat(artists)
                        this.offset+=10
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            getAlbumResults($state){
                search(this.searchVal,this.filter,this.offset,10).then(res=>{
                    if(res.data.albums.items.length>0){
                        let albums = res.data.albums.items.map((album,index)=>{
                            let album_data = {
                                id:album.id,
                                image:null,
                                name:album.name,
                                info:`${shortNum(album.total_tracks)} ${album.total_tracks>1?'songs':'song'}`,
                                album:album
                            }
                            if(album.images.length){
                                album_data.image=album.images[0]['url']
                            }
                            return album_data
                        })
                        this.sourceList = this.sourceList.concat(albums)
                        this.offset+=10
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            getPlaylistResults($state){
                search(this.searchVal,this.filter,this.offset,10).then(res=>{
                    if(res.data.playlists.items.length>0){
                        let playlists = res.data.playlists.items.map((playlist,index)=>{
                            let playlist_data = {
                                id:playlist.id,
                                image:null,
                                name:playlist.name,
                                info:`${shortNum(playlist.tracks.total)} ${playlist.tracks.total>1?'songs':'song'}`,
                                playlist:playlist
                            }
                            if(playlist.images.length){
                                playlist_data.image=playlist.images[0]['url']
                            }
                            return playlist_data
                        })
                        this.sourceList = this.sourceList.concat(playlists)
                        this.offset+=10
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            }
        },
        mounted() {

        },
        created(){
        }
    }
</script>

<style scoped></style>

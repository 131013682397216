import {requestAuthToken} from "@/services/spotify/auth";
import {logout} from "@/services/upclub/auth";
import {setSession, removeSession} from "@/services/authentication.service";
import { EventBus } from '@/eventBus';
import store from "@/store";
import Vue from 'vue'

const initialState = {
    authorizeState:"",
    accessToken: "",
    refreshToken: "",
    expiresIn: ""
};

const state = Object.assign({},initialState);

const getters = {
    getAuthorizeState: (state) => state.authorizeState,
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
    getExpiresIn: (state) => state.expiresIn
};

const mutations = {
    SET_AUTH_STATE(state, authState) {
        state.authorizeState = authState;
    },
    SET_ACCESS_TOKEN(state, token) {
        state.accessToken = token;
    },
    SET_REFRESH_TOKEN(state, token) {
        state.refreshToken = token;
    },
    SET_EXPIRES_IN(state, expiry) {
        state.expiresIn = expiry;
    },
    reset (state) {
        localStorage.removeItem('spotify_access_token')
        localStorage.removeItem('spotify_refresh_token')
        localStorage.removeItem('spotify_expires_in')
        localStorage.removeItem('spotify_authenticated')
        localStorage.removeItem('authorize_code')
        localStorage.removeItem('authorize_state')
        localStorage.removeItem('code_verifier')

        const s = initialState
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    }
};

const actions = {
    getAuthToken: async function({ commit, rootGetters, dispatch },data) {
        return new Promise((resolve, reject) => {

            if(data.grant_type==='refresh_token'){
                EventBus.$emit('DEVICE_RESPONSE', 'not_ready');
            }
            requestAuthToken(data).then(async res=>{
                const accessToken = res.data.access_token;
                const refreshToken = res.data.refresh_token;
                const expiresIn = res.data.expires_in;

                localStorage.setItem('spotify_access_token',accessToken)
                localStorage.setItem('spotify_refresh_token',refreshToken)
                localStorage.setItem('spotify_expires_in',expiresIn)
                let upclub_user_details = rootGetters["upclubAuth/getUserDetails"]
                if(rootGetters["upclubAuth/getUserDetails"].upclubuser){
                    const encryptedRefreshToken = Vue.CryptoJS.AES.encrypt(`${upclub_user_details.username}:${refreshToken}`, "P-SAE*JQsb359kUjKUuZ@ZUC-yw3Srjz").toString()
                    localStorage.setItem('upclub_spotify_access',encryptedRefreshToken)               
                }
                
                commit("SET_ACCESS_TOKEN", accessToken);
                dispatch("setAccessToken", accessToken);
                commit("SET_REFRESH_TOKEN", refreshToken);
                dispatch("setRefreshToken", refreshToken);
                commit("SET_EXPIRES_IN", expiresIn);
                dispatch("setExpiresIn", expiresIn);
                setSession('spotify_authenticated')
                dispatch("player/init", null, { root: true });
                if(data.grant_type==='refresh_token'){
                    EventBus.$emit('DEVICE_RESPONSE', 'ready');
                }
                resolve()
            }).catch(async err=>{
                commit("reset");
                reject(err)
            })
        })
        
    },
    
    setAuthState({ commit }, authState) {
        commit("SET_AUTH_STATE", authState);
    },
    setAccessToken({ commit, dispatch }, token) {
        commit("SET_ACCESS_TOKEN", token);
    },
    setRefreshToken({ commit }, token) {
        commit("SET_REFRESH_TOKEN", token);
    },
    setExpiresIn({ commit }, expiry) {
        commit("SET_EXPIRES_IN", expiry);
    },
};

const module = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default module;

<template>
    <Dropdown class="white-dropdown" @on-visible-change="onChange" transfer>
        <a href="javascript:void(0)">
            <img class="more-icon" src="../../assets/images/icons/more.svg" alt="">
        </a>
        <DropdownMenu slot="list" class="white-box more-actions">
            <DropdownItem v-if="!in_spotify" @click.native="saveTrackSpotify()">Add song to Spotify</DropdownItem>
            <DropdownItem v-else class="delete" @click.native="deleteTrackSpotify()">Remove song from Spotify</DropdownItem>
            <DropdownItem class="delete" v-if="userDetails.upclubuser===post.upclubuser&&!hide_delete&&post.post_type!==1"  @click.native="showDeleteModal=true">Delete song</DropdownItem>
            <DropdownItem @click.native="postLink('copy')">Copy link</DropdownItem>
            <DropdownItem @click.native="openShare()">Share</DropdownItem>
            <!-- <DropdownItem v-if="hasBroadcastAccess" @click.native="showSendAllModal=true">Send to all</DropdownItem> -->
            <DropdownItem v-if="hasBroadcastAccess" @click.native="showBroadcastModal=true">Broadcast Post</DropdownItem>
            <DropdownItem>Cancel</DropdownItem>
        </DropdownMenu>
        <Modal v-model="showDeleteModal" width="300" footer-hide class-name="confirm-modal delete-post white-modal">
            <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
            <p slot="header">
                <span>Delete song?</span>
            </p>
            <div class="action-buttons">
                <Button size="large" long class="btn black-btn" @click="showDeleteModal=false">Cancel</Button><br>
                <Button size="large" long class="btn black-btn" @click="deletePost(post.song_post)" :loading="deleting">
                    <span v-if="!deleting">Delete</span>
                </Button>
            </div>
        </Modal>

         <!-- BROADCAST POST MODAL -->
        <Modal v-model="showBroadcastModal" width="400" footer-hide class-name="confirm-modal broadcast-post white-modal">
            <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
            <p slot="header">
                <span>Broadcast Post</span>
            </p>
            <Form class="broadcast-message-form white-form" ref="broadcastMessageForm" :model="broadcast" :rules="broadcastRules" @keyup.enter.native="broadcastPost()">
                <FormItem prop="text_message">
                    <label for="">Message (1000 characters limit)</label>
                    <i-input type="textarea" v-model="broadcast.text_message" :autosize="{minRows: 5,maxRows: 5}"></i-input>
                </FormItem>
            </Form>
            <Row type="flex" :gutter="8" class="action-buttons" >
                <i-col :xs="12">
                    <Button size="large" long class="btn black-btn" @click="showBroadcastModal=false">Cancel</Button><br>
                </i-col>
                <i-col :xs="12">
                    <Button size="large" long class="btn black-btn" @click="broadcastPost()" :loading="broadcasting">
                        <span v-if="!broadcasting">Broadcast Post</span>
                    </Button>
                </i-col>
            </Row>
        </Modal>

        <!-- SEND TO ALL MODAL -->
        <Modal v-model="showSendAllModal" width="400" footer-hide class-name="confirm-modal send-all-post white-modal">
            <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
            <p slot="header">
                <span>Send Post to all?</span>
            </p>
            <p>Are you sure you want to send song to all UpClub members?</p><br>
            <Row type="flex" :gutter="8" class="action-buttons" >
                <i-col :xs="12">
                    <Button size="large" long class="btn black-btn" @click="showSendAllModal=false">Cancel</Button><br>
                </i-col>
                <i-col :xs="12">
                    <Button size="large" long class="btn black-btn" @click="sendAllPost()" :loading="sendingToAll">
                        <span v-if="!sendingToAll">Send to all</span>
                    </Button>
                </i-col>
            </Row>
        </Modal>
        <share-post-message ref="sharePostToMessage"></share-post-message>  
    </Dropdown>
</template>

<script>
    import { mapGetters } from "vuex";
    import { deleteSongPost } from "@/services/upclub/post";
    import { postPushNotification, postSendToAllMessage } from "@/services/upclub/profile";
    import { myTrackContains, saveTrack, deleteTrack } from "@/services/spotify/me";
    import SharePostMessage from "@/components/messages/SharePostMessage"
    import { EventBus } from '@/eventBus'

    export default {
        props: {
            post:{
                upclubuser:null,
                post_type:null,
                track_details:{
                }
            },
            postIndex:null,
            hide_delete:false
        },
        data () {
             const validateMaxWords = (rule, value, callback) => {
                let char_count = value.length;
                 if (value && char_count>1000) {
                    callback(new Error('Maximum of 1000 characters'));
                } else {
                    callback();
                }
            };
            return {
                in_spotify:false,
                showDeleteModal:false,
                deleting:false,
                showBroadcastModal:false,
                broadcasting:false,
                broadcast:{
                    text_message:""
                },
                broadcastRules: {
                    text_message: [
                        { validator: validateMaxWords, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                    ],
        
                },
                showSendAllModal:false,
                sendingToAll:false
            }
        },
        components:{SharePostMessage},
        computed:{
            ...mapGetters({
                userDetails: "upclubAuth/getUserDetails"
            }),
            hasBroadcastAccess(){
                const user_type = this.userDetails.user_type
                const USER_BROADCAST_ACCESS = 0x4;
                return (user_type & USER_BROADCAST_ACCESS) == USER_BROADCAST_ACCESS;
            }
        },
        methods: {
            onChange(val){
                if(val){
                    myTrackContains(this.post.track_details.id).then(res=>{
                        this.in_spotify=res.data[0]
                    })
                }
            },
            broadcastPost(){
                this.$refs['broadcastMessageForm'].validate(async(valid) => {
                    if (valid) {
                        this.broadcasting=true
                        this.broadcast.song_post = this.post.song_post
                        postPushNotification(this.broadcast).then(res=>{
                            this.broadcasting=false
                            this.showBroadcastModal=false
                            this.broadcast={
                                text_message:""
                            }
                            this.$refs['broadcastMessageForm'].resetFields();
                            this.$Notice.success({
                                desc: `Post successfully broadcasted`
                            });
                        })
                    }
                })
            },
            sendAllPost(){
                this.sendingToAll=true
                postSendToAllMessage({song_post:this.post.song_post}).then(res=>{
                    this.sendingToAll=false
                    this.showSendAllModal=false
                    this.$Notice.success({
                        desc: `Post successfully sent to all`
                    });
                })
            },
            saveTrackSpotify(){
                saveTrack(this.post.track_details.id).then(res=>{
                    this.in_spotify=true
                    this.$Notice.success({
                        desc: `Song now added to your Spotify "Liked songs"`
                    });
                })
            },
            deleteTrackSpotify(){
                deleteTrack(this.post.track_details.id).then(res=>{
                    this.$Notice.success({
                        desc: `Song now removed from your Spotify "Liked songs"`
                    });
                })
            },
            deletePost(id){
                this.deleting=true
                deleteSongPost(id).then(res=>{
                    this.deleting=false
                    this.showDeleteModal=false
                    this.$emit('removePost',this.postIndex)
                    if(this.$route.name==='profile_posts'){
                        EventBus.$emit('POSTS_UPDATED');
                    }
                })
            },
            openShare(){
                this.$refs.sharePostToMessage.openModal(this.post.song_post)
            },
            postLink(method){
                let post_id = this.post.song_post.toString()
                // const encryptedPostID = btoa(`BB33Lj9Q9JWqWUzyP9ka7jmZXbrLTtmk:${post_id}`)
                var url = process.env.VUE_APP_BASE_URL+'discover/?share_post='+post_id;
                if(method=='copy'){
                    navigator.clipboard.writeText(url);
                    this.$Notice.success({
                        desc: `Post link copied`
                    });
                }else{
                    return url
                }
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
.white-dropdown{
    position: relative;
}
</style>
<template>
    <div class="list-track-details flex-vertical-spacebetween">
        <div class="track-list-image">
           <img :src="post.track_details.album.images[1]['url']" alt="">
           <div class="track-overlay-play">
               
                <div v-if="playback&&playback.item&&currentPlayingPost.post&&currentPlayingPost.post.song_post===post.song_post&&!context.paused&&playback.item" class="trigger-icon-container"  @click="pauseTrack();">
                    <img src="../../assets/images/icons/pause.svg" alt="">
                </div>
                <div v-else @click="playTrack(post)" class="trigger-icon-container">
                    <img src="../../assets/images/icons/play.svg" alt="">
                </div>
            </div>
        </div>
        <div class="track-list-details">
            <div>
                <p class="track-name" @click="openMainPlayer('play',post)">{{post.track_details.name | truncate(60, '...')}}</p>
                <p class="track-singer" @click="openMainPlayer('play',post)">{{getArtistNames(post.track_details.artists) | truncate(80, '...')}}</p>
            </div>
            <div class="list-play-pause">
                <img v-if="playback&&playback.item&&currentPlayingPost.post&&currentPlayingPost.post.song_post===post.song_post&&!context.paused&&playback.item" class="trigger-icon"  @click="pauseTrack()" src="../../assets/images/icons/pause.svg" alt="">
                <img v-else @click="playTrack(post)" class="trigger-icon" src="../../assets/images/icons/play.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import { play, pause, transferUsersPlayback } from "@/services/spotify/player";
    import { EventBus } from '@/eventBus';
    import { mapGetters, mapActions } from "vuex";
    import Action from './Actions'
    export default {
        props:["page", "post", "postIndex", "postListing"],
        data () {
            return {
            }
        },
        components:{ Action },
        computed: {
            ...mapGetters({
                device_id: "player/getDeviceID",
                playback: "player/getPlayback",
                context: "player/getPlaybackContext",
                player: "player/getPlayer",
                currentPostList: "upclubPlayer/getCurrentPostList",
                currentPostPage: "upclubPlayer/getCurrentPostPage",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
                playerRepeat: "upclubPlayer/getPlayerRepeat"
            }),
        },
        methods: {
            ...mapActions({
                setCurrentPostList: "upclubPlayer/setCurrentPostList",
                setCurrentPostPage: "upclubPlayer/setCurrentPostPage",
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
                setPlayerRepeat: "upclubPlayer/setPlayerRepeat"
            }),
            getArtistNames(artists){
                 let names = artists.map(artist=>{return artist.name })
                 return names.join('  -  ')
            },
            removePost(index){
                this.$emit("removePost",index)
            },
            openMainPlayer(type,post){
                EventBus.$emit('OPEN_PLAYER', type,post);
                this.playTrack(post)
            },
            playTrack(post){
                if(this.playerRepeat==='track'){
                    this.setPlayerRepeat('all')
                }
                let data ={
                    offset: {
                        position: 0
                    },
                    uris: [post.track_details.uri],
                    position_ms: 0
                }
                if(this.playback && this.playback.item && this.playback.device.id!==this.device_id){
                    transferUsersPlayback([this.device_id], true)
                }else{
                    let postList = Object.assign([], this.postListing)
                    let postIds = postList.map(post=>{return post.song_post})
                    let postIndex = postIds.indexOf(post.song_post)
                    this.setCurrentPostList({status:'open', list:postList})
                    this.setCurrentPostPage({name:this.$route.name, params:this.$route.params})
                    this.setCurrentPlayingPost({index:postIndex, post:post})
                    play(data)
                }
            },
            pauseTrack(){
                // this.player.pause()
                pause()
            },
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

<template>
    <div class="search-all-results">
        <div class="no-data" v-if="showNoData">No results found</div>
        <div v-show="users.length">
            <div class="all-filter-label">
                <p>Users</p>
                <a class="gray-text" @click="$emit('getChangeFilter', 'users')" v-if="user_count>4">See More</a>
            </div>
            <div class="search-results-list users flex-inline-columns four-cols">
                <user v-for="(user,i) in users" :key="i" :user="user"></user>
                <!-- to fill empty space (max 4 colums) -->
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
            </div>
        </div>
        <div v-show="posts.length">
            <div class="all-filter-label">
                <p>Posts</p>
                <a class="gray-text" @click="$emit('getChangeFilter', 'posts')" v-if="post_count>4">See More</a>
            </div>
            <post-list ref="PostListing" class="search-results-list posts"></post-list>
        </div>
        <div v-show="clubs.length">
            <div class="all-filter-label">
                <p>Clubs</p>
                <a class="gray-text" @click="$emit('getChangeFilter', 'clubs')" v-if="club_count>4">See More</a>
            </div>
            <div class="search-results-list clubs display-grid clubs-grid-list" v-show="clubs.length">
                <club-card v-for="(club,i) in clubs" :key="i" :club="club"></club-card>
                 <!-- to fill empty space (max 4 colums) -->
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="getAllResults" v-if="query"></infinite-loading>              
    </div>
</template>

<script>
    import {searchUsername} from '@/services/upclub/profile'
    import {searchPostSong} from '@/services/upclub/post'
    import {getAllClubs} from '@/services/upclub/clubs'

    import { getTracks } from '@/services/spotify/tracks'
    import PostList from '@/components/postList'
    import User from '@/components/user'
    import ClubCard from '@/components/clubs/ClubCard'

    export default {
        data () {
            return {
                posts:[],
                users:[],
                clubs:[],
                post_count:null,
                user_count:null,
                club_count:null,
                userComplete:false,
                postComplete:false,
                clubComplete:false,
                infiniteId:0,
                query: null,
            }
        },
        components: {PostList,User,ClubCard},
        computed: {
            showNoData(){
                return this.post_count===0&&this.user_count===0&&this.club_count===0
            }
        },
        methods: {
            getResults(query){
                this.post_count=null
                this.user_count=null
                this.club_count=null
                if(query){
                    this.$refs.PostListing.emptyList()
                    this.posts=[]
                    this.users=[]
                    this.clubs=[]
                    this.userComplete=false
                    this.postComplete=false
                    this.clubComplete=false
                    this.query=query
                    this.infiniteId+=1
                }else{
                    this.setRecent()
                }
            },
            getAllResults($state){
                this.getUsers($state)
                this.getPosts($state)
                this.getClubs($state)
            },
            getUsers($state){
                searchUsername(this.query).then(res=>{
                    this.user_count=res.data.count
                    this.users=res.data.results.slice(0, 4)
                    this.userComplete=true
                    if(!localStorage.hasOwnProperty('recent_user_search')){
                        localStorage.setItem('recent_user_search', JSON.stringify(this.users))
                    }else{
                        let recent = JSON.parse(localStorage.getItem('recent_user_search'))
                        recent = this.users.concat(recent)
                        recent = recent.filter((user, index, array) => array.findIndex(u => u.upclubuser == user.upclubuser) == index);
                        if(recent.length>4){
                            recent = recent.slice(0, 4);
                        }
                        localStorage.setItem('recent_user_search', JSON.stringify(recent))
                    }
                    if(this.userComplete&&this.postComplete){
                        $state.complete()
                    }
                })
            },
            getClubs($state){
                getAllClubs({category:'mostpopular',searchstring:this.query}).then(res=>{
                    this.club_count=res.data.count
                    this.clubs=res.data.results.slice(0, 5)
                    this.clubComplete=true
                    if(!localStorage.hasOwnProperty('recent_club_search')){
                        localStorage.setItem('recent_club_search', JSON.stringify(this.clubs))
                    }else{
                        let recent = JSON.parse(localStorage.getItem('recent_club_search'))
                        recent = this.clubs.concat(recent)
                        recent = recent.filter((club, index, array) => array.findIndex(u => u.club == club.club) == index);
                        if(recent.length>5){
                            recent = recent.slice(0, 5);
                        }
                        localStorage.setItem('recent_club_search', JSON.stringify(recent))
                    }
                    if(this.clubComplete&&this.postComplete){
                        $state.complete()
                    }
                })
            },
            getPosts($state){
                searchPostSong(this.query).then(res=>{
                    this.post_count=res.data.count
                    if(res.data.count>0){
                        let posts=res.data.results.slice(0, 4)
                        let ids = posts.reduce((ids,post)=>{
                            if(post.post_type!=1){
                                ids.push(post.song)
                            }
                            return ids
                        },[])
                        ids = ids.join(",")
                        getTracks(ids).then(async track=>{
                            let songs = track.data.tracks.filter(function (item) {return item != null;});
                            let posttracks = posts.reduce((postTracks, post)=>{
                                let song = songs.filter(song=>{if(post.song===song.id) return song})
                                let order = this.posts.length+postTracks.length
                                if(song.length){
                                    post.order = order
                                    post.track_details = song[0]
                                    postTracks.push(post)
                                }
                                return postTracks
                            },[])
                            posttracks = posttracks.slice(0, 4)
                            this.posts = posttracks
                            this.$refs.PostListing.addToList(posttracks)
                            if(!localStorage.hasOwnProperty('recent_post_search')){
                                localStorage.setItem('recent_post_search', JSON.stringify(posttracks))
                            }else{
                                let recent = JSON.parse(localStorage.getItem('recent_post_search'))
                                posttracks.forEach(post => {
                                    let index = recent.findIndex(x => x.song_post === post.song_post);
                                    if(index==-1){
                                        recent.unshift(post)
                                        if(recent.length>4){
                                            recent = recent.slice(0, 4);
                                        }
                                        localStorage.setItem('recent_post_search', JSON.stringify(recent))
                                    } 
                                });    
                            }
                            this.postComplete=true
                            if(this.userComplete&&this.postComplete){
                                $state.complete()
                            }
                        }).catch(err=>{
                            this.postComplete=true
                            if(this.userComplete&&this.postComplete){
                                $state.complete()
                            }
                        })
                    }else{
                        this.postComplete=true
                        if(this.userComplete&&this.postComplete){
                            $state.complete()
                        }
                    }
                })
            },
            getSongPost(posts, song){
                let post = posts.filter(post=>{if(post.song===song) return post})
                return post[0]
            },
            async setRecent(){
                if(localStorage.hasOwnProperty('recent_user_search')){
                    let recent = JSON.parse(localStorage.getItem('recent_user_search'))
                    this.users = recent.slice(0, 4)
                }
                 if(localStorage.hasOwnProperty('recent_club_search')){
                    let recent = JSON.parse(localStorage.getItem('recent_club_search'))
                    this.clubs = recent.slice(0, 5)
                }
                if(localStorage.hasOwnProperty('recent_post_search')){
                    let recent = JSON.parse(localStorage.getItem('recent_post_search'))
                    this.posts = recent.slice(0, 4)
                    this.$refs.PostListing.emptyList()
                    this.$refs.PostListing.addToList(recent.slice(0, 4))
                }
                // if(!localStorage.hasOwnProperty('recent_user_search')&&!localStorage.hasOwnProperty('recent_post_search')&&!localStorage.hasOwnProperty('recent_club_search')){
                //     this.showNoData=true
                // }else{
                //     this.showNoData=false
                // }
            }
        },
        mounted() {
            this.setRecent()
        }
    }
</script>

<style scoped></style>

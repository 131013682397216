<template>
    <div class="search-results">
        <div class="no-data" v-if="showNoData">No results found</div>
        <div class="search-results-list users flex-inline-columns four-cols" v-show="users.length">
            <user v-for="(user,i) in users" :key="i" :user="user"></user>
            <!-- to fill empty space (max 4 colums) -->
            <div class="space-filler"></div>
            <div class="space-filler"></div>
            <div class="space-filler"></div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="getUsers"></infinite-loading>              
    </div>
</template>

<script>
    import {searchUsername} from '@/services/upclub/profile'
    import User from '@/components/user'

    export default {
        data () {
            return {
                next_request_uri:null,
                showNoData:false,
                users:[],
                infiniteId:0,
                query: null,
            }
        },
        components: {User},
        methods: {
            getResults(query){
                this.showNoData=false
                if(query){
                    this.next_request_uri=null
                    this.users=[]
                    this.query=query
                    this.infiniteId+=1
                }else{
                    this.setRecent()
                }
            },
            getUsers($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                searchUsername(this.query,queryParams).then(res=>{
                    this.next_request_uri = res.data.next
                    this.users=this.users.concat(res.data.results)
                    if(!localStorage.hasOwnProperty('recent_user_search')){
                        localStorage.setItem('recent_user_search', JSON.stringify(this.users))
                    }else{
                        let recent = JSON.parse(localStorage.getItem('recent_user_search'))
                        recent = this.users.concat(recent)
                        recent = recent.filter((user, index, array) => array.findIndex(u => u.upclubuser == user.upclubuser) == index);
                        if(recent.length>4){
                            recent = recent.slice(0, 4);
                        }
                        localStorage.setItem('recent_user_search', JSON.stringify(recent))
                    }
                    if(this.next_request_uri){
                        if(this.users.length){
                            this.showNoData=false
                        }
                        $state.loaded();
                    }else{
                        if(this.users.length===0){
                            this.showNoData=true
                        }else{
                            this.showNoData=false
                        }
                        $state.complete();
                    }
                })

            },
            async setRecent(){
                if(localStorage.hasOwnProperty('recent_user_search')){
                    let recent = JSON.parse(localStorage.getItem('recent_user_search'))
                    this.users = recent.slice(0, 4)
                }
                if(!localStorage.hasOwnProperty('recent_user_search')&&!localStorage.hasOwnProperty('recent_post_search')){
                    this.showNoData=true
                }
            }
        },
        mounted() {
            this.setRecent()
        }
    }
</script>

<style scoped></style>

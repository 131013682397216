import { request } from "./base";

export function getNotifCount() {
    return request.get("v1/getnotificationscount/");
}

export function getNotifications(params) {
    return request.get("v1/getnotifications", {params});
}

export function clearNotifications() {
    return request.post("v1/clearnotifications/");
}
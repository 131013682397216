<template>
    <div class="version">v{{getAppVersion()}} : api {{api_version}}</div>
</template>
<script>
getAPIversion
    import { getAPIversion } from '@/services/upclub/auth'
    export default {
        data () {
            return {
                api_version:null
            }
        },
        methods: {
            getAppVersion(){
                return process.env.PACKAGE_VERSION
            },
        },
        mounted() {
            getAPIversion().then(res=>{
                this.api_version=res.data.version
            })
        },
        destroy(){
        }
    }
</script>
<style scoped>
.version{
    color: #FFFFFF;
    background: #000000;
    position: absolute;
    font-size: 1rem;
    line-height: 1;
    bottom: 0;
    padding: 5px 10px;
    border-top-left-radius: 4px;
    right: 16px;
    z-index: 9999;
}
</style>
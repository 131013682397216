<template>
    <Modal v-model="createClubModal" @on-visible-change="visibleChange($event)" title="Create a Club" :transfer="false" :lock-scroll="false" class-name="new-club white-modal">
        <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <div class="modal-body">
            <Form class="white-form" ref="clubForm" :model="club_data" :rules="clubRules" @submit.native.prevent @keyup.enter.native="handleSubmit()">
                <label for="">Let's name your club</label>
                <FormItem prop="club_name"  >
                    <i-input type="text" v-model="club_data.club_name" ></i-input>
                </FormItem>
            </Form>
        </div>
        <div slot="footer">
            <Button size="large" class="btn black-btn" long :loading="loading" @click="handleSubmit()">
                <span v-if="!loading">Create Club</span>
            </Button>
        </div>
    </Modal>
</template>

<script>
    import { postClub } from "@/services/upclub/clubs"
    export default {
        props: {
            
        },
        data () {
            return {
                loading:false,
                createClubModal:false,
                club_data:{
                    club_name:''
                },
                clubRules: {
                    club_name: [
                        { required: true, message: 'Field required', trigger: 'change' },
                    ],
                },
            }
        },
        components: {},
        methods: {
            visibleChange(val){
                if(!val){
                    this.club_data={
                        club_name:''
                    }
                    this.loading=false
                }
            },
            openModal(){
                this.createClubModal=true
            },
            handleSubmit(){
                this.$refs.clubForm.validate(async(valid) => {
                    if (valid) {
                        this.loading=true
                        postClub(this.club_data).then(res=>{
                            this.loading=false
                            this.$emit('onSubmit',res.data)
                            this.$Notice.success({
                                desc: `Club successfully created`
                            });
                            this.createClubModal=false
                        }).catch(err=>{
                            this.loading=false
                        })
                    }
                })
            }
            
        },
        mounted() {
        },
        destroy(){
        }
    }
</script>

<style scoped>

</style>

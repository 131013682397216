import { request } from "./base";

export function getFirstLevelComments(post,params) {
    return request.get(`v1/getfirstlevelcomments/${post}`,{params});
}

export function postFirstLevelComment(data) {
    return request.post(`v1/postfirstlevelcomment/`,data);
}

export function getSecondLevelComments(parent,params) {
    return request.get(`v1/getsecondlevelcomments/${parent}`,{params});
}

export function postSecondLevelComment(data) {
    return request.post(`v1/postsecondlevelcomment/`,data);
}
<template>
    <div class="metrics-content">
        <page-header>
            <div slot="details" class="header-details">
                <p  class="header-title">Metrics</p>
            </div>
        </page-header>
        <div class="download-container">
            <Button type="primary" size="large" @click="exportData()"><Icon type="md-download"></Icon> Export data</Button>
        </div>
        <Table ref="metricTable" height="800" class="metrics-table" :columns="columns" :data="metricsData" v-if="!spinShow"></Table>

        <Spin size="large" fix v-if="spinShow"></Spin>
    </div>
</template>

<script>
    import PageHeader from '@/layout/PageHeader'
    import {getAllMetrics} from '@/services/upclub/profile'
    import { mapGetters } from "vuex";

    import moment from "moment";

    export default {
        data () {
            return {
                spinShow:true,
                categories:[
                    {value:'users', label: 'Users'},
                    {value:'songposts', label: 'Song Posts'},
                    {value:'upvotes', label: 'Up Votes'},
                    {value:'comments', label: 'Comments'},
                    {value:'messages', label: 'Messages'}
                ],
                columns:[
                    {
                        key:'day', 
                        title: 'Date', 
                        sortable: true,  
                        filters: [
                            { label: 'January', value:'01'},
                            { label: 'February', value:'02'},
                            { label: 'March', value:'03'},
                            { label: 'April', value:'04'},
                            { label: 'May', value:'05'},
                            { label: 'June', value:'06'},
                            { label: 'July', value:'07'},
                            { label: 'August', value:'08'},
                            { label: 'September', value:'09'},
                            { label: 'October', value:'10'},
                            { label: 'November', value:'11'},
                            { label: 'December', value:'12'}
                        ],
                        filterMethod (value, row) {
                            return moment(row.day).format('MM')===value;
                        }
                    },
                    {key:'users', title: 'Users', sortable: true},
                    {key:'songposts', title: 'Song Posts', sortable: true},
                    {key:'upvotes', title: 'Up Votes', sortable: true},
                    {key:'comments', title: 'Comments', sortable: true},
                    {key:'messages', title: 'Messages', sortable: true}
                ],
                metrics: [],
                metric_years:[]
            }
        },
        components: {PageHeader},
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails",
            }),
            metricsData(){
                return this.metrics
            }
        },
        methods: {
            async getAppMetrics(){
                let vm=this
                vm.categories.forEach(async (category,index) => {
                    setTimeout(() => {
                        getAllMetrics(category.value).then(async res=>{
                            let value_key = category.value
                            res.data.days.forEach(async (item,i) =>{
                                let label = moment(item.day).format('MM-DD-yyyy')
                                let item_year = moment(item.day).format('yyyy')
                                if(!this.metric_years.includes(item_year)){
                                    this.metric_years.push(item_year)
                                }
                                if(index===0){
                                    vm.metrics.push({day:label,[value_key]:item.total})
                                }else{
                                    vm.metrics[i][value_key]= item.total
                                }
                            })
                            if(index===vm.categories.length-1){
                                vm.spinShow = false
                            }
                        })
                    }, index*1000);
                });
            },
            exportData () {
                this.$refs.metricTable.exportCsv({
                    filename: 'UpClub_metrics',
                    original: false
                });
            }      
        },
        mounted() {
            this.getAppMetrics()
        }
    }
</script>

<style scoped>
.metrics-table,.download-container{
    width: 1200px;
    margin: 0 auto;
}
.download-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}
.ivu-spin-fix{
    background-color:  rgba(255,255,255,.9);;
}
</style>

<template>
    <div class="track-playback-slider flex-horizontal-centered" v-if="playback.device">
        <span class="playing-time timer">{{getPlayingTime()}}</span>
            <Slider class="player-progressbar" v-model="playPosition" @on-change="onProgressChange($event)" :tip-format="hideFormat" :max="songDuration" :disabled="!playback.item"></Slider>
        <span class="total-duration timer">- {{getRemainingTime()}}</span>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { transferUsersPlayback, play, seekPosition } from "@/services/spotify/player";

    import moment from "moment";

    export default {
        name: "PlayerSlider",
        data () {
            return {
                playingTime:0,
                trackDuration:0,
                progressInterval: null,
                isDragStart: false,
                nextTriggered: false,
            }
        },
        computed: {
            ...mapGetters({
                isPlaying: "player/isPlaying",
                playback: "player/getPlayback",
                player: "player/getPlayer",
                context: "player/getPlaybackContext",
                currentPostList: "upclubPlayer/getCurrentPostList",
                currentPostPage: "upclubPlayer/getCurrentPostPage",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
                playerRepeat: "upclubPlayer/getPlayerRepeat"
            }),
            playPosition: {
                get () {
                    return this.playingTime 
                },
                set (val) {
                    this.playingTime = val            
                }
            },
            songDuration: {
                get () {
                    return this.trackDuration 
                },
                set (val) {
                    this.trackDuration = val            
                }
            }
        },
        methods: {
            ...mapActions({
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost"
            }),
            hideFormat () {
                return null;
            },
            updatePlayingTime() {
                let vm = this
                clearInterval(vm.progressInterval);
                if (!vm.context.paused) {
                    vm.progressInterval = setInterval(() => {
                        if (vm.playback && (vm.playingTime + 1000) <= vm.trackDuration) {
                            vm.playingTime = vm.playingTime + 1000;
                            let remaining_time = vm.trackDuration - vm.playingTime
                            if(remaining_time<=2000){
                                if(!vm.nextTriggered){
                                    vm.next()
                                }
                            }
                        }
                    }, 1000);
                }
               
            },
            onProgressChange(currentValue) {
                // seekPosition(currentValue);
                if(this.player){
                    this.player.seek(currentValue)
                }
            },
            next() {
                this.nextTriggered=true
                let postNextIndex = this.currentPlayingPost.index+1
                let next_track = this.currentPostList[postNextIndex]
                if(this.playerRepeat && this.playerRepeat!=='null'){
                    if(this.playerRepeat==='all'){
                        if(!next_track){
                            postNextIndex = 0
                            next_track = this.currentPostList[postNextIndex]
                        }
                    }else{
                        postNextIndex = this.currentPlayingPost.index
                        next_track = this.currentPlayingPost.post
                    }
                    let data ={
                        offset: {
                            position: 0
                        },
                        uris: [next_track.track_details.uri],
                        position_ms: 0
                    }
                    play(data).then(res=>{
                        this.setCurrentPlayingPost({index:postNextIndex, post:next_track})
                        this.nextTriggered=false
                    })

                }else{
                    if(next_track){
                        let data ={
                            offset: {
                                position: 0
                            },
                            uris: [next_track.track_details.uri],
                            position_ms: 0
                        }
                        play(data).then(res=>{
                            this.setCurrentPlayingPost({index:postNextIndex, post:next_track})
                            this.nextTriggered=false
                        })
                    }
                }
            },
            getPlayingTime(){
                return moment.utc(this.playingTime).format('mm:ss');
            },
            getRemainingTime(){
                let remaining_time = this.trackDuration - this.playingTime
                if(remaining_time===3){
                    this.next()
                }
                return moment.utc(remaining_time).format('mm:ss');
            }
        },
        watch: {
            context:{
                handler(val){
                    this.playingTime = val.position;    
                    if(val.paused){
                        clearInterval(this.progressInterval);
                    }else{
                        this.updatePlayingTime();
                    }
                },
                 deep: true
            },
            playback() {
                if(this.playback && this.playback.item){
                    this.trackDuration = this.playback.item.duration_ms;
                }
            },
            isPlaying() {
                this.updatePlayingTime();
            }
        },
        created() {
        },
        mounted(){}
    }
</script>

<style scoped></style>

<template>
    <div>
        <div class="back-button" @click="$router.push({name:'post'})"><Icon type="ios-arrow-back" /></div>
        <track-list ref="postPlaylist" :trackList="trackList"></track-list>
        <div v-if="showNoData" class="no-data">No tracks found</div>
        <infinite-loading @infinite="getPlaylistTracks"></infinite-loading>
    </div>
</template>

<script>
    import {getPlaylistTracks} from '@/services/spotify/playlist';
    import TrackList from '@/components/trackSearchList/Tracks'

    export default {
        data () {
            return {
                showNoData:false,
                playlist_id:"",
                offset:0,
                trackList:[]
            }
        },
        components: {TrackList},
        methods: {
            getPlaylistTracks($state){
                getPlaylistTracks(this.playlist_id,this.offset,10).then(async res=>{
                    if(res.data.items.length>=10){
                        this.offset+=10
                        let tracks_arr = await res.data.items.map(track=>{return track.track})
                        this.trackList = this.trackList.concat(tracks_arr)
                        $state.loaded();
                    }else{
                        if(this.trackList.length==0){
                            this.showNoData=true
                        }
                        $state.complete();
                    }
                })
            }
        },
        mounted() {

        },
        created(){
            this.playlist_id = this.$route.params.id
        }
    }
</script>

<style scoped></style>

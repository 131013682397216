<template>
    <Modal v-model="playerModal" @on-visible-change="visibleChange($event)" fullscreen :transfer="false" :mask="false" :lock-scroll="false" :footer-hide="true" class-name="main-player full-height">
        <div slot="close" class="modal-back">
            <img src="../../assets/images/icons/chevron-left.svg" class="back-icon" alt="">
            <span>Now Playing</span>
        </div>
        <Row type="flex" :gutter="20" v-if="playingPost" class="full-height mainplayer-row white-box">
            <i-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="player-col full-height">
                <div v-if="playingPost">
                    <div class="mainplayer-post-details" >
                        <div class="mainplayer-post-user">
                            <img class="user-thumb" :src="getImage(playingPost.thumbnail_picture)"  @click="goToProfile(playingPost.username)" alt="">
                            <div>
                                <div>
                                    <p class="track-poster user-link" @click="goToProfile(playingPost.username);playerModal=false;">{{playingPost.first_name}} {{playingPost.last_name}}</p>
                                    <p class="track-poster-username small-text" @click="goToProfile(playingPost.username);playerModal=false;">@{{playingPost.username}}</p>
                                </div>
                                <span class="post-age">{{playingPost.post_age}}</span>
                                <span class="post-club" v-if="playingPost.club"  @click="playerModal=false;$router.push({name:'club_posts', params:{id:playingPost.club}})">Posted in <a class="cursor-pointer club-name">{{ playingPost.club_name }}</a></span>
                            </div>
                            <div class="menu">
                                <more-actions @removePost="removePost" :post="playingPost"></more-actions>
                            </div>
                        </div>
                        <p class="post-comment" v-if="playingPost.comment">
                            <span v-for="(comment,i) in generateMentionsText(playingPost.comment, playingPost.mentions)" :key="i" 
                            @click="comment.username?goToProfile(comment.username):'';playerModal=false;" :class="comment.username?'highlight-mention':''">{{comment.username?`@${comment.username}`:comment.text}}</span>
                        </p>
                   
                    </div>
                    <track-details :imageIndex="0" :component="'mainPlayer'" :currentPost="playingPost" :showPlay="true"/>
                    <div class="track-item-popularity" v-if="playingPost && playingPost.post_type!==1">
                        <div>
                            <div class="votes">
                                <span>{{playingPost.formatted_likes}}</span>
                                <img src="../../assets/images/icons/up-vote.svg" alt="">
                            </div>
                            <div class="comments">
                                <span> {{playingPost.formatted_comments_count}}</span>
                                <img src="../../assets/images/icons/comment.svg" alt="">
                            </div>
                            <div class="shares" @click="openShare()">
                                <span>Share</span>
                                <img src="../../assets/images/icons/share.svg" alt="">
                            </div>
                        </div>
                        <div class="track-item-vote" :class="playingPost.liked?'active':''" @click="playingPost.liked?UnlikeSongPost():LikeSongPost()">
                            <img src="../../assets/images/icons/up-vote.svg" alt="" >
                        </div>
                    </div>
                    <share-post-message ref="sharePostToMessage"></share-post-message>  
                </div>
            </i-col>
            <i-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="comment-col full-height">
                <comments ref="comments" :currentPost="playingPost" @scrollTop="scrollTop" @closePlayerModal="playerModal=false"></comments>
            </i-col>
        </Row>
    </Modal>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { EventBus } from '@/eventBus';
    import { likeSongPost,unlikeSongPost } from "@/services/upclub/post"
    import SharePostMessage from "@/components/messages/SharePostMessage"

    import TrackDetails from "./TrackDetails"
    import PlayerControls from "./PlayerControls"
    import PlayerSlider from "./PlayerSlider"
    import TrackVotes from "./TrackVotes"
    import Comments from "./Comments"
    import MoreActions from '@/components/track/MoreActions'

    export default {
        name: "Player",
        data () {
            return {
                playerModal:false,
                currentPost:null,
                currentPostListing:[],
                currentPostDetails: {},
                event:null,
                comment_html_text:""        
            }
        },
        components:{TrackDetails, PlayerControls, PlayerSlider, TrackVotes, MoreActions, Comments,SharePostMessage},
        computed: {
            ...mapGetters({
                device_id: "player/getDeviceID",
                playback: "player/getPlayback",
                context: "player/getPlaybackContext",
                currentPostList: "upclubPlayer/getCurrentPostList",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
            }),
            playingPost(){
                return this.currentPost
            },
            commentHtmlText(){
                return this.comment_html_text
            }
        },
        methods: {
            visibleChange(val){
                this.$emit('setPlayerVisibility',val)
                if(!val){
                    this.currentPost=null
                    this.currentPostListing=[]
                    this.currentPostDetails= {}
                    this.$refs.comments.resetComments()
                }else{
                    if(this.$refs.comments){
                        this.$refs.comments.increaseInfinitID()
                    }
                }
            },
            openModal(event, post){
                this.event=event
                this.playerModal=true
                this.currentPostListing = this.currentPostList
                if(post){
                    if(this.$refs.comments){
                        this.$refs.comments.reloadComments()
                    }
                    this.currentPost = post
                }else{
                    this.currentPost = this.currentPlayingPost.post
                }
            },
            closeModal(){
                this.playerModal=true
            },
            scrollTop(){
                let element = this.$el.getElementsByClassName('comment-col')[0]
                if (element) {
                    element.scrollTop=element.clientHeight+500
                }
            },
            LikeSongPost(){
                let data = {song_post:this.currentPost.song_post}
                likeSongPost(data).then(res=>{
                    this.currentPost.formatted_likes=res.data.song_post_formatted_likes
                    this.currentPost.liked=true
                    if(this.$route.name===localStorage.getItem('current_track_page')){
                        let currentPostList = JSON.parse(localStorage.getItem("current_post_list"))
                        let currentPostIndex = currentPostList.findIndex(track => track.post_details.song_post === this.currentPost.song_post);
                        currentPostList[currentPostIndex] = this.currentPost
                        localStorage.setItem('current_post_list', JSON.stringify(currentPostList))
                        if(this.playback.item && this.playback.item.id===this.currentPost.id){
                            EventBus.$emit('TRACK_CHANGED', this.currentPost);
                        }
                    }
                    if(this.$route.name==='profile_likes'){
                        EventBus.$emit('LIKES_UPDATED');
                    }
                    this.$emit('votePost', this.trackIndex)
                })
            },
            UnlikeSongPost(){
                let data = {song_post:this.currentPost.song_post}
                unlikeSongPost(data).then(res=>{
                    this.currentPost.formatted_likes=res.data.song_post_formatted_likes
                    this.currentPost.liked=false
                    if(this.$route.name===localStorage.getItem('current_track_page')){
                        let currentPostList = JSON.parse(localStorage.getItem("current_post_list"))
                        let currentPostIndex = currentPostList.findIndex(track => track.post_details.song_post === this.currentPost.song_post);
                        currentPostList[currentPostIndex] = this.currentPost
                        localStorage.setItem('current_post_list', JSON.stringify(currentPostList))
                        if(this.playback.item && this.playback.item.id===this.currentPost.id){
                            EventBus.$emit('TRACK_CHANGED', this.currentPost);
                        }
                    }
                    if(this.$route.name==='profile_likes'){
                        EventBus.$emit('LIKES_UPDATED');
                    }
                    this.$emit('unvotePost', this.trackIndex)
                })
            },
            removePost(){
                EventBus.$emit('POST_ITEM_UPDATED', 'delete', this.currentPost);
            },
            openShare(){
                this.$refs.sharePostToMessage.openModal(this.currentPost.song_post)
            }
        },
         watch: {
            '$route.name': function () {
                this.playerModal=false
            }
        },
        mounted() {
            let vm=this
            let idletimer = null
            EventBus.$on("TRACK_CHANGED", function (post) {
                if(vm.event==='play'){
                    clearTimeout(idletimer)
                    if(vm.playerModal){
                        if(post){
                            if(post.song_post!==vm.currentPost.song_post){
                                if(vm.$refs.comments){
                                    idletimer =  setTimeout(async()=>{ 
                                        vm.$refs.comments.reloadComments()
                                        vm.currentPost = post
                                    }, 500);
                                }
                            }
                        }else{
                            vm.playerModal=false
                        }
                    }
                }
            });
        }
    }
</script>

<style scoped></style>

<template>
    <Modal v-model="manageCuratorsModal" @on-visible-change="visibleChange($event)" title="Manage Curators" :transfer="false" :lock-scroll="false" class-name="club-curators white-modal">
        <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <div class="modal-body" v-if="club&&club.club">
            <h4>{{club.club_name}}</h4><br>
            <Curators :club="club" v-if="manageCuratorsModal" @onUpdateCurators="onUpdateCurators"/>
        </div>
        <div slot="footer" style="margin-top:16px">
            <Button size="large" class="btn gray-btn" long @click="$emit('showInviteCurators')" style="margin-bottom:16px">
                <span>Invite Curators</span>
            </Button><br>
        </div>
    </Modal>
</template>

<script>
    import Curators from '@/components/clubs/Curators';

    export default {
        props: ['club'],
        data () {
            return {
                loading:false,
                manageCuratorsModal:false,
                curators:[]
            }
        },
        components: {Curators},
        methods: {
            visibleChange(val){
                if(!val){
                    this.curators=[]
                    this.loading=false
                }
            },
            openModal(){
                this.manageCuratorsModal=true
            },
            onUpdateCurators(curators_count){
                this.$emit('onUpdateCurators',curators_count)
            },
            handleSubmit(){
                
            }
            
        },
        mounted() {
        },
        destroy(){
        }
    }
</script>

<style scoped>

</style>

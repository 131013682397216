import { render, staticRenderFns } from "./TrackVotes.vue?vue&type=template&id=13879557&scoped=true&"
import script from "./TrackVotes.vue?vue&type=script&lang=js&"
export * from "./TrackVotes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13879557",
  null
  
)

export default component.exports
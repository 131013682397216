<template>
    <div>
        <post-list ref="PostListing" :key="postListKey"></post-list>
        <div v-if="showNoData" class="no-data">No results found</div>
        <infinite-loading :identifier="infiniteId" @infinite="getFeeds"></infinite-loading>              
    </div>
</template>

<script>
    import { getTracks } from '@/services/spotify/tracks'
    import { getAllFeed, getMyFeed } from '@/services/upclub/feeds'
    import { mapActions } from "vuex";
    import PostList from '@/components/postList'
    export default {
        props:['category'],
        data () {
            return {
                offset:0,
                postListKey:0,
                post_tracks:[],
                next_feed_url:null,
                feedtype:"mine",
                feedcategory:"new",
                infiniteId:0,
                showNoData:false
            }
        },
        components: {PostList},
        methods: {
            ...mapActions({
                setCurrentPostList: "upclubPlayer/setCurrentPostList",
                setCurrentPostPage: "upclubPlayer/setCurrentPostPage",
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
            }),
            async filterChanged(filter){
                if(filter){
                    this.feedcategory = filter
                }
                this.next_feed_url=null
                this.post_tracks=[]
                this.$refs.PostListing.emptyList()
                this.showNoData=false
                this.infiniteId +=1
            },
            getFeeds($state){
                let queryParams = {}
                if(this.next_feed_url){
                   queryParams = this.getUrlParams(this.next_feed_url)    
                }
                let feedFunction = getMyFeed
                if(this.feedtype==="global"){
                    feedFunction = getAllFeed
                }
                feedFunction(this.feedcategory,queryParams).then(async res=>{
                    this.next_feed_url = res.data.next
                    if(res.data.count>0){
                        let posts = res.data.results
                        let ids = posts.reduce((ids,post)=>{
                            if(post.post_type!=1){
                                ids.push(post.song)
                            }
                            return ids
                        },[])
                        ids = ids.join(",")
                        getTracks(ids).then(async track=>{
                            let songs = track.data.tracks.filter(function (item) {return item != null;});
                            let posttracks = posts.reduce((postTracks, post)=>{
                                let song = songs.filter(song=>{if(post.song===song.id) return song})
                                let order = this.post_tracks.length+postTracks.length
                                if(song.length){
                                    post.order = order
                                    post.track_details = song[0]
                                    postTracks.push(post)
                                }
                                return postTracks
                            },[])
                            this.post_tracks = this.post_tracks.concat(posttracks)
                            this.$refs.PostListing.addToList(posttracks)
                            if(this.next_feed_url){
                                $state.loaded();
                                if(this.$route.query.share_post){
                                    this.setCurrentPostPage({name:this.$route.name, params:this.$route.params})
                                    this.setCurrentPostList({status:'open',list:this.post_tracks})
                                }
                            }else{
                                $state.complete();
                            }
                        }).catch(err=>{
                            if(this.next_feed_url){
                                $state.loaded();
                            }else{
                                $state.complete();
                            }
                        })
                    }else{
                        this.showNoData=true
                        $state.complete();
                    }
                })
            },
            getSongPost(posts, song){
                let post = posts.filter(post=>{if(post.song===song) return post})
                return post[0]
            },
            async routeChanged(route){
                this.$router.push({name:route,query:this.$route.query}).catch(err=>{})
                if(route=='myfeed'){
                    this.feedtype='mine'
                }else{
                    this.feedtype='global'
                }
                this.postListKey+=1
                this.filterChanged(this.category)
            }
        },
        mounted() {
            this.routeChanged(this.$route.name)
        }
    }
</script>

<style scoped></style>

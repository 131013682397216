<template>
    <div class="profile-content" :class="isUpdateOpen?'overflow-hidden':''">
        <page-header v-if="!reload">
            <div class="header-details profile-header" slot="details" >
                <Details ref="profileContents" :profile="profile" @setIsFollowing="setIsFollowing" @openUpdateModal="openUpdateModal" @triggerSearch="triggerSearch"></Details> 
            </div>
            <div class="round-trigger add" slot="extra-trigger" v-if="profile.upclubuser&&!$route.params.username"  @click="openUpdateModal(profile)">
                <img src="../../assets/images/icons/settings.svg" alt="">
            </div>
             <div class="flex-horizontal-centered header-menu" slot="page-menu">
                <Menu mode="horizontal"  :active-name="activename"  class="profile-page-menu page-tab-navigation">
                    <MenuItem name="posts" @click.native="redirectpage('posts')">
                        <span class="profile-count">{{profile.posts}}</span>Posts
                    </MenuItem>
                    <MenuItem name="clubs" @click.native="redirectpage('clubs')">
                        <span class="profile-count">{{profile.clubs}}</span>Clubs
                    </MenuItem>
                    <MenuItem name="likes" @click.native="redirectpage('likes')">
                        <span class="profile-count">{{profile.likes_given}}</span>Up Votes
                    </MenuItem>
                    <MenuItem name="followers" @click.native="redirectpage('followers')">
                       <span class="profile-count">{{profile.followers}}</span> Followers
                    </MenuItem>
                    <MenuItem name="followings" @click.native="redirectpage('followings')">
                        <span class="profile-count">{{profile.followings}}</span>Following
                    </MenuItem>
                </Menu>
                <Dropdown class="category-filter white-dropdown" transfer v-if="showFilterMenu()">
                    <div class="flex-horizontal-centered">
                        <span>{{category_label}}</span>
                        <img src="../../assets/images/icons/filter.svg" alt="">
                    </div>
                    <DropdownMenu slot="list" class="white-box">
                        <DropdownItem v-for="(category,i) in category_options" :key="i" @click.native="filterChanged(category.value)">{{category.label}}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </page-header>

        <div class="track-listing">
            <router-view ref="profilePages" :upclubuser="profile.upclubuser" @updateProfileCounts="updateProfileCounts" v-if="!reload" :key="profilePagesKey"></router-view>
        </div>
        <update-modal ref="updateModal" @refreshDetails="getProfileDetails" @setUpdateVisibility="setUpdateVisibility"></update-modal>
        <Spin size="large" fix v-if="spinShow"></Spin>
    </div>
</template>
 
<script>
    import PageHeader from '@/layout/PageHeader'
    import Details from './Details'
    import UpdateModal from './UpdateModal'
    import { mapActions, mapGetters } from "vuex";
    import { getProfileByUsername, getProfileLite } from '@/services/upclub/profile';
    import { EventBus } from '@/eventBus'

    export default {
        data () {
            return {
                profilePagesKey:0,
                activename: "posts",
                category_label: 'Newest',
                category:'new',
                category_options: [
                    {value:"new",label:"Newest"},
                    {value:"tod",label:"Today's Top"},
                    {value:"tow",label:"Weekly Hottest"},
                    {value:"tom",label:"Monthly Best"},
                    {value:"toy",label:"Yearly Rising"},
                    {value:"atg",label:"All Time Greatest"}
                ],
                spinShow:true,
                reload:false,
                isUpdateOpen: false,
                profile:{
                    upclubuser: null,
                    profile_picture: "",
                    username: "",
                    profile_description: "",
                    profile_twitter: "",
                    profile_instagram: "",
                    posts: 0,
                    likes_given: 0,
                    followers: 0,
                    followings: 0  
                },
            }
        },
        components: {Details,UpdateModal,PageHeader},
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            })
        },
        methods: {
            ...mapActions({
                getUserProfile: "upclubAuth/getUserProfile",
            }),
            setActiveName(){
                if(this.$route.name.includes('posts')){
                    this.activename='posts'
                    this.category_options = [
                        {value:"new",label:"Newest"},
                        {value:"tod",label:"Today's Top"},
                        {value:"tow",label:"Weekly Hottest"},
                        {value:"tom",label:"Monthly Best"},
                        {value:"toy",label:"Yearly Rising"},
                        {value:"atg",label:"All Time Greatest"}
                    ]
                    this.category_label= 'Newest'
                    this.category='new'
                }
                if(this.$route.name.includes('clubs')){
                    this.activename='clubs'
                    this.category_options = [
                        {value:"alphabetical",label:"Alphabetical"},
                        {value:"recentlyadded",label:"Recently Added"},
                        {value:"mostpopular",label:"Most Popular"},
                    ]
                    this.category_label= 'Most Popular'
                    this.category='mostpopular'
                }
                else if(this.$route.name.includes('likes')){
                    this.activename='likes'
                    this.category_options = [
                        {value:"new",label:"Newest"},
                        {value:"tod",label:"Today's Top"},
                        {value:"tow",label:"Weekly Hottest"},
                        {value:"tom",label:"Monthly Best"},
                        {value:"toy",label:"Yearly Rising"},
                        {value:"atg",label:"All Time Greatest"}
                    ]
                    this.category_label= 'Newest'
                    this.category='new'
                }
                else if(this.$route.name.includes('followings')){
                    this.activename='followings'
                }
                else if(this.$route.name.includes('followers')){
                    this.activename='followers'
                }
                else{
                    this.activename=""
                }
            },
            showFilterMenu(){
                if(this.$route.name.includes('posts') || this.$route.name.includes('likes') || this.$route.name.includes('clubs')){
                    return true
                }
                return false
            },
            async redirectpage(name){
                let page='profile'
                if(this.$route.params.username){
                    page='user'
                }
                if(name==='clubs'){
                    this.category_options = [
                        {value:"alphabetical",label:"Alphabetical"},
                        {value:"recentlyadded",label:"Recently Added"},
                        {value:"mostpopular",label:"Most Popular"},
                    ]
                    this.category_label= 'Most Popular'
                    this.category='mostpopular'
                }
                this.$router.push({name:`${page}_${name}`}).catch(err => {})
                this.profilePagesKey+=1
            },
            filterChanged(category){
                let vm = this
                vm.category_label=vm.category_options.filter(option=>{ return option.value===category })[0].label
                vm.$refs.profilePages.filterChanged(category)  
            },
            getProfileDetails(){
                if(this.$route.params.username){
                    if(this.getUserDetails.username==this.$route.params.username){
                        this.$router.push({name: 'profile_posts'})
                    }
                    getProfileByUsername(this.$route.params.username).then(res=>{
                        if(res){
                            let details = res.data
                            this.profile = details
                            this.reload=false
                            this.spinShow=false
                        }
                    })
                }else{
                    this.getUserProfile().then(res=>{
                        let details = this.getUserDetails
                        this.profile = details
                        this.reload=false
                        this.spinShow=false
                    })
                }
            },
            updateProfileCounts(){
                let id = this.profile.upclubuser
                getProfileLite(id).then(res=>{
                    if(res){
                        let lite_details = res.data
                        this.profile.posts = lite_details.posts
                        this.profile.likes_given = lite_details.likes_given
                        this.profile.followers = lite_details.followers
                        this.profile.followings = lite_details.followings
                    }
                })
            },
            openUpdateModal(profile){
                this.$refs.updateModal.openModal(profile)
            },
            setUpdateVisibility(value){
                this.isUpdateOpen=value
            },
            triggerSearch(){
                this.isOpen=!this.isOpen
            },
            setIsFollowing(val){
                let vm=this
                vm.profile.followed = val
                vm.reload=true
                setTimeout(()=>{ 
                    vm.reload=false
                }, 50);
            }
        },
        watch: {
            '$route.params.username': function () {
                this.reload=true
                this.spinShow=true
                this.activename="posts"
                this.category_label='Newest'
                this.category='new'
                this.setActiveName()
                this.getUserProfile().then(res=>{
                    this.getProfileDetails()
                })
            },
            '$route.name': function () {
                this.setActiveName()
            }
        },
        mounted() {
            this.setActiveName()
            this.getUserProfile().then(res=>{
                this.getProfileDetails()
            })
            let vm=this
            EventBus.$on("POSTS_UPDATED", function (reload) {
                if(reload){
                    vm.category_label= 'Newest',
                    vm.category='new',
                    vm.$refs.profilePages.filterChanged('new')
                }
                vm.updateProfileCounts()
            });
        }
    }
</script>

<style scoped>
</style>

<template>
    <div class="broadcast-message-content">
        <page-header>
            <div slot="details" class="header-details">
                <p  class="header-title">Broadcast Message</p>
            </div>
        </page-header>

        <div class="broadcast-message-wrapper">
            <card class="white-box">
                <Form class="broadcast-message-form white-form" ref="broadcastMessageForm" :model="broadcast" :rules="broadcastRules" @keyup.enter.native="handleSubmit()">
                    <FormItem prop="text_message">
                        <label for="">Message (1000 characters limit)</label>
                        <i-input type="textarea" v-model="broadcast.text_message" :autosize="{minRows: 5,maxRows: 5}"></i-input>
                    </FormItem>
                    <Button size="large" class="btn black-btn" long :loading="loading" @click="handleSubmit()">
                        <span v-if="!loading">Broadcast message</span>
                    </Button>
                </Form>
            </card>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/layout/PageHeader'
    import {postPushNotification} from '@/services/upclub/profile'

    export default {
        data () {
             const validateMaxWords = (rule, value, callback) => {
                let char_count = value.length;
                 if (value && char_count>1000) {
                    callback(new Error('Maximum of 1000 characters'));
                } else {
                    callback();
                }
            };
            return {
                loading: false,
                broadcast:{
                    text_message:""
                },
                broadcastRules: {
                    text_message: [
                        { validator: validateMaxWords, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                    ],
        
                },
                
            }
        },
        components: {PageHeader},

        methods: {
            handleSubmit() {
                this.$refs['broadcastMessageForm'].validate(async(valid) => {
                    if (valid) {
                        this.loading=true
                        postPushNotification(this.broadcast).then(res=>{
                            this.loading=false
                            this.$Notice.success({
                                desc: `Message successfully broadcasted`
                            });
                            this.broadcast={
                                text_message:""
                            }
                            this.$refs['broadcastMessageForm'].resetFields();
                        })
                    }
                })
            },
          
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
.broadcast-message-content{
    height: 100%;
}
.broadcast-message-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 100px);
}
.broadcast-message-form {
    width: 500px;
    margin: 0 auto;
}
</style>

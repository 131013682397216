<template>
    <div class="search-results">
        <div class="no-data" v-if="showNoData">No results found</div>
        <div class="search-results-list clubs display-grid clubs-grid-list" v-show="clubs.length">
                <club-card v-for="(club,i) in clubs" :key="i" :club="club"></club-card>
                 <!-- to fill empty space (max 4 colums) -->
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="getClubs"></infinite-loading>              
    </div>
</template>

<script>
    import {getAllClubs} from '@/services/upclub/clubs'
    import ClubCard from '@/components/clubs/ClubCard'

    export default {
        data () {
            return {
                next_request_uri:null,
                showNoData:false,
                clubs:[],
                infiniteId:0,
                query: null,
            }
        },
        components: {ClubCard},
        methods: {
            getResults(query){
                this.showNoData=false
                if(query){
                    this.next_request_uri=null
                    this.clubs=[]
                    this.query=query
                    this.infiniteId+=1
                }else{
                    this.setRecent()
                }
            },
            getClubs($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                queryParams['category'] = 'mostpopular'
                if(this.query&&this.query.length){
                    queryParams['searchstring'] = this.query
                }
                getAllClubs(queryParams).then(res=>{
                    this.next_request_uri = res.data.next
                    this.clubs=this.clubs.concat(res.data.results)
                    if(!localStorage.hasOwnProperty('recent_club_search')){
                        localStorage.setItem('recent_club_search', JSON.stringify(this.clubs))
                    }else{
                        let recent = JSON.parse(localStorage.getItem('recent_club_search'))
                        recent = this.clubs.concat(recent)
                        recent = recent.filter((club, index, array) => array.findIndex(u => u.club == club.club) == index);
                        if(recent.length>4){
                            recent = recent.slice(0, 4);
                        }
                        localStorage.setItem('recent_club_search', JSON.stringify(recent))
                    }
                    if(this.next_request_uri){
                        if(this.clubs.length){
                            this.showNoData=false
                        }
                        $state.loaded();
                    }else{
                        if(this.clubs.length===0){
                            this.showNoData=true
                        }else{
                            this.showNoData=false
                        }
                        $state.complete();
                    }
                })

            },
            async setRecent(){
                if(localStorage.hasOwnProperty('recent_club_search')){
                    let recent = JSON.parse(localStorage.getItem('recent_club_search'))
                    this.clubs = recent.slice(0, 4)
                }
                if(!localStorage.hasOwnProperty('recent_club_search')&&!localStorage.hasOwnProperty('recent_post_search')){
                    this.showNoData=true
                }
            }
        },
        mounted() {
            this.setRecent()
        }
    }
</script>

<style scoped></style>

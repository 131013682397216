<template>
    <div class="authorize-content full-height flex-centered-content">
        <div v-if="showErrorMessage">
            <h2>Authorize Upclub access to your spotify account to proceed.</h2>
            <Button @click.native="authorizeSpotify()" size="large" shape="circle" class="btn black-btn">Authorize</Button>
        </div>
    </div>
</template>

<script>
    import { setSession,removeSession } from '../../services/authentication.service'
    import { getAuthorizeURL,requestAuthToken } from '../../services/spotify/auth';
    import { mapActions,mapGetters } from "vuex";
    import '@/plugins/mixin'
    export default {
        data () {
            return {
                showErrorMessage: false
            }
        },
        computed: {
        ...mapGetters({
                spotifyGetAuthorizeState: "spotifyAuth/getAuthorizeState"
            })
        },
        methods: {
            ...mapActions({
                spotifyGetAuthToken: "spotifyAuth/getAuthToken",
                spotifySetAuthState: "spotifyAuth/setAuthState",
                upclubGetUserProfile: "upclubAuth/getUserProfile"
                }),
            async authorizeSpotify(){
                let state = this.generateKey(8)
                let code_verifier = await this.generateKey(43)
                let code_challenge = await this.generateCodeChallenge(code_verifier)
                localStorage.setItem('authorize_state', state)
                localStorage.setItem('code_verifier', code_verifier)
                this.spotifySetAuthState(state);
                let auth_url = getAuthorizeURL(state,code_challenge)
                window.location.href=auth_url
            },
            async verifyAccess(code){
                let state = localStorage.getItem('authorize_state')
                localStorage.setItem('authorize_code',code)
                this.upclubGetUserProfile().then(async profile=>{
                    if(this.$route.query.state===state){
                        let data={
                            grant_type: 'authorization_code',
                            code: code
                        }
                        this.spotifyGetAuthToken(data).then(res=>{
                            if(localStorage.hasOwnProperty('next_page')){
                                let next = JSON.parse(localStorage.getItem('next_page'))
                                this.$router.push(next)
                                localStorage.removeItem('next_page')
                            }else{
                                this.$router.push({name:'discover'})
                            }
                        }).catch(err=>{
                            console.log(err)
                            // removeSession()
                        });
                    }else{
                        removeSession()
                    }
                })
            }

        },
        mounted() {
            if(this.$route.query.code){
               this.verifyAccess(this.$route.query.code)
            }else{
                this.showErrorMessage = true
            }
        }
    }
</script>

<style scoped>
.authorize-content{
    width:100%;
}
.authorize-content>div{
    text-align: center;
    min-width: 700px;
    max-width: 90%;
}
.authorize-content h2{
    margin-bottom: 32px;
}
</style>
<template>
    <Modal v-model="settingsModal" @on-visible-change="visibleChange($event)" title="Message Settings" :transfer="false" :lock-scroll="false" class-name="message-settings white-modal">
        <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <Form class="thread-update-form white-form" ref="threadSettingForm" :model="messageDetails" @submit.native.prevent @keyup.enter.native="handleSubmit('threadSettingForm')">
            <FormItem prop="name">
                <label for="">Group Name</label>
                <i-input type="text" v-model="messageDetails.messagethreadname"></i-input>
            </FormItem>
            <FormItem>
                <div class="flex-horizontal-centered add-members-label">
                    <p class="small-text">Members</p>
                    <a @click="openAddMemberModal()" class="small-text gray-text">Add More</a>
                </div>
                <div class="message-setting-members">
                    <Follow :users="messageMembers" :defaultUsers="memberIds"></Follow>
                </div>
            </FormItem>
        </Form>
        <div slot="footer">
            <Row type="flex" class="setting-buttons" :gutter="16">
                <i-col :xs="16">
                    <Button size="large" class="btn gray-btn" long :loading="adding" @click="openAddMemberModal()">
                        <span v-if="!adding">Add Members</span>
                    </Button>
                </i-col>
                <i-col :xs="8">
                    <Button size="large" class="btn red-ghost-btn" long :loading="leaving" @click="showLeaveModal=true">
                        <span v-if="!leaving">Leave chat</span>
                    </Button>
                </i-col>
            </Row>
            <Button size="large" class="btn black-btn" long :loading="saving" @click="handleSubmit('threadSettingForm')">
                <span v-if="!saving">Save Changes</span>
            </Button>
        </div>
        <search-user-modal ref="addMember" @onSubmit="addMember" :title="'Add member'" :submitText="'Add Member'" :defaultSelected="newMembers" :disabledUsers="memberIds"></search-user-modal>
        <Modal v-model="showLeaveModal" width="300" footer-hide class-name="confirm-modal leave-thread white-modal">
            <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
            <p slot="header">
                <span>Leave chat ?</span>
            </p>
            <div class="action-buttons">
                <Button size="large" long class="btn black-btn" @click="showLeaveModal=false">Cancel</Button><br>
                <Button size="large" long class="btn black-btn" :loading="leaving" @click="leaveMessageThread()">
                    <span v-if="!leaving">Leave</span>
                </Button>
            </div>
        </Modal>
    </Modal>
</template>

<script>
    import Follow from '@/components/follow';
    import SearchUserModal from '@/components/messages/SearchUserModal';
    import {leaveThread,updateThreadName,addThreadMember} from '@/services/upclub/messages'

    export default {
        props:{
            message_thread:{
                default:{
                    members:{
                        upclubusers:[]
                    }
                }
            },
        },
        data () {
            return {     
                settingsModal:false,
                showLeaveModal:false,
                leaving:false,
                saving:false,
                adding:false,
                messageDetails:{
                    messagethreadname:""
                },
                memberIds:[],
                messageMembers:[],
                newMembers:[],
            }
        },
        components: {Follow, SearchUserModal},
        methods: {
            visibleChange(val){
                if(!val){
                    this.messageMembers=[]
                    this.settingsModal=false
                    this.showLeaveModal=false
                    this.leaving=false
                    this.saving=false
                    this.adding=false
                    this.messageDetails={ 
                        messagethreadname:""
                    }
                    this.memberIds=[]
                    this.messageMembers=[]
                    this.newMembers=[]
                }
            },
            openModal(){
                if(this.message_thread.messagethreadname){
                    this.messageDetails.messagethreadname = this.message_thread.messagethreadname
                }
                this.messageMembers= Object.assign([],this.message_thread.members.upclubusers)
                this.memberIds = this.messageMembers.map(user=> {return user.upclubuser})
                this.settingsModal=true
            },
            openAddMemberModal(){
                this.$refs.addMember.openModal()
            },
            leaveMessageThread(){
                this.leaving=true
                leaveThread(this.message_thread.messagethread).then(res=>{
                    this.leaving=false
                    this.$emit('onLeave')
                    this.$router.push({name:'messages'}).catch(err => {})
                })
            },
            addMember(newMembers){
                this.newMembers=[]
                this.messageMembers= Object.assign([],this.message_thread.members.upclubusers)
                let allMemberIds = this.messageMembers.map(user=> {return user.upclubuser})
                this.memberIds = allMemberIds
                newMembers.map(member=>{
                    if(allMemberIds.indexOf(member.upclubuser)===-1){
                        this.newMembers.push(member)
                        this.messageMembers.push(member)
                    }
                })
            },
            handleSubmit(name) {
                this.$refs[name].validate(async(valid) => {
                    if (valid) {
                        this.saving=true
                        updateThreadName(this.message_thread.messagethread, this.messageDetails).then(res=>{
                            let data={
                                upclubusers: this.messageMembers.map(user=> {return user.upclubuser})
                            }
                            addThreadMember(this.message_thread.messagethread,data).then(mres=>{
                                this.saving=false
                                this.$emit('onSave',this.message_thread.messagethread)
                                this.$Notice.success({
                                    desc: `Settings updated`
                                });
                                this.settingsModal=false
                            })
                        })

                    }
                })
            }
        },
        mounted() {
            

        },
    }
</script>

<style scoped>
</style>

<template>
    <div>        
        <div class="no-data" v-if="showNoData">
            No user found
        </div>
        <CheckboxGroup v-model="selected_users" @on-change="selectedChanged($event)">
            <Checkbox class="black-check flex-horizontal-centered thumb-check-list" v-for="(user,i) in search_users" :key="i" :label="user.upclubuser" :disabled="checkDisabled(user.upclubuser)">
                <img :src="getImage(user.thumbnail_picture)" alt="" class="user-thumb">
                <div class="user-search-name">
                    <p>{{user.username}}</p>
                </div>            
            </Checkbox>
            <infinite-loading :identifier="infiniteId" @infinite="searchUsers"></infinite-loading>
        </CheckboxGroup>
    </div>
</template>

<script>
    import {getSearchUsername} from '@/services/upclub/messages'
    export default {
        props:['search_query', 'defaultSelected' , 'disabledUsers'],
        data () {
            return {
                showNoData:false,
                next_request_uri:null,
                infiniteId:0,
                selected_users:[],
                search_users:[]
            }
        },
        methods: {
            checkDisabled(upclubuser){
                if(this.disabledUsers.indexOf(upclubuser)>-1){
                    return true
                }else{
                    return false
                }
            },
            searchUsers($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                if(this.search_query&&this.search_query!==''){
                    getSearchUsername(this.search_query,queryParams).then(async res=>{
                        this.next_request_uri = res.data.next
                        this.search_users = this.search_users.concat(res.data.results)
                        let users = Object.assign([],this.search_users)

                        if(!localStorage.hasOwnProperty('recent_user_search')){
                            localStorage.setItem('recent_user_search', JSON.stringify(users))
                        }else{
                            let recent = JSON.parse(localStorage.getItem('recent_user_search'))
                            recent = this.search_users.concat(recent)
                            if(recent.length>10){
                                recent = recent.slice(0, 10);
                            }
                            recent = recent.filter((user, index, array) => array.findIndex(u => u.upclubuser == user.upclubuser) == index);

                            localStorage.setItem('recent_user_search', JSON.stringify(recent))
                        }
                        
                        // let option_users = await users.map(user => {
                        //     return {value:user.upclubuser,label:user.username}
                        // })
                        this.$emit('setOptionUsers', users)
                        if(this.next_request_uri){
                            if(this.search_users.length){
                                this.showNoData=false
                            }
                            $state.loaded();
                        }else{
                            if(this.search_users.length===0){
                                this.showNoData=true
                            }else{
                                this.showNoData=false
                            }
                            $state.complete();
                        }
                    }).catch(err=>{
                        this.showNoData=true
                        $state.complete();
                    })
                }else{
                    if(this.search_users.length===0){
                        this.showNoData=true
                    }else{
                        this.showNoData=false
                    }
                    this.setRecent();
                    $state.complete();
                }
            },
            selectedChanged(users){
                this.$emit('setSelectedUsers', users)
            },
            removeSelected(data){
                let index = this.selected_users.findIndex(user => user==data.upclubuser);
                if(index>=0){
                    this.selected_users.splice(index,1)
                }
            },
            reloadInfiniteLoader(){
                this.next_request_uri=null
                this.infiniteId+=1
                this.search_users=[]
            },
            resetData(){
                this.next_request_uri=null
                this.showNoData=true
                this.infiniteId=0
                this.selected_users=[]
                this.search_users=[]
            },
            setRecent(){
                if(localStorage.hasOwnProperty('recent_user_search')){
                    let recent = JSON.parse(localStorage.getItem('recent_user_search'))
                    this.$emit('setOptionUsers', recent)
                    this.search_users = recent
                    this.showNoData=false
                }else{
                    this.showNoData=true
                }
            },
            setDefault(selected){
                this.selected_users=selected
            }
        },
        mounted() {
           
        }
    }
</script>

<style scoped>
</style>

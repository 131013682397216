<template>
    <div class="white-box">
        <div class="club-item-details">
            <div class="club-item-image" @click="$router.push({name:'club_posts', params:{id:clubDetails.club}})">
                <img :src="getImage(clubDetails.thumbnail_picture)" alt="">
            </div>
            <div class="club-item-details">
                <p class="club-item-name"  @click="$router.push({name:'club_posts', params:{id:clubDetails.club}})">{{clubDetails.club_name}}</p>
                <div class="flex-horizontal-spacebetween flex-gap-16">
                    <div class="club-item-counts  flex-horizontal-centered flex-gap-16">
                        <p >{{clubDetails.followers}} Followers</p>
                        <p >{{clubDetails.posts}} Songs</p>
                    </div>
                    <div class="club-action-options">
                        <Dropdown class="white-dropdown" transfer>
                            <a href="javascript:void(0)">
                            <div>
                                <Icon type="md-more" size="21" />
                            </div>
                            </a>
                            <DropdownMenu slot="list"  class="white-box club-owner-dropdown" v-if="getUserDetails.upclubuser===clubDetails.club_owner">
                                <DropdownItem @click.native="manageCurators()">Manage Curators</DropdownItem>
                                <DropdownItem @click.native="inviteCurators()">Invite Curators</DropdownItem>
                                <DropdownItem class="gray-text">Cancel</DropdownItem>
                            </DropdownMenu>
                            <DropdownMenu slot="list"  class="white-box club-owner-dropdown" v-else-if="clubDetails.is_curator">
                                <DropdownItem @click.native="leaveCurrentClub()" class="red-text">Leave Club</DropdownItem>
                                <DropdownItem class="gray-text">Cancel</DropdownItem>
                            </DropdownMenu>
                            <DropdownMenu slot="list"  class="white-box club-owner-dropdown" v-else>
                                <DropdownItem @click.native="unfollowCurrentClub()" v-if="clubDetails.followed">Unfollow</DropdownItem>
                                <DropdownItem @click.native="followCurrentClub()" v-else>Follow</DropdownItem>
                                <DropdownItem @click.native="requestToCurateClub()">Request to Curate</DropdownItem>
                                <DropdownItem class="gray-text">Cancel</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</template>

<script>
    import { followClub, unfollowClub, requestToCurate, leaveClub } from '@/services/upclub/clubs'
    import { mapGetters } from "vuex";
    export default {
        props: ["club", "club_index", "list_name"],
        data () {
            return {
                club_details:{},
            }
        },       
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
            clubDetails(){
                return this.club_details
            }
        },
        mounted(){
            this.club_details=this.club
        },
        methods: {
            followCurrentClub(){
                this.club_details.followed=true
                followClub({club:this.club.club}).then(res=>{
                    this.club_details.followers=res.data.followers
                    this.club_details
                })
            },
            unfollowCurrentClub(){
                this.club_details.followed=false
                unfollowClub({club:this.club.club}).then(res=>{
                    this.club_details.followers=res.data.followers
                })
            },
            requestToCurateClub(){
                requestToCurate({club:this.club.club}).then(res=>{
                    this.$Notice.success({
                        desc: `Sent request to curate club`
                    });
                })
            },
            leaveCurrentClub(){
                this.club_details.is_curator=false
                leaveClub(this.club.club).then(res=>{
                    this.club_details.curators=res.data.curators
                    this.$Notice.success({
                        desc: `You have successfully left the club`
                    });
                })
            },
            manageCurators(){
                this.$emit('manageCurators',this.club)
            },
            inviteCurators(){
                this.$emit('inviteCurators',this.club)
            }
        }
    }
</script>

<style scoped></style>

<template>
    <div class="page-header" :class="`search-${search_state}`">
        <div class="flex-horizontal-centered">
            <slot name="details"></slot>
            <slot name="middle-content"></slot>
            <div class="header-triggers">
                <slot name="extra-trigger"></slot>

                <div class="expanding-search round-trigger" :data-state="search_state">
                    <img src="../assets/images/icons/search.svg" class="expand-search-icon" alt="">
                    <i-input v-model="search_query" type="text" class="expand-search-input" ref="search_input" placeholder="Search posts and users" @click.native="goToSearch()" @on-change="triggerSearch()">
                            <span slot="append" @click="closeSearch()" v-show="search_state==='open'">
                                <img style="width:24px;" src="../assets/images/icons/close.svg">
                            </span>
                    </i-input>
                </div>
                <div class="round-trigger add">
                    <img src="../assets/images/icons/plus.svg" @click="showSearchTrackModal()" alt="">
                </div>
                <Dropdown class="white-dropdown" transfer>
                    <a href="javascript:void(0)">
                    <div class="round-trigger user">
                        <img :src="getProfileImage(getUserDetails.profile_picture)"  alt="">
                    </div>
                    </a>
                    <DropdownMenu slot="list"  class="white-box profile-dropdown">
                        <DropdownItem @click.native="$router.push({name:'profile_posts'}).catch(err=>{})">View profile</DropdownItem>
                        <DropdownItem v-if="hasMetricAccess" @click.native="$router.push({name:'metrics'}).catch(err=>{})">View app metrics</DropdownItem>
                        <DropdownItem v-if="hasBroadcastAccess" @click.native="$router.push({name:'broadcast_message'}).catch(err=>{})">Broadcast message</DropdownItem>
                        <DropdownItem v-if="hasBroadcastAccess" @click.native="$router.push({name:'send_all_message'}).catch(err=>{})">Send message to all</DropdownItem>
                        <DropdownItem @click.native="signout()">Sign out</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                
            </div>
        </div>
        <slot name="page-menu"></slot>
    </div>
</template>

<script>
    import { logout } from '@/services/upclub/auth'
    import { removeSession } from '@/services/authentication.service'
    import { mapGetters } from "vuex";
    import { EventBus } from '@/eventBus';

    export default {
        data () {
            return {
                search_state: "close",
                search_query: null,
                idletimer: null
            };
        },
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails",
                player: "player/getPlayer"
            }),
            hasMetricAccess(){
                const user_type = this.getUserDetails.user_type
                const USER_METRICS_ACCESS = 0x2;
                return (user_type & USER_METRICS_ACCESS) == USER_METRICS_ACCESS;
            },
            hasBroadcastAccess(){
                const user_type = this.getUserDetails.user_type
                const USER_BROADCAST_ACCESS = 0x4;
                return (user_type & USER_BROADCAST_ACCESS) == USER_BROADCAST_ACCESS;
            }
        },
        methods: {
            getProfileImage(image){
                return "data:image/png;base64,"+image
            },
            showSearchTrackModal(){
                EventBus.$emit('OPEN_SEARCH_TRACK');
            },
            closeSearch(){
                this.search_state='close'
                setTimeout(()=>{ 
                    this.$router.go(-1)
                }, 800);
            },
            goToSearch(){
                if(this.$route.name!=='search'){
                    this.$router.push({name:'search'}).catch(err=>{})
                    this.search_state='open';
                }
            },
            triggerSearch(){
                let vm=this
                clearTimeout(this.idletimer);
                this.idletimer =  setTimeout(async()=>{ 
                    vm.$emit('getSearchResults', vm.search_query)
                }, 600);
            },
            async signout(){
                if(this.player){
                    this.player.pause()
                }
                logout().then(res=>{
                    removeSession()
                })
            }
        },
        mounted() {
            if(this.$route.name=='search'){
                this.search_state='open';
                this.$refs.search_input.focus()
            }
        }
    }
</script>

<style scoped>

</style>

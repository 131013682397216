<template>
    <div class="send-message-content">
        <page-header>
            <div slot="details" class="header-details">
                <p  class="header-title"></p>
            </div>
        </page-header>

        <div class="send-message-wrapper">
            <div>
            <Button shape="circle" class="post-button" @click.native="openSearchSpotify()">
                <span>Send song to all</span>
                <img src="../../../assets/images/icons/plus.svg" alt="">
            </Button>
            <h2 style="text-align:center; margin: 16px 0;"><i>or</i></h2>
            <p>Send message to all</p>
            <card class="white-box">
                <Form class="send-message-form white-form" ref="sendMessageForm" :model="send_data" :rules="sendRules" @keyup.enter.native="handleSubmit()">
                    <FormItem prop="text">
                        <label for="">Message (1000 characters limit)</label>
                        <i-input type="textarea" v-model="send_data.text" :autosize="{minRows: 5,maxRows: 5}"></i-input>
                    </FormItem>
                    <Button size="large" class="btn black-btn" long :loading="loading" @click="handleSubmit()">
                        <span v-if="!loading">Send to all</span>
                    </Button>
                </Form>
            </card>
            </div>
        </div>
        <search-spotify ref="searchSpotify" :save_as="'message_to_all'" @onSaveSuccess="onSendSuccess"></search-spotify>
    </div>
</template>

<script>
    import PageHeader from '@/layout/PageHeader'
    import {postSendToAllMessage} from '@/services/upclub/profile'
    import SearchSpotify from  '@/components/searchSpotify'

    export default {
        data () {
             const validateMaxWords = (rule, value, callback) => {
                let char_count = value.length;
                 if (value && char_count>1000) {
                    callback(new Error('Maximum of 1000 characters'));
                } else {
                    callback();
                }
            };
            return {
                loading: false,
                send_data:{
                    text:""
                },
                sendRules: {
                    text: [
                        { validator: validateMaxWords, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                    ],
        
                },
                
            }
        },
        components: {PageHeader,SearchSpotify},

        methods: {
            handleSubmit() {
                this.$refs['sendMessageForm'].validate(async(valid) => {
                    if (valid) {
                        this.loading=true
                        postSendToAllMessage(this.send_data).then(res=>{
                            this.loading=false
                            this.$Notice.success({
                                desc: `Message successfully sent to all`
                            });
                        })
                    }
                })
            },
            openSearchSpotify(){
                this.$refs.searchSpotify.openSearchTrackModal()
            },
            onSendSuccess(data){
                this.$Notice.success({
                    desc: `Song successfully sent to all`
                });
            },
          
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
.send-message-content{
    height: 100%;
}
.send-message-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 100px);
}
.send-message-form {
    width: 500px;
    margin: 0 auto;
}
.post-button{
    font-size: 1.1rem;
    margin: 8px auto;
    display: block;
}
</style>

<template>
    <div class="track-action">
        <div v-if="getUserDetails.upclubuser===track.post_details.upclubuser">
            <img src="../../assets/images/icons/trash.png" class="action-image" @click="deletePost()" alt="">
        </div>
        <Dropdown trigger="custom" :transfer="true" :visible="visible" @on-clickoutside="handleClose()" placement="bottom-start"  transfer-class-name 
="share-menu">
            <a href="javascript:void(0)" @click="handleOpen">
                <img src="../../assets/images/icons/share.png" class="action-image" alt="">
            </a>
            <DropdownMenu slot="list">
                <DropdownItem @click.native="openMessageModal()">
                    <a>
                        <img src="../../assets/images/icons/upclub_btn.png" alt="">
                        <p>send in a message on up club</p>
                    </a>
                </DropdownItem>
                <DropdownItem @click.native="postLink('copy')">
                    <a>
                        <img src="../../assets/images/icons/copy_btn.png" alt="">
                        <p>copy link to send to a friend</p>
                    </a>
                </DropdownItem>
                <DropdownItem>
                    <a>
                        <img src="../../assets/images/icons/instagram_btn.png" alt="">
                        <p>post to your instagram story</p>
                    </a>
                </DropdownItem>
                <DropdownItem>
                    <a class="twitter-share-button" target="_blank" :href="`https://twitter.com/intent/tweet?url=${postLink('get')}`">
                        <img src="../../assets/images/icons/twitter_btn.png" alt="">
                        <p>share the post on twitter</p>
                    </a>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { play, pause } from "@/services/spotify/player";
    import { deleteSongPost } from "@/services/upclub/post";
    export default {
        props:["page", "track", "trackUris", "trackIndex", "trackListing"],
        data () {
            return {
                visible: false
            }
        },
        computed: {
            ...mapGetters({
                playback: "player/getPlayback",
                context: "player/getPlaybackContext",
                getUserDetails: "upclubAuth/getUserDetails",
                playerRepeat: "upclubPlayer/getPlayerRepeat"
            }),
        },
        methods: {
            ...mapActions({
                setPlayerRepeat: "upclubPlayer/setPlayerRepeat"
            }),
            handleOpen () {
                this.visible = true;
            },
            handleClose () {
                this.visible = false;
            },
            openMessageModal(){
                this.$emit('sendMessage')
            },
            playTrack(){
                if(this.playerRepeat==='track'){
                    this.setPlayerRepeat('all')
                }
                let data ={
                    offset: {
                        position: this.trackIndex
                    },
                    uris: this.trackUris,
                    position_ms: 0
                }
                play(data).then(res=>{
                    localStorage.setItem('current_track_page', this.$route.name)
                    localStorage.setItem('current_track_list', JSON.stringify(this.trackListing))
                })
            },
            pauseTrack(){
                pause()
            },
            deletePost(){
                let id = this.track.post_details.song_post

                this.$Modal.confirm({
                    title: 'Delete track',
                    content: '<p>Are you sure you want to delete this track?</p>',
                    okText: 'OK',
                    cancelText: 'Cancel',
                    onOk: () => {
                         deleteSongPost(id).then(res=>{
                            this.$emit("removePost",this.trackIndex)
                        })
                    }
                });
            },
            postLink(method){
                let post_id = this.track.post_details.song_post.toString()
                const encryptedPostID = btoa(`BB33Lj9Q9JWqWUzyP9ka7jmZXbrLTtmk:${post_id}`)
                var url = process.env.VUE_APP_BASE_URL+'feeds/?share_post='+encryptedPostID;
                if(method=='copy'){
                    navigator.clipboard.writeText(url);
                    this.$Message.success({
                        content: 'Post link copied',
                        duration: 10
                    });
                }else{
                    return url
                }
            },
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

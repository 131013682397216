<template>
    <div>
        <search-track-modal ref="search_track_modal" @openPostPlaylistModal="openPostPlaylistModal" @openTrackModal="openTrackModal" :save_as="save_as"></search-track-modal>
        <search-playlist-modal ref="playlist_tracks" @closeModal="closeModal" @openTrackModal="openTrackModal"></search-playlist-modal>
        <track-details-modal ref="trackDetailsModal" @trackDetailsModalClose="trackDetailsModalClose" @openSearchClubModal='openSearchClubModal' @onSaveSuccess="onSaveSuccess" @removeSelectedClub="removeSelectedClub" :post_club="selectedClub" :save_as="save_as" :message_thread="message_thread"></track-details-modal>
        <search-club-modal ref="searchClubModal" @selectClub="setSelectedClub"></search-club-modal> 
    </div>
</template>

<script>
    import SearchTrackModal from "./SearchTrackModal"
    import SearchPlaylistModal from "./SearchPlaylistModal"
    import TrackDetailsModal from "./TrackDetailsModal"
    import SearchClubModal from "./SearchClubModal"

    export default {
        name: "Post",
        props: ['save_as', 'message_thread'],
        data () {
            return {
                offset:0,
                infiniteId:0,
                playlist:[],
                selected_club:null,
            }
        },
        components:{SearchTrackModal, SearchPlaylistModal, TrackDetailsModal, SearchClubModal},
        computed: {
          selectedClub(){
              return this.selected_club
          }
        },
        methods: {
            openSearchTrackModal(){
                this.$refs.search_track_modal.openModal()
            },
            openPostPlaylistModal(source,details){
                this.$refs.playlist_tracks.openModal(source,details)
            },
            closeModal(){
                this.$refs.search_track_modal.searchModal=false
            },
            trackDetailsModalClose(value){
                if(value==='all'){
                    this.$refs.playlist_tracks.postPlaylistModal=false
                    this.$refs.search_track_modal.searchModal=false
                }else{
                    this.$refs.playlist_tracks.postPlaylistModal=false
                }
            },
            openTrackModal(track){
                this.$refs.trackDetailsModal.openModal(track)
            },
            onSaveSuccess(data){
                // if(this.save_as==='message'){
                //     this.trackDetailsModalClose('all')
                // }
                if(this.$listeners && this.$listeners.onSaveSuccess){
                    this.$emit('onSaveSuccess', data)
                }
                this.trackDetailsModalClose('all')
            },
            openSearchClubModal(){
                this.$refs.searchClubModal.openModal()
            },
            setSelectedClub(club){
                this.selected_club=club
            },
            removeSelectedClub(){
                this.selected_club=null
            }
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

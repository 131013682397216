<template>
    <div class="content-wrapper full-height">
        <Layout class="ivu-layout-has-sider content-layout" :class="[isMainPlayerOpen?'mainplayer-open':'',!showBottomPlayer?'no-playback':'']">
            <SideMenu ref="sideMenu" @reloadMainContent="reloadMainContent"> </SideMenu>

            <Content class="main-content scroll-container" :key="mainContentKey">
                <router-view ref="mainContent" v-if="showMainContent"></router-view>
                <main-player ref="mainPlayer" @setPlayerVisibility="setPlayerVisibility" :profile="profile" class="mainplayer-content"></main-player>
                <Spin size="large" fix v-if="spinShow"></Spin>
            </Content>
        </Layout>
        <bottom-player v-show="showBottomPlayer"></bottom-player>
        <search-spotify ref="searchSpotify" :save_as="'post'"></search-spotify>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import {getPost} from '@/services/upclub/post'
    import {getTrack} from '@/services/spotify/tracks'
    import {play} from '@/services/spotify/player'
    import {getMyProfile} from '@/services/spotify/me'
    import { logout } from '@/services/upclub/auth'
    import { removeSession } from '@/services/authentication.service'

    import SideMenu from  '../layout/SideMenu'
    import BottomPlayer from  '../components/player/BottomPlayer'
    import MainPlayer from  '../components/player/MainPlayer'
    import SearchSpotify from  '../components/searchSpotify'
    import { EventBus } from '@/eventBus';
    import moment from "moment";

    export default {
        data () {
            return {
                mainContentKey:0,
                showMainContent:false,
                environment: process.env.NODE_ENV,
                spinShow:true,
                isMainPlayerOpen:false,
                profile:{},
            };
        },
        components: {SideMenu,BottomPlayer,MainPlayer,SearchSpotify},
        computed: {
            ...mapGetters({
                player: "player/getPlayer",
                playback: "player/getPlayback",
                getUserDetails: "upclubAuth/getUserDetails",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost"
            }),
            showBottomPlayer(){
                if(this.currentPlayingPost&&this.currentPlayingPost.post&&this.playback&&this.playback.item){
                    return true
                }else{
                    return false
                }
                // if(this.playback&&this.playback.item&&this.currentPlayingPost&&this.currentPlayingPost.post&&this.currentPlayingPost.post.song===this.playback.item.id){
                //     return true
                // }else{
                //     return false
                // }
            }
        },
        methods: {
            ...mapActions({
                getUserProfile: "upclubAuth/getUserProfile",
                setCurrentPostList: "upclubPlayer/setCurrentPostList",
                setCurrentPostPage: "upclubPlayer/setCurrentPostPage",
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
                setPlayerRepeat: "upclubPlayer/setPlayerRepeat",
                setPlayerShuffle: "upclubPlayer/setPlayerShuffle",
            }),
            reloadMainContent(){
                let vm=this
                this.$refs.mainPlayer.closeModal()
                this.setPlayerVisibility(false)
                this.mainContentKey+=1
                this.showMainContent=false
                setTimeout(()=>{ 
                    vm.showMainContent=true
                }, 300);
            },
            setPlayerVisibility(value){
                this.isMainPlayerOpen=value
            },
            getPlayTrack(postid){
                // let decrypt_postid = atob(postid)
                // let uri_postid = parseInt(decrypt_postid.split(':')[1])
                // console.log(decrypt_postid)
                getPost(postid).then(post=>{
                     let posttrack = post.data
                    getTrack(post.data.song).then(track=>{
                        posttrack.track_details = track.data
                        let data ={
                            offset: {
                                position: 0
                            },
                            uris: [track.data.uri],
                            position_ms: 0
                        }
                        this.setCurrentPlayingPost({index:-1, post:posttrack})
                        play(data).then(res=>{
                            this.spinShow=false
                            EventBus.$emit('OPEN_PLAYER','play', posttrack);
                        })
                    })
                }).catch(track_err=>{
                    this.spinShow=false
                    this.$Notice.error({
                        desc: "Post not found"
                    });
                })
            },
            notifMessageRedirect(data){
                if(data.notification_type==='like'|| data.notification_type==='comment' || data.notification_type==='reply' || data.notification_type==='mention_comment' || data.notification_type==='mention_songpost' || data.notification_type==='broadcast_song_post'){
                    getPost(data.song_post).then(post=>{
                        let posttrack = post.data
                        getTrack(post.data.song).then(track=>{
                            posttrack.track_details = track.data
                            if(data.notification_type!=='like'&&data.notification_type!=='mention_songpost'&&data.notification_type!=='broadcast_song_post'){
                                this.showPost('comment',posttrack)
                            }else{
                                this.showPost('play',posttrack)
                            }
                        })
                    }).catch(track_err=>{
                        this.$Notice.error({
                            desc: "Post not found"
                        });
                    })
                }else if(data.notification_type==='follow'){
                    this.goToProfile(data.upclubuser_follower_username)
                }else if(data.notification_type==='message'){
                    this.$router.push({name:'message_box',params:{id:data.messagethread}}).catch(err => {})
                }
                else if(data.notification_type==='curatorrequest'||data.notification_type==='curatorinvite'){
                    this.$router.push({name:'club_posts',params:{id:data.club}}).catch(err => {})
                }
            },
            showPost(type,posttrack){
                if(posttrack){
                    let index=0
                    if(this.currentPlayingPost && this.currentPlayingPost.index){
                        index = this.currentPlayingPost.index
                    }
                    if(type==='play'){
                        let data ={
                            offset: {
                                position: 0
                            },
                            uris: [posttrack.track_details.uri],
                            position_ms: 0
                        }
                        play(data).then(res=>{
                            this.setCurrentPlayingPost({index:index, post:posttrack})
                            EventBus.$emit('OPEN_PLAYER', type, posttrack);
                        })
                    }else{
                        EventBus.$emit('OPEN_PLAYER', type, posttrack);
                    }
                }else{
                    this.$Notice.error({
                        desc: "Post had been removed."
                    });
                }
                
            },
        },
        created() {
            this.getUserProfile().then(res=>{
                let details = this.getUserDetails
                this.profile = details
            })
            getMyProfile().then(res=>{
                if(localStorage.hasOwnProperty('current_playing_post')){
                    let currentPlayingPost = JSON.parse(localStorage.getItem('current_playing_post'))
                    if(currentPlayingPost.post){
                        getPost(currentPlayingPost.post.song_post).then(post=>{
                            getTrack(post.data.song).then(track=>{
                                let posttrack = track.data
                                post.data.track_details = posttrack
                                this.setCurrentPlayingPost({index:currentPlayingPost.index, post:post.data})
                                this.$store.dispatch('init')
                            }).catch(track_err=>{
                                this.setCurrentPlayingPost({index:null, post:null})
                                this.$store.dispatch('init')
                            })
                        }).catch(post_err=>{
                            this.setCurrentPlayingPost({index:null, post:null})
                            this.$store.dispatch('init')
                        })
                    }else{
                        this.setCurrentPlayingPost({index:null, post:null})
                        this.$store.dispatch('init')
                    }
                }else{
                    this.$store.dispatch('init')
                }
                if(localStorage.hasOwnProperty('current_post_page')){
                    this.setCurrentPostPage(JSON.parse(localStorage.getItem('current_post_page')))
                }
                if(localStorage.hasOwnProperty('current_post_list')){
                    let post_list = JSON.parse(localStorage.getItem('current_post_list'))
                    this.setCurrentPostList({status:'close', list:post_list})
                }
                if(localStorage.hasOwnProperty('player_repeat')){
                    this.setPlayerRepeat(localStorage.getItem('player_repeat'))
                }
                if(localStorage.hasOwnProperty('player_shuffle')){
                    if(localStorage.getItem('player_shuffle')==='true'){
                        this.setPlayerShuffle(true)
                    }else{
                        this.setPlayerShuffle(false)
                    }
                }
            })
        },
        mounted(){
            let vm=this
            EventBus.$on("OPEN_PLAYER", function (type,post) {
                if(vm.$refs.mainPlayer){
                    vm.$refs.mainPlayer.openModal(type,post)
                }
            });
            EventBus.$on("OPEN_SEARCH_TRACK", function () {
                vm.$refs.searchSpotify.openSearchTrackModal()
            });
            EventBus.$on("DEVICE_RESPONSE", function (status) {
                vm.spinShow=false
                if(status==='ready'){
                    vm.reloadMainContent()
                    if(vm.$route.query.share_post){
                        vm.spinShow=true
                        setTimeout(()=>{ 
                            vm.getPlayTrack(vm.$route.query.share_post)
                        }, 300);
                    }
                }else{
                    this.router.push({ name: 'authorize_spotify' }).catch(err=>{})
                }
            });
             EventBus.$on("SPOTIFY_ACCOUNT_ERROR", function (status,data) {
                if(status==="no_network"){
                    this.$Notice.close('no_network')
                    this.$Notice.error({
                        name: "no_network",
                        desc: 'Please check your internet connection.'
                    });
                }else{
                    if(data.error.reason==="PREMIUM_REQUIRED"){
                        // this.$Notice.error({
                        //     desc: 'UpClub Music is restricted to spotify premium users only'
                        // });
                        this.$Modal.error({
                            title: 'Premium account required',
                            content: '<p>This app requires a Spotify Premium Account?</p>',
                            className:'nonpremium-block',
                            okText: 'Close',
                            onOk: () => {
                                logout().then(res=>{
                                    let app_version = localStorage.getItem('app_version')
                                    localStorage.clear();
                                    localStorage.setItem('app_version',app_version);
                                    removeSession()
                                })
                            }
                        });
                    }else{
                        this.router.push({ name: 'authorize_spotify' }).catch(err=>{})
                    }
                }
            });
            this.$messaging.onMessage((message) => {
                this.$refs.sideMenu.getNotificationCount()
                let title=""
                if(message.data.notification_type==='message'){
                    title='New Message'
                }else if(message.data.notification_type==='comment' || message.data.notification_type==='reply'){
                    title='New Comment'
                }else if(message.data.notification_type==='mention_comment'){
                    title='Comment Mention'
                }else if(message.data.notification_type==='like'){
                    title="Post Like"
                }else if(message.data.notification_type==='follow'){
                    title="New Follower"
                }else if(message.data.notification_type==='broadcast_song_post'||message.data.notification_type==='broadcast_text_message'){
                    title=message.notification.title
                }else if(message.data.notification_type==='curatorrequest'){
                    title="Club Curate Request"
                }else if(message.data.notification_type==='curatorinvite'){
                    title="Club Curate Invite"
                }else{
                    title=""
                }

                let notifname=`notif${moment().unix()}`

                this.$Notice.open({
                    name:notifname,
                    render: h => {
                        return h('div', {
                            attrs: { class: `notif-message ${message.data.notification_type}` },
                            on: {
                                click: () => {
                                    this.$Notice.close(notifname)
                                    this.notifMessageRedirect(message.data)
                                }
                            }
                        },[
                            h('div', {
                                attrs: { class: `notif-message-icon ${message.data.notification_type}` },
                            }),
                            h('div', {
                                attrs: { class: `notif-message-body` }
                            },[
                                h('h4', title),
                                h('p', message.notification.body),
                            ])
                            
                        ])
                    }
                });
                if(message.data.notification_type==='message'){
                    if(this.$route.path.includes('/messages/')){
                        this.$refs.mainContent.newMessageNotif(message.data.messagethread)
                    }
                }else{
                    if(this.$route.name==="notifications"){
                        this.$refs.mainContent.reloadNotifications()
                    }
                } 
            });

        },
        beforeDestroy(){
            if(this.player){
                this.player.disconnect()
            }
        }
    }
</script>

<style scoped>
</style>

import ContentWrapper from "../layout/ContentWrapper";
import Metrics from "../views/Admin/Metrics"
import BroadcastMessage from "../views/Admin/Broadcast/BroadcastMessage"
import SendAllMessage from "../views/Admin/Broadcast/SendAllMessage"


export default {
    path: "",
    component: ContentWrapper,
    children: [
        {
            path: "/metrics",
            name: "metrics",
            component: Metrics,
            meta: { requiredAuth: true, permission: 'hasMetricAccess' },
        },
        {
            path: "/broadcast-message",
            name: "broadcast_message",
            component: BroadcastMessage,
            meta: { requiredAuth: true, permission: 'hasBroadcastAccess' },
        },
        {
            path: "/send-all-message",
            name: "send_all_message",
            component: SendAllMessage,
            meta: { requiredAuth: true, permission: 'hasBroadcastAccess' },
        },
    ]
};

<template>
    <div class="list-votes-container">               
        <div class="track-item-popularity" v-if="post">
            <div>
                <div class="votes">
                    <span>{{post.formatted_likes}}</span>
                    <img src="../../assets/images/icons/up-vote.svg" alt="">
                </div>
                <div class="comments" @click="$emit('playTrack', postIndex, 'view', post)">
                    <span> {{post.formatted_comments_count}}</span>
                    <img src="../../assets/images/icons/comment.svg" alt="">
                </div>
                <div class="shares" @click="openShare()">
                    <span>Share</span>
                    <img src="../../assets/images/icons/share.svg" alt="">
                    <!-- <Dropdown trigger="custom" :transfer="true" :visible="visible" @on-clickoutside="handleClose()" placement="bottom-start"  transfer-class-name="share-menu white-box">
                        <a href="javascript:void(0)" @click="handleOpen">
                            <span>5.3k</span>
                            <img src="../../assets/images/icons/share.svg" alt="">
                        </a>
                        <DropdownMenu slot="list">
                            <share-options :track="track" @sendMessage="$emit('sendMessage')"></share-options>
                        </DropdownMenu>
                    </Dropdown> -->
                </div>
            </div>
            <div class="track-item-vote" :class="post.liked?'active':''" @click="post.liked?UnlikeSongPost():LikeSongPost()">
                <img src="../../assets/images/icons/up-vote.svg" alt="" >
            </div>
        </div>
        <share-post-message ref="sharePostToMessage"></share-post-message>  
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { EventBus } from '@/eventBus';
    import { likeSongPost,unlikeSongPost } from "@/services/upclub/post"
    import ShareOptions from "@/components/track/ShareOptions"
    import SharePostMessage from "@/components/messages/SharePostMessage"
    export default {
        props:["post", "postIndex","page"],
        data () {
            return {
                visible: false
            }
        },
        components:{ShareOptions,SharePostMessage},
        computed:{
            ...mapGetters({
                playback: "player/getPlayback",
                currentPostList: "upclubPlayer/getCurrentPostList",
                currentPostPage: "upclubPlayer/getCurrentPostPage",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
            })
        },
        methods: {
            ...mapActions({
                setCurrentPostList: "upclubPlayer/setCurrentPostList",
            }),
            handleOpen () {
                this.visible = true;
            },
            handleClose () {
                this.visible = false;
            },
            LikeSongPost(){
                let data = {song_post:this.post.song_post}
                likeSongPost(data).then(res=>{
                    this.post.formatted_likes=res.data.song_post_formatted_likes
                    this.post.liked=true
                    if(this.$route.name===this.currentPostPage.name){
                        let currentPostList = Object.assign([],this.currentPostList)
                        let currentPostIndex = currentPostList.findIndex(post => post.song_post === this.post.song_post);
                        currentPostList[currentPostIndex] = this.post
                        this.setCurrentPostList({status:'open',list:currentPostList})
                        if(this.playback.item && this.playback.item.id===this.post.track_details.id){
                            EventBus.$emit('TRACK_CHANGED', this.post);
                        }
                    }
                    this.$emit('votePost', this.postIndex)
                })
            },
            UnlikeSongPost(){
                let data = {song_post:this.post.song_post}
                unlikeSongPost(data).then(res=>{
                    this.post.formatted_likes=res.data.song_post_formatted_likes
                    this.post.liked=false
                    if(this.$route.name===this.currentPostPage.name){
                        let currentPostList = Object.assign([],this.currentPostList)
                        let currentPostIndex = currentPostList.findIndex(post => post.song_post === this.post.song_post);
                        currentPostList[currentPostIndex] = this.post
                        this.setCurrentPostList({status:'open',list:currentPostList})
                        if(this.playback.item && this.playback.item.id===this.post.id){
                            EventBus.$emit('TRACK_CHANGED', this.post);
                        }
                    }
                    this.$emit('unvotePost', this.postIndex)
                })
            },
            openShare(){
                this.$refs.sharePostToMessage.openModal(this.post.song_post)
            }
        },
        mounted() {

        }
    }
</script>

<style scoped></style>

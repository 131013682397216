import { request } from "./base";

export function follow(data) {
    return request.post("v1/follow/",data);
}
export function unfollow(data) {
    return request.post("v1/unfollow/",data);
}
export function getProfileFollowing(user,params) {
    return request.get(`v1/profilegetfollowing/${user}`,{params});
}
export function getProfileFollowers(user,params) {
    return request.get(`v1/profilegetfollowers/${user}`,{params});
}

const initialState = {
    trackList: [],
};

const state = Object.assign({},initialState);

const getters = {
    getThreadTracks: (state) => state.trackList,
};

const mutations = {
    SET_TRACK_LIST(state, trackList) {
        state.trackList = trackList;
    },
};

const actions = {
    setThreadTrackList({ commit }, trackList) {
        commit("SET_TRACK_LIST", trackList);
    }
};

const module = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default module;

<template>
    <div class="chat-box scroll-container" id="chatbox-post" :class="showNoData?'empty':''" v-if="newloaded">
        <div v-for="(group,gi) in dateGroup" :key="'group'+gi">
            <p class="message-group-date">{{group.date}}</p>
            <div v-for="(message,i) in group.messages" :key="i">
                <div class="messagebox-item" :class="message.upclubuser===getUserDetails.upclubuser?'me':''">
                    <img :src="getImage(message.thumbnail_picture)" class="user-thumb"  @click="goToProfile(message.username)"/>
                    <div class="message-bubble">
                        <div v-for="(msg,mi) in message.messages" :key="mi" :class="msg.type">
                            <post-message :message="msg" :request_page="request_page"></post-message> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <infinite-loading direction="top" :identifier="infiniteId" @infinite="getThreadMessages"></infinite-loading>
        <div class="no-data" v-if="showNoData">No shared songs</div>
    </div>  
</template>

<script>
    import {getMessages} from '@/services/upclub/messages'
    import {mapGetters } from "vuex";
    import PostMessage from '@/components/messages/PostMessage'
    import { EventBus } from '@/eventBus'
    import moment from "moment";
    export default {
        props:['new_thread'],
        data () {
            return {
                showNoData:false,
                newloaded: true,
                sendingMessage: false,
                next_request_uri:null,
                request_page:{page:1,last_song:null},
                infiniteId:0,
                message_data:{
                    message:"",
                },
                messageRules: {
                    message: [
                        { required: true, message: ' ', trigger: 'onsubmit' },
                    ]
                },
                messages:[],
                dateGroup:[]
            }
        },
        components: {PostMessage},
        computed: {
            ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
        },
        methods: {
            getThreadMessages($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)  
                   if(queryParams.hasOwnProperty('page')){
                        this.request_page.page=queryParams.page
                   }  
                }
                let songs=[]
                if(this.$route.params.id!=='new'){
                    getMessages(this.$route.params.id,'songs',queryParams).then(res=>{
                        EventBus.$emit('MESSAGE_THREAD_OPEN');
                        if(res.data.count>0){
                            let vm=this
                            vm.next_request_uri = res.data.next
                            // vm.messages = vm.messages.concat(res.data.results)
                            let message_ids = vm.messages.map(message=>{return message.message})
                            res.data.results.map(async(message,index)=>{
                                if(message_ids.indexOf(message.message)===-1){
                                    vm.messages.push(message)
                                    let group_length = vm.dateGroup.length
                                    let formated_date = ""
                                    if(moment(message.created_at).isSame(moment(), "day")){
                                        formated_date = 'Today '+moment(message.created_at).format('h:mm a')
                                    }else if(moment(message.created_at).isSame(moment().subtract(1, 'day'), "day")){
                                        formated_date = 'Yesterday '+moment(message.created_at).format('h:mm a')
                                    }else{
                                        formated_date = moment(message.created_at).format('MMM DD, YYYY h:mm a')
                                    }
                                    if(message.text){
                                        message['messages']=[{type:'text', message:message.text, id:message.message}]
                                    }
                                    if(message.thesongpost){
                                        songs.push(message.message)
                                        message['messages']=[{type:'post', message:message.thesongpost, id:message.message}]
                                    }
                                    if(group_length===0){
                                        vm.dateGroup.push({
                                            date:formated_date,
                                            messages:[message]
                                        })
                                    }else{
                                        let last_group_index = vm.dateGroup.length-1
                                        let latest = vm.dateGroup[last_group_index]
                                        let end = moment(message.created_at)
                                        let start = moment(latest.messages[0].created_at)
                                        let time_gap = moment.duration(start.diff(end));
                                        let gap_min = time_gap.asMinutes();
                                        if(gap_min>30){
                                            vm.dateGroup.push({
                                                date:formated_date,
                                                messages:[message]
                                            })
                                        }else{
                                            let last_message=latest.messages[0]
                                            let last_message_sender=last_message.upclubuser
                                            if(last_message_sender===message.upclubuser){
                                                let latest_end = moment(message.created_at)
                                                let latest_start = moment(last_message.created_at)
                                                let latest_time_gap = moment.duration(latest_start.diff(latest_end));
                                                let latest_gap_min = latest_time_gap.asMinutes();
                                                if(latest_gap_min<1){
                                                    if(message.text){
                                                        vm.dateGroup[last_group_index].messages[0].messages.unshift({type:'text', message:message.text, id:message.message})
                                                    }
                                                    if(message.thesongpost){
                                                        songs.push(message.message)
                                                        vm.dateGroup[last_group_index].messages[0].messages.unshift({type:'post', message:message.thesongpost, id:message.message})
                                                    }
                                                }else{
                                                    vm.dateGroup[last_group_index].messages.unshift(message)
                                                }
                                                
                                            }else{
                                                vm.dateGroup[last_group_index].messages.unshift(message)
                                            }
                                        }
                                    }
                                    if((index+1)===res.data.results.length){
                                        if(vm.next_request_uri){
                                            $state.loaded();
                                        }else{
                                            $state.complete();
                                        }
                                        this.request_page.last_song=songs[0]
                                    }
                                }
                            })
                        }else{
                            $state.complete();
                            this.showNoData=true
                        }
                        
                    })
                }else{
                    $state.complete();
                }
            },
            assignNewMessageGroup(message){
                if(message.song_post){
                    this.newloaded=false
                    let vm = this
                    if(!message.upclubuser){
                        message.upclubuser=this.getUserDetails.upclubuser
                        message.username=this.getUserDetails.usernane
                        message.thumbnail_picture=this.getUserDetails.profile_picture
                    }
                    vm.messages.push(message)
                    let group_length = vm.dateGroup.length
                    let formated_date = ""
                    if(moment(message.created_at).isSame(moment(), "day")){
                        formated_date = 'Today '+moment(message.created_at).format('h:mm a')
                    }else if(moment(message.created_at).isSame(moment().subtract(1, 'day'), "day")){
                        formated_date = 'Yesterday '+moment(message.created_at).format('h:mm a')
                    }else{
                        formated_date = moment(message.created_at).format('MMM DD, YYYY h:mm a')
                    }
                    if(message.text){
                        message['messages']=[{type:'text', message:message.text, id:message.message}]
                    }else{
                        message.thesongpost={
                            post_type: 1,
                            song: message.song,
                            title: message.title,
                            song_post: message.song_post,
                        }
                        message['messages']=[{type:'post', message:message.thesongpost, id:message.message}]
                    }
                    if(group_length===0){
                        vm.dateGroup.unshift({
                            date:formated_date,
                            messages:[message]
                        })
                        vm.newloaded=true
                    }else{
                        let last_group_index = 0
                        let latest = vm.dateGroup[0]
                        let start = moment(message.created_at)
                        let end = moment(latest.messages[0].created_at)
                        let time_gap = moment.duration(start.diff(end));
                        let gap_min = time_gap.asMinutes();
                        if(gap_min>30){
                            vm.dateGroup.unshift({
                                date:formated_date,
                                messages:[message]
                            })
                            vm.newloaded=true
                        }else{
                            let message_index = vm.dateGroup[last_group_index].messages.length -1
                            let last_message=vm.dateGroup[last_group_index].messages[message_index]
                            let last_message_sender=last_message.upclubuser

                            if(last_message_sender===message.upclubuser){
                                let latest_start = moment(message.created_at)
                                let latest_end = moment(last_message.created_at)
                                let latest_time_gap = moment.duration(latest_start.diff(latest_end));
                                let latest_gap_min = latest_time_gap.asMinutes();
                                if(latest_gap_min<1){
                                    if(message.text){
                                        vm.dateGroup[last_group_index].messages[message_index].messages.push({type:'text', message:message.text, id:message.message})
                                        vm.newloaded=true
                                    }
                                    if(message.thesongpost){
                                        vm.dateGroup[last_group_index].messages[message_index].messages.push({type:'post', message:message.thesongpost, id:message.message})
                                        vm.newloaded=true
                                    }
                                }else{
                                    vm.dateGroup[last_group_index].messages.push(message)
                                    vm.newloaded=true
                                }
                                
                            }else{
                                vm.dateGroup[last_group_index].messages.push(message)
                                vm.newloaded=true
                            }
                        }
                    }
                }else{
                    this.$emit('changeTab',0)
                }
            },
            infiniteReload(){
                this.next_request_uri=null
                this.messages=[]
                this.dateGroup=[]
                this.showNoData=false
                this.message_data={
                    message:"",
                }
                if(this.$route.params.id!=='new'){
                    this.infiniteId+=1
                }
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>

import { request } from "./base";

export function myProfile() {
    return request.get("v1/myprofile");
}

export function getProfile(id) {
    return request.get(`v1/getprofile/${id}`);
}
export function getProfileByUsername(username) {
    return request.get(`v1/getprofileviausername/${username}`);
}
export function getProfileLite(id) {
    return request.get(`v1/getprofilelite/${id}`);
}

export function createProfile(data) {
    return request.post("v1/createprofile/",data);
}

export function updateProfile(data) {
    return request.patch("v1/updateprofile/",data);
}

export function getProfilePost(user,category,params) {
    return request.get(`v1/profilegetsongposts/${user}/${category}`,{params});
}

export function getProfileLikes(user,category,params) {
    return request.get(`v1/profilegetlikedsongposts/${user}/${category}`,{params});
}

export function searchUsername(username,params) {
    return request.get(`v1/searchusernameweb/${username}`,{params});
}
export function searchUsernameBase(username,params) {
    return request.get(`v1/searchusername/${username}`,{params});
}

export function searchUsernameDefault() {
    return request.get(`v1/searchusernamedefault/`);
}

export function getAllMetrics(category,params) {
    return request.get(`v1/getallmetrics/${category}`,{params});
}

export function postPushNotification(data){
    return request.post("v1/pushnotificationbroadcast/",data);
}

export function postSendToAllMessage(data){
    return request.post("v1/sendtoallmessage/",data);
}
<template>
    <mobile-view v-if="showMobile()"></mobile-view>
    <router-view v-else-if="!showMobile()&&showContents"></router-view>
</template>

<style>
</style>
<script>
    import { logout } from '@/services/upclub/auth'
    import { removeSession } from '@/services/authentication.service'
    import { mapActions } from "vuex";
    import { EventBus } from '@/eventBus';
    import MobileView from './views/MobileView'
    import '@/plugins/mixin'
    export default {
        data () {
            return {
                loadContents:false,
            }
        },
        components:{MobileView},
        computed: {
            showContents(){
                return this.loadContents
            }
        },
        methods: {
            ...mapActions({
                spotifySetAuthState: "spotifyAuth/setAuthState",
                spotifySetAccessToken: "spotifyAuth/setAccessToken",
                spotifySetRefreshToken: "spotifyAuth/setRefreshToken",
                spotifySetExpiresIn: "spotifyAuth/setExpiresIn",
                upclubSetAccessToken: "upclubAuth/setAccessToken",
                upclubSetFCMID: "upclubPushNotif/setFCMID",
            }),
            showMobile() {
                if(this.$route.name=='terms'||this.$route.name=='privacy_policy'){
                    return false
                }else{
                    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        return true
                    } else {
                        return false
                        // if (window.matchMedia("(max-width: 993px)").matches){
                        //     return true
                        // }else{
                        //     return false
                        // }
                    }
                }
            }
        },
        async created(){
            let vm=this
            let app_version = localStorage.getItem('app_version')
            const isAuthenticated = localStorage.getItem("upclub_authenticated")
            if(app_version!==process.env.PACKAGE_VERSION){
                localStorage.setItem('app_version',process.env.PACKAGE_VERSION)
                if(process.env.RESET_ON_NEW_VERSION==='true'){
                    vm.loadContents=false

                    removeSession()
                    setTimeout(()=>{ 
                        vm.loadContents=true
                    }, 100);
                       
                }else{
                    vm.loadContents=true
                }
            }else{
                vm.loadContents=true
            }
            if(vm.loadContents==true&&isAuthenticated){
                vm.$messaging.getToken({ vapidKey: process.env.VUE_APP_FIREBASE_vapidKey}).then(currentToken=>{
                    if (currentToken) {
                        vm.upclubSetFCMID(currentToken)
                        console.log(currentToken)
                    } else {
                        console.log('No Instance ID token available.')
                    }
                })
            }
        },
        mounted() {
            let app_version = localStorage.getItem('app_version')
            if(app_version===process.env.PACKAGE_VERSION){
                if(localStorage.hasOwnProperty('authorize_state')){
                    this.spotifySetAuthState(localStorage.getItem('authorize_state'))
                }
                if(localStorage.hasOwnProperty('spotify_access_token')){
                    this.spotifySetAuthState(localStorage.getItem('authorize_state'))
                    this.spotifySetAccessToken(localStorage.getItem('spotify_access_token'))
                    this.spotifySetRefreshToken(localStorage.getItem('spotify_refresh_token'))
                    this.spotifySetExpiresIn(localStorage.getItem('spotify_expires_in'))
                }
                if(localStorage.hasOwnProperty('upclub_access_token')){
                    this.upclubSetAccessToken(localStorage.getItem('upclub_access_token'))
                }
                EventBus.$on("REQUEST_ERROR", function (status,data) {
                    if(status){
                        if(status==401){
                            this.$Notice.close('unauthorized')   
                            this.$Notice.error({
                                name: "unauthorized",
                                desc: "Unauthorized session."
                            });
                        }else{Object.keys(data).forEach(key => {
                                this.$Notice.error({
                                    desc: data[key]
                                });
                            });
                        }
                    }else{
                        if(data==='Network Error'){
                            this.$Notice.close('no_network')
                            this.$Notice.error({
                                name: "no_network",
                                desc: 'Please check your internet connection.'
                            });     
                        }else{
                            this.$Notice.error({
                                desc: data
                            });
                        }
                    }
                });
                this.$Message.config({
                    top: 80,
                    duration: 10
                });
                this.$Notice.config({
                    duration: 10
                });
            }
        },
       
    }
</script>
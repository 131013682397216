<template>
    <div class="auth-form forgot">
        <img class="logo" src="../../assets/images/upclub_logo.png" @click="$router.push({name:'welcome'})"><br>
        <h3>Reset password</h3>
        <Form ref="changePasswordForm" :model="changePasswordData" :rules="loginRules" @keyup.enter.native="handleSubmit('changePasswordForm')" class="white-box white-form">
            <FormItem prop="password">
                <i-input type="password" v-model="changePasswordData.password" placeholder="New Password"></i-input>
            </FormItem>
            <FormItem prop="resetcode">
                <i-input type="text" v-model="changePasswordData.resetcode" placeholder="Code"></i-input>
            </FormItem>
             <!-- <FormItem prop="passwordCheck">
                <i-input type="password" v-model="passwordCheck" placeholder="Confirm Password"></i-input>
            </FormItem> -->
            <Button size="large" long class="btn black-btn" :loading="loading" @click="handleSubmit('changePasswordForm')">
                <span v-if="!loading">Submit</span>
            </Button>
        </Form>      
    </div>
</template>
<script>
    import { resetPassword } from '@/services/upclub/auth'

    export default {
        data () {
            const validateNoSpace = (rule, value, callback) => {
                let regex = /\s/

                 if (value && regex.test(value)) {
                    callback(new Error('Field should not have spaces'));
                } else {
                    callback();
                }
            };
            const validatePassCheck = (rule, value, callback) => {
                if (!this.passwordCheck) {
                    callback(new Error('Please enter your password'));
                } else if (this.passwordCheck !== this.changePasswordData.password) {
                    callback(new Error('Passwords do not match!'));
                } else {
                    callback();
                }
            };
            const validatePass = (rule, value, callback) => {
                if (this.changePasswordData.password === '') {
                    callback(new Error('Please enter your password'));
                } else {
                    if (!this.passwordCheck) {
                        callback();
                        this.$refs.changePasswordForm.validateField('passwordCheck');
                    }else {
                        callback();
                        this.$refs.changePasswordForm.validateField('passwordCheck');
                    }
                }
            };
            return {
                loading:false,
                changePasswordData: {
                    password: '',
                    resetcode:''
                },
                passwordCheck:'',
                loginRules: {
                    password: [
                        // { validator: validatePass, trigger: 'change' },
                        { validator: validateNoSpace, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                        { type: 'string', min: 8, message: 'The password length cannot be less than 8 characters', trigger: 'change' }
                    ],
                    resetcode: [
                        { validator: validateNoSpace, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                        { type: 'string', max: 6, message: 'Code length cannot be greater than 6 digits', trigger: 'change' }
                    ],
                    // passwordCheck: [
                    //     { validator: validatePassCheck, trigger: 'change' }
                    // ]
                }
            }
        },
        methods: {
            handleSubmit(name) {
                this.$refs[name].validate(async (valid) => {
                    if (valid) {
                        this.loading=true
                        let details = Object.assign({},this.changePasswordData)
                        details.username = localStorage.getItem('reset_username')
                        resetPassword(details).then(res=>{
                            this.$Message.success('Password has been changed.');
                            this.$router.push({name:'welcome'})
                            this.loading=false
                        }).catch(err=>{
                            this.loading=false
                            let err_data = err.response.data
                            if(err_data.hasOwnProperty('detail')){
                                let detail = "Request failed"
                                if( err_data['detail']=='Not found.'){
                                    detail = "Username not found"
                                }
                                if( err_data['detail']=='Wrong code.'){
                                    detail = "Wrong reset code provided."
                                }
                                if( err_data['detail']=='Retry limit. New code sent.'){
                                    detail = "Retry limit. New code sent."
                                }
                                this.$Notice.error({
                                    desc: detail
                                });
                            }
                        })
                    }
                })
            }
        }
    }
</script>
import { request } from "./base";
import {removeUpclubSession} from "@/services/authentication.service";
import { EventBus } from '@/eventBus';

const requestInterceptor = (store) => {
    request.interceptors.request.use(function(config) {
      if (store.getters["upclubAuth/getAccessToken"]) {
        config.headers.common[
          "Authorization"
        ] = `Token ${store.getters["upclubAuth/getAccessToken"]}`;
      }
      return config;
    }, null);
  };

const responseInterceptor = (store) => {
    request.interceptors.response.use(null, async (error) => {
      if(error.response){
        const { status, data } = error.response;
        if (status === 400 || status === 401) {
          EventBus.$emit('REQUEST_ERROR', status, data);
          if(status===401){
            removeUpclubSession()
          }
        }
        if (status !== 401) {
          return Promise.reject(error);
        }
      }else{
        EventBus.$emit('REQUEST_ERROR', null, error.message);
      }
    });
  };

const interceptors = (store) => {
requestInterceptor(store)
responseInterceptor(store)
};

export default interceptors;
import { request } from "./base";


export function getPost(id) {
    return request.get(`v1/getsongpost/${id}`);
}

export function postSong(data) {
    return request.post("v1/postsong/",data);
}

export function likeSongPost(data) {
    return request.post("v1/likesongpost/",data);
}

export function unlikeSongPost(data) {
    return request.post("v1/unlikesongpost/",data);
}

export function deleteSongPost(id) {
    return request.delete(`v1/deletesong/${id}`);
}

export function searchPostSong(title,params) {
    return request.get(`v1/searchsongposttitle/${title}`, {params});
}


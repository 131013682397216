import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentication from './Authentication.router'
import Content from './Content.router'
import Admin from './Admin.router'
import { isUpclubAuthenticated, isSpotifyAuthenticated } from "../services/authentication.service"
import { hasPermission } from "../services/upclub/permission"
import store from "@/store";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [Authentication,Content,Admin]
})
if (process.env.NODE_ENV != 'local' && window.location.protocol!=='https:') {
  window.location.replace(`https://${window.location.hostname}${window.location.pathname}`);
}
router.beforeEach((to, from, next) => {
  if (to.matched.length) {
    if(to.meta.requiredAuth){
      if(isUpclubAuthenticated()){
        if(hasPermission(to)){
          if(to.name == "authorize_spotify" && !isSpotifyAuthenticated()){
            next();
          }
          else{
            if(isSpotifyAuthenticated()){
              // if (!store.getters["auth/getAccessToken"]) {
              //   store.dispatch("auth/getRefreshToken").then(res=>{
              //     next();
              //   }).catch(err=>{
              //     localStorage.clear()
              //     next({ name: "login" });
              //   });
              // }
              next();
            }else{
              next({ name: "authorize_spotify" });
            }
          }
        }else{
          next({ name: "discover" });
        }
      }else {
        let query = JSON.stringify({path:to.path,query:to.query})
        localStorage.setItem('next_page', query)
        next({ name: "login", query:{next:query}});
      }
    } else {
      if(to.name == "terms" || to.name == "privacy_policy"){
        next();
      }else{
        if(isUpclubAuthenticated()){
          if(isSpotifyAuthenticated()){
            next({ name: "discover" });
          }else{
            next({ name: "authorize_spotify" });
          }
        }else {
          next();
        }
      }
    }
  }else {
    next({ name: "signup" });
  }
});

export default router

<template>
    <div v-if="upclubuser">
        <p class="no-data" v-if="showNoData">No followings found</p>                         
        <Follow :users="followings" @setFollowings="setFollowings" :list-class="'flex-inline-columns three-cols'" :item-class="'white-box'"></Follow>
        <infinite-loading @infinite="getFollowings"></infinite-loading>   
    </div>
</template>

<script>
    import { getProfileFollowing } from '@/services/upclub/follow';
    import Follow from '@/components/follow';
    export default {
        props:['upclubuser'],
        data () {
            return {
                showNoData:false,
                next_request_uri:null,
                followings:[]
            }
        },
        components: {Follow},
        computed: {
            
        },
        methods: {
            getFollowings($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                getProfileFollowing(this.upclubuser, queryParams).then(res=>{
                    this.followings = this.followings.concat(res.data.results)
                    this.next_request_uri = res.data.next
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                        if(this.followings.length===0){
                            this.showNoData=true
                        }
                    }
                }).catch(err=>{
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                        if(this.followings.length===0){
                            this.showNoData=true
                        }
                    }
                }) 
            },
             setFollowings(method,user_id){
                if(!this.$route.params.username){
                    this.$emit('updateProfileCounts')
                    if(method=="dec"){
                        let index = this.followings.findIndex(following => following.upclubuser_followee==user_id);
                        if(index>=0){
                            this.followings.splice(index,1)
                        }
                    }
                }
            },
        },
        created(){
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

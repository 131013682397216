<template>
   <div class="full-height">
        <div class="full-height white-box message-wrapper">
            <div class="full-height message-threads scroll-container">
                <div class="message-sticky-header">
                    <div class="search-message">
                        <div class="search-field flex-horizontal-centered">
                            <div class="search-btn">
                                <img src="../../assets/images/icons/search.svg" alt="">
                            </div>
                            <i-input type="text" class="search-input" v-model="search_query" placeholder="Search messages" @on-change="searchChanged($event)" clearable></i-input>   
                        </div>
                    </div>
                    <Button shape="circle" class="message-plus-btn" @click="openNewMessageModal()">
                        <img src="../../assets/images/icons/plus.svg" alt="">
                    </Button>
                </div>
                <message-threads ref="messageThreads" :search_query="search_query"></message-threads>
                <Spin size="large" fix v-if="loading"></Spin>
            </div>
            <div class="message-right-col full-height">

                <div v-if="$route.name==='messages'" class="message-box full-height compose-message flex-vertical-centered">
                    <div class="compose-message-content">
                        <div class="compose-msg-icon">
                            <img src="../../assets/images/icons/message.svg" alt="">
                        </div>
                        <h2>Your Messages</h2>
                        <p>Send private messages and songs to a friend or group.</p>
                        <Button size="large" class="black-btn btn new-msg" @click="openNewMessageModal()">New Message</Button>
                    </div>
                </div>
                <router-view ref="messageBox" v-else @insertUpdatedThread="insertUpdatedThread" @removeThread="removeThread" :new_thread="new_thread"></router-view>            
            </div>
        </div>
        <search-user-modal ref="newMessage" @onSubmit="checkThread" :title="'New message'" :submitText="'Create Message'"></search-user-modal>
    </div>
</template>

<script>
    import {checkMessageThread} from '@/services/upclub/messages'
    import { getProfile } from '@/services/upclub/profile';
    import MessageThreads from './MessageThreads'
    import SearchUserModal from '@/components/messages/SearchUserModal';

    export default {
        data () {
            return {
                loading:false,
                search_query:'',
                idletimer:null,
                create_loading: false,
                new_thread:null
            }
        },
        components: {MessageThreads,SearchUserModal},
        computed: {

        },
        methods: {
            searchChanged(){
                let vm=this
                clearTimeout(this.idletimer);
                this.idletimer =  setTimeout(async()=>{ 
                    vm.$refs.messageThreads.infiniteReload()
                }, 600);
            },
            checkThread(selected_users){
                this.loading=true
                let upclubusers = {upclubusers: selected_users.map(user=> user.upclubuser)}
                checkMessageThread(upclubusers).then(res=>{
                    this.loading=false
                    if(res.data.messagethread){
                        this.$router.push({name:'message_box',params:{id:res.data.messagethread}}).catch(err => {})
                    }else{
                        let new_thread = {
                            messagethread: 'new',
                            already_read: false,
                            notification_age: '1s',
                            members: {
                                upclubusers_count: selected_users.length,
                                upclubusers: selected_users
                            },
                            latest_message: {
                                upclubuser : null,
                                username : null,
                                text : null,
                                song_post : null
                            }
                        }
                        this.new_thread = new_thread
                        this.$refs.messageThreads.insertNewThread(new_thread)
                        this.$router.push({name:'message_box',params:{id:'new'}}).catch(err => {})
                    }
                }).catch(err=>{
                    this.loading=false
                })
            },
            reloadThreads(){
                this.$refs.messageThreads.infiniteReload()
            },
            insertUpdatedThread(thread_id,type){
                this.$refs.messageThreads.insertUpdatedThread(thread_id,type)
            },
            removeThread(thread_id){
                this.$refs.messageThreads.removeThread(thread_id)
            },
            openNewMessageModal(){
                this.$refs.newMessage.openModal()
            },
            checkProfileThread(profile_id){
                getProfile(profile_id).then(res=>{
                    if(res){
                        let profile = res.data
                        profile.thumbnail_picture = profile.profile_picture
                        this.checkThread([profile])
                    }
                })
            },
            newMessageNotif(){
                this.$refs.messageBox.reloadMessages()
            }
        },
        mounted() {
            localStorage.removeItem('message_track_uris')
            if(this.$route.query.profile){
                let decrypt_profile_id = atob(this.$route.query.profile)
                let profile_id = parseInt(decrypt_profile_id.split(':')[1])
                this.checkProfileThread(profile_id)
            }
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.gray-button{
    width:300px;
    background-color: #5B5B5B;
    border-radius:25px;
    font-size: 1.3rem;
    color: #fff;
    height: auto;
    margin: 0 auto;
    display: block;
    padding: 4px;
    border:none;
}
</style>

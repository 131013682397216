<template>
    <div class="profile-details"  v-if="userprofile.upclubuser">
        <div class="image">
            <img :src="getProfileImage()" alt="">
        </div>
        <div class="details">
            <h2 class="fullname" v-if="userprofile.first_name || userprofile.last_name ">{{ userprofile.first_name }} {{ userprofile.last_name }}</h2>
            <span class="nickname">@{{ userprofile.username }}</span>
            <div>
                <p class="description">{{ userprofile.profile_description }}</p>
                <div class="action" v-if="$route.params.username">
                    <Button size="small" @click="followUser()" class="black-btn btn" v-if="!userprofile.followed">Follow</Button>
                    <Button size="small" @click="unfollowUser()" class="gray-btn btn" v-if="userprofile.followed">Unfollow</Button>
                    <Button size="small" @click="sendMessage()" class="gray-btn btn">Message</Button>
                    <a href=""><img class="more-icon" src="../../assets/images/icons/more.svg" alt=""></a>
                    <!-- <a :href="getSocialLink('instagram')" target="_blank" v-if="userprofile.profile_instagram"><img class="instagram" src="../../assets/images/profile/instagram.png" alt=""></a>
                    <a :href="getSocialLink('twitter')" target="_blank" v-if="userprofile.profile_twitter"><img class="twitter" src="../../assets/images/profile/twitter.png" alt=""></a> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { follow, unfollow } from '@/services/upclub/follow';
    export default {
        props:['profile'],
        data () {
            return {
                editModal:false,
                profileImage: "",
                followers:[]
            }
        },
        components: {},
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
            userprofile(){
                return this.profile
            }
        },
        methods: {
            ...mapActions({
                getUserProfile: "upclubAuth/getUserProfile",
            }),
            followUser(){
                follow({upclubuser_followee:this.profile.upclubuser}).then(res=>{
                    this.profile.followers=parseInt(this.profile.followers)+1
                    this.$Notice.success({
                        desc: `You are now following ${this.profile.username}`
                    });
                    this.$emit('setIsFollowing',true)
                })
            },
            unfollowUser(){
                unfollow({upclubuser_followee:this.profile.upclubuser}).then(res=>{
                    this.profile.followers=parseInt(this.profile.followers)-1
                    this.$Notice.success({
                        desc: `You had just unfollowed ${this.profile.username}`
                    });
                    this.$emit('setIsFollowing',false)
                })
            },
            getSocialLink(social){
                let link="#"
                if(social==="instagram"){
                    link =`https://www.instagram.com/${ this.profile.profile_instagram }`
                }
                if(social==="twitter"){
                    link = `https://www.twitter.com/${ this.profile.profile_twitter }`
                }
                return link
            },
            getProfileImage(){
                return "data:image/png;base64,"+this.profile.profile_picture
            },
            sendMessage(){
                const encryptedID = btoa(`7hUCSGnsxFP7DkPq:${this.profile.upclubuser}`)
                this.$router.push({name:'messages', query:{profile:encryptedID}}).catch(err=>{})
            }
        },
        created(){
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

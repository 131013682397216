<template>
    <div class="full-height flex-vertical-centered mobile-view">
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <div class="mobile-content">
            <img src="../assets/images/stacked_upclub_logo.svg" class="stacked-logo">
            <h1>Music sharing sucks, we’re here to fix it.</h1>
            <p>Join UpClub today.</p>
            <a href="https://apps.apple.com/us/app/up-club-music/id1577383041"><img src="../assets/images/app-store.png" class="app-btn"></a>
            <a href="https://play.google.com/store/apps/details?id=com.upclubmusic.android"><img src="../assets/images/play-store.png" class="app-btn"></a>
        </div>
    </div>
</template>
<script>

    export default {
        data () {
            return {
            }
        },
        components:{},
        computed: {
        },
        methods: {

        },
        mounted() {
         
        }
    }
</script>

<style scoped>
h1{
    line-height: 1.4;
    margin: 32px auto;
    font-size: 1.5rem;
}
p{
    line-height: 1;
    margin-bottom: 0;
}
.mobile-view{
    align-items: center;
}
.mobile-content{
    width: 300px;
    text-align: center;
}
.stacked-logo{
    width: 140px;
    margin: 0 auto;
}
.app-btn{
    width: 190px;
    margin: 12px auto;
    cursor: pointer;
}
</style>
<template>
    <div class="search-track-container">
        <div class="back-button" @click="$router.push({name:'post'})"><Icon type="ios-arrow-back" /></div>
        <div class="spotify-post">
            <div>
                <h2>Post from your Spotify</h2>
                <div class="search-field-container">
                    <img src="../../assets/images/icons/search.svg" class="search-icon" alt="">
                    <i-input class="search-input" v-model="searchVal" @on-change="searchChanged($event)">
                    </i-input>
                </div>
            </div>
            <RadioGroup v-model="filter" type="button" class="filter-options" @on-change="searchChanged(searchVal)">
                <Radio label="track"></Radio>
                <Radio label="artist"></Radio>
                <Radio label="album"></Radio>
            </RadioGroup>
        </div>
        <track-list ref="postPlaylist" :trackList="trackList"></track-list>
        <infinite-loading @infinite="getSearchData" :identifier="infiniteId"></infinite-loading>              
    </div>
</template>

<script>
    import {getMyTracks} from '@/services/spotify/playlist'
    import {search} from '@/services/spotify/search'
    import {getAlbumTracks} from '@/services/spotify/albums'
    import {getArtistTopTracks} from '@/services/spotify/artists'
    import TrackList from '@/components/trackSearchList/Tracks'


    export default {
        data () {
            return {
                infiniteId:0,
                idletimer:null,
                searchVal:"",
                offset:0,
                filter:"track",
                trackList:[]
            }
        },
        components: {TrackList},
        methods: {
            searchChanged(val){
                clearTimeout(this.idletimer);
                this.idletimer =  setTimeout(async()=>{ 
                    this.trackList=[]
                    this.offset=0
                    this.infiniteId += 1
                }, 600);
            },
            getSearchData($state){
                if(this.searchVal===""){
                    this.getMyTracks($state)
                }else{
                    if(this.filter==="artist"){
                        this.getArtistResults($state)
                    }else if(this.filter==="album"){
                        this.getAlbumResults($state)
                    }else{
                        this.getTrackResults($state)
                    }
                }
            },
            getMyTracks($state){
                getMyTracks(this.offset,10).then(res=>{
                    if(res.data.items.length>0){
                        this.offset+=10
                        let tracks_arr = res.data.items.map(track=>{return track.track})
                        this.trackList = this.trackList.concat(tracks_arr)
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            getTrackResults($state){
                search(this.searchVal,this.filter,this.offset,10).then(res=>{
                    if(res.data.tracks.items.length>0){
                        this.offset+=10
                        this.trackList = this.trackList.concat(res.data.tracks.items)
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            getArtistResults($state){
                search(this.searchVal,this.filter,this.offset,10).then(res=>{
                    if(res.data.artists.items.length>0){
                        res.data.artists.items.forEach(artist => {
                            getArtistTopTracks(artist.id).then(toptracks=>{
                                this.offset+=10
                                this.trackList = this.trackList.concat(toptracks.data.tracks)
                                $state.loaded();
                            })
                        });
                    }else{
                        $state.complete();
                    }
                })
            },
            getAlbumResults($state){
                search(this.searchVal,this.filter,this.offset,10).then(res=>{
                    if(res.data.albums.items.length>0){
                        res.data.albums.items.forEach(album => {
                            getAlbumTracks(album.id).then(tracks=>{
                                console.log(tracks)
                                this.offset+=10
                                let tracks_with_album = tracks.data.items.map(track=>{
                                    track.album = album
                                    return track
                                })
                                this.trackList = this.trackList.concat(tracks_with_album)
                                $state.loaded();
                            })
                        });
                    }else{
                        $state.complete();
                    }
                })
            }
        },
        mounted() {

        },
        created(){
        }
    }
</script>

<style scoped></style>



<template>
  <div>
    <Mentionable
      :keys="['@']"
      :items="items"
      :placement="'auto-start'"
      offset="6"
      filtering-disabled
      @open="loadUsers()"
      @search="loadUsers($event)"
      @apply="selectuser($event)"
      @mentionIndex="setMentionIndex"
    >
     <div class="ivu-input-wrapper" v-if="fieldType==='input'">
      <!-- <input type="text" 
          v-model="text"
          class="ivu-input"
          :placeholder="placeholder"
          :disabled="disabled"
        /> -->
        <i-input :placeholder="placeholder" :disabled="disabled" v-model="text" class="comment-input"></i-input>
     </div>
 
      <!-- <textarea v-else
        v-model="text"
        rows="6"
        class="input"
        :disabled="disabled"
        :placeholder="placeholder"
      /> -->
      <i-input v-else type="textarea" v-model="text" :placeholder="placeholder" :autosize="autosize"></i-input>

      <template #no-result>
        <div class="dim text-center">
          <span v-if="loading">
             <Spin>
                <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
            </Spin>
          </span>
          <span v-else>No result</span>
        </div>
      </template>

      <template #item-@="{ item }"  @click="selectuser(item.value)">
        <div class="user flex-horizontal-centered">
          <span class="user-profile">
              <img :src="getImage(item.thumbnail_picture)"  @click="goToProfile(item.username)" class="user-thumb"/>
          </span>
          <span class="dim">
            {{ item.label }}
          </span>
        </div>
      </template>
    </Mentionable>

    <!-- <div class="preview">{{ items }}</div> -->
  </div>
</template>
<script>
// import { Mentionable } from 'vue-mention'
import { Mentionable } from '@/components/customMentionable'
import {searchUsernameBase, searchUsernameDefault} from '@/services/upclub/profile'

export default {
  props:{
      fieldType:{
        type: String,
        default:'textarea'
      },
      disabled:{
        type:Boolean,
        default:false,
      },
      placeholder:{
        type:String,
        default:'Enter message',
      },
      autosize:{
        type: Array,
        default: () => {},
      }
  },
  components: {
    Mentionable,
  },

  data () {
    return {
      request_next_url:null,
      idletimer:null,
      text: '',
      mention_text: '',
      items: [],
      mention_ids:[],
      tentative_mentions:[],
      loading: false,
    }
  },

  methods: {
    searchUsers(searchText = null){
      let vm=this
      let queryParams = {}
      if(vm.request_next_url){
          queryParams = vm.getUrlParams(vm.request_next_url)    
      }
      clearTimeout(vm.idletimer);
      vm.idletimer = setTimeout(async()=>{ 
        searchUsernameBase(searchText,queryParams).then(async res=>{
          vm.request_next_url = res.data.next
          // let resultItems = res.data.results
          let resultItems = res.data.results.map(user=>{
            return {id:user.upclubuser,value:user.username,label:user.username, thumbnail_picture: user.thumbnail_picture}
          })
          vm.items = resultItems
          vm.loading = false
        })
      }, 300);
    },
    searchDefaultUsers(){
      let vm=this
      clearTimeout(vm.idletimer);
      vm.idletimer = setTimeout(async()=>{ 
        searchUsernameDefault().then(async res=>{
          vm.request_next_url = res.data.next
          let resultItems = res.data.results.map(user=>{
            return {id:user.upclubuser,value:user.username,label:user.username, thumbnail_picture: user.thumbnail_picture}
          })
          vm.items = resultItems
          vm.loading = false
        })
      }, 600);
    },
    loadUsers (searchText = null) {
      this.loading = true
      this.items=[]
      this.request_next_url=null
      this.idletimer=null
      if(searchText){
        this.searchUsers(searchText)
      }else{
        this.searchDefaultUsers()
      }
    },
    selectuser(user, searchText){
      // this.mention_ids.push(user.value)
      this.tentative_mentions.push(user)
    },
    setMentionIndex(startIndex, endIndex){
      // console.log(startIndex, endIndex)
    },
    getPostDetails(){
      let mentions=[]
      let text = this.text
      this.tentative_mentions.forEach((mentioned_user,index) => {
          if(text.includes(`@${mentioned_user.value}`)){
            if(mentions.indexOf(mentioned_user.id)===-1){
              mentions.push(mentioned_user.id)
            }
            text = text.replace(new RegExp(`@${mentioned_user.value}`),`<@${mentioned_user.id}>`)
          }
      });
      let post_details = {
        text: text,
        mentions: mentions
      }
      return post_details
    },
    setMentionText(text){
      this.mention_text=text
    },
    clearDetails(){
      this.request_next_url=null
      this.idletimer=null
      this.text=''
      this.mention_text= ''
      this.items=[]
      this.mention_ids=[]
      this.tentative_mentions=[]
    }
  },
}
</script>
<style scoped>
.input {
  width: 100%;
  border: #ccc 1px solid;
  border-radius: 6px;
  resize: vertical;
  min-height: 42px;
  padding: 12px;
  box-sizing: border-box;
  line-height: 1.2em;
  font-size: inherit;
}

.user {
  width: calc(100% + 20px);
  padding: 4px 16px;
  margin-left: -10px;
  border-radius: 0;
  cursor: pointer;
}
.user .user-profile{
  margin-right: 8px;
}
.mention-selected .user {
  background: #E8E8DE;
}

.dim {
  color: #666;
}

.preview {
  font-family: monospace;
  white-space: pre-wrap;
  margin-top: 12px;
  padding: 12px;
  background: #f8f8f8;
  border-radius: 6px;
}

</style>
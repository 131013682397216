<template>
    <div class="auth-form">
        <img class="logo" src="../../assets/images/upclub_logo_large.svg" @click="$router.push({name:'welcome'})">
        <Form ref="loginForm" :model="loginData" :rules="loginRules" @keyup.enter.native="handleSubmit('loginForm')" class="white-box white-form">
            <FormItem prop="username">
                <i-input type="text" v-model="loginData.username" placeholder="Username"></i-input>
            </FormItem>
            <FormItem prop="password">
                <i-input type="password" v-model="loginData.password" placeholder="Password"></i-input>
            </FormItem>
            <Button size="large"  class="black-btn btn" long :loading="loading" @click="handleSubmit('loginForm')">
                <span v-if="!loading">Login</span>
            </Button>
        </Form>
        <div class="forgot-links">
            <a @click="$router.push({name:'forgot_username'})" class="small-text">Forgot Username</a>
            <a @click="$router.push({name:'forgot_password'})" class="small-text">Forgot Password</a>
        </div>
        <div class="redirect-link">
            <span class="gray-text">New to Up Club?</span> 
            <a @click="$router.push({name:'signup'})">Sign up</a>
        </div>
    </div>
</template>
<script>
    import { setSession, removeSession } from '../../services/authentication.service'
    import { getAuthorizeURL } from '../../services/spotify/auth';
    import { mapActions } from "vuex";
    import '@/plugins/mixin'
    export default {
        data () {
            const validateNoSpace = (rule, value, callback) => {
                let regex = /\s/

                 if (value && regex.test(value)) {
                    callback(new Error('Field should not have spaces'));
                } else {
                    callback();
                }
            };

            return {
                loading: false,
                loginData: {
                    username: '',
                    password: ''
                },
                loginRules: {
                    username: [
                        { validator: validateNoSpace, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                    ],
                    password: [
                        { validator: validateNoSpace, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                        // { type: 'string', min: 8, message: 'The password length cannot be less than 8 characters', trigger: 'change' }
                    ]
                }
            }
        },
        methods: {
            ...mapActions({
                spotifyGetAuthToken: "spotifyAuth/getAuthToken",
                spotifySetAuthState: "spotifyAuth/setAuthState",
                upclubGetAuthToken: "upclubAuth/getAuthToken",
                upclubGetUserProfile: "upclubAuth/getUserProfile",
                }),
            async getAuthorizeCode(){
                let state = this.generateKey(8)
                let code_verifier = await this.generateKey(43)
                let code_challenge = await this.generateCodeChallenge(code_verifier)
                localStorage.setItem('authorize_state', state)
                localStorage.setItem('code_verifier', code_verifier)
                this.spotifySetAuthState(state);
                let auth_url = getAuthorizeURL(state, code_challenge)
                window.location.href=auth_url
                return false;
            },
            async handleSubmit(name) {
                this.$refs[name].validate(async(valid) => {
                    if (valid) {
                        this.loading=true
                        this.upclubGetAuthToken(this.loginData).then(async res=>{
                            this.loading=false
                            this.upclubGetUserProfile().then(async profile=>{
                                if(!localStorage.hasOwnProperty('authorize_code') && !localStorage.hasOwnProperty('upclub_spotify_access')){
                                    this.getAuthorizeCode()
                                }else{
                                    let data={
                                        grant_type: 'authorization_code',
                                        code: localStorage.getItem('authorize_code')
                                    }
                                    if(localStorage.hasOwnProperty('upclub_spotify_access')){
                                        try{
                                            let upclub_spotify_access = localStorage.getItem('upclub_spotify_access')
                                            let decrypt_access = this.CryptoJS.AES.decrypt(upclub_spotify_access, "P-SAE*JQsb359kUjKUuZ@ZUC-yw3Srjz").toString(this.CryptoJS.enc.Utf8)
                                            let split_access = decrypt_access.split(':')
                                            let username = split_access[0]
                                            let refresh_token = split_access[1]
                                            if(this.loginData.username===username){
                                                data={
                                                    grant_type: 'refresh_token',
                                                    refresh_token: refresh_token
                                                }
                                            }else{
                                                localStorage.removeItem('recent_user_search')
                                                localStorage.removeItem('recent_post_search')
                                            }
                                        }catch{
                                            localStorage.removeItem('upclub_spotify_access')
                                            this.getAuthorizeCode()
                                        }
                                    }
                                    this.spotifyGetAuthToken(data).then(res=>{
                                        if(this.$route.query.next){
                                            let next = JSON.parse(this.$route.query.next)
                                            this.$router.push(next)
                                            localStorage.removeItem('next_page')
                                        }else{
                                            this.$router.push({name:'discover'})
                                        }
                                    }).catch(err=>{
                                        // localStorage.removeItem('upclub_spotify_access')
                                        this.getAuthorizeCode()
                                    });
                                } 
                            })
                        }).catch(err=>{
                            this.loading=false
                        });
                        
                    }
                })
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>

</style>
import { request } from "./base";
import store from "@/store";

export function getAPIversion() {
    return request.get("v1/backendversion/");
}

export function register(data) {
    return request.post("v1/register/",data);
}

export function login(data) {
    let fcm_id = store.getters["upclubPushNotif/getFCMID"]
    if(fcm_id.length!==0){
        data.fcm_device = fcm_id
        return request.post("v1/login2/",data);
    }else{
        return request.post("v1/login/",data);
    }
}

export function logout() {
    let fcm_id = store.getters["upclubPushNotif/getFCMID"]
    if(fcm_id){
        return request.post("v1/logout2/",{"fcm_device":fcm_id});
    }else{
        return request.post("v1/logout/");
    }
}

export function resetPasswordCode(data) {
    return request.post("v1/sendcode/",data);
}

export function resetPassword(data) {
    return request.post("v1/resetpassword/",data);
}

export function sendUsername(data) {
    return request.post("v1/sendusername/",data);
}

<template>
    <div class="message-box chat-contents full-height">
        <div class="chat-header flex-horizontal-centered">
            <div class="messagebox-member">
                 <div class="flex-horizontal-centered" v-if="message_thread.messagethread">
                    <div class="message-member-thumb small">
                        <div class="single" v-if="message_thread.members.upclubusers_count===1">
                            <img :src="getImage(message_thread.members.upclubusers[0].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[0].username)" alt="">
                        </div>
                        <div class="double" v-if="message_thread.members.upclubusers_count===2">
                            <img :src="getImage(message_thread.members.upclubusers[0].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[0].username)" alt="">
                            <img :src="getImage(message_thread.members.upclubusers[1].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[1].username)" alt="">
                        </div>
                        <div class="triple" v-if="message_thread.members.upclubusers_count===3">
                            <img :src="getImage(message_thread.members.upclubusers[0].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[0].username)" alt="">
                            <img :src="getImage(message_thread.members.upclubusers[1].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[1].username)" alt="">
                            <img :src="getImage(message_thread.members.upclubusers[2].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[2].username)" alt="">
                        </div>
                        <div class="more" v-if="message_thread.members.upclubusers_count>3">
                            <img :src="getImage(message_thread.members.upclubusers[0].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[0].username)" alt="">
                            <img :src="getImage(message_thread.members.upclubusers[1].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[1].username)" alt="">
                            <img :src="getImage(message_thread.members.upclubusers[2].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[2].username)" alt="">
                            <img :src="getImage(message_thread.members.upclubusers[3].thumbnail_picture)" @click="goToProfile(message_thread.members.upclubusers[3].username)" alt="" v-if="message_thread.members.upclubusers_count>4">
                            <Badge :count="message_thread.members.upclubusers_count-3" overflow-count="999" v-else class="message-member-badge"></Badge>
                        </div>
                    </div>
                    <p class="chathead-members" v-if="message_thread.messagethreadname">{{message_thread.messagethreadname}}</p>
                    <p class="chathead-members" v-else>
                        <a v-for="(member,i) in message_thread.members.upclubusers" :key="i" @click="goToProfile(member.username)">
                            {{member.username}}<span v-if="i!==message_thread.members.upclubusers_count-1">, </span>
                        </a>
                    </p>
                </div>
            </div>
            <img v-if="message_thread.messagethread!=='new'" src="../../assets/images/icons/settings.svg" class="message-setting-trigger" alt="" @click="goToSettings()">
        </div>
        <Tabs :animated="false" class="message-tab full-height" @on-click="onTabChanged" v-model="activeTab">
            <TabPane label="all">
                <all-messages ref="allMessages"></all-messages>
            </TabPane>
            <TabPane label="songs">
                <post-messages ref="postMessages" @changeTab="changeTab"></post-messages>
            </TabPane>
        </Tabs>
        <div class="flex-horizontal-centered chat-footer">
            <img class="user-thumb" :src="getImage(getUserDetails.profile_picture)"  @click="goToProfile(getUserDetails.username)" alt="">
            <Form ref="messageForm" :model="message_data" :rules="messageRules" @submit.native.prevent  @keyup.enter.native="sendMessage()">
                <FormItem prop="message" class="message-input comment">
                    <Input placeholder="type your message..." v-model="message_data.message">
                        <div slot="append" class="message-send-icon trigger-icon" @click="sendMessage()">
                            <span v-if="!sendingMessage">
                                <img src="../../assets/images/icons/plus.svg"  v-if="message_data.message.length===0" alt="">
                                <span v-else>Send</span>
                            </span>
                            <div class="spin-col field-loader" v-else span="8">
                                <Spin fix>
                                    <Icon type="ios-loading" color="#57a3f3" size=24 class="spin-icon-load"></Icon>
                                </Spin>
                            </div>
                        </div>
                    </Input>
                </FormItem>
            </Form>
        </div>
        <search-spotify ref="searchSpotify" :save_as="'message'" :message_thread="message_thread" @onSaveSuccess="onSendSuccess"></search-spotify>
        <message-settings ref="settings" :message_thread="message_thread" @onLeave="$emit('removeThread',message_thread.messagethread)" @onSave="onSettingSaved"></message-settings> 
    </div>
</template>

<script>
    import {createMessage,getMessageThreadInfo} from '@/services/upclub/messages'
    import {mapGetters, mapActions} from "vuex";
    import AllMessages from './AllMessages'
    import PostMessages from './PostMessages'
    import SearchSpotify from  '@/components/searchSpotify'
    import MessageSettings from './MessageSettingsModal'

    export default {
        props:['new_thread'],
        data () {
            const validateNotSpaceOnly = (rule, value, callback) => {
                 if (value.length === value.replace(/\s/g, '').length) {
                    callback(new Error(''));
                } else {
                    callback();
                }
            };

            return {
                activeTab:0,
                sendingMessage: false,
                next_request_uri:null,
                infiniteId:0,
                message_thread:{
                    members:{
                        upclubusers:[]
                    }
                },
                message_data:{
                    message:"",
                },
                messageRules: {
                    message: [
                        // { validator: validateNotSpaceOnly, trigger: 'change' },
                        { required: true, message: ' ', trigger: 'onsubmit' },
                    ]
                },
            }
        },
        components: {AllMessages,PostMessages,SearchSpotify,MessageSettings},
        computed: {
            ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
        },
        methods: {
            ...mapActions({
                setThreadTrackList: "upclubMessages/setThreadTrackList",
            }),
            goToSettings(){
                // this.$router.push({name:'message_settings',params:{id:this.$route.params.id}})
                this.$refs.settings.openModal()
            },
            onTabChanged(tab){
                this.setThreadTrackList([])
                if(tab===1){
                    this.$refs.postMessages.infiniteReload()
                }else{
                    this.$refs.allMessages.infiniteReload()
                }
            },
            changeTab(tab){
                this.activeTab=tab
                if(tab===1){
                    this.$refs.postMessages.infiniteReload()
                }else{
                    this.$refs.allMessages.infiniteReload()
                }
            },
            getThreadDetails(){
                if(this.$route.params.id==='new'){
                    this.message_thread=this.new_thread
                }else{
                    getMessageThreadInfo(this.$route.params.id).then(res=>{
                        this.message_thread=res.data
                    })
                }
            },
            getMemberNames(members){
                 let names = members.map(member=>{return member.username })
                 return names
            },
            openSearchSpotify(){
                this.$refs.searchSpotify.openSearchTrackModal()
            },
            sendMessage(){
                if(this.message_data.message.length){
                    this.$refs.messageForm.validate(async(valid) => {
                        if (valid) {
                            this.sendingMessage=true
                            let data = {}
                            if(this.$route.params.id==='new'){
                                data = {
                                    messagethread: null,
                                    text: this.message_data.message,
                                    song_post:null,
                                    upclubusers: this.new_thread.members.upclubusers.map(user=> user.upclubuser)
                                }  
                            }else{
                                data = {
                                    messagethread: this.$route.params.id,
                                    text: this.message_data.message,
                                }
                            }
                            
                            createMessage(data).then(res=>{
                                this.sendingMessage=false
                                this.message_data={
                                    message:"",
                                }
                                this.$emit('insertUpdatedThread', parseInt(res.data.messagethread),'new_message')
                                this.onSendSuccess(res.data)
                                
                            }).catch(err=>{
                                this.sendingMessage=false
                            })
                        }
                    })
                }else{
                    this.openSearchSpotify()
                }
            },
            onSendSuccess(data){

                if(this.$route.params.id==='new'){
                    this.$router.push({name:'message_box',params:{id:data.messagethread}}).catch(err => {})
                }else{
                    var container = this.$el.querySelector("#chatbox-all");
                    if(this.activeTab===1){
                        this.$refs.postMessages.assignNewMessageGroup(data)
                        container = this.$el.querySelector("#chatbox-post");
                    }else{
                        this.$refs.allMessages.assignNewMessageGroup(data)
                        container = this.$el.querySelector("#chatbox-all");
                    }
                    container.scrollTop = container.scrollHeight+100;
                    // this.$refs.allMessages.infiniteReload()
                    // this.$refs.postMessages.infiniteReload()
                }
            },
            onSettingSaved(thread_id){
                this.getThreadDetails()
                this.$emit('insertUpdatedThread',thread_id,'update_thread')
            },
            reloadMessages(){
                this.$refs.allMessages.infiniteReload()
                this.$refs.postMessages.infiniteReload()
                this.getThreadDetails()
                this.setThreadTrackList([])
            }
        },
         watch: {
            '$route.params.id': function () {
                this.reloadMessages()
            }
        },
        mounted() {
            this.getThreadDetails()
            if(this.$route.params.id==='new' && !this.new_thread){
                this.$router.push({name:'messages'}).catch(err=>{})
            }
        },
        beforeDestroy(){
            this.setThreadTrackList([])
        }
    }
</script>

<style scoped>

</style>

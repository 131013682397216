<template>
    <div class="feed-content">
        <page-header>
            <div slot="details" class="header-details">
                <a @click="redirect('discover')" class="header-title" :class="$route.name==='discover'?'active':''" >Discover</a>
                <a @click="redirect('myfeed')" class="header-title" :class="$route.name==='myfeed'?'active':''">My Feed</a>
            </div>
            <Dropdown slot="middle-content" class="round-trigger extra white-dropdown">
                <div>
                    <span>{{categoryLabel}}</span>
                    <img src="../../assets/images/icons/filter.svg" alt="">
                </div>
                <DropdownMenu slot="list" class="white-box item-no-border">
                    <DropdownItem disabled class="dropdown-label">Sort Feed By:</DropdownItem>
                    <template v-for="(category,i) in category_options" >
                        <DropdownItem :key="i" v-if="category.subcategory.length===0" @click.native="filterChanged(category)">{{category.label}}</DropdownItem>
                        <Dropdown :key="i" placement="right-start" v-else>
                            <DropdownItem class="flex-horizontal-spacebetween" style="align-items:center">
                                {{category.label}}
                                <Icon type="ios-arrow-forward"></Icon>
                            </DropdownItem>
                            <DropdownMenu slot="list" class="white-box item-no-border">
                                <DropdownItem v-for="(subcategory,sub_i) in category.subcategory" :key="sub_i" @click.native="filterChanged(subcategory)">{{subcategory.label}}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                    
                </DropdownMenu>
            </Dropdown>
        </page-header>
        <router-view ref="pageView" :key="routeviewKey" :category="feedcategory"></router-view>
    </div>
</template>

<script>
    import PageHeader from '@/layout/PageHeader'
    export default {
        data () {
            return {
                feedcategory:"new",
                category_label:'Newest',
                routeviewKey:0,
                category_options: [
                    {value:"trending",label:"Trending", subcategory:[]},
                    {value:"new",label:"Newest", subcategory:[]},
                    {value:null,label:'Top', subcategory:[
                        {value:"tod",label:"Daily"},
                        {value:"tow",label:"Weekly"},
                        {value:"tom",label:"Monthly"},
                        {value:"toy",label:"Yearly"},
                        {value:"atg",label:"All Time"}
                    ]}
                ],
            }
        },
        components: {PageHeader},
        computed:{
            categoryLabel(){
                return this.category_label
            },
        },
        methods: {
            redirect(route){
                this.routeviewKey+=1
                this.$refs.pageView.routeChanged(route)
            },
            
            filterChanged(category){
                this.feedcategory=category.value
                this.category_label=category.label
                this.$refs.pageView.filterChanged(category.value)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

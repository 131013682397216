<template>
    <div v-if="upclubuser">
        <p class="no-data" v-if="showNoData">No followers found</p>              
        <Follow :users="followers" @setFollowings="setFollowings" :list-class="'flex-inline-columns three-cols'" :item-class="'white-box'"></Follow>
        <infinite-loading @infinite="getFollowers"></infinite-loading>
    </div>
</template>

<script>
    import { getProfileFollowers } from '@/services/upclub/follow';
    import Follow from '@/components/follow';
    export default {
        props:['upclubuser'],
        data () {
            return {
                showNoData:false,
                next_request_uri:null,
                followers:[]
            }
        },
        components: {Follow},
        computed: {
            
        },
        methods: {
            getFollowers($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                getProfileFollowers(this.upclubuser, queryParams).then(res=>{
                    this.next_request_uri = res.data.next
                    this.followers = this.followers.concat(res.data.results)
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                        if(this.followers.length===0){
                            this.showNoData=true
                        }
                    }
                }).catch(err=>{
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                        if(this.followers.length===0){
                            this.showNoData=true
                        }
                    }
                }) 
            },
            setFollowings(){
                if(!this.$route.params.username){
                    this.$emit('updateProfileCounts')
                }
            }
        },
        created(){
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

<template>
    <div v-if="getUserDetails">
        <p class="no-data" v-if="showNoData">No curators found</p>              
        <Follow :users="curators" :list-class="'flex-inline-columns three-cols'" :item-class="'white-box'"></Follow>
        <infinite-loading @infinite="getCurators"></infinite-loading>
    </div>
</template>

<script>
    import { getClubCurators } from '@/services/upclub/clubs';
    import { mapGetters } from "vuex";
    import Follow from '@/components/follow';
    export default {
        props:['club'],
        data () {
            return {
                showNoData:false,
                next_request_uri:null,
                curators:[]
            }
        },
        components: {Follow},
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            })
        },
        methods: {
            getCurators($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                getClubCurators(this.club.club, queryParams).then(res=>{
                    this.next_request_uri = res.data.next
                    this.curators = this.curators.concat(res.data.results)
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                        if(this.curators.length===0){
                            this.showNoData=true
                        }
                    }
                }).catch(err=>{
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                        if(this.curators.length===0){
                            this.showNoData=true
                        }
                    }
                }) 
            },
            
        },
        created(){
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

<template>
    <Modal v-model="sharePostModal" @on-visible-change="visibleChange($event)" title="Share post" :transfer="true" :lock-scroll="false" class-name="share-post-message white-modal">
        <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <div class="modal-body">
            <div class="search-threads">
                <div class="search-field flex-horizontal-centered">
                    <div class="search-btn">
                        <img src="../../assets/images/icons/search.svg" alt="">
                    </div>
                    <multiselect class="search-multiselect"
                    v-model="selected_items"
                    :options="options"
                    :multiple="true"    
                    :closeOnSelect="false"
                    :preserveSearch="true"
                    label="label"
                    track-by="label" 
                    placeholder="Search Users & Groups"
                    @search-change="searchRemoteMethod"
                    @remove="removeSelected">
                    </multiselect>
                </div>
            </div>
            <threads-and-users ref="threadsAndUsers" :search_query="search_query" @setOptions="setOptions" @setSelected="setSelected"></threads-and-users>
        </div>
        <div slot="footer">
            <Button size="large" class="btn black-btn" long :loading="loading" @click="sharePost()" :disabled="selected_items.length===0">
                <span v-if="!loading">Share Post</span>
            </Button>
        </div>
    </Modal>
</template>

<script>
    import {sharePost} from '@/services/upclub/messages'
    import Multiselect from 'vue-multiselect'
    import ThreadsAndUsers from './ThreadsAndUsers'

    export default {
        data () {
            return {
                loading:false,
                sharePostModal:false,
                idletimer:null,
                search_query:null,
                song_post:null,
                options:[],
                selected_items:[],
            }
        },
        components: {Multiselect, ThreadsAndUsers},
        computed: {

        },
        methods: {
            visibleChange(val){
                if(!val){
                    this.search_query=''
                    this.selected_items=[]
                    this.loading=false
                    this.$refs.threadsAndUsers.clearSelected()
                }
            },
            searchRemoteMethod (query) {
                this.search_query=query
                let vm=this
                clearTimeout(this.idletimer);
                this.idletimer = setTimeout(async()=>{ 
                    vm.$refs.threadsAndUsers.getResults(query)
                }, 600);
                
            },
            async setOptions(type,options){
                let current_options = Object.assign([],this.options)
                let current_values = []
                let merged = []
                if(type==='threads'){
                    current_values = new Set(current_options.map(option => option.messagethread));
                    merged = [...current_options, ...options.filter(option => !current_values.has(option.messagethread))];
                }else{
                    current_values = new Set(current_options.map(option => option.upclubuser));
                    merged = [...current_options, ...options.filter(option => !current_values.has(option.upclubuser))];
                }
                this.options = merged
            },
            async setSelected(items){
                let selected_users = items.reduce((users,item)=>{
                    let item_data = item.split(':')
                    if(item_data[0]==='upclubuser'){
                        users.push(parseInt(item_data[1]))
                    }
                    return users
                },[])
                let selected_threads = items.reduce((threads,item)=>{
                    let item_data = item.split(':')
                    if(item_data[0]==='messagethread'){
                        threads.push(parseInt(item_data[1]))
                    }
                    return threads
                },[])
                this.selected_items = this.options.reduce((selected,option)=>{
                    if(option.hasOwnProperty('upclubuser') && selected_users.indexOf(option.upclubuser)>-1){
                        option.label=option.username
                        selected.push(option)
                    }
                    if(option.hasOwnProperty('messagethread') && selected_threads.indexOf(option.messagethread)>-1){
                        if(option.messagethreadname){
                            option.label=option.messagethreadname
                        }else{
                            if(option.members.upclubusers_count===1){
                                option.label=option.members.upclubusers[0].username
                            }else{
                                let count = option.members.upclubusers_count-1
                                option.label=`${option.members.upclubusers[0].username} (${count})`
                            }
                        }
                        selected.push(option)
                    }
                    return selected
                },[])
            },
            searchQueryChange(query){
                this.search_query=query
            },
            removeSelected(data){
                let label=''
                if(data.hasOwnProperty('messagethread')){
                    label =`messagethread:${data.messagethread}`
                }
                if(data.hasOwnProperty('upclubuser')){
                    label =`upclubuser:${data.upclubuser}`
                }
                this.$refs.threadsAndUsers.removeSelected(label)
            },
            async sharePost(){
                this.loading=true
                let upclubusers = this.selected_items.reduce((users,item)=>{
                    if(item.upclubuser){
                        users.push(item.upclubuser)
                    }
                    return users
                },[])
                let messagethreads = this.selected_items.reduce((threads,item)=>{
                    if(item.messagethread){
                        threads.push(item.messagethread)
                    }
                    return threads
                },[])
                let data = {
                    upclubusers: upclubusers,
                    messagethreads:messagethreads,
                    song_post: this.song_post
                }
                sharePost(data).then(res=>{
                    this.loading=false
                    this.sharePostModal=false
                    this.$Notice.success({
                        desc: `Post successfully shared`
                    });
                }).catch(err=>{
                    this.loading=false
                })
            },
            openModal(song_post){
                this.sharePostModal=true
                this.song_post=song_post
                this.$refs.threadsAndUsers.getResults(null)
            },
        },
        mounted() {
        },
        destroy(){
        }
    }
</script>

<style scoped>
</style>

<template>
    <div class="modal-search-results">
         <CheckboxGroup v-model="selected_items" @on-change="selectedChanged($event)">
            <Checkbox class="black-check thumb-check-list" v-for="(item,i) in search_results" :key="i" :label="item.messagethread?`messagethread:${item.messagethread}`:`upclubuser:${item.upclubuser}`">
            <div v-if="item.messagethread" class="flex-horizontal-centered">
                <div class="message-member-thumb small">
                    <div class="single" v-if="item.members.upclubusers_count===1">
                        <img :src="getImage(item.members.upclubusers[0].thumbnail_picture)" alt="">
                    </div>
                    <div class="double" v-if="item.members.upclubusers_count===2">
                        <img :src="getImage(item.members.upclubusers[0].thumbnail_picture)" alt="">
                        <img :src="getImage(item.members.upclubusers[1].thumbnail_picture)" alt="">
                    </div>
                    <div class="triple" v-if="item.members.upclubusers_count===3">
                        <img :src="getImage(item.members.upclubusers[0].thumbnail_picture)" alt="">
                        <img :src="getImage(item.members.upclubusers[1].thumbnail_picture)" alt="">
                        <img :src="getImage(item.members.upclubusers[2].thumbnail_picture)" alt="">
                    </div>
                    <div class="more" v-if="item.members.upclubusers_count>3">
                        <img :src="getImage(item.members.upclubusers[0].thumbnail_picture)" alt="">
                        <img :src="getImage(item.members.upclubusers[1].thumbnail_picture)" alt="">
                        <img :src="getImage(item.members.upclubusers[2].thumbnail_picture)" alt="">
                        <img :src="getImage(item.members.upclubusers[3].thumbnail_picture)" alt="" v-if="item.members.upclubusers_count>4">
                        <Badge :count="item.members.upclubusers_count-3" overflow-count="999" v-else class="message-member-badge"></Badge>
                    </div>
                </div>
                <div class="message-content" >
                    <div>
                        <p class="message-members" v-if="item.messagethreadname">{{item.messagethreadname}}</p>
                        <p class="message-members" v-else>{{getMemberNames(item.members.upclubusers)}}</p>
                    </div>
                </div>
            </div>
            <div v-else class="flex-horizontal-centered">
                <div class="message-member-thumb small">
                    <div class="single">
                        <img :src="getImage(item.thumbnail_picture)" alt="">
                    </div>
                </div>
                <div class="message-content">
                    <div>
                        <p class="message-members">{{item.username}}</p>
                    </div>
                </div>
            </div>
           
            </Checkbox>
            <infinite-loading :identifier="infiniteId" @infinite="getAllResults"></infinite-loading>
        </CheckboxGroup>
        <div class="no-data" v-if="showNoData">No messages found</div>
    </div>
</template>

<script>
    import {getMessageThreads, getSearchThreadName, getSearchUsername} from '@/services/upclub/messages'
    export default {
        props:['search_query'],
        data () {
            return {
                infiniteId:0,
                showNoData:false,
                next_request_uri:null,
                thread_request_uri:null,
                user_request_uri:null,
                thread_loaded:false,
                user_loaded:false,
                thread_complete:false,
                user_complete:false,
                selected_items:[],
                search_results:[]
            }
        },
        methods: {
            getMemberNames(members){
                 let names = members.map(member=>{return member.username })
                 return names.join(', ')
            },
            getResults(query){
                this.showNoData=false
                this.search_results=[]
                this.next_request_uri=null
                this.thread_request_uri=null
                this.user_request_uri=null
                this.thread_loaded=false
                this.user_loaded=false
                this.thread_complete=false
                this.user_complete=false
                this.infiniteId+=1
            },
            getAllResults($state){
                if(this.search_query){
                    this.searchMessageThreads($state)
                    this.searchMessageUsers($state)
                }else{
                    this.getAllMessageThreads($state)
                }
            },
            getAllMessageThreads($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                getMessageThreads(queryParams).then(res=>{
                    this.next_request_uri = res.data.next
                    this.search_results = this.search_results.concat(res.data.results)
                    let results = Object.assign([],this.search_results)
                    this.$emit('setOptions', 'threads',results)
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        if(this.search_results.length===0){
                            this.showNoData=true
                        }
                        $state.complete();
                    }
                })
            },
            searchMessageThreads($state){
                let queryParams = {}
                if(this.thread_request_uri){
                   queryParams = this.getUrlParams(this.thread_request_uri)    
                }
                getSearchThreadName(this.search_query, queryParams).then(res=>{
                    this.thread_request_uri = res.data.next
                    this.search_results = this.search_results.concat(res.data.results)
                    let results = Object.assign([],res.data.results)
                    this.$emit('setOptions', 'threads',results)
                    if(this.thread_request_uri){
                        this.thread_loaded=true
                        if((this.thread_complete||this.thread_loaded)&&(this.user_complete||this.user_loaded)){
                            $state.loaded();
                        }
                    }else{
                        this.thread_complete=true
                        if((this.thread_complete||this.thread_loaded)&&(this.user_complete||this.user_loaded)){
                            if(this.search_results.length===0){
                                this.showNoData=true
                            }
                            $state.complete();
                        }
                    }
                })
            },
            searchMessageUsers($state){
                let queryParams = {}
                if(this.user_request_uri){
                   queryParams = this.getUrlParams(this.user_request_uri)    
                }
                getSearchUsername(this.search_query, queryParams).then(res=>{
                    this.user_request_uri = res.data.next
                    this.search_results = this.search_results.concat(res.data.results)
                    let results = Object.assign([],res.data.results)
                    this.$emit('setOptions', 'users',results)
                    if(this.user_request_uri){
                        this.user_loaded=true
                        if((this.thread_complete||this.thread_loaded)&&(this.user_complete||this.user_loaded)){
                            $state.loaded();
                        }
                    }else{
                        this.user_complete=true
                        if((this.thread_complete||this.thread_loaded)&&(this.user_complete||this.user_loaded)){
                            if(this.search_results.length===0){
                                this.showNoData=true
                            }
                            $state.complete();
                        }
                    }
                })
            },
            selectedChanged(items){
                this.$emit('setSelected', items)
            },
            removeSelected(data){
                let index = this.selected_items.findIndex(thread => thread==data);
                if(index>=0){
                    this.selected_items.splice(index,1)
                }
            },
            clearSelected(){
                this.selected_items=[]
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>

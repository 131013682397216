import { request } from "./base";

export function getMyTracks(offset,limit) {
    return request.get(`me/tracks?offset=${offset}&limit=${limit}&market=ES`);
}

export function getMyPlaylist(offset,limit) {
    return request.get(`me/playlists?offset=${offset}&limit=${limit}`);
}

export function getPlaylistTracks(id,offset,limit) {
    let fields ="items(track(id,name,artists(name,total_tracks),album(images)))"
    let params={market:'ES',fields:fields}
    if(offset){
        params.offset=offset
    }
    if(limit){
        params.limit=limit
    }

    return request.get(`playlists/${id}/tracks/`,{params:params});
}
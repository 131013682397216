<template>
    <div class="club-playlist">
        <div v-if="showNoData" class="no-data">No results found</div>
        <post-list ref="PostListing" :key="postListKey"></post-list>
        <infinite-loading :identifier="infiniteId" @infinite="getPostPlaylist"></infinite-loading>              
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import PostList from '@/components/postList'
    export default {
        props:['club'],
        data () {
            return {
                showNoData:false,
                category:"new",
                postListKey:0,
                infiniteId:0,
                posts:[],
                next_request_uri:null,
            }
        },
        components: {PostList},
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            })
        },
        methods: {
            filterChanged(category){
                this.postListKey+=1
                this.category=category
                if(this.$refs.PostListing){
                    this.$refs.PostListing.emptyList()
                }
                this.posts=[]
                this.next_request_uri=null
                this.infiniteId+=1
                this.showNoData=false
            },
            async getPostPlaylist($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                
                this.$emit('getClubPosts',$state,queryParams)
            },
            appendPosts($state, posttracks, next_request_uri){
                if(posttracks.length){
                    this.next_request_uri=next_request_uri
                    this.posts = this.posts.concat(posttracks)
                    this.$refs.PostListing.addToList(posttracks)
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                }else{
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        if(this.posts.length){
                            this.showNoData=false
                        }else{
                            this.showNoData=true
                        }
                        $state.complete();
                    }
                }
            },
            getSongPost(posts, song){
                let post = posts.filter(post=>{if(post.song===song) return post})
                return post[0]
            },
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
.track-filters{
    text-align: center;
}
</style>

import { request, client_id } from "./base";

export function getDevices() {
    return request.get("me/player/devices");
}
export function getPlayer() {
    return request.get("me/player");
}
export function getPlayingTrack() {
    return request.get("me/player/currently-playing");
}
export function getRecentlyPlayed() {
    return request.get("me/player/recently-played");
}
export function transferUsersPlayback(device_ids, play = false) {
    return request.put("me/player", {device_ids, play});
}
export function nextTrack(device_id) {
    return request.post("me/player/next", {device_id});
}

export function previousTrack(device_id) {
    return request.post("me/player/previous", {device_id});
}

export function pause(device_id) {
    return request.put("me/player/pause", {device_id});
}

export function play(data) {
    return request.put("me/player/play",data);
}

export function shuffle(state, device_id) {
    return request.put("/me/player/shuffle", null, {params:{state:!state, device_id:device_id}});
}

export function repeat(state, device_id) {
    return request.put("/me/player/repeat", null, {params:{state:state, device_id:device_id}});
}

export function volume(volume_percent, device_id) {
    return request.put("/me/player/volume", null, {params:{volume_percent:volume_percent, device_id:device_id}});
}

export function seekPosition(position_ms, device_id) {
    return request.put("/me/player/seek",null,{params: {position_ms,device_id}});
}

export function addToQueue(uri, device_id) {
    return request.post("/me/player/queue",null,{params: {uri,device_id}});
}
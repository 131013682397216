<template>
    <div class="flex-horizontal-centered white-box">
        <div class="follow-user-details flex-horizontal-centered">
            <img :src="getImage(user.thumbnail_picture)" @click="goToProfile(user.username)" class="user-thumb"  alt="">
            <div>
                <p class="user-link" @click="goToProfile(user.username)">{{user.username}}</p>
                <p class="follow-fullname" @click="goToProfile(user.username)">{{user.name}}</p>
                <div class="user-follow-details">
                    <span class="gray-text small-text">{{user.followers}} {{user.followers>1?'Followers':'Follower'}}</span>
                    <span class="gray-text small-text">{{user.posts}} {{user.posts>1?'Posts':'Post'}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["user"],
        data () {
            return {
            }
        },       
        computed: {

        },
        methods: {

        },
        mounted() {
        }
    }
</script>

<style scoped></style>

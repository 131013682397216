<template>
    <div class="search-item-container">
        <div v-for="(item,i) in sourceListing" :key="i" class="search-item" :class="itemClass? itemClass:''" @click="showTracks(item)">
            <div class="search-item-image" :class="[!item.image?'no-image':'',source==='artist'?'round':'']">
                <img v-if="item.image" :src="item.image" alt="">
            </div>
            <div class="search-item-details">
                <div>
                    <h4 class="track-name">{{item.name}}</h4>
                    <p class="track-artist">{{item.info}}</p>
                </div>
            </div>
            <div class="search-item-play flex-vertical-centered">
                <img class="trigger-icon" src="../../assets/images/icons/chevron-right.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TrackSource",
        props: ["sourceList", 'itemClass', 'source'],
        data () {
            return {
            }
        },
        components:{ },
        computed: {
            sourceListing: function () {
                return this.sourceList
            },
        },
        methods: {
            showTracks(source_details){
                this.$emit('goToPlaylist',this.source,source_details)
            },
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

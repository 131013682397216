<template>
    <Sider breakpoint="md" collapsible :collapsed-width="78" v-model="isCollapsed" :class="siderClasses" v-click-outside="onfocusout"> 
        <div class="logo-container">
            <img class="logo" src="../assets/images/upclub_logo.svg" alt="">
        </div>
        <Menu :active-name="activename" width="auto" class="main-menu" :class="menuitemClasses">
            <MenuItem name="discover" @click.native="goToPage('discover')">
                <div class="icon-container">
                    <img src="../assets/images/icons/home-filled.svg" alt="" class="active">
                    <img src="../assets/images/icons/home-line.svg" alt="" class="inactive">
                    <img src="../assets/images/icons/home-filled-alert.svg" alt="" class="active-alert">
                    <img src="../assets/images/icons/home-line-alert.svg" alt="" class="inactive-alert">
                </div>
                <span>Discover</span>
            </MenuItem>
            <MenuItem name="notifications" @click.native="goToPage('notifications')" :class="notif_count>0?'alert':''">
                <div class="icon-container">
                    <img src="../assets/images/icons/notification-filled.svg" alt="" class="active">
                    <img src="../assets/images/icons/notification.svg" alt="" class="inactive">
                    <img src="../assets/images/icons/notification-filled-alert.svg" alt="" class="active-alert">
                    <img src="../assets/images/icons/notification-alert.svg" alt="" class="inactive-alert">
                </div>
                <span>Notifications</span>
            </MenuItem>
            <MenuItem name="messages" @click.native="goToPage('messages')" :class="message_count>0?'alert':''">
                <div class="icon-container">
                    <img src="../assets/images/icons/message-filled.svg" alt="" class="active">
                    <img src="../assets/images/icons/message.svg" alt="" class="inactive">
                    <img src="../assets/images/icons/message-filled-alert.svg" alt="" class="active-alert">
                    <img src="../assets/images/icons/message-alert.svg" alt="" class="inactive-alert">
                </div>
                <span>Messages</span>
            </MenuItem>
            <MenuItem name="clubs" @click.native="goToPage('all_clubs')">
                <div class="icon-container">
                    <img src="../assets/images/icons/club-filled.svg" alt="" class="active">
                    <img src="../assets/images/icons/club.svg" alt="" class="inactive">
                </div>
                <span>Clubs</span>
            </MenuItem>
            <MenuItem name="profile" @click.native="goToPage('profile_posts')">
                <div class="icon-container">
                    <img src="../assets/images/icons/user-filled.svg" alt="" class="active">
                    <img src="../assets/images/icons/user-line.svg" alt="" class="inactive">
                    <img src="../assets/images/icons/user-filled-alert.svg" alt="" class="active-alert">
                    <img src="../assets/images/icons/user-line-alert.svg" alt="" class="inactive-alert">
                </div>
                <span>Profile</span>
            </MenuItem>
            <Button shape="circle" class="post-button" @click.native="showSearchTrackModal()" v-show="!isCollapsed">
                <span>Post</span>
                <img src="../assets/images/icons/plus.svg" alt="">
            </Button>
        </Menu>
        <div class="bottom-menu">
            <ul class="page-links" v-show="!isCollapsed">
                <li>Up Club © {{current_year}}</li>
                <!-- <li><a @click="goToPage('terms'}).catch(err => {})">Terms</a></li> -->
                <li><a @click="goToPage('privacy_policy')">Privacy policy</a></li>
            </ul>
        </div>
    </Sider>
</template>

<script>
    import {getNotifCount} from '@/services/upclub/notifications'
    import { EventBus } from '@/eventBus';
    import moment from 'moment';

    export default {
        data () {
            return {
                notifInterval: null,
                isCollapsed: false,
                activename: "profile",
                notif_count: null,
                message_count: null,
                window_size:null,
                current_year: moment().year(),
            };
        },
        computed: {
            menuitemClasses: function () {
                return [
                    'menu-item',
                    this.isCollapsed ? 'collapsed-menu' : ''
                ]
            },
            siderClasses: function () {
                return [
                    '',
                    this.isCollapsed ? 'collapsed-sider' : ''
                ]
            },
            getActiveName: function(){
                return this.activename
            }
        },
        methods: {
            goToPage(route_name){
                if(this.$route.name===route_name){
                    this.$emit('reloadMainContent')
                }else{
                    this.$router.push({name:route_name}).catch(err => {})
                }
            },
            setActiveName(){
                this.onfocusout()
                if(this.$route.path.includes('/profile/')){
                    this.activename='profile'
                }
                else if(this.$route.path.includes('/clubs/')){
                    this.activename='clubs'
                }
                else if(this.$route.path.includes('/discover/') || this.$route.path.includes('/myfeed/')){
                    this.activename='discover'
                }
                else if(this.$route.path.includes('/notifications/')){
                    this.activename='notifications'
                    this.notif_count=0
                }
                else if(this.$route.path.includes('/messages/')){
                    this.activename='messages'
                }
                else{
                    this.activename=""
                }
            },
            getNotificationCount(){
                getNotifCount().then(res=>{
                    this.notif_count=res.data.notifications
                    this.message_count=res.data.message_notifications
                })
            },
            showSearchTrackModal(){
                EventBus.$emit('OPEN_SEARCH_TRACK');
            },
            onfocusout(){
                if(this.window_size<=768){
                    this.isCollapsed=true
                }else{
                    this.isCollapsed=false
                }
            },
            resizeHandler(){
                this.window_size=window.innerWidth
            }
        },
        watch: {
            '$route': function (id) {
                this.setActiveName()
            }
        },
        created() {
            this.resizeHandler()
            window.addEventListener("resize", this.resizeHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeHandler);
        },
        mounted() {
            let vm = this
            vm.setActiveName()
            vm.getNotificationCount()

            vm.notifInterval = setInterval(() => {
                vm.getNotificationCount()
            }, 900000);

            EventBus.$on("MESSAGE_THREAD_OPEN", function () {
                vm.getNotificationCount()
            });
        }
    }
</script>

<style scoped>
    .layout-con{
        height: 100%;
        width: 100%;
        background: transparent;
    }
    .menu-item span:not(.ivu-badge){
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: bottom;
        transition: width .2s ease .2s;
    }
    .menu-item i{
        transform: translateX(0px);
        transition: font-size .2s ease, transform .2s ease;
        vertical-align: middle;
        font-size: 16px;
    }
    .collapsed-menu span{
        width: 0px;
        transition: width .2s ease;
    }
    .collapsed-menu i{
        transform: translateX(5px);
        transition: font-size .2s ease .2s, transform .2s ease .2s;
        vertical-align: middle;
        font-size: 22px;
    }
    .main-content{
        padding: 32px;
    }
    .logo{
        display: block;
        width: 100%;
        max-width: 130px;
        margin-left: 16px;
    }
    .bottom-menu{
        width: 100%;
    }
    .page-links{
        list-style: none;
        width: 100%;
    }
    .page-links li{
        line-height: 1;
        margin: 0 6px;
        display: inline-block;

    }
    .page-links li,.page-links a{
        color: #888888;
        font-size: .7rem;
    }
    .collapsed-sider .logo{
        width: 50px;
        margin: 0 auto;
    }
</style>

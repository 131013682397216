const initialState = {
    fcm_id: [],
};

const state = Object.assign({},initialState);

const getters = {
    getFCMID: (state) => state.fcm_id,
};

const mutations = {
    SET_FCM_ID(state, id) {
        state.fcm_id = id;
    },
};

const actions = {
    setFCMID({ commit }, id) {
        commit("SET_FCM_ID", id);
    }
};

const module = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default module;

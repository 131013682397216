<template>
    <div>
        <div class="spotify-post">
            <h2>Post from your Spotify</h2>
            <div class="search-field-container" @click="$router.push({name:'post_tracks'})">
                <img src="../../assets/images/icons/search.svg" class="search-icon" alt="">
                <AutoComplete class="search-input">
                </AutoComplete>
            </div>
        </div>
        <div class="playlist-post">
            <h2>Post from your Playlist</h2>
            <playlist @goToPlaylist="goToPlaylist"></playlist>
        </div>
    </div>
</template>

<script>
    import Playlist from "@/components/playlist"
    export default {
        data () {
            return {

            }
        },
        components:{Playlist},
        methods: {
            goToPlaylist(id){
                this.$router.push({name:'post_playlist',params: { id: id }})
            }
        },
        mounted() {

        }
    }
</script>

<style scoped></style>

import Vue from 'vue'
import store from "@/store";
import router from '@/router'

Vue.mixin({
    methods: {
      generateKey(length){
        let characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~"
        let result = "";
        let randomIndices = new Int8Array(length);
        window.crypto.getRandomValues(randomIndices);
        const byteLength = 256
        const charactersLength = Math.min(characters.length, byteLength);
        const scalingFactor = byteLength / charactersLength;

        for (var i = 0; i < length; i++) {
            result += characters[Math.floor(Math.abs(randomIndices[i]) / scalingFactor)];
        }
        return result;

      },
      async generateSHA256(verifier){
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        return window.crypto.subtle.digest('SHA-256', data);
      },
      async generateCodeChallenge(verifier){
        let sha256 = await this.generateSHA256(verifier)
        let base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(sha256)))
        .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return base64
      },
      getUrlParams(url) {
        let queryString = url.split('?')[1];
        let queryParams = {};
        if (queryString) {
            queryString = queryString.split('#')[0];
            let arr = queryString.split('&');
            for (let i = 0; i < arr.length; i++) {
                let a = arr[i].split('=');
                let key = a[0];
                let value = typeof (a[1]) === 'undefined' ? true : a[1];
                queryParams[key] = value
            }
        }
        return queryParams;
      },
      goToProfile(username){
        let userDetails = store.getters["upclubAuth/getUserDetails"]
        if(userDetails.username!==username){
            if(router.history.current.params.hasOwnProperty('username')){
                if(router.history.current.params.username!=username){
                    router.push({name:'user_posts',params:{username:username}})
                }
            }else{
                router.push({name:'user_posts',params:{username:username}})
            }
        }else{
            if(router.history.current.name!=="profile_posts"){
                router.push({name:'profile_posts'})
            }
        }
      }, 
      getImage(image){
        if(image){
          if(!image.includes("data:image/png;base64,")){
              return "data:image/png;base64,"+image
          }else{
              return image
          }
        }else{
            return ""
        }
      },
      generateMentionsText(text, mentions){

        mentions.forEach((mentioned_user,index) => {
            text = text.replace(new RegExp(`<@${mentioned_user.upclubuser}>`, 'g'),`<@/#~><@${mentioned_user.upclubuser}><@/#~>`,)
        });

        let arr_text = text.split("<@/#~>")
        let new_array_text = []
        let mentioned_tags = mentions.map(mention=>{return `<@${mention.upclubuser}>`})
        arr_text.forEach((word,index) => {
            if(mentioned_tags.indexOf(word)>-1){
              let mention_index = mentioned_tags.indexOf(word)
              new_array_text.push(
                { text:word,username:mentions[mention_index].username}
              )
            }else{
                new_array_text.push(
                  { text:word,username:null}
                )
            }
        });
        return new_array_text

        let userDetails = store.getters["upclubAuth/getUserDetails"]
        mentions.forEach((mentioned_user,index) => {
          if(userDetails.username!==mentioned_user.username){
            text = text.replace(new RegExp(`<@${mentioned_user.upclubuser}>`, 'g'),`<a href="/profile/${mentioned_user.username}">@${mentioned_user.username}</a>`,)
          }else{
            text = text.replace(new RegExp(`<@${mentioned_user.upclubuser}>`, 'g'),`<a href="/profile">@${mentioned_user.username}</a>`,)
          }
        });
        return text
    },
    }
  })

<template>
    <div  class="search-track-container all-search-results">
        <div v-if="showNoData" class="no-data">No results found</div>
        <div v-if="tracks.length">
            <div class="all-filter-label">
                <p>Tracks</p>
                <a class="gray-text" @click="$emit('changeFilter','track')">Show More</a>
            </div>
            <track-list ref="postPlaylist" :trackList="tracks" :itemClass="'white-box'" @openTrackModal="openTrackModal"></track-list>
        </div>
        <div v-if="artists.length">
            <div class="all-filter-label">
                <p>Artist</p>
                <a class="gray-text" @click="$emit('changeFilter','artist')">Show More</a>
            </div>
            <source-list ref="postSourceList" :source="'artist'" :sourceList="artists" :itemClass="'white-box'" @goToPlaylist="goToPlaylist"></source-list>
        </div>
        <div v-if="albums.length">
            <div class="all-filter-label">
                <p>Album</p>
                <a class="gray-text" @click="$emit('changeFilter','album')">Show More</a>
            </div>
            <source-list ref="postSourceList" :source="'album'" :sourceList="albums" :itemClass="'white-box'" @goToPlaylist="goToPlaylist"></source-list>
        </div>
        <div v-if="playlists.length">
            <div class="all-filter-label">
                <p>Playlist</p>
                <a class="gray-text" @click="$emit('changeFilter','playlist')">Show More</a>
            </div>
            <source-list ref="postSourceList" :source="'playlist'" :sourceList="playlists" :itemClass="'white-box'" @goToPlaylist="goToPlaylist"></source-list>
        </div>
        <infinite-loading @infinite="getSearchData" :identifier="infiniteId"></infinite-loading>              
    </div>
</template>

<script>
    const shortNum = require('number-shortener')

    import {search} from '@/services/spotify/search'
    import TrackList from '@/components/trackSearchList/Tracks'
    import SourceList from '@/components/trackSearchList/TrackSource'

    export default {
        data () {
            return {
                showNoData:false,
                idletimer:null,
                infiniteId:0,
                searchVal:"",
                offset:0,
                tracks:[],
                artists:[],
                albums:[],
                playlists:[]
            }
        },
        components: {TrackList,SourceList},
        methods: {
            goToPlaylist(source,details){
                this.$emit('goToPlaylist',source,details)
            },
            openTrackModal(track){
                this.$emit('openTrackModal',track)
            },
            searchChanged(query,filter){
                this.searchVal=query
                clearTimeout(this.idletimer);
                this.idletimer =  setTimeout(async()=>{ 
                    this.tracks=[]
                    this.artists=[]
                    this.albums=[]
                    this.playlists=[]
                    this.infiniteId+=1
                }, 600);

            },
            getSearchData($state){
                search(this.searchVal,'track,artist,album,playlist',0,3).then(res=>{
                    if(res.data.tracks.items.length>0){
                        this.tracks = res.data.tracks.items
                    }
                    if(res.data.artists.items.length>0){
                        let artists = res.data.artists.items.map((artist,index)=>{
                            let artist_data = {
                                id:artist.id,
                                image:null,
                                name:artist.name,
                                info:`${shortNum(artist.followers.total)} ${artist.followers.total>1?'followers':'follower'}`,
                                artist: artist
                            }
                            if(artist.images.length){
                                artist_data.image=artist.images[0]['url']
                            }
                            return artist_data
                        })
                        this.artists = this.artists.concat(artists)
                    }
                    if(res.data.albums.items.length>0){
                        let albums = res.data.albums.items.map((album,index)=>{
                            let album_data = {
                                id:album.id,
                                image:null,
                                name:album.name,
                                info:`${shortNum(album.total_tracks)} ${album.total_tracks>1?'songs':'song'}`,
                                album:album
                            }
                            if(album.images.length){
                                album_data.image=album.images[0]['url']
                            }
                            return album_data
                        })
                        this.albums = this.albums.concat(albums)
                    }
                    if(res.data.playlists.items.length>0){
                        let playlists = res.data.playlists.items.map((playlist,index)=>{
                            let playlist_data = {
                                id:playlist.id,
                                image:null,
                                name:playlist.name,
                                info:`${shortNum(playlist.tracks.total)} ${playlist.tracks.total>1?'songs':'song'}`,
                                playlist:playlist
                            }
                            if(playlist.images.length){
                                playlist_data.image=playlist.images[0]['url']
                            }
                            return playlist_data
                        })
                        this.playlists = this.playlists.concat(playlists)
                    }
                    $state.complete();
                    if(res.data.tracks.items.length==0&&res.data.artists.items.length==0&&res.data.albums.items.length==0&&res.data.playlists.items.length==0){
                        this.showNoData=true
                    }else{
                        this.showNoData=false
                    }
                })
            },

        },
        mounted() {

        },
        created(){
        }
    }
</script>

<style scoped></style>

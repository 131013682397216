import { request } from "./base";

export function postClub(data) {
    return request.post(`v1/createclub/`,data);
}

export function getAllClubs(params) {
    return request.get(`v1/getallclubs/`,{params});
}

export function getOwnedCuratedClubs(params) {
    return request.get(`v1/getallownedandcuratedclubs/`,{params});
}

export function getFollowedClubs(params) {
    return request.get(`v1/getallfollowedclubs/`,{params});
}

export function getClub(id) {
    return request.get(`v1/getclub/${id}`);
}

export function putClub(id,data) {
    return request.patch(`v1/updateclub/${id}`,data);
}

export function searchClub(club,params) {
    return request.get(`v1/searchallclubs/${club}`,{params});
}

export function getClubPosts(club,category,params) {
    return request.get(`v1/getclubsongposts/${club}/${category}`,{params});
}

export function getClubFollowers(club,params) {
    return request.get(`v1/getclubfollowers/${club}`,{params});
}

export function getClubCurators(club,params) {
    return request.get(`v1/getclubcurators/${club}`,{params});
}

export function getCuratorsAndRequests(club,params){
    return request.get(`v1/getclubcuratorsandrequests/${club}`,{params});    
}

export function removeClubCurator(curator_id){
    return request.patch(`v1/removecuratorfromclub/${curator_id}`);    
}

export function respondClubCuratorRequest(curator_id,data){
    return request.patch(`v1/respondtocuratorrequest/${curator_id}`,data);    
}

export function searchClubUsername(club,params){
    return request.get(`v1/clubsearchusername/${club}`,{params});    
}

export function inviteclubcurators(data){
    return request.post(`v1/inviteclubcurators/`,data);    
}

export function followClub(data){
    return request.post(`v1/followclub/`,data);    
}

export function unfollowClub(data){
    return request.post(`v1/unfollowclub/`,data);    
}

export function requestToCurate(data){
    return request.post(`v1/requesttocurate/`,data);    
}

export function leaveClub(club_id){
    return request.patch(`v1/leaveclub/${club_id}`);    
}
export function respondToCuratorRequest(invite_id,data){
    return request.patch(`v1/respondtocuratorrequest/${invite_id}`,data);    
}
export function respondToCuratorInvite(invite_id,data){
    return request.patch(`v1/respondtocuratorinvite/${invite_id}`,data);    
}

<template>
    <div class="search-content">
        <page-header @getSearchResults="getSearchResults">
            <div class="search-header" slot="page-menu">
                <div class="search-label">
                    <span v-if="!query">Recent Searches</span>
                    <span v-else>Search Results</span>
                </div>
                <Menu mode="horizontal" class="search-filters" :active-name="activename">
                    <MenuItem name="all" @click.native="activename='all';getSearchResults(query)">
                        <span>All</span>
                    </MenuItem>
                    <MenuItem name="posts" @click.native="activename='posts';getSearchResults(query)">
                        <span>Posts</span>
                    </MenuItem>
                    <MenuItem name="users" @click.native="activename='users';getSearchResults(query)">
                        <span>Users</span>
                    </MenuItem>
                    <MenuItem name="clubs" @click.native="activename='clubs';getSearchResults(query)">
                        <span>Clubs</span>
                    </MenuItem>
                </Menu>
            </div>
        </page-header>
        <div class="searc-results">
            <all ref="searchAll" v-show="activename==='all'" @getChangeFilter="getChangeFilter"></all>
            <posts ref="searchPost" v-show="activename==='posts'"></posts>
            <users ref="searchUser" v-show="activename==='users'"></users>
            <clubs ref="searchClub" v-show="activename==='clubs'"></clubs>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/layout/PageHeader'
    import All from './All'
    import Posts from './Posts'
    import Users from './Users'
    import Clubs from './Clubs'

    export default {
        data () {
            return {
                activename:'all',
                query:null   
            }
        },
        components: {PageHeader, All, Posts, Users, Clubs},
        methods: {
            getSearchResults(query){
                this.query=query
                if(this.activename==='all'){
                    this.$refs.searchAll.getResults(query)
                }
                if(this.activename==='posts'){
                    this.$refs.searchPost.getResults(query)
                }
                if(this.activename==='users'){
                    this.$refs.searchUser.getResults(query)
                }
                if(this.activename==='clubs'){
                    this.$refs.searchClub.getResults(query)
                }
            },
            getChangeFilter(filter){
                this.activename=filter
                this.getSearchResults(this.query)
            }
        },
        mounted() {

        }
    }
</script>

<style scoped></style>

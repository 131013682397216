<template>
    <Modal @on-visible-change="modalChanged($event)"  width="400" v-model="showModal" class-name="vertical-center-modal post-track-modal white-modal">
        <p slot="header" @click="$emit('postModalClose', 'back');showModal=false;" class="modal-back-header">
            <img src="../../assets/images/icons/chevron-left.svg" class="back-to-search" alt="">
            <span >Back to search</span>
        </p>
        <div slot="close" @click="$emit('postModalClose', 'all')"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <div class="modal-body" v-if="track.id">
            <img :src="track.album.images[1]['url']" alt="">
            <div class="flex-horizontal-centered modal-track-details">
                <div>
                    <h3>{{track.name}}</h3>
                    <p class="gray-text small-text">{{getArtistNames(track.artists)}}</p>
                </div>
                <div class="search-track-play  flex-vertical-centered">
                    <img v-if="!context.paused&&playing_id==track.id" class="trigger-icon"  @click="pause();"   src="../../assets/images/icons/pause.svg" alt="">
                    <img v-else @click="playTrack(track)" class="trigger-icon" src="../../assets/images/icons/play.svg" alt="">
                </div>
            </div>
            <Form class="white-form" ref="postForm" :model="post" :rules="postRules" v-if="save_as==='post'">
                <FormItem prop="comment"  class="mention-field">
                    <!-- <i-input type="textarea" v-model="post.comment" placeholder="Write a caption (30 word limit)" :autosize="{minRows: 1,maxRows: 2}"></i-input> -->
                    <MentionableField ref="mentionableField" :fieldType="'textarea'" class="comment-input" placeholder="Write a caption (400 character limit)"></MentionableField>
                </FormItem>
            </Form>
        </div>
        <div slot="footer">
            <div v-if="post_club" class="post-club-wrapper">
                <div class="post-club flex-horizontal-centered flex-gap-8">
                    <div class="post-club-image">
                        <img :src="getImage(post_club.thumbnail_picture)" alt="">
                    </div>
                    <div class="post-club-details">
                        <div>
                            <h4 class="post-club-name">{{post_club.club_name}}</h4>
                            <div class="club-item-counts flex-horizontal-centered flex-gap-16">
                                <p >{{post_club.followers}} Followers</p>
                                <p >{{post_club.posts}} Posts</p>
                            </div>
                        </div>
                    </div>
                    <Icon type="md-close"  class="cursor-pointer" @click="$emit('removeSelectedClub')"/>
                </div>
                <Button  size="large" class="btn black-btn sharetoclub-btn" long :loading="posting" @click="postTrack()" v-if="save_as==='post'">
                    <span  v-if="!posting"><img src="../../assets/images/icons/club-white.svg">Share to Club</span>
                </Button>
            </div>
            <div v-else>
                <Button  size="large" class="btn gray-btn sharetoclub-btn" long  @click="openSearchClub()" v-if="save_as==='post'">
                    <span><img src="../../assets/images/icons/club-white.svg">Share to Club</span>
                </Button>
                <Button size="large" class="btn black-btn" long :loading="posting" @click="postTrack()" v-if="save_as==='post'">
                    <span v-if="!posting">Post this song</span>
                </Button>
                <Button size="large" class="btn black-btn" long :loading="posting" @click="sendTrack()" v-if="save_as==='message'">
                    <span v-if="!posting">Send</span>
                </Button>
                <Button size="large" class="btn black-btn" long :loading="posting" @click="sendTrackToAll()" v-if="save_as==='message_to_all'">
                    <span v-if="!posting">Send to all</span>
                </Button>
            </div>
           
        </div>
    </Modal>
</template>

<script>
    import { postSong } from "@/services/upclub/post"
    import { createMessage } from '@/services/upclub/messages'
    import { postSendToAllMessage } from "@/services/upclub/profile";
    import { getTrack } from '@/services/spotify/tracks'
    import { play, pause } from "@/services/spotify/player"
    import { EventBus } from '@/eventBus'
    import { mapGetters, mapActions } from "vuex"
    import MentionableField from '@/components/mentionableField/index'

    export default {
        props: ['save_as', 'message_thread', 'post_club'],
        data () {
            const validateMaxCharacters= (rule, value, callback) => {
                if (value && value.length>400) {
                    callback(new Error('Maximum of 400 characters'));
                } else {
                    callback();
                }
            };
            const validateMaxWords = (rule, value, callback) => {
                let word_count = value.split(' ').filter(function(n) { return n != '' }).length;
                if (value && word_count>30) {
                    callback(new Error('Maximum of 30 words'));
                } else {
                    callback();
                }
            };
             const validateMaxMentions = (rule, value, callback) => {
                 if(this.post.mentions.length>8){
                    callback(new Error('Maximum of 8 mentions only'));
                }else{
                    callback();
                }
            };
            return {
                showModal:false,
                posting:false,
                playing_id:null,
                track:{},
                post:{
                    comment:"",
                    mentions:[]
                },
                postRules: {
                    comment: [
                        { validator: validateMaxCharacters, trigger: 'change' },
                        { validator: validateMaxMentions, trigger: 'change' },
                    ],
                },
            }
        },
        components:{MentionableField},
        computed: {
        ...mapGetters({
            device_id: "player/getDeviceID",
            playback: "player/getPlayback",
            context: "player/getPlaybackContext",
            currentPlayingPost: "upclubPlayer/getCurrentPlayingPost"
        }),
        },
        methods: {
            ...mapActions({
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
            }),
            openModal(track){
                this.track=track
                this.showModal = true
            },
            modalChanged(val){
                if(!val){
                    this.track={}
                    if(!this.playback.item.post_details&&!this.context.paused){
                        this.pause()
                    }
                    this.playing_id=null
                    this.resetData()
                }
            },
            getArtistNames(artists){
                 let names = artists.map(artist=>{return artist.name })
                 return names.join('  -  ')
            },
            resetData(){
                this.post={
                    comment:"",
                    mentions:[]
                }
                this.$emit('removeSelectedClub')
                this.$refs.mentionableField.clearDetails()
            },
            postTrack(){
                let comment_post_details = this.$refs.mentionableField.getPostDetails()
                this.post.comment = comment_post_details.text
                this.post.mentions = comment_post_details.mentions
                this.$refs.postForm.validate((valid) => {
                    if(valid){
                        let data={
                            song: this.track.id,
                            title: this.track.name,
                            comment: this.post.comment,
                            mentions: this.post.mentions
                        }
                        if(this.post_club){
                            data.club=this.post_club.club
                        }
                        this.posting=true
                        postSong(data).then(res=>{
                            this.posting=false
                            this.showModal=false
                            this.resetData()
                            this.$Notice.success({
                                desc: "Track successfully posted."
                            });
                            this.$emit('onSaveSuccess', res.data)
                            if(this.$route.name==='profile_posts' || (this.$route.name==='club_posts'&&this.post_club)){
                                EventBus.$emit('POSTS_UPDATED','reload');
                            }
                        }).catch(err=>{
                            this.showModal=false
                            this.posting=false
                            this.resetData()
                            // if(err.response.data.detail==='You have reached your post limit for one day, you can post again in 24 hours.'){
                            //     this.$Notice.error({
                            //         title: err.response.data.detail,
                            //     });
                            // }
                            this.$Notice.error({
                                desc: err.response.data.detail,
                            });
                        })
                    }
                })
            },
            sendTrack(){
                let data={
                    song: this.track.id,
                    title: this.track.name,
                    post_type:1,
                    comment: ""
                }
                this.posting=true
                postSong(data).then(res=>{
                    let data = {}
                    if(this.message_thread.messagethread==='new'){
                        data = {
                            messagethread: null,
                            song_post: res.data.song_post,
                            upclubusers: this.message_thread.members.upclubusers.map(user=> user.upclubuser)
                        }  
                    }else{
                        data = {
                            messagethread: this.message_thread.messagethread,
                            song_post: res.data.song_post
                        }
                    }

                    createMessage(data).then(msg_res=>{
                        this.posting=false
                        this.showModal=false
                        let msgPostData= Object.assign({},msg_res.data)
                            msgPostData.song= this.track.id
                            msgPostData.title= this.track.name
                        this.$emit('onSaveSuccess',msgPostData)
                    }).catch(err=>{
                        this.posting=false
                    })
                    
                }).catch(err=>{
                    this.showModal=false
                    this.posting=false
                })

            },
            sendTrackToAll(){
                let data={
                    song: this.track.id,
                    title: this.track.name,
                    post_type:1,
                    comment: ""
                }
                this.posting=true
                postSong(data).then(res=>{
                    postSendToAllMessage({song_post:res.data.song_post}).then(res=>{
                        this.posting=false
                        this.showModal=false
                        this.$emit('onSaveSuccess',res.data)
                    })
                }).catch(err=>{
                    this.showModal=false
                    this.posting=false
                })
            },
            playTrack(song){
                getTrack(song.id).then(track=>{
                    let data ={
                        offset: {
                            position: 0
                        },
                        uris: [track.data.uri],
                        position_ms: 0
                    }
                    play(data).then(res=>{
                        this.playing_id=song.id
                        // this.setCurrentPlayingPost({index:this.currentPlayingPost.index, post:null})
                        // localStorage.setItem('current_track_page', this.$route.name)
                        // localStorage.setItem('current_track_list', JSON.stringify([track.data]))
                    })
                })
            },
            openSearchClub(){
                this.$emit('openSearchClubModal')
            },
            pause(){
                pause()
            }
             
        },
        mounted() {
        }
    }
</script>

<style scoped>
.post-club{
    position: relative;
    top: -24px;
}
.post-club-image{
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}
.post-club-details{
    width: 100%;
    text-align: left;
}
.post-club-name{
    color: #000;
}
</style>

<template>
    <div class="comment-item">
        <div class="commentor-image user-thumb">
            <img class="user-thumb" :src="getImage(comment.thumbnail_picture)" @click="goToProfile(comment.username)" alt="">
        </div>
        <div class="comment-details">
            <div>
                <a class="commentor-name user-link"  @click="goToProfile(comment.username)">{{ comment.username }}</a>
                <span class="comment-age gray-text">{{ comment.comment_age }}</span>
            </div>
            <div class="comment-text">
                <!-- <p v-html="generateMentionsText(comment.text,comment.mentions)"></p> -->
                <p>
                    <span v-for="(comment,i) in generateMentionsText(comment.text,comment.mentions)" :key="i" 
                    @click="comment.username?goToProfile(comment.username):'';$emit('closePlayerModal')" :class="comment.username?'highlight-mention':''">{{comment.username?`@${comment.username}`:comment.text}}</span>
                </p>
            </div>
        </div>
        <div class="comment-action" v-if="!hideReply">
            <img src="../../assets/images/icons/reply.svg" @click="level===1?reply(comment):reply(parent)" class="trigger-icon">
        </div>
    </div>
</template>

<script>
    export default {
        props:['comment', 'parent', 'level', 'hideReply'],
        data () {
            return {
            }
        },
        computed:{
        },
        methods: {
            getImage(image){
                if(image){
                    return "data:image/png;base64,"+image
                }else{
                    return ""
                }
            },
            reply(comment){
                this.$emit('reply',comment)
            }
        },
        mounted() {
        //    console.log(this.comment)
        }
    }
</script>

<style scoped>
</style>

import {login} from "@/services/upclub/auth";
import { myProfile, createProfile } from '@/services/upclub/profile';
import {setSession} from "@/services/authentication.service"

const initialState = {
    userDetails:{
        upclubuser: null,
        profile_picture: "",
        username: "",
        profile_description: "",
        profile_twitter: "",
        profile_instagram: "",
        posts: 0,
        likes_given: 0,
        followers: 0,
        followings: 0  
    },
    accessToken: "",
    refreshToken: "",
};

const state = Object.assign({},initialState);

const getters = {
    getUserDetails: (state) => state.userDetails,
    getAccessToken: (state) => state.accessToken,
    getRefreshToken: (state) => state.refreshToken,
};

const mutations = {
    SET_AUTH_STATE(state, details) {
        localStorage.setItem('user_type', details.user_type)
        state.userDetails = details;
    },
    SET_ACCESS_TOKEN(state, token) {
        state.accessToken = token;
    },
    SET_REFRESH_TOKEN(state, token) {
        state.refreshToken = token;
    },
    reset (state) {
        localStorage.removeItem('user_type')
        localStorage.removeItem('upclub_authenticated')
        localStorage.removeItem('upclub_access_token')
        localStorage.removeItem('current_post_list')
        localStorage.removeItem('current_post_page')
        localStorage.removeItem('current_playing_post')

        const s = initialState
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
};

const actions = {

    getAuthToken: async function({ commit, state, dispatch }, authData) {
        return new Promise((resolve, reject) => {
            login(authData).then(res=>{
                const accessToken = res.data.token;
                localStorage.setItem('upclub_access_token',accessToken)
                commit("SET_ACCESS_TOKEN", accessToken);
                dispatch("setAccessToken", accessToken);
                setSession('upclub_authenticated')
                resolve()
            }).catch(err=>{
                reject(err)
            })
        })
        
    },
    getUserProfile: async function({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            myProfile().then(res=>{
                if(res){
                    let details = res.data
                    dispatch("setUserDetails", details);
                    resolve()
                }
            }).catch(err=>{
                if(err.response && err.response.status===404){
                    createProfile({}).then(res=>{
                        let details = res.data
                        dispatch("setUserDetails", details);
                        resolve()
                    }).catch(error=>{
                        reject(error)
                    })
                }
            })
        })
        
    },
    setUserDetails({ commit }, userDetails) {
        commit("SET_AUTH_STATE", userDetails);
    },
    setAccessToken({ commit }, token) {
        commit("SET_ACCESS_TOKEN", token);
    },
    setRefreshToken({ commit }, token) {
        commit("SET_REFRESH_TOKEN", token);
    }
};

const module = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default module;

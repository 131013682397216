<template>
    <div>
        <DropdownItem @click.native="openMessageModal()">
            <a>
                <img src="../../assets/images/icons/upclub_btn.png" alt="">
                <p>send in a message on up club</p>
            </a>
        </DropdownItem>
        <DropdownItem @click.native="postLink('copy')">
            <a>
                <img src="../../assets/images/icons/copy_btn.png" alt="">
                <p>copy link to send to a friend</p>
            </a>
        </DropdownItem>
        <DropdownItem>
            <a>
                <img src="../../assets/images/icons/instagram_btn.png" alt="">
                <p>post to your instagram story</p>
            </a>
        </DropdownItem>
        <DropdownItem>
            <a class="twitter-share-button" target="_blank" :href="`https://twitter.com/intent/tweet?url=${postLink('get')}`">
                <img src="../../assets/images/icons/twitter_btn.png" alt="">
                <p>share the post on twitter</p>
            </a>
        </DropdownItem>
    </div>
</template>

<script>
    export default {
        props: {
            track:{
                post_details:{
                    upclubuser:null
                }
            },
        },
        data () {
            return {
            }
        },
        computed:{

        },
        methods: {
            openMessageModal(){
                this.$emit('sendMessage')
            },
            postLink(method){
                let post_id = this.track.post_details.song_post.toString()
                const encryptedPostID = btoa(`BB33Lj9Q9JWqWUzyP9ka7jmZXbrLTtmk:${post_id}`)
                var url = process.env.VUE_APP_BASE_URL+'discover/?share_post='+encryptedPostID;
                if(method=='copy'){
                    navigator.clipboard.writeText(url);
                    this.$Message.success({
                        content: 'Post link copied',
                    });
                }else{
                    return url
                }
            },
           
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
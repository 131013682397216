import { request } from "./base";

export function getMessageThreads(params) {
    return request.get("v1/getmessagethreads",{params});
}

export function getMessageThreadInfo(id) {
    return request.get(`v1/getmessagethreadinfo/${id}`);
}

export function getMessages(id,category,params) {
    return request.get(`v1/getmessages/${id}/${category}`,{params});
}

export function getSearchUsername(name,params) {
    return request.get(`v1/messagessearchusername/${name}`,{params});
}

export function getSearchThreadName(name,params) {
    return request.get(`v1/messagessearchmessagethreadnames/${name}`,{params});
}

export function searchMessageThreads(query,params) {
    return request.get(`v1/searchmessagethreads/${query}`,{params});
}

export function checkMessageThread(data) {
    return request.post("v1/checkmessagethread/",data);
}

export function createMessage(data) {
    return request.post("v1/createmessage/",data);
}

export function updateThreadName(id,data) {
    return request.patch(`v1/setmessagethreadname/${id}`,data);
}

export function addThreadMember(id,data) {
    return request.patch(`v1/adduserstomessagethread/${id}`,data);
}

export function leaveThread(id) {
    return request.post(`v1/leavemessagethread/${id}`);
}

export function sharePost(data) {
    return request.post("v1/sharepost/",data);
}



<template>
    <div class="current-track-details full-height" :class="currentPost.post_type===1?'spotify-song':'posted-song'">
        <div class="track-image">
            <img :src="currentPost.track_details.album.images[imageIndex]['url']" alt="" @click="openMainPlayer('play',track)">
            <div class="track-overlay-play" v-if="showPlay">
                <div v-if="!context.paused&&currentPlayingPost.post&&currentPost.song_post===currentPlayingPost.post.song_post" class="trigger-icon-container"  @click="pause();">
                    <img src="../../assets/images/icons/pause.svg" alt="">
                </div>
                <div v-else @click="play();" class="trigger-icon-container">
                    <img src="../../assets/images/icons/play.svg" alt="">
                </div>
            </div>
        </div>
        <div class="track-details">
            <p class="track-name" @click="openMainPlayer('play',currentPost)">{{currentPost.track_details.name}}</p>
            <p class="track-singer" @click="openMainPlayer('play',currentPost)">{{currentPost.track_details.artists[0]['name']}}</p>
        </div>
    </div>
</template>

<script>
    import {transferUsersPlayback, pause, play} from "@/services/spotify/player";
    import { mapGetters, mapActions } from "vuex";
    import { EventBus } from '@/eventBus';

    export default {
        name: "TrackDetails",
        props:{
            currentPost:{},
            showPlay:{
                default:false
            },
            imageIndex:{
                default:2
            }
        },
        data () {
            return {
            }
        },
        computed: {
            ...mapGetters({
                device_id: "player/getDeviceID",
                playback: "player/getPlayback",
                context: "player/getPlaybackContext",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost"
            }),
        },
        methods: {
            ...mapActions({
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
            }),
            openMainPlayer(type, post){
                if(this.currentPlayingPost&&this.currentPlayingPost.post_type!==1){
                    EventBus.$emit('OPEN_PLAYER',type, post);
                }
            },
            pause() {
               pause();
            },
            async play() {
                let index = 0 
                let data ={
                    offset: {
                        position: 0
                    },
                    uris: [this.currentPost.track_details.uri],
                    position_ms: 0
                }
                if(this.playback && this.playback.item && this.playback.device.id!==this.device_id){
                    transferUsersPlayback([this.device_id], true)
                }
                if(this.currentPlayingPost.post && this.currentPost.song_post === this.currentPlayingPost.post.song_post){
                    play()
                }else{
                    if(this.currentPlayingPost.post){
                        index=this.currentPlayingPost.index
                    }
                    play(data).then(res=>{
                        this.setCurrentPlayingPost({index:index, post:this.currentPost})
                    })
                }
            },
        },
        watch: {
            
        },
        mounted() {

        }
    }
</script>

<style scoped></style>

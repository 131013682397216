<template>
    <Modal v-model="showModal" @on-visible-change="visibleChange($event)" title="Club Settings" :transfer="false" :lock-scroll="false" class-name="update-club-settings white-modal">
        <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <Form class="club-settings-update-form white-form" ref="clubSettingForm" :model="clubDetails" :rules="clubRules" @keyup.enter.native="handleSubmit('clubSettingForm')">
            <div v-show="cropPhoto">
                  <Alert type="error" show-icon v-if="showAlertError" class="modal-alert">
                    Unsaved club picture
                    <span slot="desc">
                        Please click on the <span class="check">&#x2713;</span> to save the image or <span class="cross">&#x2718;</span> to cancel.
                    </span>
                </Alert>
                <vue-croppie 
                    ref="croppieRef" 
                    :enableOrientation="true"
                    :enableResize="false"
                    :boundary="{width: 460, height: 258.75}"
                    :viewport="{width: 400, height: 225, type: 'square' }"
                    @result="result">
                </vue-croppie>
                <div class="croppie-action">
                <Button type="success" @click="savingClubSettings=true;crop(1280);" shape="circle" icon="md-checkmark"></Button>
                <Button type="error" @click="latestFile='';cropPhoto=false" shape="circle" icon="md-close"></Button>
                </div>
            </div>
            <FormItem v-show="!cropPhoto" prop="club_picture" class="club-picture">
                <div class="text-center">
                    <Upload ref="clubPhoto" class="circle-upload-image"
                            type="drag"
                            :before-upload="handleUpload"
                            action="">
                            <img :src="clubPhoto" alt="">
                            <div class="upload-overlay">
                                <img src="../../assets/images/icons/edit.svg" alt="">
                            </div>
                    </Upload>
                    <!-- <p>Change Photo</p> -->
                </div>
            </FormItem>
            <FormItem prop="club_name">
                <label for="">Club Name</label>
                <i-input type="text" v-model="clubDetails.club_name"></i-input>
            </FormItem>
            <FormItem prop="club_description">
                <label for="">Description (400 characters limit)</label>
                <i-input type="textarea" v-model="clubDetails.club_description" :autosize="{minRows: 2,maxRows: 2}" class="update-description"></i-input>
            </FormItem>
            
        </Form>
        <div slot="footer">
            <Button size="large" class="btn black-btn" long :loading="loading" @click="handleSubmit('clubSettingForm')">
                <span v-if="!loading">Save Changes</span>
            </Button>
            <div class="invite-curator-section flex-horizontal-spacebetween">
                <div class="flex-horizontal-centered flex-gap-8">
                    <img src="../../assets/images/icons/add_curator.svg" alt="">
                    <div class="text-left">
                        <h4>Invite Curators</h4>
                        <p>Collaborate with others</p>
                    </div>
                </div>
                <Button size="small" class="btn black-btn" @click="$emit('showInviteCurators')">
                    <span>Invite</span>
                </Button>
            </div>
        </div>
        <Spin fix v-show="savingClubSettings">
            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        </Spin>
    </Modal>
</template>

<script>
    import { putClub } from '@/services/upclub/clubs'
    import { mapGetters } from "vuex";

    export default {
        data () {
            const validateMaxCharacters= (rule, value, callback) => {
                if (value && value.length>400) {
                    callback(new Error('Maximum of 400 characters'));
                } else {
                    callback();
                }
            };
            return {
                loading: false,
                savingClubSettings: false,
                showAlertError: false,
                latestFile:"",
                clubPhotoUpdated:false,
                clubPhoto:"",
                club:{},
                clubDetails:{
                    club:null,
                    club_description:"",
                    club_name:"",
                    club_owner:null,
                    club_owner_name:"",
                    club_picture:"",
                    created_at:"",
                    curators:"",
                    followers:"",
                    posts:"",
                },
                clubRules: {
                    club_name: [
                        { required: true, message: 'Field required', trigger: 'change' },
                    ],
                    club_description: [
                        { validator: validateMaxCharacters, trigger: 'change' },
                    ]
                },
                showModal:false,
                cropPhoto:false,
            }
        },
        components: {},
        computed: {
            ...mapGetters({
                player: "player/getPlayer",
            }),
        },
        methods: {
            visibleChange(val){
                this.$emit('setUpdateVisibility',val)
            },
            openModal(club){
                this.showModal=true
                this.club = club
                this.clubDetails = Object.assign({},club)
                this.clubPhoto = "data:image/jpeg;base64,"+club.club_picture
            },
            getBase64(file) {
                return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
                });
            },
            handleUpload (file) {
                this.latestFile=file
                this.cropPhoto=true
                this.getBase64(file).then(data => {
                this.$refs.croppieRef.bind({url:data})
                this.clubDetails.club_picture = ""
                })
                return false;
            },
            rotate(rotationAngle) {
                this.$refs.croppieRef.rotate(rotationAngle);
            },
            crop(size){
                setTimeout(async()=>{ 
                    this.photo_crop(size)
                }, 100);
            },
            photo_crop(size) {
                let vm=this
                let height = size*9/16 // aspect ration 16:9
                let options = {
                    type: 'base64',
                    size: { width: size,height:height },
                    format: 'png',
                    circle: false
                }
                vm.$refs.croppieRef.result(options, (output) => {
                    var base64result = output.split(',')[1];
                    if(base64result.length<=1000000){
                        vm.clubDetails.club_picture = base64result;
                        vm.clubPhoto= output
                        this.thumbnail_crop(250)
                    }else{
                        this.photo_crop(size-5)
                    }
                });
            },
            thumbnail_crop(size) {
                let vm=this
                let height = size*9/16 // aspect ration 16:9
                let options = {
                    type: 'base64',
                    size: { width: size,height:height },
                    format: 'png',
                    circle: false
                }
                vm.$refs.croppieRef.result(options, (output) => {
                    var base64result = output.split(',')[1];
                    if(base64result.length<=50000){
                        vm.cropPhoto = false
                        vm.clubDetails.thumbnail_picture = base64result;
                        vm.savingClubSettings = false
                        vm.clubPhotoUpdated = true
                        vm.showAlertError=false
                    }else{
                        this.thumbnail_crop(size-2)
                    }
                });
            },
            result(output) {
                let vm=this
                vm.clubDetails.club_picture = output;
            },
            handleSubmit(name) {
                if(this.cropPhoto){
                    this.showAlertError=true
                }else{
                    this.showAlertError=false
                    this.$refs[name].validate(async(valid) => {
                        if (valid) {
                            this.loading=true
                            let updatedData = {}
                            Object.keys(this.clubDetails).forEach(key => {
                                if(this.club.hasOwnProperty(key)){
                                    if(this.club[key]!==this.clubDetails[key]){
                                        updatedData[key]=this.clubDetails[key]
                                    }
                                }else{
                                    updatedData[key]=this.clubDetails[key]
                                }
                            });
                            if(!this.clubPhotoUpdated){
                                delete updatedData['club_picture']
                                delete updatedData['thumbnail_picture']
                            }
                            putClub(this.club.club,updatedData).then(res=>{
                                this.showModal=false
                                this.loading=false
                                this.$emit('onSubmit')
                            }).catch(err=>{
                                this.loading=false
                            })
                        }
                    })
                }
            }
        },
        mounted() {
            
        },
        destroy(){
            this.clubDetails = Object.assign({},this.club)
        }
    }
</script>

<style scoped>
    .invite-curator-section img{
        width: 45px;
    }
    .invite-curator-section{
        margin-top: 16px;
        line-height: 1.2;
    }
    .invite-curator-section .btn{
        height: 40px;
        padding: 6px 16px!important;
    }
    .check{
        font-size: 1.2rem;
        font-weight: 600;
        color: #19be6b;
    }
    .cross{
        font-size: 1rem;
        color: #ed4014;
    }
</style>

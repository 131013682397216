<template>
    <div class="auth-form">
        <img class="logo" src="../../assets/images/upclub_logo_large.svg" @click="$router.push({name:'welcome'})">
        <Form ref="signupForm" :model="signupData" :rules="loginRules" @keyup.enter.native="handleSubmit('signupForm')" class="white-box white-form">
            <FormItem prop="username">
                <i-input type="text" v-model="signupData.username" placeholder="Username"></i-input>
            </FormItem>
            <FormItem prop="first_name">
                <i-input type="text" v-model="signupData.first_name" placeholder="First Name"></i-input>
            </FormItem>
            <FormItem prop="last_name">
                <i-input type="text" v-model="signupData.last_name" placeholder="Last Name"></i-input>
            </FormItem>
            <FormItem prop="email">
                <i-input type="email" v-model="signupData.email" placeholder="Email"></i-input>
            </FormItem>
            <FormItem prop="phone_number">
                <CustomPhoneNumberInput v-model="signupData.phone_number" @update="onPhoneUpdate" :only-countries="allowedCountries"  no-use-browser-locale/>
            </FormItem>
            <FormItem prop="password">
                <i-input type="password" v-model="signupData.password" placeholder="Password"></i-input>
            </FormItem>
             <!-- <FormItem prop="passwordCheck">
                <i-input type="password" v-model="passwordCheck" placeholder="Confirm Password"></i-input>
            </FormItem> -->
            <Button size="large"  long class="btn black-btn" :loading="loading" @click="handleSubmit('signupForm')">
                <span v-if="!loading">Sign up</span>
            </Button>
        </Form>      
        <div class="redirect-link">
            <span class="gray-text">Already have an account?</span> 
            <a @click="$router.push({name:'login'})">Login</a>
        </div>
    </div>
</template>
<script>
    import { setSession } from '@/services/authentication.service'
    import { getAuthorizeURL } from '@/services/spotify/auth';
    import { register } from '@/services/upclub/auth'
    import { createProfile } from '@/services/upclub/profile'
    import { mapActions } from "vuex";
    // import VuePhoneNumberInput from 'vue-phone-number-input';
    import CustomPhoneNumberInput from '@/components/customPhoneNumberInput';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
 
    import '@/plugins/mixin'
    export default {
        data () {
            const validateAlphaNumeric = (rule, value, callback) => {
                let regex = /^[a-zA-Z0-9\-\s]+$/ 

                if (value && !regex.test(value)) {
                    callback(new Error('Field should not have special characters'));
                } else {
                    callback();
                }
            };
            const validateNoSpace = (rule, value, callback) => {
                let regex = /\s/

                 if (value && regex.test(value)) {
                    callback(new Error('Field should not have spaces'));
                } else {
                    callback();
                }
            };
            const validateUsername = (rule, value, callback) => {
                let regex = /^[a-zA-Z0-9-_@.+]+$/
                 if (value && value.search(regex) === -1) {
                    callback(new Error('Invalid username.'));
                } else {
                    callback();
                }
            };
            const validatePassCheck = (rule, value, callback) => {
                if (!this.passwordCheck) {
                    callback(new Error('Please enter your password'));
                } else if (this.passwordCheck !== this.signupData.password) {
                    callback(new Error('Passwords do not match!'));
                } else {
                    callback();
                }
            };
            const validatePass = (rule, value, callback) => {
                if (this.signupData.password === '') {
                    callback(new Error('Please enter your password'));
                } else {
                    if (!this.passwordCheck) {
                        callback();
                        this.$refs.signupForm.validateField('passwordCheck');
                    }else {
                        callback();
                        this.$refs.signupForm.validateField('passwordCheck');
                    }
                }
            };
            const validatePhoneNumber = (rule, value, callback) => {
                if (value) {
                    if(!this.phone_number_data.isValid && this.phone_number_data.phoneNumber){
                        callback(new Error('Invalid phone number'));
                    }else{
                        //check if landline
                        if (this.phone_number_data.type==='FIXED_LINE') {
                            callback(new Error('Accepts mobile number only'));
                        }else{
                            callback();
                        }
                    }
                }else{
                    callback();
                }
            };
            return {
                loading:false,
                signupData: {
                    first_name:'',
                    last_name:'',
                    username: '',
                    email: '',
                    phone_number:'',
                    password: ''
                },
                allowedCountries:['US','CA','MX','AT','BE','BG','CY','CZ','DE','DK','EE','ES','FI','FR','GB','GR','HU','HR','IE','IT','LT','LU','LV','MT','NL','PL','PT','RO','SE','SI','SK'],
                passwordCheck:'',
                loginRules: {
                    first_name: [
                        { validator: validateAlphaNumeric, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' }
                    ],
                    last_name: [
                        { validator: validateAlphaNumeric, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' }
                    ],
                    username: [
                        { validator: validateUsername, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' }
                    ],
                    email: [
                        { required: true, message: 'Field required', trigger: 'change' },
                        { type: 'email', message: 'Incorrect email format', trigger: 'change' }
                    ],
                    phone_number: [
                        { validator: validatePhoneNumber, trigger: 'change' },
                    ],
                    password: [
                        // { validator: validatePass, trigger: 'change' },
                        { validator: validateNoSpace, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                        { type: 'string', min: 8, message: 'The password length cannot be less than 8 characters', trigger: 'change' }
                    ],
                    // passwordCheck: [
                    //     { validator: validatePassCheck, trigger: 'change' }
                    // ]
                },
                phone_number_data:{}
            }
        },
        components: {CustomPhoneNumberInput},
        methods: {
            ...mapActions({
                spotifySetAuthState: "spotifyAuth/setAuthState",
                upclubGetAuthToken: "upclubAuth/getAuthToken",
                }),
            handleSubmit(name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.signupData.phone_number){
                            this.signupData.phone_number=this.phone_number_data.formattedNumber
                        }
                        register(this.signupData).then(async res=>{
                            let state = this.generateKey(8)
                            let code_verifier = await this.generateKey(43)
                            let code_challenge = await this.generateCodeChallenge(code_verifier)
                            localStorage.setItem('authorize_state', state)
                            localStorage.setItem('code_verifier', code_verifier)
                            await this.spotifySetAuthState(state);
                            let loginData ={
                                username: this.signupData.username,
                                password: this.signupData.password
                            }
                            await this.upclubGetAuthToken(loginData).then(res=>{
                                createProfile({})
                            })
                            let auth_url = getAuthorizeURL(state, code_challenge)
                            window.location.href=auth_url
                            this.loading = false
                        }).catch(err=>{
                            this.loading=false
                        });
                    }
                })
            },
            onPhoneUpdate(data){
                this.phone_number_data=data
                this.$refs.signupForm.validateField('phone_number');
            }
        }
    }
</script>
<template>
    <Modal v-model="newMessageModal" @on-visible-change="visibleChange($event)" :title="title" :transfer="false" :lock-scroll="false" class-name="new-message white-modal">
        <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <div class="modal-body">
            <div class="search-users">
                <div class="search-field flex-horizontal-centered">
                    <div class="search-btn">
                        <img src="../../assets/images/icons/search.svg" alt="">
                    </div>
                    <multiselect class="search-multiselect"
                    v-model="selected_users"
                    :options="optionUsers"
                    :multiple="true"    
                    :closeOnSelect="false"
                    :preserveSearch="true"
                    placeholder="Search user"
                    label="username"
                    track-by="username" 
                    @search-change="searchRemoteMethod"
                    @remove="removeSelected">
                    </multiselect>
                </div>
            </div>
            <search-user-result ref="userSearchResults" class="modal-search-results" :search_query="search_query" :defaultSelected="defaultSelected" :disabledUsers="disabledUsers" @setOptionUsers="setOptionUsers" @setSelectedUsers="setSelectedUsers"></search-user-result>
        </div>
        <div slot="footer">
            <Button size="large" class="btn black-btn" long :loading="loading" @click="handleSubmit()">
                <span v-if="!loading">{{submitText}}</span>
            </Button>
        </div>
    </Modal>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import SearchUserResult from './SearchUserResult'

    export default {
        props: {
            title:{default:'Search user'}, 
            submitText:{default:'Submit'},
            defaultSelected:{
                type: Array,
                default: () => []
            },
            disabledUsers:{
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                loading:false,
                newMessageModal:false,
                search_query:'',
                idletimer:null,
                selected_users:[],
                option_users: [],
            }
        },
        components: {Multiselect,SearchUserResult},
        computed: {
            optionUsers(){
                return this.option_users
            }
        },
        methods: {
            visibleChange(val){
                if(val){
                    this.$refs.userSearchResults.setRecent()
                }
                else{
                    this.search_query=''
                    this.selected_users=[]
                    this.option_users= []
                    this.create_loading=false
                    this.$refs.userSearchResults.resetData()
                }
            },
            openModal(){
                this.newMessageModal=true
                this.selected_users=this.defaultSelected
                let selectedIds = this.defaultSelected.map(user=>{return user.upclubuser})
                this.$refs.userSearchResults.setDefault(selectedIds)
            },
            searchRemoteMethod (query) {
                this.search_query=query
                let vm=this
                if (query && query !== '') {
                    clearTimeout(this.idletimer);
                    this.idletimer =  setTimeout(async()=>{ 
                        vm.$refs.userSearchResults.reloadInfiniteLoader()
                    }, 600);
                }else{
                    this.$refs.userSearchResults.setRecent()
                }
            },
            async setOptionUsers(options){
                let current_options = Object.assign([],this.option_users)
                let current_values = new Set(current_options.map(option => option.upclubuser));
                let merged = [...current_options, ...options.filter(option => !current_values.has(option.upclubuser))];
                this.option_users = merged
            },
            async setSelectedUsers(users){
                let recent = JSON.parse(localStorage.getItem('recent_user_search'))
                this.selected_users = this.option_users.reduce((selected,option)=>{
                    if(users.indexOf(option.upclubuser)>-1){
                        selected.push(option)
                        recent.unshift(option)
                    }
                    return selected
                },[])

                if(recent.length>10){
                    recent = recent.slice(0, 10);
                }
                recent = recent.filter((user, index, array) => array.findIndex(u => u.upclubuser == user.upclubuser) == index);

                localStorage.setItem('recent_user_search', JSON.stringify(recent))
            },
            searchQueryChange(query){
                this.search_query=query
            },
            removeSelected(data){
                this.$refs.userSearchResults.removeSelected(data)
            },
            async handleSubmit(){
                let count = this.selected_users.length + this.disabledUsers.length
                if(count<25){
                    this.$emit('onSubmit', this.selected_users)
                    this.search_query=''
                    this.selected_users=[]
                    this.option_users= []
                    this.loading=false
                    this.newMessageModal=false
                }else{
                    this.$Modal.warning({
                        title: 'Exceed Limit',
                        content: 'You cannot add more that 25 users',
                        width:350
                    });
                }
            },
        },
        mounted() {
        },
        destroy(){
        }
    }
</script>

<style scoped>

</style>

<template>
    <div class="profile-playlist" v-if="upclubuser">
        <div v-if="showNoData" class="no-data">No results found</div>
        <post-list ref="PostListing" @onUnvote="onUnvote" :page="'mylikes'" :key="postListKey"></post-list>
        <infinite-loading :identifier="infiniteId" @infinite="getLikePlaylist"></infinite-loading>              
    </div>
</template>

<script>
    import { getProfileLikes} from '@/services/upclub/profile'
    import { getTracks } from '@/services/spotify/tracks'
    import { EventBus } from '@/eventBus'
    import PostList from '@/components/postList'
    export default {
        props:['upclubuser'],
        data () {
            return {
                showNoData:false,
                category:"new",
                postListKey:0,
                infiniteId:0,
                likes:[],
                next_request_uri:null,
            }
        },
        components: {PostList},
        methods: {
            filterChanged(category){
                this.postListKey+=1
                this.category=category
                this.$refs.PostListing.emptyList()
                this.likes=[]
                this.next_request_uri=null
                this.infiniteId+=1
                this.showNoData=false
            },
            async getLikePlaylist($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                getProfileLikes(this.upclubuser, this.category, queryParams).then(res=>{
                    if(res.data.count>0){
                        this.showNoData=false
                        this.next_request_uri = res.data.next
                        let posts = res.data.results
                        let ids = posts.reduce((ids,post)=>{
                            if(post.post_type!=1){
                                ids.push(post.song)
                            }
                            return ids
                        },[])
                        ids = ids.join(",")
                        getTracks(ids).then(async track=>{
                            let songs = track.data.tracks.filter(function (item) {return item != null;});
                            let posttracks = posts.reduce((postTracks, post)=>{
                                let song = songs.filter(song=>{if(post.song===song.id) return song})
                                let order = this.likes.length+postTracks.length
                                if(song.length){
                                    post.order = order
                                    post.track_details = song[0]
                                    postTracks.push(post)
                                }
                                return postTracks
                            },[])
                            this.likes = this.likes.concat(posttracks)
                            this.$refs.PostListing.addToList(posttracks)
                            if(this.next_request_uri){
                                $state.loaded();
                            }else{
                                $state.complete();
                            }
                        }).catch(err=>{
                            if(this.next_feed_url){
                                $state.loaded();
                            }else{
                                $state.complete();
                            }
                        })
                    }else{
                        this.showNoData=true
                        $state.complete();
                    }
                })
            },
            getSongPost(posts, song){
                let post = posts.filter(post=>{if(post.song===song) return post})
                return post[0]
            },
            onUnvote(){
                this.$emit('updateProfileCounts')
            }
        },
        mounted() {
            let vm = this
            EventBus.$on("LIKES_UPDATED", function () {
                vm.filterChanged('new')
            });
        }
    }
</script>

<style scoped>
.track-filters{
    text-align: center;
}
</style>

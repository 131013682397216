<template>
    <div class="search-results">
        <div class="no-data" v-if="showNoData">No results found</div>
        <post-list ref="PostListing" class="search-results-list"></post-list>
        <infinite-loading :identifier="infiniteId" @infinite="getPosts"></infinite-loading>              
    </div>
</template>

<script>
    import {searchPostSong} from '@/services/upclub/post'
    import { getTracks } from '@/services/spotify/tracks'
    import PostList from '@/components/postList'

    export default {
        data () {
            return {
                next_request_uri:null,
                showNoData:false,
                posts:[],
                infiniteId:0,
                query: null,
            }
        },
        components: {PostList},
        methods: {
            getResults(query){
                this.showNoData=false
                if(query){
                    this.$refs.PostListing.emptyList()
                    this.next_request_uri=null
                    this.posts=[]
                    this.query=query
                    this.infiniteId+=1
                }else{
                    this.setRecent()
                }
            },
            getPosts($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                searchPostSong(this.query,queryParams).then(res=>{
                    if(res.data.count>0){
                        this.showNoData=false
                        this.next_request_uri = res.data.next
                        let posts = res.data.results
                        let ids = posts.reduce((ids,post)=>{
                            if(post.post_type!=1){
                                ids.push(post.song)
                            }
                            return ids
                        },[])
                        ids = ids.join(",")
                        getTracks(ids).then(async track=>{
                            let songs = track.data.tracks.filter(function (item) {return item != null;});
                            let posttracks = posts.reduce((postTracks, post)=>{
                                let song = songs.filter(song=>{if(post.song===song.id) return song})
                                let order = this.posts.length+postTracks.length
                                if(song.length){
                                    post.order = order
                                    post.track_details = song[0]
                                    postTracks.push(post)
                                }
                                return postTracks
                            },[])
                            this.posts = this.posts.concat(posttracks)
                            this.$refs.PostListing.addToList(posttracks)
                            if(!localStorage.hasOwnProperty('recent_post_search')){
                                let recent_posttracks = posttracks.slice(0, 4)
                                localStorage.setItem('recent_post_search', JSON.stringify(recent_posttracks))
                            }else{
                                let recent = JSON.parse(localStorage.getItem('recent_post_search'))
                                posttracks.forEach(post => {
                                    let index = recent.findIndex(x => x.song_post === post.song_post);
                                    if(index==-1){
                                        recent.unshift(post)
                                        if(recent.length>4){
                                            recent = recent.slice(0, 4);
                                        }
                                        localStorage.setItem('recent_post_search', JSON.stringify(recent))
                                    } 
                                });    
                            }
                            if(this.next_request_uri){
                                $state.loaded();
                            }else{
                                $state.complete();
                            }
                        }).catch(err=>{
                            if(this.next_feed_url){
                                $state.loaded();
                            }else{
                                $state.complete();
                            }
                        })
                    }else{
                        this.showNoData=true
                        $state.complete();
                    }
                })
            },
            getSongPost(posts, song){
                let post = posts.filter(post=>{if(post.song===song) return post})
                return post[0]
            },
            async setRecent(){
                if(localStorage.hasOwnProperty('recent_post_search')){
                    let recent = JSON.parse(localStorage.getItem('recent_post_search'))
                    this.posts = recent.slice(0, 4)
                    this.$refs.PostListing.emptyList()
                    this.$refs.PostListing.addToList(recent.slice(0, 4))
                }
                if(!localStorage.hasOwnProperty('recent_post_search')){
                    this.showNoData=true
                }
            }
        },
        mounted() {
            this.setRecent()
        }
    }
</script>

<style scoped></style>

<template>
    <div class="auth-form forgot">
        <img class="logo" src="../../assets/images/upclub_logo_large.svg" @click="$router.push({name:'welcome'})"><br>
        <h3>Forgot password</h3>
        <Form ref="forgotCredentials" :model="forgotData" :rules="forgotRules" @submit.native.prevent @keyup.enter.native="handleSubmit('forgotCredentials')" class="white-box white-form">
            <FormItem prop="username">
                <i-input type="text" v-model="forgotData.username" placeholder="Username"></i-input>
            </FormItem>
            <Button size="large" long class="btn black-btn" :loading="loading" @click="handleSubmit('forgotCredentials')">
                <span v-if="!loading">Reset</span>
            </Button>
        
        </Form>
    </div>
</template>
<script>
    import { resetPasswordCode } from '@/services/upclub/auth'

    export default {
        data () {
            const validateNoSpace = (rule, value, callback) => {
                let regex = /\s/

                 if (value && regex.test(value)) {
                    callback(new Error('Field should not have spaces'));
                } else {
                    callback();
                }
            };
            return {
                loading:false,
                forgotData: {
                    username: '',
                },
                forgotRules: {
                    username: [
                        { validator: validateNoSpace, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                    ]
                }
            }
        },
        methods: {
           
            handleSubmit(name) {
                this.$refs[name].validate(async(valid) => {
                    if (valid) {
                        this.loading=true
                        resetPasswordCode(this.forgotData).then(res=>{
                            this.$Message.success('A password reset code has been sent to your email.');
                            localStorage.setItem('reset_username', this.forgotData.username)
                            this.$router.push({name:'change_password'})
                            this.loading=false
                        }).catch(err=>{
                            this.loading=false
                            let err_data = err.response.data
                            if(err_data.hasOwnProperty('detail')){
                                let detail = "Request failed"
                                if( err_data['detail']=='Not found.'){
                                    detail = "Username not found"
                                }
                                this.$Notice.error({
                                    desc: detail
                                });
                            }
                        })
                    }
                })
            }
        }
    }
</script>
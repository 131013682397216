<template>
    <Modal v-model="editModal" @on-visible-change="visibleChange($event)" title="Profile Settings" :transfer="false" :lock-scroll="false" class-name="update-profile white-modal">
        <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <Form class="profile-update-form white-form" ref="profileForm" :model="profileDetails" :rules="profileRules" @keyup.enter.native="handleSubmit('profileForm')">
            <div v-show="cropPhoto">
                  <Alert type="error" show-icon v-if="showAlertError" class="modal-alert">
                    Unsaved profile picture
                    <span slot="desc">
                        Please click on the <span class="check">&#x2713;</span> to save the image or <span class="cross">&#x2718;</span> to cancel.
                    </span>
                </Alert>
                <vue-croppie 
                    ref="croppieRef" 
                    :enableOrientation="true"
                    :enableResize="false"
                    :boundary="{width: 200, height: 200}"
                    :viewport="{width: 200, height: 200, type: 'circle' }"
                    @result="result">
                </vue-croppie>
                <div class="croppie-action">
                <!-- <Button @click="rotate(-90)" shape="circle" icon="ios-redo"></Button>
                <Button @click="rotate(90)" shape="circle" icon="ios-undo"></Button> -->
                <Button type="success" @click="savingProfile=true;crop(400);" shape="circle" icon="md-checkmark"></Button>
                <Button type="error" @click="latestFile='';cropPhoto=false" shape="circle" icon="md-close"></Button>
                </div>
            </div>
            <FormItem v-show="!cropPhoto" prop="profile_picture" class="profile_picture photo-spotify-wrapper">
                <div>
                    <Upload ref="contactPhoto" class="circle-upload-image"
                            type="drag"
                            :before-upload="handleUpload"
                            action="">
                            <img :src="profileImage" alt="">
                            <div class="upload-overlay">
                                <img src="../../assets/images/icons/edit.svg" alt="">
                            </div>
                    </Upload>
                    <p>Change Photo</p>
                </div>
                <!-- <div class="unlink-spotify-wrapper">
                    <div class="unlink-spotify" @click="showUnlinkSpotify=true">
                        <img src="../../assets/images/icons/spotify_logo.png" alt="">
                        <div class="upload-overlay">
                            <Icon type="ios-loading" size=32 class="spin-icon-load" color="white" v-if="unlinkLoading"></Icon>
                            <img src="../../assets/images/icons/close.svg" alt="" v-else>
                        </div>
                    </div>
                    <p>Unlink Spotify</p>
                </div> -->
            </FormItem>
            <FormItem prop="first_name">
                <label for="">First Name (optional)</label>
                <i-input type="text" v-model="profileDetails.first_name"></i-input>
            </FormItem>
            <FormItem prop="last_name">
                <label for="">Last Name (optional)</label>
                <i-input type="text" v-model="profileDetails.last_name"></i-input>
            </FormItem>
            <FormItem prop="username">
                <label for="">Username</label>
                <i-input type="text" v-model="profileDetails.username" class="update-nickname"></i-input>
            </FormItem>
            <FormItem prop="phone_number">
                <CustomPhoneNumberInput v-model="profileDetails.phone_number" @update="onPhoneUpdate" :only-countries="allowedCountries" no-use-browser-locale/>
            </FormItem>
            <FormItem prop="profile_description">
                <label for="">Bio (50 characters limit)</label>
                <i-input type="textarea" v-model="profileDetails.profile_description" :autosize="{minRows: 2,maxRows: 2}" class="update-description"></i-input>
            </FormItem>
            <!-- <Row class="connect-social" :gutter="40">
                <i-col :xs="24" :sm="12">
                     <FormItem prop="profile_twitter">
                        <i-input type="text" v-model="profileDetails.profile_twitter" placeholder="connect twitter"></i-input>
                        <img class="twitter" src="../../assets/images/profile/twitter.png" alt="">
                    </FormItem>
                </i-col>
                <i-col :xs="24" :sm="12">
                    <FormItem prop="profile_instagram">
                        <i-input type="text" v-model="profileDetails.profile_instagram"  placeholder="connect instagram"></i-input>
                        <img class="instagram" src="../../assets/images/profile/instagram.png" alt="">
                    </FormItem>
                </i-col>
            </Row> -->
            
        </Form>
        <div slot="footer">
            <Button size="large" class="btn black-btn" long :loading="loading" @click="handleSubmit('profileForm')">
                <span v-if="!loading">Save Changes</span>
            </Button>
            <app-version></app-version>
        </div>
        <Spin fix v-show="savingProfile">
            <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        </Spin>
        <Modal v-model="showUnlinkSpotify" width="450" footer-hide class-name="confirm-modal delete-post white-modal">
            <div slot="close"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
            <p slot="header">
                <span>Unlink Spotify?</span>
            </p>
            <div>
                <p>Are you sure you want to unlink your spotify account?</p>
                <p>You will be logged out if you continue.</p>
            </div><br>
            <div class="action-buttons">
                <Button size="large" long class="btn black-btn" @click="showUnlinkSpotify=false">Cancel</Button><br>
                <Button size="large" long class="btn black-btn" @click="signout()" :loading="unlinkLoading">
                    <span v-if="!unlinkLoading">Continue</span>
                </Button>
            </div>
        </Modal>
    </Modal>
</template>

<script>
    import { updateProfile } from '@/services/upclub/profile'
    import { logout } from '@/services/upclub/auth'
    import { mapGetters } from "vuex";
    import AppVersion from "@/components/version"
    // import VuePhoneNumberInput from 'vue-phone-number-input';
    import CustomPhoneNumberInput from '@/components/customPhoneNumberInput';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        data () {
            const validateNoSpace = (rule, value, callback) => {
                let regex = /\s/
                 if (value && regex.test(value)) {
                    callback(new Error('Field should not have spaces'));
                } else {
                    callback();
                }
            };
            const validateUsername = (rule, value, callback) => {
                let regex = /^[a-zA-Z0-9-_@.+]+$/
                 if (value && value.search(regex) === -1) {
                    callback(new Error('Invalid username.'));
                } else {
                    callback();
                }
            };
            const validateMaxCharacters= (rule, value, callback) => {
                if (value && value.length>50) {
                    callback(new Error('Maximum of 50 characters'));
                } else {
                    callback();
                }
            };
            const validateMaxWords = (rule, value, callback) => {
                let word_count = value.split(' ').filter(function(n) { return n != '' }).length;
                 if (value && word_count>20) {
                    callback(new Error('Maximum of 20 words'));
                } else {
                    callback();
                }
            };
            const validatePhoneNumber = (rule, value, callback) => {
                if (value) {
                    if(!this.phone_number_data.isValid && this.phone_number_data.phoneNumber){
                        callback(new Error('Invalid phone number'));
                    }else{
                        //check if landline
                        if (this.phone_number_data.type==='FIXED_LINE') {
                            callback(new Error('Accepts mobile number only'));
                        }else{
                            callback();
                        }
                    }
                }else{
                    callback();
                }
            };
            return {
                loading: false,
                savingProfile: false,
                showAlertError: false,
                unlinkLoading:false,
                showUnlinkSpotify: false,
                latestFile:"",
                profileImageUpdated:false,
                profileImage:"",
                profile:{},
                profileDetails:{
                    profile_picture: "",
                    first_name:"",
                    last_name:"",
                    username: "",
                    phone_number:"",
                    profile_description: "",
                    profile_twitter: "",
                    profile_instagram: "",
                    thumbnail_picture: ""   
                },
                allowedCountries:['US','CA','MX','AT','BE','BG','CY','CZ','DE','DK','EE','ES','FI','FR','GB','GR','HU','HR','IE','IT','LT','LU','LV','MT','NL','PL','PT','RO','SE','SI','SK'],
                profileRules: {
                    username: [
                        { validator: validateUsername, trigger: 'change' },
                        { required: true, message: 'Field required', trigger: 'change' },
                    ],
                    profile_description: [
                        { validator: validateMaxCharacters, trigger: 'change' },
                    ],
                    profile_twitter: [
                        { validator: validateNoSpace, trigger: 'change' },
                    ],
                    profile_instagram: [
                        { validator: validateNoSpace, trigger: 'change' },
                    ],
                    phone_number: [
                        { validator: validatePhoneNumber, trigger: 'change' },
                    ],
                },
                editModal:false,
                cropPhoto:false,
                phone_number_data:{}
            }
        },
        components: {AppVersion,CustomPhoneNumberInput},
        computed: {
            ...mapGetters({
                player: "player/getPlayer",
            }),
        },
        methods: {
            visibleChange(val){
                this.$emit('setUpdateVisibility',val)
            },
            onPhoneUpdate(data){
                this.phone_number_data=data
                this.$refs.profileForm.validateField('phone_number');
            },
            openModal(profile){
                this.editModal=true
                this.profile = profile
                this.profileDetails = Object.assign({},profile)
                this.profileImage = "data:image/jpeg;base64,"+profile.profile_picture
            },
            getBase64(file) {
                return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
                });
            },
            handleUpload (file) {
                this.latestFile=file
                this.cropPhoto=true
                this.getBase64(file).then(data => {
                this.$refs.croppieRef.bind({url:data})
                this.profileDetails.thumbnail_picture = ""
                })
                return false;
            },
            rotate(rotationAngle) {
                this.$refs.croppieRef.rotate(rotationAngle);
            },
            crop(size){
                setTimeout(async()=>{ 
                    this.profile_crop(size)
                }, 100);
            },
            profile_crop(size) {
                let vm=this
                let options = {
                    type: 'base64',
                    size: { width: size, height: size },
                    format: 'png',
                    circle: false
                }
                vm.$refs.croppieRef.result(options, (output) => {
                    var base64result = output.split(',')[1];
                    if(base64result.length<=160000){
                        vm.profileDetails.profile_picture = base64result;
                        vm.profileImage= output
                        this.thumbnail_crop(250)
                    }else{
                        this.profile_crop(size-2)
                    }
                });
            },
            thumbnail_crop(size) {
                let vm=this
                let options = {
                    type: 'base64',
                    size: { width: size, height: size },
                    format: 'png',
                    circle: false
                }
                vm.$refs.croppieRef.result(options, (output) => {
                    var base64result = output.split(',')[1];
                    if(base64result.length<=30000){
                        vm.cropPhoto = false
                        vm.profileDetails.thumbnail_picture = base64result;
                        vm.savingProfile = false
                        vm.profileImageUpdated = true
                        vm.showAlertError=false
                    }else{
                        this.thumbnail_crop(size-2)
                    }
                });
            },
            result(output) {
                let vm=this
                vm.profileDetails.profile_picture = output;
            },
            handleSubmit(name) {
                if(this.cropPhoto){
                    this.showAlertError=true
                }else{
                    this.showAlertError=false
                    this.$refs[name].validate(async(valid) => {
                        if (valid) {
                            this.loading=true
                            let updatedData = {}
                            if(this.phone_number_data.formattedNumber){
                                this.profileDetails.phone_number=this.phone_number_data.formattedNumber
                            }
                            Object.keys(this.profileDetails).forEach(key => {
                                if(this.profile.hasOwnProperty(key)){
                                    if(this.profile[key]!==this.profileDetails[key]){
                                        updatedData[key]=this.profileDetails[key]
                                    }
                                }else{
                                    updatedData[key]=this.profileDetails[key]
                                }
                            });
                            if(!this.profileImageUpdated){
                                delete updatedData['profile_picture']
                                delete updatedData['thumbnail_picture']
                            }
                            updateProfile(updatedData).then(res=>{
                                this.editModal=false
                                this.loading=false
                                this.$emit('refreshDetails')
                            }).catch(err=>{
                                this.loading=false
                            })
                        }
                    })
                }
            },
            async signout(){
                this.unlinkLoading=true
                if(this.player){
                    this.player.pause()
                }
                logout().then(res=>{
                    this.unlinkLoading=false
                    let app_version = localStorage.getItem('app_version')
                    localStorage.clear();
                    localStorage.setItem('app_version',app_version);
                    setTimeout(()=>{ 
                        this.$router.push({ name: 'login' })
                    }, 100);
                })
            }
        },
        mounted() {
            

        },
        destroy(){
            this.profileDetails = Object.assign({},this.profile)
        }
    }
</script>

<style scoped>
    .logout-btn{
        width: 160px;
        border: 1px solid #ffffff;
    }
    .version{
        background: transparent;
        color: gray;
        font-size: .8rem;
    }
    .check{
        font-size: 1.2rem;
        font-weight: 600;
        color: #19be6b;
    }
    .cross{
        font-size: 1rem;
        color: #ed4014;
    }
</style>

<template>
    <div class="messages-content height-minus-header">
        <page-header>
            <div slot="details" class="header-details">
                <p  class="header-title">Messages</p>
            </div>
        </page-header>
        <router-view ref="pageView"></router-view>
    </div>
</template>

<script>
    import PageHeader from '@/layout/PageHeader'
    export default {
        data () {
            return {

            }
        },
        components: {PageHeader},
        methods: {
            newMessageNotif(thread){
                // this.$refs.pageView.reloadThreads()
                this.$refs.pageView.insertUpdatedThread(parseInt(thread),'new_message')
                if(this.$route.name==='message_box' && this.$route.params.id==thread){
                    this.$refs.pageView.newMessageNotif()
                }

            }
        },
        mounted() {
            
        }
    }
</script>

<style scoped></style>

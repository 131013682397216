<template>
    <div class="myclubs-content">
        <div v-if="!showNoData" class="myclubs-listing">
            <div class="owned-clubs-partial" v-if="owned_count">
                <div class="all-filter-label">
                    <p>Owner & Curator</p>
                    <a class="gray-text" v-if="owned_count>=5" @click="$router.push({name:'owned_clubs'})">See More</a>
                </div>
                <div class="display-grid clubs-grid-list">
                    <club-card v-for="(club,oi) in owned_clubs" :key="oi" :club="club" @manageCurators="manageCurators" @inviteCurators="inviteCurators"></club-card>
                    <!-- to fill empty space (max 4 colums) -->
                    <div class="space-filler"></div>
                    <div class="space-filler"></div>
                    <div class="space-filler"></div>
                    <div class="space-filler"></div>
                </div>
            </div>
            <div class="followed-clubs-partial" v-if="followed_count">
                <div class="all-filter-label">
                    <p>Following</p>
                    <a class="gray-text" v-if="followed_count>=5" @click="$router.push({name:'followed_clubs'})">See More</a>
                </div>
                <div class="display-grid clubs-grid-list">
                    <club-card v-for="(club,fi) in followed_clubs" :key="fi" :club="club" @manageCurators="manageCurators" @inviteCurators="inviteCurators"></club-card>
                    <!-- to fill empty space (max 4 colums) -->
                    <div class="space-filler"></div>
                    <div class="space-filler"></div>
                    <div class="space-filler"></div>
                    <div class="space-filler"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getOwnedCuratedClubs, getFollowedClubs } from '@/services/upclub/clubs'
    import ClubCard from '@/components/clubs/ClubCard'
    import {mapGetters} from 'vuex'
    export default {
        props:['searchstring', 'category'],
        data () {
            return {
                dataloading:false,
                owned_count:null,
                owned_clubs:[],
                followed_count:null,
                followed_clubs:[],
            }
        },
        components: {ClubCard},
        mounted(){
            this.getResults()
        },
        computed: {
            ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
            showNoData(){
                return !this.dataloading && this.owned_count===0 && this.followed_count===0
            },
            searchIsActive(){
                return this.searchstring&&this.searchstring.length>0?true:false
            }
        },
        methods: {
            getResults(){
                this.$emit('setLoadingStatus',true)
                let queryParams = {upclubuser:this.getUserDetails.upclubuser}
                if(this.searchstring&&this.searchstring.length){
                    queryParams['searchstring'] = this.searchstring
                }
                if(this.category){
                    queryParams['category'] = this.category
                }
                this.getAllResults(queryParams)
            },
            getAllResults(params){
                this.dataloading=true
                this.$emit('setLoadingStatus',true)
                this.getAllOwnedCuratedClubs(params)
                this.getAllFollowedClubs(params)
            },
            reloadData(){
                this.getAllResults()
            },
            getAllOwnedCuratedClubs(params){
                this.owned_count=null
                this.owned_clubs=[]
                getOwnedCuratedClubs(params).then(res=>{
                    this.owned_count=res.data.count
                    this.owned_clubs=res.data.results.slice(0, 5)
                    let data_count = res.data.count
                    let owned_count = res.data.count??0
                    let followed_count = this.followed_count??0
                    data_count = owned_count + followed_count
                    this.$emit('setDataCount',data_count)
                    if(this.owned_count&&this.followed_count){
                        this.dataloading=false
                        this.$emit('setLoadingStatus',false)
                     }
                    
                })
            },
            getAllFollowedClubs(params){
                this.followed_count=null
                this.followed_clubs=[]
                 getFollowedClubs(params).then(res=>{
                    this.followed_count=res.data.count
                    this.followed_clubs=res.data.results.slice(0, 5)
                    this.dataloading=false
                    this.$emit('setLoadingStatus',false)
                    let data_count = res.data.count
                    let followed_count = res.data.count??0
                    let owned_count = this.followed_count??0
                    data_count = owned_count + followed_count
                    this.$emit('setDataCount',data_count)
                   if(this.owned_count&&this.followed_count){
                        this.dataloading=false
                        this.$emit('setLoadingStatus',false)
                    }
                })
            },
            manageCurators(club){
                this.$emit('manageCurators',club)
            },
            inviteCurators(club){
                this.$emit('inviteCurators',club)
            }
        }
    }
</script>

<style scoped>

</style>


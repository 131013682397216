import Authentication from "../views/Authentication";
import Welcome from "../views/Authentication/Welcome";
import Login from "../views/Authentication/Login";
import Signup from "../views/Authentication/Signup";
import ForgotPassword from "../views/Authentication/ForgotPassword";
import ChangePassword from "../views/Authentication/ChangePassword";
import ForgotUsername from "../views/Authentication/ForgotUsername";
import Terms from "../views/TermsAndPrivacy/Terms";
import Privacy from "../views/TermsAndPrivacy/Privacy";
import AuthorizeSpotify from "../views/Authentication/AuthorizeSpotify";


export default {
    path: "/",
    component: Authentication,
    children: [
        {
            path: "",
            name: "welcome",
            component: Welcome,
        },
        {
            path: "login",
            name: "login",
            component: Login,
        },
        {
            path: "signup",
            name: "signup",
            component: Signup,
        },
        {
            path: "terms",
            name: "terms",
            component: Terms,
        },
        {
            path: "privacy-policy",
            name: "privacy_policy",
            component: Privacy,
        },
        {
            path: "forgot-password",
            name: "forgot_password",
            component: ForgotPassword,
        },
        {
            path: "change-password",
            name: "change_password",
            component: ChangePassword,
        },
        {
            path: "forgot-username",
            name: "forgot_username",
            component: ForgotUsername,
        },
        {
            path: "authorize-spotify",
            name: "authorize_spotify",
            component: AuthorizeSpotify,
            meta: { requiredAuth: true },
        }
    ]
};

<template>
    <div class="track-votes flex-vertical-centered full-height" v-if="currentPost">
        <div class="vote-triggers">
            <!-- <div class="numvotes" v-if="currentPost.post_type!==1">
            </div>  -->
            <div class="vote" v-if="currentPost.post_type!==1">
                <span>{{currentPost.formatted_likes}}</span>
                <img @click="currentPost.liked? UnlikeSongPost():LikeSongPost();" :class="currentPost.liked?'active':''" src="../../assets/images/icons/up-vote.svg" alt="">
            </div> 
            <div class="comment" v-if="currentPost.post_type!==1">
                <img @click="openMainPlayer('view', currentPost)" src="../../assets/images/icons/comment.svg" alt="">
            </div>
            <div class="volume">
                 <Dropdown class="volume-dropdown">
                    <img @click="setVolume(0)" v-if="volume>=50" src="../../assets/images/icons/volume.svg" alt="">
                    <img @click="setVolume(0)" v-else-if="volume<50&&volume>0" src="../../assets/images/icons/volume-low.svg" alt="">
                    <img @click="unMute()" v-else src="../../assets/images/icons/muted.svg" alt="">
                    <div class="volume-slider" slot="list">
                        <Slider v-model="volume" @on-change="setVolume($event)"></Slider>
                    </div>
                </Dropdown>
            </div>
            <div class="menu">
                <more-actions @removePost="removePost" :post="currentPost"></more-actions>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import { likeSongPost,unlikeSongPost } from "@/services/upclub/post"
    import { volume } from "@/services/spotify/player"
    import { EventBus } from '@/eventBus';
    import MoreActions from '@/components/track/MoreActions'


    export default {
        name: "TrackVotes",
        props: ["currentPost"],
        data () {
            return {
                volume:0
            }
        },
        components: {MoreActions},
        computed: {
            ...mapGetters({
                device_id: "player/getDeviceID",
                playback: "player/getPlayback",
                player: "player/getPlayer",
                currentPostList: "upclubPlayer/getCurrentPostList",
                currentPostPage: "upclubPlayer/getCurrentPostPage",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
            }),
        },
        methods: {
            ...mapActions({
                setCurrentPostList: "upclubPlayer/setCurrentPostList",
            }),
            LikeSongPost(){
                let data = {song_post:this.currentPost.song_post}
                likeSongPost(data).then(res=>{
                    let num_likes = parseInt(this.currentPost.formatted_likes)
                    this.currentPost.formatted_likes=num_likes+1
                    this.currentPost.liked=true

                    let currentPostList = Object.assign([],this.currentPostList)
                    let currentPostIndex = currentPostList.findIndex(post => post.song_post === this.currentPost.song_post);
                    currentPostList[currentPostIndex] = this.currentPost
                    this.setCurrentPostList({status:'open',list:currentPostList})
                    if(this.$route.name===this.currentPostPage.name){
                        EventBus.$emit('POST_ITEM_UPDATED', 'update', this.currentPost);
                    }
                    if(this.$route.name==='profile_likes'){
                        EventBus.$emit('LIKES_UPDATED');
                    }
                })
            },
            UnlikeSongPost(){
                let data = {song_post:this.currentPost.song_post}
                unlikeSongPost(data).then(res=>{
                    let num_likes = parseInt(this.currentPost.formatted_likes)
                    this.currentPost.formatted_likes=num_likes-1
                    this.currentPost.liked=false

                    let currentPostList = Object.assign([],this.currentPostList)
                    let currentPostIndex = currentPostList.findIndex(post => post.song_post === this.currentPost.song_post);
                    currentPostList[currentPostIndex] = this.currentPost
                    this.setCurrentPostList({status:'open',list:currentPostList})
                    if(this.$route.name===this.currentPostPage.name){
                        EventBus.$emit('POST_ITEM_UPDATED', 'update', this.currentPost);
                    }
                    if(this.$route.name==='profile_likes'){
                        EventBus.$emit('LIKES_UPDATED');
                    }
                })
            },
            openMainPlayer(type, post){
                EventBus.$emit('OPEN_PLAYER', type, post);
            },
            removePost(){
                EventBus.$emit('POST_ITEM_UPDATED', 'delete', this.currentPost);
            },
            setVolume(val){
                // let volume_per = val/100

                if(val!==0){
                    localStorage.setItem('player_volume', val)
                }
                
                this.volume=val
                // this.player.setVolume(volume_per);
                volume(val,this.device_id)
            },
            async unMute(){
                if(localStorage.hasOwnProperty('player_volume')){
                    this.volume = parseInt(localStorage.getItem('player_volume'))
                }else{
                    this.volume = 100
                }
                this.setVolume(this.volume)
            }
        },
        mounted() {
            let vm=this
            vm.unMute()
            EventBus.$on("DEVICE_RESPONSE", function (status) {
                if(status==='ready'){
                    vm.unMute()
                }
            });
        }
    }
</script>

<style scoped>

</style>

<template>
    <Modal @on-visible-change="visibleChange($event)"  width="400" v-model="showModal" footer-hide :transfer="true" :lock-scroll="false" class-name="vertical-center-modal post-track-modal white-modal">
        <p slot="header" @click="$emit('postModalClose', 'back');showModal=false;" class="modal-back-header">
            <img src="../../assets/images/icons/chevron-left.svg" class="back-to-search" alt="">
            <span >Share to club</span>
        </p>
        <div slot="close" @click="$emit('postModalClose', 'all')"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <div class="modal-body">
            <Button size="large" class="black-btn btn create-club-button" @click="openClubForm()" long>Create a Club</Button>
            <div class="search-with-btn">
                <div class="search-club">
                    <div class="search-field flex-horizontal-centered">
                        <div class="search-btn">
                            <img src="../../assets/images/icons/search.svg" alt="">
                        </div>
                        <i-input type="text" class="search-input" clearable v-model="query" placeholder="Find Club" @on-change="getResults($event)"></i-input>   
                    </div>
                </div>
            </div>
            <div class="search-club-results" v-if="showModal">
                <div v-if="showNoData" class="no-data">No clubs found</div>
                <div class="search-item-container search-club-list modal-search-results">
                    <div v-for="(club,i) in clubs" :key="i" class="search-item" @click="setSelectedClub(club)">
                        <div class="search-item-image">
                            <img :src="getImage(club.thumbnail_picture)" alt="">
                        </div>
                        <div class="search-item-details">
                            <div>
                                <h4 class="track-name">{{club.club_name}}</h4>
                                <p class="track-artist">{{club.club_owner_name}}</p>
                                <div class="club-item-counts item-counts flex-horizontal-centered flex-gap-16">
                                    <p >{{club.followers}} Followers</p>
                                    <p >{{club.posts}} Posts</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <infinite-loading :identifier="infiniteId" @infinite="getClubs"></infinite-loading>
            </div>
        </div>
        <CreateClubModalVue ref="createClubForm" @onSubmit="onClubCreate"></CreateClubModalVue>
    </Modal>
</template>

<script>
    import { getOwnedCuratedClubs } from '@/services/upclub/clubs'
    import CreateClubModalVue from '@/components/clubs/CreateClubModal.vue' 
    import {mapGetters} from 'vuex'
    
    export default {
        data () {
            return {
                showModal:false,
                dataloading:false,
                next_request_uri:null,
                clubs_count:null,
                clubs:[],
                infiniteId:0,
                idletimer:null,
                query: null,
            }
        },
        components: {CreateClubModalVue},
        computed: {
            ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
            showNoData(){
                return !this.dataloading&&this.clubs_count===0
            },
            searchIsActive(){
                return this.query&&this.query.length>0?true:false
            }
        },
        methods: {
            visibleChange(val){
                if(!val){
                    this.resetData()
                }
            },
            openModal(){
                this.clubs=[]
                this.showModal = true
            },
            getResults(){
                let vm = this
                vm.dataloading=true
                clearTimeout(vm.idletimer);
                vm.idletimer = setTimeout(()=>{ 
                    vm.next_request_uri=null
                    vm.clubs=[]
                    vm.infiniteId+=1
                },600)
            },
            resetData(){
                this.next_request_uri=null
                this.clubs=[]
                this.query=null
            },
            reloadData(){
                this.resetData()
                this.infiniteId+=1
            },
            openClubForm(){
                this.$refs.createClubForm.openModal()
            },
            onClubCreate(){
                this.reloadData()
            },
            async getClubs($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                queryParams['upclubuser'] = this.getUserDetails.upclubuser
                queryParams['category'] = 'recentlyadded'
                if(this.query&&this.query.length){
                    queryParams['searchstring'] = this.query
                }
                getOwnedCuratedClubs(queryParams).then(res=>{
                    this.clubs_count=res.data.count
                    this.next_request_uri = res.data.next
                    this.clubs=this.clubs.concat(res.data.results)
                    this.dataloading=false
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            setSelectedClub(club){
                this.showModal=false
                this.$emit('selectClub',club)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
.create-club-button{
    padding: 12px;
    margin-bottom: 16px;
    color: #fff;
}
.search-club{
    width: 100%!important;
    margin-right: 0!important;
}
.search-club-results{
    margin-top: 16px;
    min-height: 100px;
}
.search-item-details{
    line-height: 1.2;
}
</style>

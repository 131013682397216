import {transferUsersPlayback, getPlayer, play} from "@/services/spotify/player";
import { EventBus } from '@/eventBus';
import store from "@/store";

const initialState = {
    deviceID:"",
    player:null,
    playback:{},
    playbackContext: ""
};

const state = Object.assign({},initialState);
let idletimer=null

const getters = {
    getDeviceID: (state) => state.deviceID,
    getPlayer: (state) => state.player,
    getPlayback: (state) => state.playback,
    getPlaybackContext: (state) => state.playbackContext,
    getRecentlyPlayed: (state) => state.recentlyPlayed,
    isPlaying: (state) => state.playback.is_playing
};

const mutations = {
    SET_DEVICE_ID(state, id) {
        state.deviceID = id;
    },
    SET_PLAYBACK(state, track) {
        state.playback = track;
    },
    SET_PLAYER(state, player) {
      state.player = player;
    },
    SET_PLAYBACK_CONTEXT(state, playback) {
        state.playbackContext = playback;
    },
    reset (state) {
        const s = initialState
        Object.keys(s).forEach(key => {
          state[key] = s[key]
        })
    }
};

const actions = {
    init: async function({ commit, rootGetters, dispatch }) {
        window.onSpotifyWebPlaybackSDKReady = () => {}
        async function waitForSpotifyWebPlaybackSDKToLoad() {
          return new Promise((resolve) => {
            if (window.Spotify) {
              resolve(window.Spotify);
            } else {
              window.onSpotifyWebPlaybackSDKReady = () => {
                resolve(window.Spotify);
              };
            }
          });
        }
        
        (async () => {
          const { Player } = await waitForSpotifyWebPlaybackSDKToLoad();
          const token = rootGetters["spotifyAuth/getAccessToken"];
          const player = new Player({
            name: "UpClub Web Player",
            getOAuthToken: (cb) => {
              cb(token);
            }
          });

          // Error handling
          player.addListener("initialization_error", ({ message }) => {
            console.error(message);
            EventBus.$emit('DEVICE_RESPONSE', 'not_ready');
          });
    
          player.addListener("authentication_error", ({ message }) => {
            let refresh_token = rootGetters["spotifyAuth/getRefreshToken"]
            if(!refresh_token){
              refresh_token = localStorage.getItem('spotify_refresh_token')
            }
            if(message){
              let data={
                grant_type: 'refresh_token',
                refresh_token: refresh_token
              }
              store.dispatch("spotifyAuth/getAuthToken",data);
            }
          });
    
          player.addListener("account_error", ({ message }) => {
            console.error(message);
            EventBus.$emit('SPOTIFY_ACCOUNT_ERROR', null, {error:{reason:"PREMIUM_REQUIRED"}});
            EventBus.$emit('DEVICE_RESPONSE', 'not_ready');
          });
    
          player.addListener("playback_error", ({ message }) => {
            console.error(message);
            EventBus.$emit('DEVICE_RESPONSE', 'not_ready');
          });
    
          // Playback status updates
          player.addListener("player_state_changed", (context) => {
            if (context) {
              dispatch("setPlaybackContext", context);
              dispatch("setPlayback");
            }else{
              let context = state.playbackContext
              if(context){
                context.paused=true
                dispatch("setPlaybackContext", context);
                dispatch("setPlayback");
              }
            }
          });
    
          // Ready
          player.addListener("ready", ({ device_id }) => {
            console.log("Ready with Device ID", device_id);
            commit("SET_DEVICE_ID", device_id);
            commit("SET_PLAYER", player);
            EventBus.$emit('DEVICE_RESPONSE', 'ready');
            setTimeout(()=>{ 
                transferUsersPlayback([device_id], false).then(res=>{
                  if(player&&device_id){
                    if(localStorage.hasOwnProperty('play_after_reauthentication')){
                      try {
                        let data = JSON.parse(localStorage.getItem('play_after_reauthentication'))
                        play(data).then(play_res=>{
                          localStorage.removeItem('play_after_reauthentication')
                          return true;
                        })
                      } catch (e) {
                          return false;
                      }
                      
                    }
                  }
                });
            }, 300);
            
          });
    
          // Not Ready
          player.addListener("not_ready", ({ device_id }) => {
            console.log("Device ID has gone offline", device_id);
            this.init()
            EventBus.$emit('DEVICE_RESPONSE', 'not_ready');
          });

          // Connect to the player!
          player.connect();
    
        })();
    },
    setPlayback({ commit }, playback) {
      let currentPlaying =  store.getters["upclubPlayer/getCurrentPlayingPost"];

      if(playback){
        clearTimeout(idletimer);
        commit("SET_PLAYBACK", playback);
      }else{
        try {
          const response = getPlayer().then(response=>{
            if (response.data.item){
              let playback = response.data
              commit("SET_PLAYBACK", playback);
              let playback_song_id = playback.item.id
              if(currentPlaying&&currentPlaying.post){
                if(currentPlaying.post.song===playback_song_id){
                  clearTimeout(idletimer);
                  EventBus.$emit('TRACK_CHANGED', currentPlaying.post);
                }else{
                  clearTimeout(idletimer);
                  idletimer = setTimeout(async()=>{ 
                    store.dispatch("upclubPlayer/setCurrentPlayingPost",{index:null, post:null});
                    EventBus.$emit('TRACK_CHANGED', null);
                  }, 2000);
                }
              }
            }
          })
        } catch (e) {
          console.log(e);
          this.init()
          EventBus.$emit('DEVICE_RESPONSE', 'not_ready');
        }
      }
    },
    setPlaybackContext({ commit }, context) {
        commit("SET_PLAYBACK_CONTEXT", context);
    }
};

const module = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default module;

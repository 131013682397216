<template>
    <div class="track-playback-control">
       <div class="track-controls" v-if="playback.item">
            <div class="shuffle">
                <img @click="shuffle();" class="trigger-icon" :class="[!playback.item?'disable-click':'',getPlayerShuffle?'active':'']" src="../../assets/images/icons/shuffle.svg" alt="">
            </div> 
            <div class="prev" >
                <img @click="!disablePrev?prev():null;" class="trigger-icon" :class="!playback.item?'disable-click':''" src="../../assets/images/icons/skip-back.svg" alt="">
            </div>
            <div class="play-pause">
                <img v-if="!context.paused&&playback.item.id===currentPlayingPost.post.song" class="trigger-icon"  @click="pause();" :class="!playback.item?'disable-click':''"  src="../../assets/images/icons/pause.svg" alt="">
                <img v-else @click="play();" class="trigger-icon" :class="!playback.item?'disable-click':''" src="../../assets/images/icons/play.svg" alt="">
            </div>
            <div class="next">
                <img @click="!disableNext?next():null;" class="trigger-icon" :class="!playback.item?'disable-click':''" src="../../assets/images/icons/skip-forward.svg" alt="">
            </div>
            <div class="repeat">
                <img @click="repeat();" class="trigger-icon active" v-if="playerRepeat==='track'" src="../../assets/images/icons/repeat-1.svg" alt="">
                <img @click="repeat();" class="trigger-icon" v-else :class="playerRepeat==='all'?'active':''" src="../../assets/images/icons/repeat.svg" alt="">
            </div> 
        </div>

        <!-- <div class="share">
            <Dropdown trigger="custom" :class="!playback.item?'disable-click':''" :visible="visible" :transfer="true" @on-clickoutside="handleClose()" placement="bottom-start"  transfer-class-name="share-menu player">
                <a href="javascript:void(0)" @click="handleOpen">
                    <img src="../../assets/images/icons/share.png" class="action-image" alt="">
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem>
                        <img src="../../assets/images/icons/upclub_btn.png" alt="">
                        <p>send in a message on up club</p>
                    </DropdownItem>
                    <DropdownItem>
                        <img src="../../assets/images/icons/copy_btn.png" alt="">
                        <p>copy link to send to a friend</p>
                    </DropdownItem>
                    <DropdownItem>
                        <img src="../../assets/images/icons/instagram_btn.png" alt="">
                        <p>post to your instagram story</p>
                    </DropdownItem>
                    <DropdownItem>
                        <img src="../../assets/images/icons/twitter_btn.png" alt="">
                        <p>share the post on twitter</p>
                    </DropdownItem>
                    <DropdownItem>
                        <img src="../../assets/images/icons/Imessage_btn.png" alt="">
                        <p>send post in a message </p>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div> -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import {transferUsersPlayback, nextTrack, previousTrack, pause, play, shuffle, repeat} from "@/services/spotify/player";

    export default {
        name: "PlayerControls",
        data () {
            return {
                visible:false,
                disablePrev:false,
                disableNext:false,
                pause_next:false 
            }
        },
        computed: {
            ...mapGetters({
                device_id: "player/getDeviceID",
                player: "player/getPlayer",
                playback: "player/getPlayback",
                context: "player/getPlaybackContext",
                currentPostList: "upclubPlayer/getCurrentPostList",
                currentPostPage: "upclubPlayer/getCurrentPostPage",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
                playerRepeat: "upclubPlayer/getPlayerRepeat",
                getPlayerShuffle: "upclubPlayer/getPlayerShuffle"
            }),
        },
        methods: {
            ...mapActions({
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
                setPlayerRepeat: "upclubPlayer/setPlayerRepeat",
                setPlayerShuffle: "upclubPlayer/setPlayerShuffle"
            }),
            handleOpen () {
                this.visible = true;
            },
            handleClose () {
                this.visible = false;
            },
            async next() {
                // nextTrack().then(res=>{
                //     if(this.playback.device.id!==this.device_id){
                //         transferUsersPlayback([this.device_id], true);
                //     }
                // })
                this.disableNext=true
                if(this.playerRepeat==='track'){
                    this.setPlayerRepeat('all')
                }
                let postNextIndex = this.currentPlayingPost.index+1
                let next_track = this.currentPostList[postNextIndex]
                if(!next_track){
                    this.pause_next=true
                    postNextIndex = 0
                    next_track = this.currentPostList[0]
                }else{
                   this.pause_next=false 
                }
                let data ={
                    offset: {
                        position: 0
                    },
                    uris: [next_track.track_details.uri],
                    position_ms: 0
                }
                if(this.playback && this.playback.item && this.playback.device.id!==this.device_id){
                    transferUsersPlayback([this.device_id], true).then(res=>{
                        this.disableNext=false
                    })
                    
                }else{
                    play(data).then(res=>{
                        // if(this.pause_next){
                        //     pause().then(res=>{
                        //         this.pause_next=false 
                        //     })
                        // }
                        this.setCurrentPlayingPost({index:postNextIndex, post:next_track})
                        this.disableNext=false
                    })
                }
            },
            async prev() {
                // previousTrack().then(res=>{
                //     if(this.playback.device.id!==this.device_id){
                //         transferUsersPlayback([this.device_id], true);
                //     }
                // })
                this.disablePrev=true
                if(this.playerRepeat==='track'){
                    this.setPlayerRepeat('all')
                }
                let postPrevIndex = this.currentPlayingPost.index-1
                let prev_track = this.currentPostList[postPrevIndex]
                if(!prev_track){
                    postPrevIndex = this.currentPostList.length-1
                    prev_track = this.currentPostList[postPrevIndex]
                }
                let data ={
                    offset: {
                        position: 0
                    },
                    uris: [prev_track.track_details.uri],
                    position_ms: 0
                }
                if(this.playback && this.playback.item && this.playback.device.id!==this.device_id){
                    transferUsersPlayback([this.device_id], true).then(res=>{
                        this.disablePrev=false
                    })
                }else{
                    play(data).then(res=>{
                        this.disablePrev=false
                        this.setCurrentPlayingPost({index:postPrevIndex, post:prev_track})
                    })
                }
                

            },
            pause() {
            //    this.player.pause();
                pause()
            },
            play() {
                if(this.playback.device.id!==this.device_id){
                    transferUsersPlayback([this.device_id], true)
                }else{
                    this.player.resume();
                }
            },
            shuffle() {
                this.setPlayerShuffle(!this.getPlayerShuffle)
            },
            async repeat(){
                if(this.playerRepeat==='track'){
                    this.setPlayerRepeat(null)
                }else if(this.playerRepeat==='all'){
                    this.setPlayerRepeat('track')
                }else{
                    this.setPlayerRepeat('all')
                }
                // repeat(state)
            }
        },
        mounted() {
           
        },
    }
</script>

<style scoped>

</style>

import router from '@/router'

const initialState = {
    current_post_list: [],
    current_list_state: 'open',
    current_post_page: {name:'',params:{}},
    current_playing_post:{index:null,post:null},
    repeat:'all',
    shuffle:false,
};

const state = Object.assign({},initialState);

const getters = {
    getCurrentPostList: (state) => state.current_post_list,
    getCurrentPostListState: (state) => state.current_list_state,
    getCurrentPostPage: (state) => state.current_post_page,
    getCurrentPlayingPost: (state) => state.current_playing_post,
    getPlayerRepeat: (state) => state.repeat,
    getPlayerShuffle: (state) => state.shuffle
};

const mutations = {
    SET_CURRENT_POST_LIST(state, postList) {
        state.current_list_state = postList.status;
        state.current_post_list = postList.list;
        localStorage.setItem('current_post_list', JSON.stringify(state.current_post_list))
    },
    SET_CURRENT_POST_PAGE(state, page) {
        state.current_post_page = page;
        localStorage.setItem('current_post_page', JSON.stringify(page))
    },
    SET_CURRENT_PLAYING_POST(state, post) {
        state.current_playing_post = post;
        localStorage.setItem('current_playing_post', JSON.stringify(post))
    },
    SET_SHUFFLE(state, shuffle) {
        state.shuffle = shuffle;
        localStorage.setItem('player_shuffle', shuffle)
    },
    SET_REPEAT(state, repeat) {
        state.repeat = repeat;
        localStorage.setItem('player_repeat', repeat)
    },
};

const actions = {
    async setCurrentPostList({ state, commit }, postList) {
        if(state.shuffle==true){
            postList.list = postList.list.sort(() => Math.random() - 0.5)
        }
        if(state.current_playing_post.post){
            let postIds = postList.list.map(post=>{return post.song_post})
            let postIndex = postIds.indexOf(state.current_playing_post.post.song_post)
            state.current_playing_post.index=postIndex
            localStorage.setItem('current_playing_post', JSON.stringify(state.current_playing_post))
        }
        commit("SET_CURRENT_POST_LIST", postList);
    },
    async appendCurrentPostList({ state, commit }, newItems) {
        if(state.current_list_state==='open'){
            if(state.current_post_page.name===router.history.current.name&&state.current_post_page.params===router.history.current.params){
                if(state.shuffle==true){
                    newItems = newItems.sort(() => Math.random() - 0.5)
                }
                let new_list = state.current_post_list.concat(newItems)
                commit("SET_CURRENT_POST_LIST", {status:'open', list:new_list});
            }else{
                if(!state.current_post_page.name){
                    let new_list = state.current_post_list.concat(newItems)
                    commit("SET_CURRENT_POST_LIST", {status:'open', list:new_list});
                    commit("SET_CURRENT_POST_PAGE", {name:router.history.current.name, params:router.history.current.params});
                }
            }
        }
    },
    async prependCurrentPostList({ state, commit }, newItems) {
        if(state.current_list_state==='open'){
            if(state.current_post_page.name===router.history.current.name&&state.current_post_page.params===router.history.current.params){
                if(state.shuffle==true){
                    newItems = newItems.sort(() => Math.random() - 0.5)
                }
                newItems = newItems.concat(state.current_post_list)
                commit("SET_CURRENT_POST_LIST", {status:'open', list:newItems});
            }else{
                if(!state.current_post_page.name){
                    newItems = newItems.concat(state.current_post_list)
                    commit("SET_CURRENT_POST_LIST", {status:'open', list:newItems});
                    commit("SET_CURRENT_POST_PAGE", {name:router.history.current.name, params:router.history.current.params});
                }
            }
            if(state.current_playing_post.post){
                let postIds = newItems.map(post=>{return post.song_post})
                let postIndex = postIds.indexOf(state.current_playing_post.post.song_post)
                state.current_playing_post.index=postIndex
                localStorage.setItem('current_playing_post', JSON.stringify(state.current_playing_post))
            }
        }
    },
    setCurrentPostPage({ commit }, page) {
        commit("SET_CURRENT_POST_PAGE", page);
    },
    async setCurrentPlayingPost({ state, commit }, post) {
        if(post.post){
            if(state.shuffle==true){
                let postIds = state.current_post_list.map(post=>{return post.song_post})
                let postIndex = postIds.indexOf(post.post.song_post)
                post.index=postIndex
            }
        }
        commit("SET_CURRENT_PLAYING_POST", post);
    },
    setPlayerRepeat({ commit }, repeat) {
        commit("SET_REPEAT", repeat);
    },
    setPlayerShuffle({ state, commit }, shuffle) {
        commit("SET_SHUFFLE", shuffle);
        if(shuffle==true){
            state.current_post_list = state.current_post_list.sort(() => Math.random() - 0.5)
        }else{
            state.current_post_list = state.current_post_list.sort(function(a, b) { 
                return - ( b.order - a.order);
            });
        }
        if(state.current_playing_post.post){
            let postIds = state.current_post_list.map(post=>{return post.song_post})
            let postIndex = postIds.indexOf(state.current_playing_post.post.song_post)
            state.current_playing_post.index=postIndex
            localStorage.setItem('current_playing_post', JSON.stringify(state.current_playing_post))
        }
        localStorage.setItem('current_post_list', JSON.stringify(state.current_post_list))
    }

};

const module = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default module;

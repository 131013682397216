<template>
    <div class="notifications-content">
        <page-header>
            <div slot="details" class="header-details">
                <p  class="header-title">Notifications</p>
            </div>
        </page-header>
        <router-view ref="pageView"></router-view>
    </div>
</template>

<script>
    import PageHeader from '@/layout/PageHeader'
    export default {
        data () {
            return {

            }
        },
        components: {PageHeader},
        methods: {
            reloadNotifications(){
                this.$refs.pageView.reloadData()
            },
        },
        mounted() {

        }
    }
</script>

<style scoped></style>

<template>
    <div class="comments-container">
        <div  class="comments-list">
            <div class="first-comment-list" v-show="!replyComment">
                <div class="first-level-comment white-box" v-for="(comment,i) in getCommentListing" :key="i">
                    <comment-details :level="1" :comment="comment" @reply="setCurrentSecondComment"  @closePlayerModal="$emit('closePlayerModal')"></comment-details>
                    <div class="second-level-comment" v-if="comment.formatted_replies>0">
                        <comment-details :level="2" v-for="(second_comment,j) in comment.second_level_comments.slice(0, 1)" :key="j" :comment="second_comment" :parent="comment" @reply="setCurrentSecondComment"  @closePlayerModal="$emit('closePlayerModal')"></comment-details>
                        <a class="view-more-comments" v-if="comment.formatted_replies>1" @click="setCurrentSecondComment(comment)">
                            View {{ comment.formatted_replies-1 }} more {{ comment.formatted_replies-1==1 ? 'reply':'replies' }}...
                        </a>
                    </div>
                </div>
                <div class="no-data" v-if="showNoData && !disableComment">no comments yet</div>
                <infinite-loading :identifier="firstInfinitID" @infinite="getTrackComments"></infinite-loading>
            </div>
            <div v-if="replyComment">
                <div class="second-comment-close" @click="replyComment=null"><img src="../../assets/images/icons/chevron-left.svg"><span>Back</span></div>
                <div class="second-comment-list white-box">
                    <comment-details :level="2" :hideReply="true" :comment="replyComment"  @closePlayerModal="$emit('closePlayerModal')"></comment-details>
                    <div class="second-level-comment" v-if="getCommentReplies.length">
                        <comment-details v-for="(reply,i) in getCommentReplies" :key="i" :level="2" :hideReply="true" :comment="reply"  @closePlayerModal="$emit('closePlayerModal')"></comment-details>
                    </div>
                </div>
                <infinite-loading :identifier="secondInfinitID" @infinite="getReplies"></infinite-loading>
            </div>
        </div>
        <div class="comment-form-container chat-footer flex-horizontal-centered">
            <img class="user-thumb" :src="getImage(getUserDetails.profile_picture)"  @click="goToProfile(getUserDetails.username)" alt="">      
            <Form ref="commentForm" :model="comment_data" :rules="commentRules" @submit.native.prevent @keyup.enter.native="sendComment()" v-if="showCommentField">
                <FormItem prop="text" class="message-input">
                    <!-- <i-input placeholder="Enter comment" v-model="comment_data.text" class="comment-input" :disabled="disableComment">
                        <div slot="append" class="message-send-icon trigger-icon" v-if="!disableComment" @click="sendComment()">
                            <span v-if="!sendingComment">
                                <img src="../../assets/images/icons/chevron-right.svg">
                            </span>
                            <div class="spin-col field-loader" v-else span="8">
                                <Spin fix>
                                    <Icon type="ios-loading" color="#57a3f3" size=24 class="spin-icon-load"></Icon>
                                </Spin>
                            </div>
                        </div>
                    </i-input> -->
                    <div class="mention-field">
                        <MentionableField ref="mentionableField" :fieldType="'input'" class="comment-input" :disabled="disableComment" :placeholder="'Enter comment'"></MentionableField>
                        <div class="message-send-icon trigger-icon" v-if="!disableComment" @click="sendComment()">
                            <span v-if="!sendingComment">
                                <img src="../../assets/images/icons/chevron-right.svg">
                            </span>
                            <div class="spin-col field-loader" v-else span="8">
                                <Spin fix>
                                    <Icon type="ios-loading" color="#57a3f3" size=24 class="spin-icon-load"></Icon>
                                </Spin>
                            </div>
                        </div>
                    </div>
                </FormItem>
            </Form>
        </div>
    </div>
</template>

<script>
    import {getFirstLevelComments,postFirstLevelComment,getSecondLevelComments,postSecondLevelComment} from '@/services/upclub/comments'
    import { EventBus } from '@/eventBus';
    import {mapGetters} from "vuex";
    import CommentDetails from '@/components/comments/CommentDetails'
    import MentionableField from '@/components/mentionableField/index'
    export default {
        props:{
            currentPost:{
                song_post:null
            }
        },
        data () {
            const validateMaxMentions = (rule, value, callback) => {
                if(this.comment_data.mentions.length>8){
                    callback(new Error('Maximum of 8 mentions only'));
                }else{
                    callback();
                }
            };
            return {
                showNoData: false,
                showCommentField:true,
                sendingComment: false,                
                comment_data:{
                    text:"",
                    mentions:[],
                    comment_parent:null
                },
                commentRules: {
                    text: [
                        { required: true, message: 'Field required', trigger: 'onsubmit' },
                        { validator: validateMaxMentions, trigger: 'onsubmit' },
                    ]
                },
                replyComment:null,
                comment_replies:[],
                commentListing:[],
                showSecondLevelComment:false,
                firstlevel_next_url: null,
                secondlevel_next_url: null,
                comment_text:"",
                second_comment_text:"",
                second_comment:{},
                firstInfinitID:0,
                secondInfinitID:0
            }
        },
        components:{CommentDetails, MentionableField},
        computed: {
            ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            }),
            getCommentListing(){
                return this.sortComments(this.commentListing)
            },
            getCommentReplies(){
                return this.sortComments(this.comment_replies)
            },
            disableComment(){
                return this.currentPost.post_type===1
            }
        },
        methods: {
            increaseInfinitID(){
                this.firstInfinitID+=1
            },
            sortComments(comments){
                let sorted_comments = comments.sort((a, b) => a.comment - b.comment );
                return sorted_comments
            },
            getTrackComments($state){
                let first_queryParams = {}
                if(this.firstlevel_next_url){
                   first_queryParams = this.getUrlParams(this.firstlevel_next_url)    
                }
                getFirstLevelComments(this.currentPost.song_post,first_queryParams).then(res=>{
                    this.firstlevel_next_url = res.data.next
                    let results = res.data.results
                    if(results.length){
                        results.forEach((comment,index) => {
                            if(comment.formatted_replies>0){
                                getSecondLevelComments(comment.comment).then(second=>{
                                    comment.second_level_comments = second.data.results
                                    this.commentListing.push(comment)
                                    if(index===results.length-1){
                                        this.checkLoadComplete($state)
                                    }
                                })
                            }else{
                                comment.second_level_comments = []
                                this.commentListing.push(comment)
                                if(index===results.length-1){
                                    this.checkLoadComplete($state)
                                }
                            }
                        });
                    }else{
                        this.checkLoadComplete($state)
                    }
                })
            },
            checkLoadComplete($state){
                if(this.firstlevel_next_url){
                    $state.loaded();
                }else{
                    if(this.commentListing.length===0){
                        this.showNoData=true
                    }else{
                        this.showNoData=false
                    }
                    $state.complete();
                }
            },
            sendComment(){
                if(!this.replyComment){
                    this.sendFirstLevelComment()
                }else{
                    this.sendSecondLevelComment()
                }
            },
            sendFirstLevelComment(){
                let comment_post_details = this.$refs.mentionableField.getPostDetails()
                this.comment_data.text = comment_post_details.text
                this.comment_data.mentions = comment_post_details.mentions
                this.$refs.commentForm.validate((valid) => {
                    if (valid) {
                        this.sendingComment=true
                        this.comment_data.song_post = this.currentPost.song_post
                        postFirstLevelComment(this.comment_data).then(res=>{
                            let data = res.data
                            data.second_level_comments = []
                            this.commentListing.push(data)
                            this.comment_data.text=""
                            this.comment_data.mentions=[]
                            this.sendingComment=false
                            this.showNoData=false
                            this.$refs.mentionableField.clearDetails()
                            this.updateCommentCount()
                            this.$emit('scrollTop')
                        }).then(err=>{
                            this.sendingComment=false
                        })
                    }
                })
                
            },
            sendSecondLevelComment(){
                let comment_post_details = this.$refs.mentionableField.getPostDetails()
                this.comment_data.text = comment_post_details.text
                this.comment_data.mentions = comment_post_details.mentions
                this.$refs.commentForm.validate((valid) => {
                    if (valid) {
                        this.sendingComment=true
                        this.comment_data.comment_parent=this.replyComment.comment
                        postSecondLevelComment(this.comment_data).then(res=>{
                            this.comment_data.text=""
                            this.comment_data.mentions=[]
                            this.replyComment.formatted_replies = parseInt(this.replyComment.formatted_replies)+1
                            this.replyComment.second_level_comments.push(res.data)
                            this.comment_replies.push(res.data)
                            this.sendingComment=false
                            this.$refs.mentionableField.clearDetails()
                            this.updateCommentCount()
                        }).then(err=>{
                            this.sendingComment=false
                        })
                    }
                })
            },
            getReplies($state){
                let queryParams = {}
                if(this.secondlevel_next_url){
                   queryParams = this.getUrlParams(this.secondlevel_next_url)    
                }
                getSecondLevelComments(this.replyComment.comment,queryParams).then(res=>{
                    this.secondlevel_next_url = res.data.next
                    this.comment_replies=this.comment_replies.concat(res.data.results)
                    if(this.secondlevel_next_url){
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            updateCommentCount(){
                if(this.$route.name===localStorage.getItem('current_track_page')){
                    let num_comments = parseInt(this.currentPost.formatted_comments_count)
                    this.currentPost.formatted_comments_count=num_comments+1
                    EventBus.$emit('POST_ITEM_UPDATED', 'update', this.currentTrack);
                }
            },
            setCurrentSecondComment(comment){
                this.replyComment = comment
                this.comment_replies=[]
                this.secondlevel_next_url=null
                this.secondInfinitID+=1
            },
            collectSecondLevelComments(parent){
                let vm=this
                getSecondLevelComments(parent).then(res=>{
                     return res.data.results
                })
            },
            resetComments(){
                let vm = this
                this.showCommentField=false
                this.commentListing=[]
                this.comment_replies=[]
                this.replyComment=null
                this.firstlevel_next_url= null
                this.secondlevel_next_url= null
                this.showNoData=false
                this.comment_text=""
                setTimeout(async()=>{ 
                    vm.showCommentField=true
                }, 50);
            },
            async reloadComments(){
                await this.resetComments()
                this.firstInfinitID+=1
            }
              
        },
        mounted() {
           
        }
    }
</script>

<style scoped>
</style>

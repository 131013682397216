<template>
    <div>
        <div class="track-list flex-inline-columns five-cols" v-if="postList.length">
            <div v-for="(post,i) in postListing" :key="i" class="track-list-item-wrapper">
                <div v-if="post.club&&$route.name!=='club_posts'" class="club-post-details">
                    <p @click="$router.push({name:'club_posts', params:{id:post.club}})">Posted in <a class="cursor-pointer club-name">{{ post.club_name }}</a></p>
                </div>
                <div  class="track-list-item white-box flex-vertical-spacebetween" :class="isCurrentlyPlaying(post)?'current-playing':''">
                    <div class="post-details">
                        <div>
                            <div class="post-user">
                                <img class="user-thumb" :src="getImage(post.thumbnail_picture)"  @click="goToProfile(post.username)" alt="">
                            </div>
                            <div class="post-desc">
                                <a @click="goToProfile(post.username)" class="user-link">{{post.username}}</a>
                                <span class="post-age">{{post.post_age}}</span>
                                <!-- <span class="comment">{{post.comment | truncate(60, '...')}}</span> -->
                                <p class="description" v-if="post.comment">
                                    <!-- <span class="comment-text" :inner-html.prop="generateMentionsText(post.comment,post.mentions) | truncate(100, '...')"></span> -->
                                    <span v-for="(comment,i) in generateMentionsText(post.comment,post.mentions)" :key="i" 
                                    @click="comment.username?goToProfile(comment.username):''" :class="comment.username?'highlight-mention':''">{{comment.username?`@${comment.username}`:comment.text}}</span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <more-actions @removePost="removePost" :post="post" :postIndex="i"></more-actions>
                        </div>
                    </div>
                    <div class="track-container" v-if="post">
                        <Details :post="post" :page="page" :postIndex="i" :trackUris="trackUris()" @removePost="removePost" :postListing="postListing" @sendMessage="$refs.trackMessageModal.openModal()"></Details>
                    </div>
                    <Votes :post="post" :postIndex="i" :page="page" @votePost="votePost" @unvotePost="unvotePost" @playTrack="playTrack" @sendMessage="$refs.trackMessageModal.openModal()"></Votes>
                </div>
            </div>
            <!-- to fill empty space (max 8 colums) to avoid uneven width -->
            <div class="space-filler"></div>
            <div class="space-filler"></div>
            <div class="space-filler"></div>
            <div class="space-filler"></div>
            <div class="space-filler"></div>
            <div class="space-filler"></div>
            <div class="space-filler"></div>
            <div class="space-filler"></div>
        </div>
        <TrackMessageModal ref="trackMessageModal"></TrackMessageModal>
    </div>
</template>

<script>
    import { mapGetters,mapActions } from "vuex";
    import { EventBus } from '@/eventBus';
    import { play, transferUsersPlayback } from "@/services/spotify/player";
    import { deleteSongPost } from "@/services/upclub/post";
    import Details from './Details'
    import Votes from './Votes'
    import TrackMessageModal from './TrackMessageModal';
    import MoreActions from '@/components/track/MoreActions'
    export default {
        props: {
            page:{default:null}
        },
        data () {
            return {
                postList:[],
            }
        },
        computed:{
            ...mapGetters({
                device_id: "player/getDeviceID",
                playback: "player/getPlayback",
                userDetails: "upclubAuth/getUserDetails",
                currentTPostList: "upclubPlayer/getCurrentTPostList",
                currentTPostPage: "upclubPlayer/getCurrentTPostPage",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost"
            }),
            postListing: function () {
                return this.postList
            }
        },
        components:{ Details, Votes, TrackMessageModal, MoreActions },
        methods: {
            ...mapActions({
                setPlayback: "player/setPlayback",
                setPlaybackContext: "player/setPlaybackContext",
                setCurrentPostList: "upclubPlayer/setCurrentPostList",
                setCurrentPostPage: "upclubPlayer/setCurrentPostPage",
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
                appendCurrentPostList: "upclubPlayer/appendCurrentPostList",
            }),
            isCurrentlyPlaying(post){
                return this.playback&&this.playback.item&&post.track_details&&this.playback.item.id === post.track_details.id&&this.currentPlayingPost&&this.currentPlayingPost.post&&this.currentPlayingPost.post.song_post===post.song_post
            },
            trackUris() {
                return this.postListing ? this.postListing.map((el) => el.uri) : [];
            },
            votePost(index){
                if(this.$listeners && this.$listeners.onVote){
                    this.$emit('onVote', index)
                }
            },
            unvotePost(index){      
                if(this.$listeners && this.$listeners.onUnvote){
                    this.$emit('onUnvote', index)
                    if(this.page==='mylikes'){
                        this.removePost(index)
                    }
                }         
            },
            addToList(posts){
                this.postList= this.postList.concat(posts)
                this.appendCurrentPostList(Object.assign([],posts))
            },
            emptyList(){
                this.postList=[]
            },
            async removePost(index){
                let delete_post = Object.assign({},this.postListing[index])
                this.postListing.splice(index,1);
                this.setCurrentPostList({status:'open',list:Object.assign([],this.postListing)})
                if(this.playback.item&&this.playback.item.id===this.currentPlayingPost.post.track_details.id&&this.currentPlayingPost.post.song_post === delete_post.song_post){
                    if(index===this.postListing.length){
                        index = 0
                    }
                    let next_track = this.postListing[index]
                    let data ={
                        offset: {
                            position: 0
                        },
                        uris: [next_track.track_details.uri],
                        position_ms: 0
                    }
                    if(next_track&&this.postListing.length>0){
                        play(data).then(res=>{
                            this.setCurrentPostList({status:'open',list: Object.assign([],this.postListing)})
                            this.setCurrentPostPage({name:this.$route.name, params:this.$route.params})
                            this.setCurrentPlayingPost({index:index, post:next_track})
                        })
                    }else{
                        EventBus.$emit('TRACK_CHANGED', null);
                        this.setPlayback({})
                        this.setPlaybackContext({})
                    }
                }
            },
            playTrack(index,type,post){
                if(type==='play'){
                    let data ={
                        offset: {
                            position: index
                        },
                        uris: [post.track_details.uri],
                        position_ms: 0
                    }
                    if(!this.playback.item || post.track_details.id!==this.playback.item.id){
                        play(data).then(res=>{
                            // pause()
                            this.setCurrentPostList({status:'open',list:Object.assign([],this.postListing)})
                            this.setCurrentPostPage({name:this.$route.name, params:this.$route.params})
                            EventBus.$emit('OPEN_PLAYER',type, post);
                        })
                    }else{
                        this.setCurrentPostList({status:'open',list:Object.assign([],this.postListing)})
                        this.setCurrentPostPage({name:this.$route.name, params:this.$route.params})
                        EventBus.$emit('OPEN_PLAYER',type, post);
                    }
                }else{
                    EventBus.$emit('OPEN_PLAYER',type, post);
                }
            },
        },
        mounted() {
            let vm=this
            EventBus.$on("POST_ITEM_UPDATED", function (method,post) {
                let index = vm.postListing.findIndex(post_item => post_item.song_post === post.song_post);
                if(method==='update'){
                    if(index>=0){
                        vm.postList.splice(index,1,post)
                    }
                }
                if(method==='delete'){
                    vm.removePost(index)
                }
            });
        }
    }
</script>

<style scoped>
.post-age{
    color: #888888;
    margin-left: 16px;
}
</style>

<template>
    <div>
        <div class="playlist-container">
            <div v-for="(item,i) in playlist" :key="i" @click="$emit('goToPlaylist',item.id)" class="playlist-item">
                <img v-if="item.images[0]" :src="item.images[0]['url']" alt="">
                <div class="playlist-no-image" v-else>
                    <p>{{item.name}}</p>
                </div>
                <p>{{item.name}}</p>
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="getPlaylist"><div slot="no-more"></div></infinite-loading>
    </div>
</template>

<script>
    import { getMyPlaylist } from "@/services/spotify/playlist";
    export default {
        name: "Playerlist",
        data () {
            return {
                offset:0,
                infiniteId:0,
                playlist:[]
            }
        },
        components:{

        },
        computed: {
          
        },
        methods: {
            getPlaylist($state){
                getMyPlaylist(this.offset, 10).then(res=>{
                    if(res.data.items.length>0){
                        this.offset+=10
                        this.playlist = this.playlist.concat(res.data.items)
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                }).catch(err=>{
                    $state.complete();
                })
            },
            reloadInfinite(){
                this.offset=0
                this.playlist=[]
                this.infiniteId +=1
            }
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

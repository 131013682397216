<template>
     <Row class="main-content-row">
        <i-col :xs="24" :sm="24" :md="24" :lg="16" :xl="18" class="notification-list" >
            
            <div v-if="notif_count>0&&filterGroup.length>0">
                <div class="white-box" v-if="getNotifListing.today.length>0">
                    <h4>Today</h4>
                    <notif-details v-for="(notif,i) in notifsToday" :key="i" :notif="notif"></notif-details>
                </div>
                <div class="white-box" v-if="getNotifListing.week.length>0">
                    <h4>This Week</h4>
                    <notif-details v-for="(notif,i) in notifsWeek" :key="i" :notif="notif"></notif-details>
                </div>
                <div class="white-box" v-if="getNotifListing.month.length>0">
                    <h4>This Month</h4>
                    <notif-details v-for="(notif,i) in notifsMonth" :key="i" :notif="notif"></notif-details>
                </div>
                <div class="white-box" v-if="getNotifListing.year.length>0">
                    <h4>This Year</h4>
                    <notif-details v-for="(notif,i) in notifsYear" :key="i" :notif="notif"></notif-details>
                </div>
                <div class="white-box" v-if="getNotifListing.others.length>0">
                    <h4>Others</h4>
                    <notif-details v-for="(notif,i) in notifsOthers" :key="i" :notif="notif"></notif-details>
                </div>
            </div>
            <div v-else class="no-data"><p>No notifications found</p></div>
            <infinite-loading :identifier="infiniteId"  @infinite="getNotifs"></infinite-loading>
        </i-col>
        <i-col :xs="24" :sm="24" :md="24" :lg="8" :xl="6"  class="notif-filters-col">
            <div class="notif-filters">
                <h4>Filters</h4>
                <CheckboxGroup v-model="filterGroup" @on-change="checkAllGroupChange" class="notif-filter-group">
                    <Checkbox label="comments" class="black-check">Comments</Checkbox>
                    <Checkbox label="followers" class="black-check">Followers</Checkbox>
                    <Checkbox label="upvotes" class="black-check">Up Votes</Checkbox>
                    <Checkbox label="mention_comment" class="black-check">Comment Mentions</Checkbox>
                    <Checkbox label="mention_songpost" class="black-check">Post Mentions</Checkbox>
                    <Checkbox label="curatorinvite" class="black-check">Club Curator Invite</Checkbox>
                    <Checkbox label="curatorrequest" class="black-check">Club Curator Request</Checkbox>
                    <Checkbox label="clubfollow" class="black-check">Club Followers</Checkbox>
                    <!-- <Checkbox label="mentions" class="black-check">Mentions</Checkbox> -->
                </CheckboxGroup>
                <Checkbox class="notif-filter-action"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAll">
                    <span :class="!checkAll?'active':''">Select All</span>
                </Checkbox>
                <Checkbox class="notif-filter-action"
                    :value="checkAll"
                    @click.prevent.native="handleDeselectAll">
                    <span :class="checkAll?'active':''">Deselect All</span>
                </Checkbox>
            </div>
        </i-col>
        <Spin size="large" fix v-if="spinShow"></Spin>
     </Row>
</template>

<script>
    import {getNotifications,clearNotifications} from '@/services/upclub/notifications'
    import {getPost} from '@/services/upclub/post'
    import {getTrack} from '@/services/spotify/tracks'
    import NotifDetails from './NotifDetails';
    import moment from "moment";

    export default {
        data () {
            return {
                spinShow: false,
                notif_next_url: null,
                notif_count: null,
                notifications: {
                    today:[],
                    week:[],
                    month:[],
                    year:[],
                    others:[]
                },
                notifInterval:null,
                infiniteId:0,
                indeterminate: false,
                checkAll: true,
                filterGroup: ['comments', 'followers', 'upvotes', 'mention_comment', 'mention_songpost', 'curatorinvite', 'curatorrequest', 'clubfollow']
            }
        },
        components:{NotifDetails},
        computed: {
            getNotifListing(){
                return this.notifications
            },
            notifsToday(){
                return this.notifications.today
            },
            notifsWeek(){
                return this.notifications.week
            },
            notifsMonth(){
                return this.notifications.month
            },
            notifsYear(){
                return this.notifications.year
            },
            notifsOthers(){
                return this.notifications.others
            }
        },
        methods: {
            getNotifs($state){
                let queryParams = {}
                if(this.notif_next_url){
                   queryParams = this.getUrlParams(this.notif_next_url)    
                }
                queryParams.category = this.filterGroup.join(',')
                getNotifications(queryParams).then(res=>{
                    let vm = this
                    this.notif_count=res.data.count
                    this.notif_next_url = res.data.next
                    res.data.results.forEach(async notif=>{
                        notif.post = null
                        if(notif.notification_type!=='follow' && notif.song_post){
                            getPost(notif.song_post).then(post=>{
                                let posttrack = post.data
                                getTrack(post.data.song).then(track=>{
                                    posttrack.track_details = track.data
                                    notif.post = posttrack
                                }).catch(songerr=>{
                                    notif.post = null
                                })
                            }).catch(posterr=>{
                                notif.post = null
                            })
                        }
                        this.assignDateGroup(notif)
                    })
                    if(this.notif_next_url){
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                }).catch(err=>{
                    this.resetNotif()
                    this.notif_count=0
                    $state.complete();
                })
            },
            assignDateGroup(notif){
                if(moment(notif.created_at).isSame(new Date(), 'day')){
                    this.notifications.today.push(notif)
                }
                else if(moment(notif.created_at).isSame(new Date(), 'week')){
                    this.notifications.week.push(notif)
                }
                else if(moment(notif.created_at).isSame(new Date(), 'month')){
                    this.notifications.month.push(notif)
                }
                else if(moment(notif.created_at).isSame(new Date(), 'year')){
                    this.notifications.year.push(notif)
                }else{
                    this.notifications.others.push(notif)
                }
            },
            sort(notifs){
                return notifs.sort((a, b) => b.created_at - a.created_at)
            },
            handleCheckAll () {
                if (this.indeterminate) {
                    this.checkAll = true;
                } else {
                    this.checkAll = !this.checkAll;
                }
                this.indeterminate = false;
                if (this.checkAll) {
                    this.filterGroup = ['comments', 'followers', 'upvotes', 'mention_comment', 'mention_songpost', 'curatorinvite', 'curatorrequest', 'clubfollow'];
                    this.resetNotif()
                    this.infiniteId+=1
                } 
            },
            handleDeselectAll(){
                this.checkAll = false
                this.filterGroup = [];
                this.resetNotif()
                this.infiniteId+=1
            },
            checkAllGroupChange (data) {
                if (data.length === 8) {
                    this.indeterminate = false;
                    this.checkAll = true;
                } else if (data.length > 0) {
                    this.indeterminate = true;
                    this.checkAll = false;
                } else {
                    this.indeterminate = false;
                    this.checkAll = false;
                }
                this.resetNotif()
                this.infiniteId+=1
            },
            resetNotif(){
                this.notif_next_url= null
                this.notifications= {
                    today:[],
                    week:[],
                    month:[],
                    year:[],
                    others:[]
                }
            },
            reloadData(){    
                this.resetNotif()
                this.infiniteId+=1
                clearNotifications()
            }
        },
        mounted() {
            let vm = this
            vm.notifInterval = setInterval(() => {
                vm.resetNotif()
                vm.infiniteId+=1
            }, 900000);
            clearNotifications()
        }
    }
</script>

<style scoped>
    .user-link{
        margin-left: 8px;
    }
</style>

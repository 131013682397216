<template>
    <div class="post-content full-height">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        methods: {

        },
        mounted() {

        }
    }
</script>

<style scoped></style>

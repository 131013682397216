<template>
    <Row type="flex" :gutter="20" class="bottom-player" v-if="currentPost">
        <i-col :xs="16" :sm="18" :md="8" :lg="8" :xl="6" class="details-col">
            <track-details :component="'bottomPlayer'" :currentPost="currentPost"/>
        </i-col>
        <i-col :xs="8" :sm="6" :md="10" :lg="10" :xl="12" class="slider-col flex-vertical-centered">
            <player-controls ref="playerControls"/>   
            <player-slider/>   
        </i-col>
        <i-col :xs="0" :sm="0" :md="6" :lg="6" :xl="6" class="vote-col">
            <track-votes :currentPost="currentPost" />
        </i-col>
    </Row>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import TrackDetails from "./TrackDetails"
    import PlayerControls from "./PlayerControls"
    import PlayerSlider from "./PlayerSlider"
    import TrackVotes from "./TrackVotes"
    import { EventBus } from '@/eventBus';

    export default {
        name: "Player",
        data () {
            return {
                currentPost:null,
            }
        },
        components:{TrackDetails, PlayerControls, PlayerSlider,TrackVotes},
        computed: {
            ...mapGetters({
                playback: "player/getPlayback",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost"
            }),
        },
        methods: {

        },
        mounted() {
            let vm=this
            EventBus.$on("TRACK_CHANGED", function (post) {
                if(post){
                    vm.currentPost = post
                }else{
                    // vm.$refs.playerControls.pause()
                    vm.currentPost = null
                }
            });
        }
    }
</script>

<style scoped></style>

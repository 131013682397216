import store from "../store";
import router from '../router'

export function isUpclubAuthenticated() {
    let authenticated = false;

    const isAuthenticated = localStorage.getItem("upclub_authenticated");

    if (isAuthenticated) {
        authenticated = true;
    } else {
        authenticated = false;
    }

    return authenticated;
}

export function isSpotifyAuthenticated() {
    let authenticated = false;

    const isAuthenticated = localStorage.getItem("spotify_authenticated");

    if (isAuthenticated) {
        authenticated = true;
    } else {
        authenticated = false;
    }

    return authenticated;
}


export function setSession(session) {
    localStorage.setItem(session, true);
}

export function removeSession() {
    // pause()
    // localStorage.clear();

    store.dispatch("resetAll")
    setTimeout(()=>{ 
        router.push({ name: 'login' }).catch(err=>{})
    }, 100);
}


export function removeUpclubSession() {
    // pause()
    // localStorage.clear();

    store.dispatch("resetUpClub")
    setTimeout(()=>{ 
        router.push({ name: 'login' }).catch(err=>{})
    }, 100);
}
<template>
    <Modal v-model="postPlaylistModal" width="624" @on-visible-change="visibleChange($event)" footer-hide :transfer="true" :lock-scroll="false" class-name="post-song white-modal">
        <p slot="header" @click="postPlaylistModal=false" class="modal-back-header">
            <img src="../../assets/images/icons/chevron-left.svg" alt="">
            <span >Back to search</span>
        </p>
        <div slot="close" @click="closeModal()"><img src="../../assets/images/icons/close.svg" class="close-icon" alt=""></div>
        <div class="modal-body">
            <div class="flex-horizontal-centered" v-if="source_details">
                <img v-if="source_details.image" :src="source_details.image" class="playlist-image" alt="">
                <div class="playlist-image no-image" v-else></div>
                <div>
                    <h3>{{source_details.name}}</h3>
                    <p>{{source_details.info}}</p>
                </div>
            </div>
            <div v-if="showNoData" class="no-data">No tracks found</div>
            <track-list ref="postPlaylist" v-if="postPlaylistModal" :trackList="trackList" @openTrackModal="openTrackModal" class="modal-search-results"></track-list>
            <infinite-loading :identifier="infiniteId" @infinite="getSourceTracks"></infinite-loading>
        </div>
    </Modal>
</template>

<script>
    import {getPlaylistTracks} from '@/services/spotify/playlist';
    import {getAlbumTracks} from '@/services/spotify/albums'
    import {getArtistTopTracks} from '@/services/spotify/artists'
    import { pause } from "@/services/spotify/player";
    import { mapGetters } from "vuex";
    import TrackList from '@/components/trackSearchList/Tracks'
    export default {
        data () {
            return {
                postPlaylistModal:false,
                showNoData:false,
                source:null,
                source_details:null,
                offset:0,
                trackList:[],
                infiniteId:0
            }
        },
        components: {TrackList},
        computed: {
            ...mapGetters("player", {
                device_id: "getDeviceID",
                playback: "getPlayback",
                context: "getPlaybackContext"
            }),
        },
        methods: {
            visibleChange(val){
                if(!val){
                    if(!this.playback.item&&!this.context.paused){
                        pause()
                    }
                    // this.$emit('closeModal')
                }
            },
            closeModal(){
                this.$emit('closeModal')
            },
            openModal(source, source_details){
                this.postPlaylistModal=true
                this.source=source
                this.source_details = source_details
                this.offset=0
                this.trackList=[]
                this.infiniteId+=1
            },
            openTrackModal(track){
                this.$emit('openTrackModal',track)
            },
            getSourceTracks($state){
                if(this.source==="artist"){
                    this.getArtistAllTracks($state)
                }else if(this.source==="album"){
                    this.getAlbumAllTracks($state)
                }else if(this.source==="playlist"){
                    this.getPlaylistAllTracks($state)
                }
            },
            async getPlaylistAllTracks($state){
                getPlaylistTracks(this.source_details.id,this.offset,10).then(async res=>{
                    this.offset+=10
                    let tracks_arr = await res.data.items.map(track=>{return track.track})
                    this.trackList = this.trackList.concat(tracks_arr)
                    $state.loaded();
                    if(this.trackList.length==0){
                        this.showNoData=true
                    }else{
                        this.showNoData=false
                    }
                    if(res.data.items.length<10){
                        $state.complete();
                    }
                })
            },
            async getArtistAllTracks($state){
                let params = {offset:this.offset,limit:10}
                getArtistTopTracks(this.source_details.id,params).then(async res=>{
                    if(res.data.tracks.length){
                    this.offset+=10
                    this.trackList = this.trackList.concat(res.data.tracks)
                    $state.loaded();
                    $state.complete();
                    }else{
                        if(this.trackList.length==0){
                            this.showNoData=true
                        }else{
                            this.showNoData=false
                        }
                        $state.loaded();
                        $state.complete();
                    }
                })
               
            },
            async getAlbumAllTracks($state){
                let params = {offset:this.offset,limit:10}
                getAlbumTracks(this.source_details.id,params).then(async res=>{
                    this.offset+=10
                    let tracks_with_album = res.data.items.map(track=>{
                        track.album = this.source_details.album
                        return track
                    })
                    this.trackList = this.trackList.concat(tracks_with_album)
                    $state.loaded();
                    if(res.data.items.length<10){
                        if(this.trackList.length==0){
                            this.showNoData=true
                        }else{
                            this.showNoData=false
                        }
                        $state.complete();
                    }
                })
            }
      
        },
        mounted() {
        },
        destroy(){
        }
    }
</script>

<style scoped>
.playlist-image{
    width: 100px;
    margin-right: 16px;
}
.playlist-image.no-image{
    width: 100px;
    height: 100px;
    background-color: #818181;
}

</style>

import { request } from "./base";
import { EventBus } from '@/eventBus';

const requestInterceptor = (store) => {
    request.interceptors.request.use(function(config) {
      if (store.getters["spotifyAuth/getAccessToken"]) {
        config.headers.common[
          "Authorization"
        ] = `Bearer ${store.getters["spotifyAuth/getAccessToken"]}`;
      }
      return config;
    }, null);
  };

const responseInterceptor = (store) => {
    request.interceptors.response.use(null, async (error) => {
      const originalRequest = error.config;
      
      if(error.response){
        const { status, data } = error.response;

        // reject promise if custom error
        if (!originalRequest._retry && status === 401) {
          try {
            originalRequest._retry = true;
            let refresh_token = store.getters["spotifyAuth/getRefreshToken"]
            if(!refresh_token){
              refresh_token = localStorage.getItem('spotify_refresh_token')
            }
            let data={
              grant_type: 'refresh_token',
              refresh_token: refresh_token
            }
            store.dispatch("spotifyAuth/getAuthToken",data);
            if(originalRequest.url==="me/player/play"){
              localStorage.setItem('play_after_reauthentication',originalRequest.data);
            }
            return request(originalRequest);
          } catch (e) {
            console.error(e);
            return Promise.reject(error);
          }
        }else{
          if(status === 404){
            store.dispatch("player/init", null, { root: true });
            if (!originalRequest._retry) {
              try {
                originalRequest._retry = true;
                return request(originalRequest);
              } catch (e) {
                console.error(e);
                return Promise.reject(error);
              }
            }
          }
          if(status === 403){
            EventBus.$emit('SPOTIFY_ACCOUNT_ERROR', status, data);
          }
          return Promise.reject(error);
        }
      }else{
        EventBus.$emit('SPOTIFY_ACCOUNT_ERROR', 'no_network', null);
      }
    });
  };

const interceptors = (store) => {
requestInterceptor(store)
responseInterceptor(store)
};

export default interceptors;
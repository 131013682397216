<template>
    <div class="authentication-content full-height" :class="$route.name">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        components:{},
        methods: {

        },
        mounted() {

        }
    }
</script>

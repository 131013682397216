import { request } from "./base";

export function getMyProfile() {
    return request.get('me');
}

export function myTrackContains(id) {
    return request.get(`me/tracks/contains/?ids=${id}`);
}

export function saveTrack(id) {
    return request.put(`me/tracks/?ids=${id}`);
}

export function deleteTrack(id) {
    return request.delete(`me/tracks/?ids=${id}`);
}
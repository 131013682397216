<template>
    <div class="club-content">
        <page-header>
            <div slot="details" class="header-details">
                <div class="header-title flex-centered-content">
                    <a @click="redirect(backRoute.route)"><img src="../../assets/images/icons/chevron-left.svg" class="back-arrow"></a>
                    <p><span class="cursor-pointer" @click="redirect(backRoute.route)">{{backRoute.label}}</span> <span style="color:#888888" v-if="club.club_name">|</span> {{club.club_name}}</p>
                </div>
            </div>
            <Button shape="circle" slot="middle-content" class="create-club-button round-trigger extra white-dropdown flex-column-centered" @click="openClubForm">
                <span>Create Club</span>
                <img src="../../assets/images/icons/plus.svg" alt="">
            </Button>
            <div slot="extra-trigger">
                <div class="round-trigger add" v-if="getUserDetails.upclubuser===club.club_owner">
                    <img src="../../assets/images/icons/settings.svg" alt=""  @click="$refs.clubSettingModal.openModal(club)">
                </div>
                <Dropdown v-else class="white-dropdown" transfer>
                    <a href="javascript:void(0)">
                        <div class="round-trigger add">
                            <img src="../../assets/images/icons/lock.svg" alt="" style="width:20px;margin:0 auto;">
                        </div>
                    </a>
                    <DropdownMenu slot="list"  class="white-box club-visitor-dropdown">
                        <DropdownItem @click.native="requestToCurateClub()">Request to Curate</DropdownItem>
                        <DropdownItem class="gray-text">Cancel</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </page-header>
        <div class="white-box club-banner-wrapper">
            <div v-if="club.club" class="club-banner" :style="{background:accent}">
                <img ref="clubImage" id="club-picture" class="club-image" :src="getImage(club.club_picture)" @load="getPalette()" alt="">
                <div class="flex-horizontal-alignend">
                    <div class="flex-horizontal-spacebetween full-width club-owner-wrapper">
                        <div class="club-owner flex-horizontal-centered flex-gap-8">
                            <img class="user-thumb" :src="getImage(club.club_owner_thumbnail)"  @click="goToProfile(club.club_owner_username)" alt="">    
                            <a @click="goToProfile(club.club_owner_username)" class="user-link">{{club.club_owner_username}}</a>
                        </div>
                        <div class="club-play">
                            <div v-if="playback&&playback.item&&currentPlayingPost.post&&currentPostPage.name.includes('club')&&currentPostPage.params.id==club.club&&!context.paused&&playback.item" class="trigger-icon-container play-club"  @click="pauseTrack();">
                                <img src="../../assets/images/icons/pause.svg" alt="">
                            </div>
                            <div v-else  alt="" @click="playTrack(posts[0])" class="trigger-icon-container play-club">
                                <img src="../../assets/images/icons/play.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-overlay"></div>
            </div>
            <div class="club-header flex-horizontal-spacebetween">
                <div class="club-details" >
                    <h2 class="club-name">{{club.club_name}}</h2>
                    <p class="club-description" v-if="club.club_description">{{club.club_description}}</p>
                </div>
                <div class="follow-club">
                    <div v-if="getUserDetails.upclubuser!==club.club_owner">
                        <Button size="small" class="gray-btn btn" v-if="club.is_curator" @click="leaveCurrentClub()">Curating</Button>
                        <Button size="small" class="gray-btn btn" v-else-if="club.followed" @click="unfollowCurrentClub()">Following</Button>
                        <Button size="small" class="black-btn btn" v-else @click="followCurrentClub()">Follow</Button>
                    </div>
                    <Dropdown class="white-dropdown" transfer>
                        <a href="javascript:void(0)">
                        <div>
                            <Icon type="md-more" size="32" />
                        </div>
                        </a>
                        <DropdownMenu slot="list"  class="white-box club-owner-dropdown" v-if="getUserDetails.upclubuser===club.club_owner">
                            <DropdownItem @click.native="$refs.manageCuratorsModal.openModal()">Manage Curators</DropdownItem>
                            <DropdownItem @click.native="$refs.inviteCuratorModal.openModal()">Invite Curators</DropdownItem>
                            <DropdownItem class="gray-text">Cancel</DropdownItem>
                        </DropdownMenu>

                        <DropdownMenu slot="list"  class="white-box club-owner-dropdown" v-else-if="club.is_curator">
                            <DropdownItem @click.native="leaveCurrentClub()" class="red-text">Leave Club</DropdownItem>
                            <DropdownItem class="gray-text">Cancel</DropdownItem>
                        </DropdownMenu>
                        <DropdownMenu slot="list"  class="white-box club-owner-dropdown" v-else>
                            <DropdownItem @click.native="requestToCurateClub()">Request to Curate</DropdownItem>
                            <DropdownItem class="gray-text">Cancel</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    
                </div>
            </div>
        </div>
        <div class="flex-horizontal-centered tab-menu">
                <Menu mode="horizontal"  :active-name="activename"  class="club-page-menu page-tab-navigation">
                    <MenuItem name="club_posts" @click.native="redirect('club_posts')">
                        <span class="club-count">{{club.posts}}</span> Posts
                    </MenuItem>
                    <MenuItem name="club_followers" @click.native="redirect('club_followers')">
                        <span class="club-count">{{club.followers}}</span> Followers
                    </MenuItem>
                    <MenuItem name="club_curators" @click.native="redirect('club_curators')">
                        <span class="club-count">{{club.curators}}</span> Curators
                    </MenuItem>
                </Menu>
                <Dropdown class="category-filter white-dropdown" v-if="$route.name==='club_posts'">
                    <div class="flex-horizontal-centered">
                        <span>{{category_label}}</span>
                        <img src="../../assets/images/icons/filter.svg" alt="">
                    </div>
                    <DropdownMenu slot="list" class="white-box">
                        <DropdownItem v-for="(category,i) in category_options" :key="i" @click.native="filterChanged(category.value)">{{ category.label}}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <router-view ref="pageView" v-if="club.club" :club="club" :key="routeviewKey" @getClubPosts="getClubPostList"></router-view>
        <CreateClubModalVue ref="createClubForm" @onSubmit="onClubCreate"></CreateClubModalVue>
        <ClubSettingsModal ref="clubSettingModal" @onSubmit="updateClubData" @showInviteCurators="$refs.inviteCuratorModal.openModal()"/>
        <ManageCuratorsModal ref="manageCuratorsModal" :club="club" @onUpdateCurators="onUpdateCurators" @showInviteCurators="$refs.inviteCuratorModal.openModal()"/>
        <InviteCuratorModal ref="inviteCuratorModal" @onSubmit="sendCuratorInvite" :club="club" :title="'Invite'" :submitText="'Confirm'"></InviteCuratorModal>
    </div>
</template>

<script>
    import { getTracks } from '@/services/spotify/tracks'
    import { getClub, followClub, unfollowClub, requestToCurate, leaveClub,getClubPosts } from '@/services/upclub/clubs'
    import { play, pause, transferUsersPlayback } from "@/services/spotify/player";
    import { mapGetters, mapActions } from "vuex";
    import { EventBus } from '@/eventBus'
    import PageHeader from '@/layout/PageHeader'
    import CreateClubModalVue from '@/components/clubs/CreateClubModal.vue'
    import ClubSettingsModal from '@/components/clubs/ClubSettingsModal.vue';
    import ManageCuratorsModal from '@/components/clubs/ManageCuratorsModal.vue';
    import InviteCuratorModal from '@/components/clubs/InviteCuratorModal';

    import Vibrant from 'node-vibrant'

    export default {
        data () {
            return {
                spinShow:false,
                routeviewKey:0,
                activename:"club_posts",
                club:{
                    club:null,
                    club_description:"",
                    club_name:"",
                    club_owner:null,
                    club_owner_name:"",
                    club_picture:"",
                    created_at:"",
                    curators:"",
                    followers:"",
                    posts:"",
                },
                category_label: 'Newest',
                category:'new',
                category_options: [
                    {value:"trending",label:"Trending"},
                    {value:"new",label:"Newest"},
                    {value:"tod",label:"Today's Top"},
                    {value:"tow",label:"Weekly Hottest"},
                    {value:"tom",label:"Monthly Best"},
                    {value:"toy",label:"Yearly Rising"},
                    {value:"atg",label:"All Time Greatest"}
                ],
                posts:[],
                palette:[],
                currently_playing_page:null,
                prevRoute: null,
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        components:{PageHeader,CreateClubModalVue,ClubSettingsModal,ManageCuratorsModal,InviteCuratorModal},
        computed:{
            ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails",
                device_id: "player/getDeviceID",
                playback: "player/getPlayback",
                context: "player/getPlaybackContext",
                player: "player/getPlayer",
                currentPostList: "upclubPlayer/getCurrentPostList",
                currentPostPage: "upclubPlayer/getCurrentPostPage",
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
                playerRepeat: "upclubPlayer/getPlayerRepeat"
            }),
            dark(){
                if(this.palette&&this.palette.DarkVibrant){
                    return this.palette.DarkVibrant.getHex();
                }else{
                    return `#E8E8DE` ;
                }
            },
            light(){
                if(this.palette&&this.palette.LightMuted){
                    return this.palette.LightMuted.getHex();
                }else{
                    return `#E8E8DE` ;
                }
            },
            accent(){
                if(this.palette&&this.palette.Vibrant){
                    return this.palette.Vibrant.getHex();
                }else{
                    return `#E8E8DE` ;
                }
            },
            backRoute(){
                if(this.prevRoute&&this.prevRoute.name==='my_clubs'){
                    return {route:'my_clubs', label:'My Clubs'}
                }else if(this.prevRoute&&this.prevRoute.name==='owned_clubs'){
                    return {route:'owned_clubs', label:'Owner & Curator'}
                }else if(this.prevRoute&&this.prevRoute.name==='followed_clubs'){
                    return {route:'followed_clubs', label:'Following'}
                }else{
                    return {route:'all_clubs', label:'All Clubs'}
                }
            }
        },
        methods: {
            ...mapActions({
                setCurrentPostList: "upclubPlayer/setCurrentPostList",
                setCurrentPostPage: "upclubPlayer/setCurrentPostPage",
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
                setPlayerRepeat: "upclubPlayer/setPlayerRepeat"
            }),
            getPalette(){
                const img = document.getElementById(`club-picture`)
                const vibrant = new Vibrant(img)

                vibrant.getPalette().then(
                    (palette)=>this.$set(this, `palette`, palette),
                    (reason)=>{console.error(reason)})
            },
            getClubDetails(){
                 this.spinShow=true
                getClub(this.$route.params.id).then(res=>{
                    this.club=res.data
                    this.spinShow=false
                    if(this.$route.query.state&&this.$route.query.state==='new'){
                        this.$refs.clubSettingModal.openModal(res.data)
                        this.$router.replace({ query:{} });
                    }
                })
            },
            openClubForm(){
                this.$refs.createClubForm.openModal()
            },
            onClubCreate(data){
                this.$router.push({name:'club_posts', params:{id:data.club}, query:{state:'new'}})
            },
            setActiveName(){
                if(this.$route.name.includes('club_posts')){
                    this.activename='club_posts'
                }else if(this.$route.name.includes('club_followers')){
                    this.activename='club_followers'
                }else if(this.$route.name.includes('club_curators')){
                    this.activename='club_curators'
                }else{
                    this.activename=""
                }
            },
            redirect(route){
                this.routeviewKey+=1
                this.$router.push({name:route})
                // this.$refs.pageView.routeChanged(route)
            },
            filterChanged(category){
                let vm = this
                vm.posts=[]
                vm.category=category
                vm.category_label=vm.category_options.filter(option=>{ return option.value===category })[0].label
                vm.$refs.pageView.filterChanged(category)  
            },
            updateClubData(data){
                this.getClubDetails()
            },
            sendCuratorInvite(users){
                console.log(users)
            },
            onUpdateCurators(curator_count){
                this.club.curators=curator_count
            },
            followCurrentClub(){
                this.club.followed=true
                followClub({club:this.club.club}).then(res=>{
                    this.club.followers=res.data.followers
                    if(this.$route.name==='club_followers'){
                        this.$refs.pageView.reloadFollowers()
                    }
                })
            },
            unfollowCurrentClub(){
                this.club.followed=false
                unfollowClub({club:this.club.club}).then(res=>{
                    this.club.followers=res.data.followers
                    if(this.$route.name==='club_followers'){
                        this.$refs.pageView.reloadFollowers()
                    }
                })
            },
            requestToCurateClub(){
                requestToCurate({club:this.club.club}).then(res=>{
                    this.$Notice.success({
                        desc: `Sent request to curate club`
                    });
                })
            },
            leaveCurrentClub(){
                this.club.is_curator=false
                leaveClub(this.club.club).then(res=>{
                    this.club.curators=res.data.curators
                    this.$Notice.success({
                        desc: `You have successfully left the club`
                    });
                })
            },
            getClubPostList(state, queryParams){
                getClubPosts(this.$route.params.id,this.category,queryParams).then(async res=>{
                    if(res.data.count>0){
                        this.next_request_uri = res.data.next
                        let posts = res.data.results
                        let ids = posts.reduce((ids,post)=>{
                            if(post.post_type!=1){
                                ids.push(post.song)
                            }
                            return ids
                        },[])
                        ids = ids.join(",")
                        getTracks(ids).then(async track=>{
                            let songs = track.data.tracks.filter(function (item) {return item != null;});
                            let posttracks = posts.reduce((postTracks, post)=>{
                                let song = songs.filter(song=>{if(post.song===song.id) return song})
                                let order = this.posts.length+postTracks.length
                                if(song.length){
                                    post.order = order
                                    post.track_details = song[0]
                                    postTracks.push(post)
                                }
                                return postTracks
                            },[])
                            this.posts = this.posts.concat(posttracks)
                            if(this.$route.name==='club_posts'){
                                this.$refs.pageView.appendPosts(state,posttracks,res.data.next)  
                            }                         
                        }).catch(err=>{
                            if(this.$route.name==='club_posts'){
                                this.$refs.pageView.appendPosts(state,[],res.data.next)
                            }
                        })
                    }else{
                        if(this.$route.name==='club_posts'){
                            this.$refs.pageView.appendPosts(state,[],res.data.next)
                        }
                    }
                })
            },
            playTrack(post){
                let data ={
                    offset: {
                        position: 0
                    },
                    uris: [post.track_details.uri],
                    position_ms: 0
                }
                if(this.playback && this.playback.item && this.playback.device.id!==this.device_id){
                    transferUsersPlayback([this.device_id], true)
                }else{
                    let postList = Object.assign([], this.posts)
                    let postIds = postList.map(post=>{return post.song_post})
                    let postIndex = postIds.indexOf(post.song_post)
                    this.setCurrentPostList({status:'open', list:postList})
                    this.setCurrentPostPage({name:this.$route.name, params:this.$route.params})
                    this.setCurrentPlayingPost({index:postIndex, post:post})
                    play(data)
                }
            },
            pauseTrack(){
                // this.player.pause()
                pause()
            },
            
        },
        mounted() {
            let vm=this
            this.setActiveName()
            this.getClubDetails()
            if(this.$route.name!=='club_posts'){
                this.getClubPostList(null,{})
            }else{
                EventBus.$on("POSTS_UPDATED", function (reload) {
                if(reload){
                    vm.category_label= 'Newest',
                    vm.category='new',
                    vm.$refs.pageView.filterChanged("new")  
                }
                vm.getClubDetails()
            });
            }
        },
        watch: {
            '$route.params.id': function () {
                this.spinShow=true
                this.activename="club_posts"
                this.setActiveName()
                this.getClubDetails()
                if(this.$route.name!=='club_posts'){
                    this.getClubPostList(null,{})
                }else{
                    this.category_label= 'Newest',
                    this.category='new',
                    this.$refs.pageView.filterChanged("new")
                }
            },
        },
    }
</script>

<style scoped>

</style>

<template>
    <div class="follow-list-container" :class="listClass">
        <div  v-for="(user,i) in users" :key="i" class="flex-horizontal-centered follow-user" :class="[itemClass,checkDefault(user.upclubuser)?'new':'']">
            <div class="follow-user-details flex-horizontal-centered">
                <img :src="getUserThumb(user.thumbnail_picture)" @click="redirectToProfile(user)" class="user-thumb"  alt="">
                <div>
                    <p class="user-link" @click="redirectToProfile(user)">{{user.username}}</p>
                    <p class="follow-fullname" @click="redirectToProfile(user)">{{user.name}}</p>
                    <div class="user-follow-details">
                        <span class="gray-text small-text">{{user.followers}} {{user.followers>1?'Followers':'Follower'}}</span>
                        <span class="gray-text small-text">{{user.posts}} {{user.posts>1?'Posts':'Post'}}</span>
                    </div>
                </div>
            </div>
            <Button size="small" @click="unfollowUser(user)" class="gray-btn btn" shape="circle" v-if="user.followed && !isCurrentUser(user)">Following</Button>
            <Button size="small" @click="followUser(user)" class="black-btn btn" shape="circle" v-if="!user.followed && !isCurrentUser(user)">Follow</Button>
        </div>
        <!-- to fill empty space (max 3 colums) -->
        <div class="space-filler"></div>
        <div class="space-filler"></div>
    </div>
</template>

<script>
    import { mapActions,mapGetters } from "vuex";
    import { follow, unfollow } from '@/services/upclub/follow';
    export default {
        name: "Follow",
        props: ["users", 'listClass', 'itemClass', 'defaultUsers'],
        data () {
            return {
                current_user_id:null,
            }
        },       
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            })
        },
        methods: {
            ...mapActions({
                getUserProfile: "upclubAuth/getUserProfile",
            }),
            checkDefault(user){
                if(this.defaultUsers&&this.defaultUsers.indexOf(user)===-1){
                    return true
                }else{
                    return false
                }
            },
            redirectToProfile(user){
                this.goToProfile(user.username)
                // if(user.hasOwnProperty('upclubuser_follower')){
                //     this.goToProfile(user.upclubuser_follower)
                // }
                // else if(user.hasOwnProperty('upclubuser_followee')){
                //     this.goToProfile(user.upclubuser_followee)
                // }else{
                //     this.goToProfile(user.upclubuser)
                // }
            },
            getUserThumb(thumb){
                return "data:image/png;base64,"+thumb
            },
            isCurrentUser(user){
                if(this.getUserDetails.upclubuser!==user.upclubuser){
                    if(user.hasOwnProperty('upclubuser_follower')){
                        if(user.upclubuser_follower===this.getUserDetails.upclubuser){
                            return true
                        }
                    }
                    if(user.hasOwnProperty('upclubuser_followee')){
                        if(user.upclubuser_followee===this.getUserDetails.upclubuser){
                            return true
                        }
                    }
                }else{
                    return true
                }
                return false
            },
            followUser(user){
                let user_id=null
                if(user.hasOwnProperty('upclubuser_follower')){
                    user_id = user.upclubuser_follower
                }
                else if(user.hasOwnProperty('upclubuser_followee')){
                    user_id = user.upclubuser_followee
                }else{
                    user_id = user.upclubuser
                }
                if(user_id){
                    follow({upclubuser_followee:user_id}).then(res=>{
                        user.followed=true
                        user.followers=parseInt(user.followers)+1
                        this.$emit('setFollowings')
                        this.$Notice.success({
                            desc: `You are now following ${user.username}`
                        });
                    })
                }
            },
            unfollowUser(user){
                let user_id=null
                if(user.hasOwnProperty('upclubuser_follower')){
                    user_id = user.upclubuser_follower
                }
                else if(user.hasOwnProperty('upclubuser_followee')){
                    user_id = user.upclubuser_followee
                }
                else{
                    user_id = user.upclubuser
                }
                if(user_id){
                    unfollow({upclubuser_followee:user_id}).then(res=>{
                        user.followed=false
                        user.followers=parseInt(user.followers)-1
                        this.$emit('setFollowings', 'dec', user_id)
                        this.$Notice.success({
                            desc: `You had just unfollowed ${user.username}`
                        });
                    })
                }
                
            },
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

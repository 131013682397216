import ContentWrapper from "../layout/ContentWrapper";
import Profile from "../views/Profile";
import ProfilePosts from "../views/Profile/Posts";
import ProfileClubs from "../views/Profile/Clubs";
import ProfileLikes from "../views/Profile/Likes";
import ProfileFollowers from "../views/Profile/Followers";
import ProfileFollowings from "../views/Profile/Followings";
import Feeds from "../views/Feeds";
import FeedList from "../views/Feeds/Feeds";
import Notifs from "../views/Notifications";
import NotifList from "../views/Notifications/Notifications";
import Messages from "../views/Messages";
import MessageList from "../views/Messages/Messages";
import MessageBox from "../views/Messages/MessageBox";
import MessageSettings from "../views/Messages/MessageSettings";
import Posts from "../views/Post";
import PostList from "../views/Post/Post";
import SearchTrack from "../views/Post/SearchTrack"
import SearchPlaylist from "../views/Post/SearchPlaylist"
import Search from "../views/Search"
import Clubs from "../views/Clubs/index.vue"
import AllClubs from "../views/Clubs/AllClubs.vue"
import MyClubs from "../views/Clubs/MyClubs.vue"
import OwnedClubs from "../views/Clubs/OwnedClubs.vue"
import FollowedClubs from "../views/Clubs/FollowedClubs.vue"
import Club from "../views/Club"
import ClubPosts from "../views/Club/Posts.vue"
import ClubFollowers from "../views/Club/Followers.vue"
import ClubCurators from "../views/Club/Curators.vue"


export default {
    path: "",
    component: ContentWrapper,
    children: [
        {
            path: "/profile",
            component: Profile,
            children: [
                {
                    path: "",
                    name: "profile_posts",
                    component: ProfilePosts,
                    meta: { requiredAuth: true },
                },
                {
                    path: "clubs",
                    name: "profile_clubs",
                    component: ProfileClubs,
                    meta: { requiredAuth: true },
                },
                {
                    path: "likes",
                    name: "profile_likes",
                    component: ProfileLikes,
                    meta: { requiredAuth: true },
                },
                {
                    path: "followers",
                    name: "profile_followers",
                    component: ProfileFollowers,
                    meta: { requiredAuth: true },
                },
                {
                    path: "followings",
                    name: "profile_followings",
                    component: ProfileFollowings,
                    meta: { requiredAuth: true },
                },
            ]
        },
        {
            path: "/profile/:username",
            component: Profile,
            children: [
                {
                    path: "",
                    name: "user_posts",
                    component: ProfilePosts,
                    meta: { requiredAuth: true },
                },
                {
                    path: "clubs",
                    name: "user_clubs",
                    component: ProfileClubs,
                    meta: { requiredAuth: true },
                },
                {
                    path: "likes",
                    name: "user_likes",
                    component: ProfileLikes,
                    meta: { requiredAuth: true },
                },
                {
                    path: "followers",
                    name: "user_followers",
                    component: ProfileFollowers,
                    meta: { requiredAuth: true },
                },
                {
                    path: "followings",
                    name: "user_followings",
                    component: ProfileFollowings,
                    meta: { requiredAuth: true },
                },
            ]
        },
        {
            path: "/clubs/",
            component: Clubs,
            children: [
                {
                    path: "",
                    name: "all_clubs",
                    component: AllClubs,
                    meta: { requiredAuth: true },
                },
                {
                    path: "my-clubs",
                    name: "my_clubs",
                    component: MyClubs,
                    meta: { requiredAuth: true },
                },
                {
                    path: "owned-clubs",
                    name: "owned_clubs",
                    component: OwnedClubs,
                    meta: { requiredAuth: true },
                },
                {
                    path: "followed-clubs",
                    name: "followed_clubs",
                    component: FollowedClubs,
                    meta: { requiredAuth: true },
                }
            ]
        },
        {
            path: "/clubs/:id",
            component: Club,
            children: [
                {
                    path: "",
                    name: "club_posts",
                    component: ClubPosts,
                    meta: { requiredAuth: true },
                },
                {
                    path: "followers",
                    name: "club_followers",
                    component: ClubFollowers,
                    meta: { requiredAuth: true },
                },
                {
                    path: "curators",
                    name: "club_curators",
                    component: ClubCurators,
                    meta: { requiredAuth: true },
                },
            ]
        },
        {
            path: "/discover",
            component: Feeds,
            children: [
                {
                    path: "",
                    name: "discover",
                    component: FeedList,
                    meta: { requiredAuth: true },
                }
            ]
        },
        {
            path: "/myfeed",
            component: Feeds,
            children: [
                {
                    path: "",
                    name: "myfeed",
                    component: FeedList,
                    meta: { requiredAuth: true },
                }
            ]
        },
        {
            path: "/notifications",
            component: Notifs,
            children: [
                {
                    path: "",
                    name: "notifications",
                    component: NotifList,
                    meta: { requiredAuth: true },
                }
            ]
        },
        {
            path: "/messages",
            component: Messages,
            children: [
                {
                    path: "",
                    name: "messages",
                    component: MessageList,
                    meta: { requiredAuth: true },
                    children: [
                        {
                            path: ":id",
                            name: "message_box",
                            component: MessageBox,
                            meta: { requiredAuth: true },
                        },
                        {
                            path: ":id/settings",
                            name: "message_settings",
                            component: MessageSettings,
                            meta: { requiredAuth: true },
                        }
                    ]
                }
            ]
        },
        {
            path: "/post",
            component: Posts,
            children: [
                {
                    path: "",
                    name: "post",
                    component: PostList,
                    meta: { requiredAuth: true },
                },
                {
                    path: "playlist/:id",
                    name: "post_playlist",
                    component: SearchPlaylist,
                    meta: { requiredAuth: true },
                },
                {
                    path: "tracks/",
                    name: "post_tracks",
                    component: SearchTrack,
                    meta: { requiredAuth: true },
                }
            ]
        },
        {
            path: "/search",
            name: "search",
            component: Search,
            meta: { requiredAuth: true }
        }
    ]
};

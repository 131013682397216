<template>
    <div class="managecurator-list-container" :class="listClass" v-if="club">
        <div  v-for="(curator,i) in curators" :key="i" class="flex-horizontal-centered curator-user" :class="[itemClass,checkDefault(curator.upclubuser)?'new':'']">
            <div class="curator-user-details flex-horizontal-centered">
                <img :src="getImage(curator.thumbnail_picture)" @click="redirectToProfile(curator)" class="user-thumb"  alt="">
                <div>
                    <p class="user-link" @click="redirectToProfile(curator)">{{curator.username}}</p>
                    <p class="curator-fullname" @click="redirectToProfile(curator)">{{curator.name}}</p>
                    <p class="curator-role" @click="redirectToProfile(curator)">{{curator.upclubuser===club.club_owner?'Admin':curator.is_curator?'Curator':'Request to Curate'}}</p>
                   
                </div>
            </div>
            <div v-if="curator.upclubuser!==club.club_owner" class="curator-actions">
                <Button size="small" @click="removeCurator(curator)" class="red-btn btn" shape="circle" v-if="curator.is_curator">Remove</Button>
                <Button size="small" @click="approveCurator(curator)" class="black-btn btn" shape="circle" v-else>Approve</Button>
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="getAllCuratorsAndRequest"></infinite-loading> 
    </div>
</template>

<script>
    import { getCuratorsAndRequests,respondClubCuratorRequest, removeClubCurator } from '@/services/upclub/clubs'
    import { mapActions,mapGetters } from "vuex";
    export default {
        name: "Curator",
        props: ["club", 'listClass', 'itemClass', 'defaultCurators'],
        data () {
            return {
                dataloading:false,
                next_request_uri:null,
                infiniteId:0,
                curators:[],
            }
        },       
        computed: {
        ...mapGetters({
                getUserDetails: "upclubAuth/getUserDetails"
            })
        },
        methods: {
            ...mapActions({
                getUserProfile: "upclubAuth/getUserProfile",
            }),
            checkDefault(user){
                if(this.defaultCurators&&this.defaultCurators.indexOf(user)===-1){
                    return true
                }else{
                    return false
                }
            },
            redirectToProfile(user){
                this.goToProfile(user.username)
            },
            resetData(){
                this.next_request_uri=null
                this.curators=[]
            },
            reloadData(){
                this.resetData()
                this.infiniteId+=1
            },
            approveCurator(curator){
                let curator_id = curator.curator
                let curator_name = curator.name
                respondClubCuratorRequest(curator_id,{invite_request_state:2}).then(res=>{
                    this.$Notice.success({
                        desc: `${curator_name} is now a curator of ${this.club.club_name}`
                    });
                    this.reloadData()
                    this.$emit('onUpdateCurators',res.data.curators)
                })
            },
            removeCurator(curator){
                let curator_id = curator.curator
                let curator_name = curator.name
                removeClubCurator(curator_id).then(res=>{
                    this.$Notice.success({
                         desc: `${curator_name} is removed as a curator of ${this.club.club_name}`
                    });
                    this.reloadData()
                    this.$emit('onUpdateCurators',res.data.curators)
                })
            },
            getAllCuratorsAndRequest($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                getCuratorsAndRequests(this.club.club,queryParams).then(res=>{
                    this.next_request_uri = res.data.next
                    this.curators=this.curators.concat(res.data.results)
                    this.dataloading=false
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
        },
        mounted() {
        }
    }
</script>

<style scoped></style>

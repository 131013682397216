
<template>
    <div class="ownedclubs-content">
        <div v-if="!showNoData">
            <div class="display-grid clubs-grid-list">
                <club-card v-for="(club,i) in clubs" :key="i" :club="club" @manageCurators="manageCurators" @inviteCurators="inviteCurators"></club-card>
                 <!-- to fill empty space (max 4 colums) -->
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
                <div class="space-filler"></div>
            </div>
        </div>
        <infinite-loading :identifier="infiniteId" @infinite="getClubs"></infinite-loading> 
        <ManageCuratorsModal ref="manageCuratorsModal" :club="selectedClub" @onSubmit="updateCurators" @showInviteCurators="$refs.inviteCuratorModal.openModal()"/>
        <InviteCuratorModal ref="inviteCuratorModal" @onSubmit="sendCuratorInvite" :club="selectedClub" :title="'Invite'" :submitText="'Confirm'"></InviteCuratorModal>
    </div>
</template>

<script>
    import { getOwnedCuratedClubs } from '@/services/upclub/clubs'
    import ClubCard from '@/components/clubs/ClubCard'
    import ManageCuratorsModal from '@/components/clubs/ManageCuratorsModal.vue';
    import InviteCuratorModal from '@/components/clubs/InviteCuratorModal';
    export default {
        props:['upclubuser'],
        data () {
            return {
                dataloading:false,
                next_request_uri:null,
                category:'mostpopular',
                clubs_count:null,
                clubs:[],
                selected_club:null,
                infiniteId:0,
            }
        },
        components: {ClubCard, ManageCuratorsModal, InviteCuratorModal},
        computed: {
            showNoData(){
                return !this.dataloading&&this.clubs_count===0
            },
            searchIsActive(){
                return this.searchstring&&this.searchstring.length>0?true:false
            },
            selectedClub(){
                return this.selected_club
            }
        },
        methods: {
            filterChanged(category){
                this.category=category
                this.dataloading=true
                this.next_request_uri=null
                this.clubs=[]
                this.infiniteId+=1
            },
            resetData(){
                this.next_request_uri=null
                this.clubs=[]
            },
            reloadData(){
                this.resetData()
                this.infiniteId+=1
            },
            async getClubs($state){
                let queryParams = {}
                if(this.next_request_uri){
                   queryParams = this.getUrlParams(this.next_request_uri)    
                }
                queryParams['upclubuser'] = this.upclubuser
                if(this.searchstring&&this.searchstring.length){
                    queryParams['searchstring'] = this.searchstring
                }
                if(this.category){
                    queryParams['category'] = this.category
                }
                getOwnedCuratedClubs(queryParams).then(res=>{
                    this.clubs_count=res.data.count
                    this.$emit('setDataCount',res.data.count)
                    this.next_request_uri = res.data.next
                    this.clubs=this.clubs.concat(res.data.results)
                    this.dataloading=false
                    this.$emit('setLoadingStatus',false)
                    if(this.next_request_uri){
                        $state.loaded();
                    }else{
                        $state.complete();
                    }
                })
            },
            updateClubData(data){
                console.log(data)
            },
            sendCuratorInvite(users){
                console.log(users)
            },
            updateCurators(data){
                console.log(data)
            },
            manageCurators(club){
                this.selected_club=club
                this.$refs.manageCuratorsModal.openModal()
            },
            inviteCurators(club){
                this.selected_club=club
                this.$refs.inviteCuratorModal.openModal()
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>



<template>
    <div class="notif-item flex-horizontal-centered">
        <div class="notif-sender-thumb">
            <img class="user-thumb-double" :src="getImage(notif.thumbnail_picture)"  @click="goToProfile(notif.username)" alt="">
            <div class="notif-icon flex-horizontal-centered">
                <img src="../../assets/images/icons/comment.svg" v-if="notif.notification_type==='comment' || notif.notification_type==='reply' || notif.notification_type==='mention_comment'" />
                <img src="../../assets/images/icons/plus.svg" v-if="notif.notification_type==='follow'" />
                <img src="../../assets/images/icons/up-vote.svg" v-if="notif.notification_type==='like'" />
                <img src="../../assets/images/icons/play.svg" v-if="notif.notification_type==='mention_songpost'" />
                <img src="../../assets/images/icons/club.svg" v-if="notif.notification_type==='curatorinvite'||notif.notification_type==='curatorrequest' || notif.notification_type==='clubfollow'" />
            </div>
        </div>
        <div class="notif-text-details flex-horizontal-centered">
            <div class="notif-desc">
                <a class="user-link" @click="goToProfile(notif.username)">{{notif.username}}</a>
                <div v-if="notif.notification_type==='comment'">
                    <div>
                        <p class="notif-text gray-text"> Commented on your <a class="gray-text" @click="showPost('comment',notif.post)">Post</a></p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                    <!-- <p :inner-html.prop="highlightMentions(notif.usercomment_text)"></p> -->
                    <p>{{notif.usercomment_text}}</p>
                </div>
                <div v-if="notif.notification_type==='reply'">
                    <div>
                        <p class="notif-text gray-text"> Replied to your comment in <a class="gray-text" @click="showPost('comment',notif.post)">Post</a></p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                    <!-- <p :inner-html.prop="highlightMentions(notif.usercomment_text)"></p> -->
                    <p>{{notif.usercomment_text}}</p>
                </div>
                <div v-if="notif.notification_type==='follow'">
                    <div>
                        <p class="notif-text gray-text"> Followed you</p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                </div>
                <div v-if="notif.notification_type==='like'">
                    <div>
                        <p class="notif-text gray-text"> Upvoted your <a class="gray-text" @click="showPost('play',notif.post)">Post</a></p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                </div>
                <div v-if="notif.notification_type==='mention_comment'">
                    <div>
                        <p class="notif-text gray-text"> Mentioned you in a comment</p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                    <!-- <p :inner-html.prop="highlightMentions(notif.usercomment_text)"></p> -->
                    <p>{{notif.usercomment_text}}</p>
                </div>
                <div v-if="notif.notification_type==='mention_songpost'">
                    <div>
                        <p class="notif-text gray-text"> Mentioned you in a post</p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                    <!-- <p :inner-html.prop="highlightMentions(notif.usercomment_text)"></p> -->
                    <p>{{notif.usercomment_text}}</p>
                </div>
                <div v-if="notif.notification_type==='curatorinvite'">
                    <div>
                        <p class="notif-text gray-text">invites you to <a @click="$router.push({name:'club_posts', params:{id:notif.club}})">{{notif.club_name}}</a></p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                </div>
                <div v-if="notif.notification_type==='curatorrequest'">
                    <div>
                        <p class="notif-text gray-text">wants to curate <a @click="$router.push({name:'club_posts', params:{id:notif.club}})">{{notif.club_name}}</a></p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                </div>
                <div v-if="notif.notification_type==='clubfollow'">
                    <div>
                        <p class="notif-text gray-text">started following <a @click="$router.push({name:'club_posts', params:{id:notif.club}})">{{notif.club_name}}</a></p>
                        <span  class="notif-age gray-text">{{notif.notification_age}}</span>
                    </div>
                </div>
            </div>
            <div class="notif-action">
                <div v-if="notif.notification_type==='follow'">
                    <Button size="small" @click="followUser()" class="black-btn btn" v-if="!notif.followed">Follow</Button>
                    <Button size="small" @click="unfollowUser()" class="gray-btn btn" v-else>Following</Button>
                </div>
                <div v-if="notif.notification_type==='curatorrequest'">
                    <Button size="small" class="gray-btn btn" v-if="notif.curatorinviterequeststate===2" @click="$router.push({name:'club_posts', params:{id:notif.club}})">Curating</Button>
                    <Button size="small" class="gray-btn btn" v-else-if="notif.curatorinviterequeststate===1" @click="$router.push({name:'club_posts', params:{id:notif.club}})">Declined</Button>
                    <Dropdown class="white-dropdown" transfer v-else>
                        <a href="javascript:void(0)">
                            <Button size="small" class="black-btn btn">Respond</Button>
                        </a>
                        <DropdownMenu slot="list"  class="white-box club-visitor-dropdown">
                            <DropdownItem @click.native="respondCuratorRequest(notif,2)">Accept</DropdownItem>
                            <DropdownItem @click.native="respondCuratorRequest(notif,1)" class="red-text">Decline</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div v-if="notif.notification_type==='curatorinvite'">
                    <Button size="small" class="gray-btn btn" v-if="notif.curatorinviterequeststate===2" @click="$router.push({name:'club_posts', params:{id:notif.club}})">Curator</Button>
                    <Button size="small" class="gray-btn btn" v-else-if="notif.curatorinviterequeststate===1" @click="$router.push({name:'club_posts', params:{id:notif.club}})">Declined</Button>
                    <Dropdown class="white-dropdown" transfer v-else>
                        <a href="javascript:void(0)">
                            <Button size="small" class="black-btn btn">Respond</Button>
                        </a>
                        <DropdownMenu slot="list"  class="white-box club-visitor-dropdown">
                            <DropdownItem @click.native="respondCuratorInvite(notif,2)">Accept</DropdownItem>
                            <DropdownItem @click.native="respondCuratorInvite(notif,1)" class="red-text">Decline</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div v-if="notif.notification_type==='clubfollow'">
                    <Button size="small" class="gray-btn btn" @click="$router.push({name:'club_posts', params:{id:notif.club}})">Visit</Button>
                </div>
                <div v-else @click="notif.notification_type==='like'||notif.notification_type==='mention_songpost'?showPost('play',notif.post):showPost('comment',notif.post)">
                    <img v-if="notif.post" :src="notif.post.track_details.album.images[2].url" alt="" class="notif-post-image">
                    <div v-else class="notif-post-blank"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { follow, unfollow } from '@/services/upclub/follow';
    import { respondToCuratorInvite, respondToCuratorRequest } from '@/services/upclub/clubs'
    import { mapGetters, mapActions } from "vuex";
    import {play} from '@/services/spotify/player'
    import { EventBus } from '@/eventBus';

    export default {
        props: ['notif'],
        data () {
            return {              
            }
        },
        computed: {
            ...mapGetters({
                currentPlayingPost: "upclubPlayer/getCurrentPlayingPost",
                getUserDetails: "upclubAuth/getUserDetails"
            })
        },
        methods: {
             ...mapActions({
                setCurrentPlayingPost: "upclubPlayer/setCurrentPlayingPost",
            }),
            getImage(image){
                if(image){
                    return "data:image/png;base64,"+image
                }else{
                    return ""
                }
            },
            followUser(){
                follow({upclubuser_followee:this.notif.upclubuser}).then(res=>{
                    this.notif.followed=true
                    this.$Notice.success({
                        desc: `You are now following ${this.notif.username}`
                    });
                    this.$emit('setIsFollowing',true)
                })
            },
            unfollowUser(){
                unfollow({upclubuser_followee:this.notif.upclubuser}).then(res=>{
                    this.notif.followed=false
                    this.$Notice.success({
                        desc: `You had just unfollowed ${this.notif.username}`
                    });
                    this.$emit('setIsFollowing',false)
                })
            },
            respondCuratorRequest(notif, state){
                respondToCuratorRequest(notif.curatorrequest,{invite_request_state:state}).then(res=>{
                    this.notif.curatorinviterequeststate=state
                    if(state===2){
                        this.$Notice.success({
                            desc: `You had just accepted ${notif.username}'s request to curate ${notif.club_name}`
                        });
                    }else{
                        this.$Notice.success({
                            desc: `You had just declined ${notif.username}'s request to curate ${notif.club_name}`
                        });
                    }
                })
            },
            respondCuratorInvite(notif,state){
                respondToCuratorInvite(notif.curatorinvite,{invite_request_state:state}).then(res=>{
                    this.notif.curatorinviterequeststate=state
                    if(state===2){
                        this.$Notice.success({
                            desc: `You are now a curator of club ${notif.club_name}`
                        });
                    }else{
                        this.$Notice.success({
                            desc: `You had just declined ${notif.username}'s invite to curate ${notif.club_name}`
                        });
                    }
                })
            },
            highlightMentions(text){
                return text.replace(new RegExp(`@${this.getUserDetails.username}`, 'g'),`<b>@${this.getUserDetails.username}</b>`)
            },
            showPost(type,posttrack){
                if(posttrack){
                    let index=0
                    if(this.currentPlayingPost && this.currentPlayingPost.index){
                        index = this.currentPlayingPost.index
                    }
                    if(type==='play'){
                        let data ={
                            offset: {
                                position: 0
                            },
                            uris: [posttrack.track_details.uri],
                            position_ms: 0
                        }
                        play(data).then(res=>{
                            // localStorage.setItem('current_track_page', this.$route.name)
                            // localStorage.setItem('current_track_list', JSON.stringify([posttrack]))
                            this.setCurrentPlayingPost({index:index, post:posttrack})
                            EventBus.$emit('OPEN_PLAYER', type, posttrack);
                            this.spinShow=false
                        })
                    }else{
                        EventBus.$emit('OPEN_PLAYER', type, posttrack);
                    }
                }else{
                    this.$Notice.error({
                        desc: "Post had been removed."
                    });
                }
                
            },
         
            
        },
        mounted() {
           
        }
    }
</script>

<style scoped>
</style>

<template>
    <div class="clubs-content">
        <page-header>
            <div slot="details" class="header-details" v-if="$route.name==='all_clubs'||$route.name==='my_clubs'">
                <a @click="redirect('all_clubs')" class="header-title" :class="$route.name==='all_clubs'?'active':''" >All Clubs</a>
                <a @click="redirect('my_clubs')" class="header-title" :class="$route.name==='my_clubs'?'active':''">My Clubs</a>
            </div>
            <div slot="details" class="header-details" v-else>
                <div class="header-title flex-centered-content">
                    <a @click="redirect('my_clubs')"><img src="../../assets/images/icons/chevron-left.svg" class="back-arrow"></a>
                    <p v-if="$route.name==='owned_clubs'" class="cursor-pointer" @click="redirect('my_clubs')">Owner & Curator</p>
                    <p v-if="$route.name==='followed_clubs'" class="cursor-pointer" @click="redirect('my_clubs')">Following</p>
                </div>
            </div>
            <Button v-if="$route.name!=='followed_clubs'" shape="circle" slot="middle-content" class="create-club-button round-trigger extra white-dropdown flex-column-centered" @click="openClubForm">
                <span>Create Club</span>
                <img src="../../assets/images/icons/plus.svg" alt="">
            </Button>
        </page-header>
        <div class="clubs-listing">
            <div class="page-filter-fields" v-show="!showNoData||searchIsActive">
                <div class="flex-horizontal-centered flex-gap-16">
                    <i-input v-model="searchstring" @on-change="getSearchResults($event)" placeholder="Find club" class="search-input">
                        <Icon type="ios-search" slot="prefix" />
                    </i-input>
                    <Dropdown class="white-dropdown" transfer>
                        <a href="javascript:void(0)">
                            <div class="sort-filter">
                            <img src="../../assets/images/icons/sort.svg" class="sort-icon">
                            </div>
                        </a>
                        <DropdownMenu slot="list"  class="white-box club-visitor-dropdown">
                            <DropdownItem disabled class="dropdown-label">Sort Feed By:</DropdownItem>
                            <DropdownItem v-for="(category_option,i) in category_options" :key="i" @click.native="filterChanged(category_option.value)" :class="category_option.value===category?'active':''">{{category_option.label}}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            <div v-if="showNoData" class="clubs-empty" :class="!searchIsActive?'flex-centered-content':''">
                <div v-if="searchIsActive" class="no-data">No Clubs Found</div>
                <div v-else>
                    <h1>Oops! There’s nothing here yet!</h1>
                    <h3>Create or find clubs using the buttons below.</h3>
                    
                    <Button size="large" class="darkgray-btn btn" @click="openClubForm">Create a Club</Button>
                    <Button size="large" class="black-btn btn" @click="$router.push({name:'search'})">Find Clubs</Button>
                </div>
            </div>
            <router-view ref="pageView" :key="routeviewKey" :searchstring="searchstring" :category="category" @openClubForm="openClubForm" @manageCurators="manageCurators" @inviteCurators="inviteCurators" @setDataCount="setDataCount" @setLoadingStatus="setLoadingStatus" @resetFilters="resetFilters"></router-view>
        </div>
        <CreateClubModalVue ref="createClubForm" @onSubmit="onClubCreate"></CreateClubModalVue>
        <ManageCuratorsModal ref="manageCuratorsModal" :club="selectedClub" @onSubmit="updateCurators" @showInviteCurators="$refs.inviteCuratorModal.openModal()"/>
        <InviteCuratorModal ref="inviteCuratorModal" @onSubmit="sendCuratorInvite" :club="selectedClub" :title="'Invite'" :submitText="'Confirm'"></InviteCuratorModal>
    </div>
</template>

<script>
    import PageHeader from '@/layout/PageHeader'
    import CreateClubModalVue from '@/components/clubs/CreateClubModal.vue'
    import ManageCuratorsModal from '@/components/clubs/ManageCuratorsModal.vue';
    import InviteCuratorModal from '@/components/clubs/InviteCuratorModal';
    export default {
        data () {
            return {
                routeviewKey:0,
                category_options: [
                    {value:"alphabetical",label:"Alphabetical"},
                    {value:"recentlyadded",label:"Recently Added"},
                    {value:"mostpopular",label:"Most Popular"},
                ],
                clubs_count:null,
                selected_club:null,
                idletimer:null,
                searchstring: null,
                category: 'mostpopular',
                dataloading:false,
            }
        },
        components: {PageHeader,CreateClubModalVue,ManageCuratorsModal,InviteCuratorModal},
        computed: {
            showNoData(){
                return !this.dataloading&&this.clubs_count===0
            },
            searchIsActive(){
                return this.searchstring&&this.searchstring.length>0?true:false
            },
            selectedClub(){
                return this.selected_club
            }
        },
        methods: {
            redirect(route){
                this.routeviewKey+=1
                this.$router.push({name:route})
                // this.$refs.pageView.routeChanged(route)
            },
            openClubForm(){
                this.$refs.createClubForm.openModal()
            },
            onClubCreate(data){
                // this.$refs.pageView.reloadData()
                this.$router.push({name:'club_posts', params:{id:data.club}, query:{state:'new'}})
            },
            getSearchResults(){
                let vm = this
                clearTimeout(vm.idletimer);
                vm.idletimer = setTimeout(()=>{ 
                    vm.$refs.pageView.getResults()
                },600)
            },
            filterChanged(category){
                this.category=category
                this.getSearchResults()
            },
            setDataCount(count){
                this.clubs_count=count
            },
            setLoadingStatus(status){
                this.dataloading=status
            },
            resetFilters(){
                this.searchstring= null
                this.category= 'mostpopular'
            },
            updateClubData(data){
                console.log(data)
            },
            sendCuratorInvite(users){
                console.log(users)
            },
            updateCurators(data){
                console.log(data)
            },
            manageCurators(club){
                this.selected_club=club
                this.$refs.manageCuratorsModal.openModal()
            },
            inviteCurators(club){
                this.selected_club=club
                this.$refs.inviteCuratorModal.openModal()
            }
        },
        mounted() {
            
        }
    }
</script>

<style scoped>
/* .create-club-button img{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 20px;
    top:-2px;
    margin-left: 8px;
} */
</style>
